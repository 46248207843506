import React from 'react';
import { Box, Button, Grid } from '@mui/material';
import { CaptureContext, MetricRecordEntities } from '../../../@types/shared';
import { FeedbackSnackbarContext } from '../../../context/FeedbackSnackbarContext';
import { LoadingButton } from '@mui/lab';
import { API_ENDPOINT, GCP_FILE_UPLOAD_BUCKET, CLOUD_FUNCTION_URL } from '../../../env';
import { MuiFileInput } from 'mui-file-input';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import ImportConfirmModal from './ImportConfirmModal';
import { generateUuidV4 } from '../../../util/uuid';
import { generateFileUploadRequest } from '../../../lib/file_upload/uploadRequestPrep';
import axios from 'axios';
import {
  Company,
  Division,
  EmissionFactor,
  Group,
  ImportMetaData,
  Region,
  ReportingPeriod,
  Site,
  Subdivision
} from '@esg/esg-global-types';
import {
  createPendingImport,
  generateImportListener,
  getImports
} from '../../../lib/metric_capture/import';
import { UserContext } from '../../../context/UserContext';
import ImportsGrid from './ImportsGrid';
import { DocumentData } from 'firebase/firestore';
import { GroupContext } from '../../../context/GroupContext';
import { CompanyContext } from '../../../context/CompanyContext';
import { getEmissionFactorForReportingPeriod } from '../../../lib/app/emission_factor';
import { MetadataError } from '@ep/error-handling';
import { uuidv4 } from '@firebase/util';
import { log } from '../../../util/log';
import { reportingPeriodFilterByGroup } from '../../../lib/metric_capture/reporting_period';
import { MetricExtended } from '../../../lib/metric_capture/metric';

interface MappedTemplateMetricData {
  metric: string;
  unit: string;
}

interface MappedTemplateSiteData {
  site: string;
  subdivision: string;
  division: string;
}

interface TemplateEntityData {
  metrics: Array<MappedTemplateMetricData>;
  sites: Array<MappedTemplateSiteData>;
  reporting_period?: string;
  reporting_period_group?: string;
}

interface MappedImportMetricData {
  [metric_name: string]: {
    id: string;
    group_id: string;
    emission_factor_id?: string;
    emission_factors?: { [reporting_period_name: string]: string };
  };
}

interface MappedImportSiteData {
  [site_name: string]: {
    id: string;
    region_id: string;
    region_name: string;
    subdivision_id: string;
    subdivision_name: string;
    division_id: string;
    division_name: string;
  };
}

interface ImportEntityData {
  metrics: MappedImportMetricData;
  sites: MappedImportSiteData;
}

interface ImportPubsubMessage {
  id: string;
  group: string;
  company: string;
  external_company: string | null;
  reporting_period?: string;
  reporting_period_group?: string;
  standard: string;
  file_name: string;
  user: string;
}

/**
 * Wrapper component of metric record importing functionality
 * @param {CaptureContext} capture_context Context of metric records to import
 * @param {MetricRecordEntities} capture_entities List of Metric Record entity data to be used by import and template functions
 * @returns {JSX.Element}
 */
const ImportsHub = ({
  capture_context,
  capture_entities
}: {
  capture_context: CaptureContext;
  capture_entities: MetricRecordEntities;
}) => {
  const { setFeedbackData } = React.useContext(FeedbackSnackbarContext);
  const user = React.useContext(UserContext);
  const group: Group | null = React.useContext(GroupContext);
  const company: Company | null = React.useContext(CompanyContext);
  const [importFile, setImportFile] = React.useState<File | null>(null);
  const [importRows, setImportRows] = React.useState<Array<ImportMetaData>>([]);
  const [showConfirmModal, setShowConfirmModal] = React.useState<boolean>(false);
  const [loadingTemplate, setLoadingTemplate] = React.useState<boolean>(false);
  const [loadingGrid, setLoadingGrid] = React.useState<boolean>(false);

  let view_only: boolean;

  if (capture_context.reporting_period?.id !== 'all') {
    view_only = capture_context.reporting_period?.locked ? true : false;
  } else {
    view_only = reportingPeriodFilterByGroup(
      capture_context.reporting_period_group?.id ?? 'all',
      capture_entities.reporting_periods
    ).some((reporting_period) => reporting_period.locked === true);
  }

  const standard_metrics: Array<MetricExtended> = capture_entities.metrics.filter(
    (metric: MetricExtended) => metric.standard.id === capture_context.standard?.id
  );

  const valid_input: boolean = importFile ? true : false;

  const handleFileChange = (new_file: File | null): void => {
    setImportFile(new_file);
    if (new_file === null) return;
  };

  const fetchImportsForGrid = async (): Promise<void> => {
    try {
      setLoadingGrid(true);
      if (group && company && capture_context.reporting_period_group) {
        if (capture_context.reporting_period && capture_context.reporting_period.id !== 'all') {
          const imports: Array<ImportMetaData> = await getImports(
            group.id,
            company.id,
            'period',
            capture_context.reporting_period.id,
            capture_context.external_company ? capture_context.external_company.id : null
          ).catch((error) => {
            throw new Error(error);
          });
          setImportRows(imports);
        } else {
          const imports: Array<ImportMetaData> = await getImports(
            group.id,
            company.id,
            'group',
            capture_context.reporting_period_group.id,
            capture_context.external_company ? capture_context.external_company.id : null
          ).catch((error) => {
            throw new Error(error);
          });
          setImportRows(imports);
        }
      }
    } catch (err: unknown) {
      const tracking_id: string = uuidv4();
      log(
        'error',
        new MetadataError(
          err instanceof Error
            ? err.message
            : 'Error: ImportsHub failed on an unknown error while calling fethImportsForGrid.',
          {
            group: group,
            company: company,
            capture_context: capture_context
          },
          tracking_id
        )
      );
      setFeedbackData({
        message: `Unable to fetch imports. Tracking ID: ${tracking_id}`,
        state: true,
        type: 'error'
      });
    } finally {
      setLoadingGrid(false);
    }
  };

  const removeImport = (import_id: string): void => {
    setImportRows(importRows.filter((import_row) => import_row.id !== import_id));
  };

  const downloadTemplateHandler = async (): Promise<void> => {
    try {
      setLoadingTemplate(true);
      if (company && capture_context.reporting_period_group) {
        const mapped_entities: TemplateEntityData = processCaptureEntitiesForTemplate();
        const data_object: TemplateEntityData =
          capture_context.reporting_period && capture_context.reporting_period.id !== 'all'
            ? {
                sites: mapped_entities.sites,
                metrics: mapped_entities.metrics,
                reporting_period: capture_context.reporting_period.name
              }
            : {
                sites: mapped_entities.sites,
                metrics: mapped_entities.metrics,
                reporting_period_group: capture_context.reporting_period_group.name
              };
        const response = await axios({
          method: 'post',
          url: `${CLOUD_FUNCTION_URL}/generateImportTemplate`,
          data: data_object
        }).catch((error) => {
          throw error.response;
        });
        if (response) {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute(
            'download',
            `${company.id}_${capture_context.reporting_period && capture_context.reporting_period.id !== 'all' ? capture_context.reporting_period.name.replace(/ /g, '_').toLowerCase() : capture_context.reporting_period_group.name.replace(/ /g, '_').toLowerCase()}_import.csv`
          );
          document.body.appendChild(link);
          link.click();
          link.remove();
          window.URL.revokeObjectURL(url);
        }
      }
    } catch (err: unknown) {
      const tracking_id: string = uuidv4();
      log(
        'error',
        new MetadataError(
          err instanceof Error
            ? err.message
            : 'Error: ImportsHub failed on an unknown error while calling downloadTemplateHandler.',
          {
            company: company,
            capture_context: capture_context
          },
          tracking_id
        )
      );
      setFeedbackData({
        message: `Unable to download import template. Tracking ID: ${tracking_id}`,
        state: true,
        type: 'error'
      });
    } finally {
      setLoadingTemplate(false);
    }
  };

  const processCaptureEntitiesForTemplate = (): TemplateEntityData => {
    const mapped_sites: Array<MappedTemplateSiteData> = capture_entities.sites.map((site: Site) => {
      const site_subdivision: Subdivision | undefined = capture_entities.subdivisions.find(
        (subdivision) => subdivision.id === site.subdivision.id
      );
      const site_division: Division | undefined = capture_entities.divisions.find(
        (division) => division.id === site_subdivision?.division.id
      );
      return {
        site: site.name,
        division: site_division?.name ?? '',
        subdivision: site_subdivision?.name ?? ''
      };
    });
    const mapped_metrics: Array<MappedTemplateMetricData> = standard_metrics.map(
      (metric: MetricExtended) => {
        return {
          metric: `${metric.metric_group.name}: ${metric.name}`,
          unit: metric.unit ? metric.unit : ''
        };
      }
    );
    return {
      sites: mapped_sites,
      metrics: mapped_metrics
    };
  };

  const processCaptureEntitiesForImport = async (): Promise<ImportEntityData> => {
    const import_site_data: MappedImportSiteData = capture_entities.sites.reduce(
      (import_site_data: MappedImportSiteData, site: Site) => {
        const site_region: Region | undefined = capture_entities.regions.find(
          (region: Region) => region.id === site.region.id
        );
        const site_subdivision: Subdivision | undefined = capture_entities.subdivisions.find(
          (subdivision: Subdivision) => subdivision.id === site.subdivision.id
        );
        const site_division: Division | undefined = capture_entities.divisions.find(
          (division: Division) => division.id === site_subdivision?.division.id
        );
        import_site_data[site.name.toLowerCase()] = {
          id: site.id,
          region_id: site.region.id,
          region_name: site_region?.name.toLowerCase() ?? '',
          subdivision_id: site_subdivision?.id ?? '',
          subdivision_name: site_subdivision?.name.toLowerCase() ?? '',
          division_id: site_division?.id ?? '',
          division_name: site_division?.name.toLowerCase() ?? ''
        };
        return import_site_data;
      },
      {}
    );
    const filtered_reporting_periods: Array<ReportingPeriod> = reportingPeriodFilterByGroup(
      capture_context.reporting_period_group?.id ?? 'all',
      capture_entities.reporting_periods
    );
    const metric_promises: Promise<Array<MappedImportMetricData>> = Promise.all(
      standard_metrics.map(async (metric: MetricExtended) => {
        if (group && company && capture_context.reporting_period_group) {
          if (capture_context.reporting_period && capture_context.reporting_period.id !== 'all') {
            const emission_factor: EmissionFactor | null =
              await getEmissionFactorForReportingPeriod(
                group.id,
                company.id,
                metric.metric_group.id,
                metric.id,
                capture_context.reporting_period
              );
            return {
              [metric.name.toLowerCase()]: {
                id: metric.id,
                group_id: metric.metric_group.id,
                emission_factor_id: emission_factor ? emission_factor.id : '',
                scope: capture_context.external_company ? 3 : metric.scope
              }
            };
          } else {
            const mapped_factors: { [reporting_period_name: string]: string } = {};
            for (const reporting_period of filtered_reporting_periods) {
              const emission_factor: EmissionFactor | null =
                await getEmissionFactorForReportingPeriod(
                  group.id,
                  company.id,
                  metric.metric_group.id,
                  metric.id,
                  reporting_period
                );
              if (emission_factor !== null) {
                mapped_factors[reporting_period.name] = emission_factor.id;
              }
            }
            return {
              [metric.name.toLowerCase()]: {
                id: metric.id,
                group_id: metric.metric_group.id,
                emission_factors: mapped_factors,
                scope: capture_context.external_company ? 3 : metric.scope
              }
            };
          }
        } else return {};
      })
    );
    const mapped_metrics_array: Array<MappedImportMetricData> = await metric_promises;
    const import_metric_data: MappedImportMetricData = mapped_metrics_array.reduce(
      (metric_map: MappedImportMetricData, next_metric: MappedImportMetricData) => {
        metric_map = { ...metric_map, ...next_metric };
        return metric_map;
      },
      {}
    );
    const mapped_entities: ImportEntityData = {
      sites: import_site_data,
      metrics: import_metric_data
    };
    return mapped_entities;
  };

  const updateImportEvent = (import_data: DocumentData | undefined): void => {
    if (import_data) {
      const filtered_import_rows: Array<ImportMetaData> = importRows.filter(
        (import_row: ImportMetaData) => import_row.id !== import_data.id
      );
      const has_errors: boolean =
        import_data.errors.sites.length > 0 ||
        import_data.errors.metrics.length > 0 ||
        import_data.errors.values.length > 0 ||
        import_data.errors.proportions.length > 0 ||
        import_data.errors.notes.length > 0 ||
        import_data.errors.bannedCharacters.length > 0;

      let updated_import: ImportMetaData = {
        id: import_data.id,
        file_name: import_data.file_name,
        errors: import_data.errors,
        reporting_period: null,
        reporting_period_group: null,
        external_company: import_data.external_company,
        valid_metric_records: import_data.valid_metric_records,
        invalid_metric_records: import_data.invalid_metric_records,
        null_value_records: import_data.null_value_records,
        user: import_data.user,
        created: import_data.created.toDate(),
        deleted: import_data.deleted,
        pending: import_data.pending
      };

      updated_import = capture_context.reporting_period
        ? {
            ...updated_import,
            reporting_period: import_data.reporting_period
          }
        : {
            ...updated_import,
            reporting_period_group: import_data.reporting_period_group
          };

      setImportRows([updated_import, ...filtered_import_rows]);
      setFeedbackData({
        message: `Metric Record Import: ${import_data.id} Completed${has_errors ? ' with Errors' : ''}.`,
        state: true,
        type: has_errors ? 'warning' : 'success'
      });
    }
  };

  const handleImport = async (): Promise<void> => {
    try {
      if (
        group &&
        company &&
        capture_context.reporting_period_group &&
        importFile &&
        user &&
        capture_context.standard
      ) {
        const uuid: string = generateUuidV4();
        const import_entity_data: ImportEntityData = await processCaptureEntitiesForImport();
        const mapping_file: File = new File([JSON.stringify(import_entity_data)], 'mapping.json', {
          type: 'application/json'
        });
        const import_file_upload: FormData = generateFileUploadRequest(
          importFile,
          'metric_records',
          atob(GCP_FILE_UPLOAD_BUCKET),
          `${group.id}/${company.id}/imports/${uuid}`,
          'false'
        );
        const mapping_file_upload: FormData = generateFileUploadRequest(
          mapping_file,
          'mapping',
          atob(GCP_FILE_UPLOAD_BUCKET),
          `${group.id}/${company.id}/imports/${uuid}`,
          'false'
        );
        const pubsub_template: ImportPubsubMessage = {
          id: uuid,
          group: group.id,
          company: company.id,
          external_company: capture_context.external_company
            ? capture_context.external_company.id
            : null,
          standard: capture_context.standard.id,
          file_name: importFile.name,
          user: user.email
        };

        const pubsub_message: ImportPubsubMessage =
          capture_context.reporting_period && capture_context.reporting_period.id !== 'all'
            ? {
                ...pubsub_template,
                reporting_period: capture_context.reporting_period.id
              }
            : {
                ...pubsub_template,
                reporting_period_group: capture_context.reporting_period_group?.id
              };

        await axios({
          method: 'post',
          url: `${API_ENDPOINT}/upload_file`,
          data: mapping_file_upload
        });
        await axios({
          method: 'post',
          url: `${API_ENDPOINT}/upload_file`,
          data: import_file_upload
        });
        if (capture_context.reporting_period) {
          await createPendingImport(
            group.id,
            company.id,
            uuid,
            'period',
            capture_context.reporting_period.id,
            user.email,
            importFile.name
          );
        } else {
          await createPendingImport(
            group.id,
            company.id,
            uuid,
            'group',
            capture_context.reporting_period_group.id,
            user.email,
            importFile.name
          );
        }
        await generateImportListener(group.id, company.id, uuid, updateImportEvent);
        await axios({
          method: 'post',
          url: `${API_ENDPOINT}/send_data_upload_request`,
          data: pubsub_message
        });
        const new_import: ImportMetaData = {
          id: uuid,
          file_name: importFile.name,
          created: new Date(),
          user: user.email,
          errors: {
            sites: [],
            metrics: [],
            values: [],
            proportions: [],
            notes: [],
            bannedCharacters: []
          },
          valid_metric_records: 0,
          invalid_metric_records: 0,
          null_value_records: 0,
          reporting_period_group: null,
          reporting_period: null,
          external_company: null,
          deleted: null,
          pending: true
        };
        setImportRows([new_import, ...importRows]);
        setFeedbackData({
          message: `Metric Record Import started successfully, tracking ID: ${uuid}`,
          state: true,
          type: 'success'
        });
      }
    } catch (err: unknown) {
      const tracking_id: string = uuidv4();
      log(
        'error',
        new MetadataError(
          err instanceof Error
            ? err.message
            : 'Error: ImportsHub failed on an unknown error while calling handleImport.',
          {
            group: group,
            company: company,
            importFile: importFile,
            user: user,
            capture_context: capture_context
          },
          tracking_id
        )
      );
      setFeedbackData({
        message: `Unable to import metric records. Tracking ID: ${tracking_id}`,
        state: true,
        type: 'error'
      });
    }
  };

  React.useEffect(() => {
    try {
      (async () => {
        fetchImportsForGrid();
      })().catch((error) => {
        throw new Error(error);
      });
    } catch (err: unknown) {
      const tracking_id: string = uuidv4();
      log(
        'error',
        new MetadataError(
          err instanceof Error
            ? err.message
            : 'Error: ImportsHub failed on an unknown error while initialising.',
          null,
          tracking_id
        )
      );
      setFeedbackData({
        message: `An error occurred. Tracking ID: ${tracking_id}`,
        state: true,
        type: 'error'
      });
    }
    return;
  }, []);

  return (
    <>
      <ImportConfirmModal
        open={showConfirmModal}
        import_file={importFile}
        capture_level={
          capture_context.reporting_period && capture_context.reporting_period.id !== 'all'
            ? 'period'
            : 'group'
        }
        label={
          capture_context.reporting_period?.name ??
          capture_context.reporting_period_group?.name ??
          ''
        }
        handleCloseImportModal={() => setShowConfirmModal(false)}
        handleImport={() => handleImport()}
      />
      <Box>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <LoadingButton
              disabled={view_only}
              loading={loadingTemplate}
              onClick={downloadTemplateHandler}
              startIcon={<FileDownloadIcon />}
              sx={{ marginY: 2, alignSelf: 'flex-end' }}
            >
              Download Template
            </LoadingButton>
          </Grid>
          <Grid item xs={4}>
            <MuiFileInput
              value={importFile}
              onChange={handleFileChange}
              title="Choose data file"
              label="Data File"
              inputProps={{ accept: '.csv' }}
              disabled={view_only}
            />
          </Grid>
          <Grid item xs={4}>
            <Button
              disabled={!valid_input}
              onClick={() => setShowConfirmModal(true)}
              variant="outlined"
              startIcon={<ImportExportIcon />}
              sx={{
                my: 2
              }}
            >
              Import Data
            </Button>
          </Grid>
        </Grid>
        {capture_context.reporting_period_group && (
          <Box sx={{ mt: 4 }}>
            <ImportsGrid
              import_rows={importRows}
              capture_context={capture_context}
              view_only={view_only}
              grid_loading={loadingGrid}
              handleRemoveImport={removeImport}
              refreshImports={fetchImportsForGrid}
            />
          </Box>
        )}
      </Box>
    </>
  );
};

export default ImportsHub;
