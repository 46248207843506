import {
  collection,
  QuerySnapshot,
  doc,
  updateDoc,
  deleteField,
  QueryDocumentSnapshot,
  DocumentData
} from 'firebase/firestore';
import { refCompanyDoc } from '../app/company';
import { Country, EntityLabel, MetricRecord, Region, ReportingPeriod } from '@esg/esg-global-types';
import { createCountries, getCountries } from './country';
import { db } from '../google/firebase';
import { createAuditLog } from '../app/audit';
import {
  BatchWrite,
  deleteFirestoreDoc,
  processBatchWrites,
  readFirestoreDocs,
  updateFirestoreDoc
} from '../app/db_util';
import { getReportingPeriods } from './reporting_period';
import { getMetricRecords } from './metric_record';
import { FirestoreQueryParam } from '../../@types/shared';
import { DocumentReference } from 'firebase/firestore';
import { uuidv4 } from '@firebase/util';
import { MetadataError } from '@ep/error-handling';
import { validateMasterListParams } from '../../util/validation';

export type RegionData = Omit<Region, 'id'>;

export interface RegionExtended extends Region {
  country: Country;
}

// Singular and plural label for model entity.
export const region_label: EntityLabel = {
  one: 'Region',
  many: 'Regions'
};

/**
 * Create a collection path string for the master list regions or configured regions.
 * @param {boolean} master_list Reference the master list collection.
 * @param {string} group_id When not referencing a master list collection, a group id for the configured group is required.
 * @param {string} company_id When not referencing a master list collection, a company id for the configured company is required.
 * @returns {string} Path to a countries collection.
 */
export const createRegionCollectionPath = (
  master_list: boolean,
  group_id?: string,
  company_id?: string
): string => {
  try {
    validateMasterListParams(master_list, group_id, company_id);
    return master_list
      ? `/region_master_list`
      : `groups/${group_id}/companies/${company_id}/regions`;
  } catch (err: unknown) {
    const tracking_id: string = uuidv4();
    throw new MetadataError(
      err instanceof Error
        ? err.message
        : 'Error: createRegionCollectionPath failed on an unknown error.',
      {
        master_list: master_list,
        group_id: group_id,
        company_id: company_id
      },
      tracking_id
    );
  }
};

/**
 * Query all region documents for master list or current portal company joined with relative region data
 * @param {boolean} master_list Return master list or configured region documents.
 * @param {string} group_id Group object of current portal company
 * @param {string} company_id id of current portal company to reference firestore documents
 * @param {string} id query a specific region by id.
 * @returns {Promise<Array<Region>>}
 */
export const getRegions = async (
  master_list: boolean,
  group_id?: string,
  company_id?: string,
  id?: string
): Promise<Array<Region>> => {
  try {
    const collection_path: string = createRegionCollectionPath(master_list, group_id, company_id);
    const query_params: Array<FirestoreQueryParam> = [
      { field_name: 'deleted', operator: '==', value: null }
    ];
    if (id !== undefined && id.length > 0)
      query_params.push({ field_name: 'id', operator: '==', value: id });
    const region_snapshot: QuerySnapshot = await readFirestoreDocs(collection_path, query_params);
    const regions: Array<Region> = region_snapshot.docs.map((region: QueryDocumentSnapshot) => {
      const region_data: DocumentData = region.data();
      return {
        ...{
          id: region.id,
          deleted: region_data.deleted,
          name: region_data.name,
          country: region_data.country,
          reference: region.ref
        }
      };
    });
    return regions;
  } catch (err: unknown) {
    const tracking_id: string = uuidv4();
    throw new MetadataError(
      err instanceof Error ? err.message : 'Error: getRegions failed on an unknown error.',
      {
        master_list: master_list,
        group_id: group_id,
        company_id: company_id
      },
      tracking_id
    );
  }
};

/**
 * Query all region documents for master list or current portal company joined with relative region and country data
 * @param {boolean} master_list Return master list or configured region documents.
 * @param {string | undefined} group_id Group object of current portal company
 * @param {string | undefined} company_id id of current portal company to reference firestore documents
 * @returns {Array<RegionExtended>}
 */
export const getRegionJoinCountries = async (
  master_list: boolean,
  group_id?: string,
  company_id?: string
): Promise<Array<RegionExtended>> => {
  const regions_arr: Array<RegionExtended> = [];
  const regions_collection: string = createRegionCollectionPath(master_list, group_id, company_id);
  try {
    const countries: Array<Country> = await getCountries(master_list, group_id, company_id);
    const region_promises: Array<Promise<void>> = countries.map(async (country: Country) => {
      const query_params: Array<FirestoreQueryParam> = [
        { field_name: 'deleted', operator: '==', value: null },
        {
          field_name: 'country',
          operator: '==',
          value: master_list
            ? doc(db, `/country_master_list/${country.id}`)
            : doc(db, `/groups/${group_id}/companies/${company_id}/countries/${country.id}`)
        }
      ];
      const region_snapshot: QuerySnapshot = await readFirestoreDocs(
        regions_collection,
        query_params
      );
      region_snapshot.docs.forEach((region: QueryDocumentSnapshot) => {
        regions_arr.push({
          id: region.id,
          deleted: region.data().deleted,
          name: region.data().name,
          country: country,
          archived: region.data().archived
        });
      });
    });
    await Promise.all(region_promises);
    return regions_arr;
  } catch (err: unknown) {
    const tracking_id: string = uuidv4();
    throw new MetadataError(
      err instanceof Error
        ? err.message
        : 'Error: lib/metric_capture/region.ts failed on an unknown error while calling getRegionJoinCountries.',
      {
        master_list: master_list,
        group_id,
        company_id
      },
      tracking_id
    );
  }
};

/**
 * Create Region data in firestore on master list or company level.
 * @param {boolean} master_list Flag to create new Regions within master list or company level collection.
 * @param {Array<RegionExtended | RegionData>} regions list of new regions to be added to the database.
 * @param {string | undefined} group_id Optional ID of Group to create regions for.
 * @param {string | undefined} company_id Optional ID of Company to create regions for.
 * @returns {Promise<Array<BatchWrite>>}
 */
export const createRegions = async (
  master_list: boolean,
  regions: Array<RegionExtended | RegionData>,
  group_id?: string,
  company_id?: string
): Promise<Array<BatchWrite>> => {
  try {
    const master_list_countries: Array<Country> = await getCountries(true);
    const configured_countries: Array<Country> | undefined =
      !master_list && group_id && company_id
        ? await getCountries(master_list, group_id, company_id)
        : undefined;
    const region_writes: Array<BatchWrite> = [];

    for (const region of regions) {
      // Get Master List Country data for supplied Region.
      const master_list_country: Country | undefined = master_list_countries.find(
        (country: Country) => country.id === region.country.id
      );
      if (!master_list_country) {
        throw new Error('Cannot create Region for Country not in Database');
      }
      // Create configured Country for Region if not existing and creating on Company level.
      if (
        configured_countries &&
        !configured_countries.find((country: Country) => country.id === region.country.id)
      ) {
        await createCountries(false, [master_list_country], group_id, company_id);
        configured_countries.push(master_list_country);
      }
      const region_collection_path: string = createRegionCollectionPath(
        master_list,
        group_id,
        company_id
      );
      const region_id: string = `${region.country.id}_${region.name[0]}${region.name
        .substring(1)
        .match(/[^aeiou\d_\W]/gi)
        ?.join('')}`.toUpperCase();
      const regions_ref: DocumentReference = doc(
        collection(db, region_collection_path),
        'id' in region ? region.id : region_id
      );
      const region_data: RegionData = {
        deleted: region.deleted,
        name: region.name,
        country: doc(
          db,
          master_list
            ? `country_master_list/${region.country.id}`
            : `groups/${group_id}/companies/${company_id}/countries/${region.country.id}`
        )
      };
      region_writes.push({ reference: regions_ref, operation: 'create', data: region_data });
    }
    await processBatchWrites(region_writes);
    return region_writes;
  } catch (err: unknown) {
    const tracking_id: string = uuidv4();
    throw new MetadataError(
      err instanceof Error
        ? err.message
        : 'Error: lib/metric_capture/region.ts failed on an unknown error while calling createRegions.',
      {
        master_list: master_list,
        regions: regions,
        group_id,
        company_id
      },
      tracking_id
    );
  }
};

/**
 * Update region with relative data
 * @param {boolean} master_list Flag to update region in master list
 * @param {Region} updated_region New Region data to push into document
 * @param {Region} original_region Old Region data
 * @param {string} group_id Optional ID of Group to update region for
 * @param {string} company_id Optional ID of Company to update region for
 * @returns {Promise<void>}
 */
export const updateRegion = async (
  master_list: boolean,
  updated_region: Region,
  original_region: Region,
  group_id?: string,
  company_id?: string
): Promise<void> => {
  try {
    const collection_path = createRegionCollectionPath(master_list, group_id, company_id);
    const region_data: RegionData = {
      deleted: updated_region.deleted,
      name: updated_region.name,
      country: doc(
        db,
        master_list
          ? `country_master_list/${updated_region.country.id}`
          : `groups/${group_id}/companies/${company_id}/countries/${updated_region.country.id}`
      )
    };
    const updated_region_doc: DocumentReference = await updateFirestoreDoc(
      collection_path,
      updated_region.id,
      region_data
    );
    if (!master_list && group_id && company_id) {
      await createAuditLog(
        group_id,
        'update',
        JSON.stringify(original_region),
        JSON.stringify(region_data),
        updated_region_doc
      );
    }
  } catch (err: unknown) {
    const tracking_id: string = uuidv4();
    throw new MetadataError(
      err instanceof Error
        ? err.message
        : 'Error: lib/metric_capture/region.ts failed on an unknown error while calling updateRegion.',
      {
        group_id: group_id,
        company_id: company_id,
        updated_region: updated_region,
        original_region: original_region
      },
      tracking_id
    );
  }
};

/**
 * Soft delete a Region.
 * @param {string} group_id ID of Group to delete region for
 * @param {string} company_id ID of Company to delete region for
 * @param {string} region_id ID of region to delete
 * @param {string} region_group_id ID of region group to find delete region in
 * @returns {void}
 */
export const deleteRegion = async (
  master_list: boolean,
  region_id: string,
  region_name: string,
  group_id?: string,
  company_id?: string
): Promise<void> => {
  const collection_path = createRegionCollectionPath(master_list, group_id, company_id);
  try {
    const region_doc: DocumentReference = await deleteFirestoreDoc(collection_path, region_id);
    if (!master_list && group_id && company_id) {
      await createAuditLog(group_id, 'delete', region_name, '', region_doc);
    }
  } catch (err: unknown) {
    const tracking_id: string = uuidv4();
    throw new MetadataError(
      err instanceof Error
        ? err.message
        : 'Error: lib/metric_capture/region.ts failed on an unknown error while calling deleteRegion.',
      {
        master_list: master_list,
        group_id: group_id,
        company_id: company_id,
        region_id: region_id,
        region_name: region_name
      },
      tracking_id
    );
  }
};

/**
 * Function to archive company Region
 * @param {string} group_id ID of Group to archive region for
 * @param {string} company_id ID of Company to archive region for
 * @param {string} region_id ID of region to archive
 * @returns {void}
 */
export const archiveRegion = async (group_id: string, company_id: string, region_id: string) => {
  try {
    if (group_id && company_id && region_id) {
      const region_doc = doc(collection(refCompanyDoc(group_id, company_id), `regions`), region_id);
      await updateDoc(region_doc, {
        archived: new Date()
      });
      await createAuditLog(group_id, 'archive', '', '', region_doc);
    }
    return;
  } catch (err) {
    const error = `Error while archiving Region in Firebase: ${JSON.stringify({
      message: err instanceof Error ? err.message : '',
      stacktrace: err instanceof Error ? err.stack : ''
    })}`;
    throw new Error(`Error: archiveRegion: ${JSON.stringify(error)}.`);
  }
};

/**
 * Function to unarchive company Region
 * @param {string} group_id ID of Group to unarchive region for
 * @param {string} company_id ID of Company to unarchive region for
 * @param {string} region_id ID of region to unarchive
 * @returns {void}
 */
export const unarchiveRegion = async (group_id: string, company_id: string, region_id: string) => {
  try {
    if (group_id && company_id && region_id) {
      const region_doc = doc(collection(refCompanyDoc(group_id, company_id), `regions`), region_id);
      await updateDoc(region_doc, {
        archived: deleteField()
      });
      await createAuditLog(group_id, 'archive', 'archived', '', region_doc);
    }
    return;
  } catch (err) {
    const error = `Error while unarchiving Region in Firebase: ${JSON.stringify({
      message: err instanceof Error ? err.message : '',
      stacktrace: err instanceof Error ? err.stack : ''
    })}`;
    throw new Error(`Error: unarchiveRegion: ${JSON.stringify(error)}.`);
  }
};

/**
 * Function to check if a Region can be safely deleted
 * @param {string} group_id ID of Group to check Region for
 * @param {string} company_id ID of Company to check Region for
 * @param {string} region_id ID of region to check
 * @returns {boolean}
 */
export const allowDeleteRegion = async (
  group_id: string,
  company_id: string,
  region_id: string
) => {
  try {
    const reporting_periods = await getReportingPeriods(group_id, company_id, true);
    if (reporting_periods.length === 0) return true;
    const reporting_period_ids = reporting_periods.map(
      (reporting_period: ReportingPeriod) => reporting_period.id
    );
    const query_params: Array<FirestoreQueryParam> = [
      {
        field_name: 'region',
        operator: '==',
        value: doc(collection(refCompanyDoc(group_id, company_id), 'regions'), region_id)
      }
    ];
    const metric_records = await getMetricRecords(group_id, company_id, query_params);
    const allow_delete = !metric_records.some((metric_record: MetricRecord) => {
      if (reporting_period_ids.includes(metric_record.reporting_period?.id ?? '')) {
        return true;
      }
    });
    return allow_delete;
  } catch (err) {
    const error = `Error while checking Region for deletion: ${JSON.stringify({
      message: err instanceof Error ? err.message : '',
      stacktrace: err instanceof Error ? err.stack : ''
    })}`;
    throw new Error(`Error: allowDeleteRegion: ${JSON.stringify(error)}.`);
  }
};
