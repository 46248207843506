import { db } from '../google/firebase';
import {
  collection,
  doc,
  DocumentReference,
  CollectionReference,
  getDocs,
  getDoc,
  QuerySnapshot,
  query,
  Query,
  where,
  DocumentData,
  updateDoc
} from 'firebase/firestore';
import { User } from '@esg/esg-global-types';
import { log } from '../../util/log';
import { MetadataError } from '@ep/error-handling';
import { uuidv4 } from '@firebase/util';
import { updateFirestoreDoc } from '../app/db_util';

type UserData = Omit<User, 'id' | 'created'>;

/**
 * Function to query all user documents
 * @returns {Array<User>}
 */
export const getUsers = async (get_deleted: boolean) => {
  const users_collection: CollectionReference = collection(db, 'users');
  const users_query: Query = get_deleted
    ? query(users_collection)
    : query(users_collection, where('deleted', '==', null));
  try {
    const user_docs: QuerySnapshot = await getDocs(users_query).catch((error) => {
      throw new Error(error);
    });
    const users: Array<User> = user_docs.docs.map((user) => {
      const user_data: DocumentData = user.data();
      return {
        id: user.id,
        created: user_data.created,
        modified: user_data.modified,
        first_name: user_data.first_name,
        last_name: user_data.last_name,
        email: user_data.email,
        phone: user_data.phone,
        super_admin: user_data.super_admin,
        groups: user_data.groups,
        deleted: user_data.deleted,
        type: user_data.type,
        last_accessed: user_data.last_accessed
      };
    });
    return users;
  } catch (err) {
    const error = `Error while getting Users from Firebase: ${JSON.stringify({
      message: err instanceof Error ? err.message : '',
      stacktrace: err instanceof Error ? err.stack : '',
      variables: {
        companies_collection: users_collection,
        countries_query: users_query
      }
    })}`;
    throw new Error(`Error: getUsers: ${JSON.stringify(error)}.`);
  }
};

/**
 * Function to get full user document from firestore
 * @param {string} id ID of User to retrieve details for
 * @returns {User}
 */
export const getUserDetails = async (id: string) => {
  const user_collection: CollectionReference = collection(db, 'users');
  const user: DocumentReference = doc(user_collection, id);
  try {
    const user_doc: DocumentData = await getDoc(user).catch((error) => {
      throw new Error(error);
    });
    return {
      id: user_doc.id,
      ...user_doc.data()
    };
  } catch (err) {
    const error = `Error while getting User details from Firestore: ${JSON.stringify({
      message: err instanceof Error ? err.message : '',
      stacktrace: err instanceof Error ? err.stack : '',
      variables: {
        user_collection: user_collection,
        user: user
      }
    })}`;
    throw new Error(`Error: getUserDetails: ${JSON.stringify(error)}.`);
  }
};

/**
 * @param {string} user_id ID of user to update
 * @param {User} updated_user Data to update User document with
 */
export const updateUser = async (user_id: string, updated_user: User) => {
  try {
    const collection_path = `users`;
    const user_data: UserData = {
      first_name: updated_user.first_name,
      last_name: updated_user.last_name,
      super_admin: updated_user.super_admin,
      email: updated_user.email,
      phone: updated_user.phone,
      groups: updated_user.groups,
      type: updated_user.type || 'any',
      modified: new Date(),
      deleted: updated_user.deleted,
      last_accessed: updated_user.last_accessed
    };
    await updateFirestoreDoc(collection_path, user_id, user_data);
  } catch (err: unknown) {
    const tracking_id: string = uuidv4();
    throw new MetadataError(
      err instanceof Error
        ? err.message
        : 'Error: lib/metric_capture/user.ts failed on an unknown error while calling updateUser.',
      {
        user_id: user_id,
        updated_user: updated_user
      },
      tracking_id
    );
  }
};

/**
 * Function to undelete a user in firestore
 * @param {string} user_id ID of user to restore
 * @returns {void}
 */
export const restoreUser = async (user_id: string) => {
  const user_collection: CollectionReference = collection(db, 'users');
  const user_doc = doc(user_collection, user_id);
  try {
    await updateDoc(user_doc, {
      deleted: null,
      modified: new Date()
    });
  } catch (err: unknown) {
    const tracking_id: string = uuidv4();
    log(
      'error',
      new MetadataError(
        err instanceof Error
          ? err.message
          : 'Error: lib/user_management/users.ts failed on an unknown error while calling restoreUser.',
        {
          user_collection: user_collection,
          user_id: user_id
        },
        tracking_id
      )
    );
    throw new Error(`Error: updateUser`);
  }
};
