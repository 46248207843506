import { EntityLabel, Subscription } from '@esg/esg-global-types';
import { FirestoreQueryParam } from '../../@types/shared';
import {
  DocumentData,
  DocumentReference,
  QueryDocumentSnapshot,
  QuerySnapshot,
  doc,
  setDoc
} from 'firebase/firestore';
import { deleteFirestoreDoc, readFirestoreDocs, updateFirestoreDoc } from './db_util';
import { uuidv4 } from '@firebase/util';
import { MetadataError } from '@ep/error-handling';
import { db } from '../google/firebase';

export type SubscriptionData = Omit<Subscription, 'id'>;

// Singular and plural label for model entity.
export const subscription_label: EntityLabel = {
  one: 'Subscription',
  many: 'Subscriptions'
};

/**
 * Query all subscription documents for master list
 * @param {string} id Optional query for a specific subscription document
 * @returns {Promise<Array<Subscription>>}
 */
export const getSubscriptions = async (id?: string): Promise<Array<Subscription>> => {
  try {
    const collection_path = `subscription_master_list`;
    const query_params: Array<FirestoreQueryParam> = [
      { field_name: 'deleted', operator: '==', value: null }
    ];
    if (id !== undefined && id.length > 0)
      query_params.push({ field_name: 'id', operator: '==', value: id });
    const subscription_snapshot: QuerySnapshot = await readFirestoreDocs(
      collection_path,
      query_params
    );
    const subscriptions: Array<Subscription> = subscription_snapshot.docs.map(
      (subscription: QueryDocumentSnapshot) => {
        const subscription_data: DocumentData = subscription.data();
        return {
          id: subscription.id,
          deleted: subscription_data.deleted,
          name: subscription_data.name,
          price: subscription_data.price,
          user_limit: subscription_data.user_limit
        };
      }
    );
    return subscriptions;
  } catch (err: unknown) {
    const tracking_id: string = uuidv4();
    throw new MetadataError(
      err instanceof Error ? err.message : 'Error: getSubscriptions failed on an unknown error.',
      {
        id: id
      },
      tracking_id
    );
  }
};

/**
 * Create Subscription with relative data
 * @param {SubscriptionData} subscription Data of new Subscription to add to doc
 * @returns {DocumentReference}
 */
export const createSubscription = async (
  subscription: SubscriptionData
): Promise<DocumentReference> => {
  try {
    const subscription_id: string = subscription.name.replaceAll(' ', '_').toLowerCase();
    const subscription_doc: DocumentReference = doc(
      db,
      `subscription_master_list/${subscription_id}`
    );
    await setDoc(subscription_doc, subscription);
    return subscription_doc;
  } catch (err: unknown) {
    const tracking_id: string = uuidv4();
    throw new MetadataError(
      err instanceof Error
        ? err.message
        : 'Error: lib/metric_capture/subscription.ts failed on an unknown error while calling createSubscription.',
      {
        subscription: subscription
      },
      tracking_id
    );
  }
};

/**
 * Update Subscription with relative data
 * @param {Subscription} updated_subscription New subscription data to push into document
 * @returns {void}
 */
export const updateSubscription = async (updated_subscription: Subscription): Promise<void> => {
  try {
    const collection_path = 'subscription_master_list';
    const subscription_data: SubscriptionData = {
      deleted: updated_subscription.deleted,
      name: updated_subscription.name,
      price: Number(updated_subscription.price),
      user_limit: Number(updated_subscription.user_limit)
    };
    await updateFirestoreDoc(collection_path, updated_subscription.id, subscription_data);
  } catch (err: unknown) {
    const tracking_id: string = uuidv4();
    throw new MetadataError(
      err instanceof Error
        ? err.message
        : 'Error: lib/metric_capture/subscription.ts failed on an unknown error while calling updateSubscription.',
      {
        updated_subscription: updated_subscription
      },
      tracking_id
    );
  }
};

/**
 * Soft delete a Subscription.
 * @param {string} subscription_id ID of subscription to delete
 * @returns {Promise<void>}
 */
export const deleteSubscription = async (subscription_id: string): Promise<void> => {
  const collection_path = `subscription_master_list`;
  try {
    await deleteFirestoreDoc(collection_path, subscription_id);
  } catch (err: unknown) {
    const tracking_id: string = uuidv4();
    throw new MetadataError(
      err instanceof Error
        ? err.message
        : 'Error: lib/metric_capture/subscription.ts failed on an unknown error while calling deleteSubscription.',
      {
        subscription_id: subscription_id
      },
      tracking_id
    );
  }
};
