import React from 'react';
import { Checkbox, FormControlLabel } from '@mui/material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { getLatestEmissionFactorForMetrics } from '../../../../lib/app/emission_factor';
import { EmissionFactor } from '@esg/esg-global-types';
import { MetricExtended } from '../../../../lib/metric_capture/metric';

interface IncludeEmissionFactorCheckboxProps {
  handleChange?: (emission_factors: Array<EmissionFactor> | null) => void;
  metrics: Array<MetricExtended>;
  label?: string;
}

// Checkbox icons
const check_icon_blank = <CheckBoxOutlineBlankIcon fontSize="small" />;
const check_icon_filled = <CheckBoxIcon fontSize="small" />;

/**
 * A checkbox that returns the latest Emission Factors from the master list.
 * @param {Array<EmissionFactor> | any | null} props.handleChange Callback function to notify the parent component when the selected value have changed.
 * @param {Array<MetricExtended>} props.metrics List of Metrics for which the latest Emission Factor should be obtained from the master list.
 * @returns {JSX.Element}
 */
const IncludeLatestEmissionFactorCheckbox = (
  props: IncludeEmissionFactorCheckboxProps
): JSX.Element => {
  const [includeLatestEmissionFactors, setIncludeLatestEmissionFactors] =
    React.useState<boolean>(false);
  const [latestEmissionFactors, setLatestEmissionFactors] = React.useState<Array<EmissionFactor>>(
    []
  );
  const [lastSelectedMetricsCount, setLastSelectedMetricsCount] = React.useState<number>(0);
  React.useEffect(() => {
    (async () => {
      if (includeLatestEmissionFactors) {
        // On first load, fetch latest emission factors from database.
        if (latestEmissionFactors.length < 1 || props.metrics.length != lastSelectedMetricsCount) {
          const latest_emission_factors_db: Array<EmissionFactor> =
            await getLatestEmissionFactorForMetrics(true, props.metrics);
          setLatestEmissionFactors(latest_emission_factors_db);
          setLastSelectedMetricsCount(props.metrics.length);
          if (props.handleChange) props.handleChange(latest_emission_factors_db);
          // Set latest emission factors from cache.
        } else {
          if (props.handleChange) props.handleChange(latestEmissionFactors);
        }
      } else {
        if (props.handleChange) props.handleChange([]);
      }
    })();
  }, [props.metrics, includeLatestEmissionFactors]);

  return (
    <FormControlLabel
      control={
        <Checkbox
          icon={check_icon_blank}
          checkedIcon={check_icon_filled}
          checked={includeLatestEmissionFactors}
          color="primary"
          onChange={(event) => {
            setIncludeLatestEmissionFactors(event.target.checked);
          }}
        />
      }
      label="Include Latest Emission Factors"
    />
  );
};

export default IncludeLatestEmissionFactorCheckbox;
