import React from 'react';
import { Step } from 'react-joyride';

const skip_label = <strong aria-label="skip">Disable Tour</strong>;
const last_button = <strong aria-label="last">End Tour</strong>;

const usertype_select_step: Step = {
  target: '#user-type-select',
  content:
    'Select "Internal" if you are capturing on behalf on the main company, or "External" if you are capturing on behalf of a third-party supplier.',
  placement: 'top',
  placementBeacon: 'top',
  locale: { skip: skip_label }
};

const view_records_step: Step = {
  target: '#view-data-button',
  content: 'Click here to view records for the selections made.',
  placement: 'top',
  placementBeacon: 'top',
  locale: { skip: skip_label }
};

const reporting_period_group_step: Step = {
  target: '#reporting-period-group-select',
  content:
    'Select the Reporting Period Group from the drop-down list, or choose "Capture By Reporting Period" if you want to capture for a single reporting period.',
  placement: 'top',
  locale: { skip: skip_label }
};

export const account_menu_hamburger_step: Step = {
  target: '#account-settings-hamburger',
  content:
    'Thank you for taking this tour. You can now "Disable Tour" and continue capturing, or sign out. Remember you can re-enable this tour at any time by clicking the help icon (grey question mark) on the top right.',
  placement: 'bottom',
  placementBeacon: 'top',
  locale: { skip: skip_label, last: last_button }
};

const quantitative_action_grid_steps: Array<Step> = [
  {
    target: '#upload-supporting-docs',
    content: 'Click here to add (optional) documentation that supports a captured value.',
    placement: 'top',
    placementBeacon: 'top',
    locale: { skip: skip_label }
  },
  {
    target: '#edit-metric-record',
    content: 'Click here if you would like to edit a captured value.',
    placement: 'bottom',
    placementBeacon: 'top',
    locale: { skip: skip_label }
  },
  {
    target: '#save-record-changes',
    content: 'Click here to save your changes.',
    placement: 'bottom',
    placementBeacon: 'top',
    locale: { skip: skip_label }
  },
  {
    target: '#delete-metric-record',
    content: 'Click here to delete a captured record.',
    placement: 'bottom',
    placementBeacon: 'top',
    locale: { skip: skip_label }
  }
];

export const default_steps: Array<Step> = [
  {
    target: '#capture-by-heading',
    content:
      'Welcome to the Metric Capture module. This tour will guide you through capturing a metric - just click the floating beacon at each step. Click "Disable Tour" to skip this tour or click "Next" to continue.',
    placement: 'left',
    placementBeacon: 'left',
    hideCloseButton: true,
    locale: { skip: skip_label },
    floaterProps: { hideArrow: true, autoOpen: true }
  },
  {
    target: '#start-capture-tour',
    content: 'Click on this help icon if you ever need to restart the tour.',
    placement: 'left',
    placementBeacon: 'left',
    hideCloseButton: true,
    locale: { skip: skip_label },
    floaterProps: { autoOpen: true }
  },
  {
    target: '#standard-select',
    content: 'Get your journey started by selecting a standard',
    placement: 'bottom',
    placementBeacon: 'top-end',
    locale: { skip: skip_label }
  },
  {
    target: '#capture-level-tab-0',
    content:
      'Select which level you will be capturing data for by clicking on the tabs. You can choose: Country, Region, Division, Subdivision, or Site.',
    placement: 'bottom-start',
    placementBeacon: 'top-start',
    locale: { skip: skip_label }
  },
  {
    target: '#capture-input-grid-item-0',
    content:
      'Select the Country, Region, Division, Subdivision and/or Site from the drop-down lists.',
    placement: 'top-start',
    locale: { skip: skip_label }
  },
  reporting_period_group_step
];

export const import_level_steps: Array<Step> = [
  ...default_steps.slice(0, 3),
  {
    target: '#capture-input-grid-item-0',
    content:
      'Select the Reporting Period Group from the drop-down list, or choose "Capture By Reporting Period" if you want to capture for a single reporting period.',
    placement: 'top-start',
    locale: { skip: skip_label }
  },
  ...default_steps.slice(5)
];

export const default_steps_qualitative: Array<Step> = [
  {
    target: '#standard-select',
    content: 'Get your journey started by selecting a standard',
    placement: 'bottom',
    placementBeacon: 'top-end',
    locale: { skip: skip_label }
  },
  {
    target: '#reporting-period-group-select',
    content:
      'Select the Reporting Period Group from the drop-down list, or choose "Capture By Reporting Period" if you want to capture for a single reporting period.',
    placement: 'top',
    locale: { skip: skip_label }
  },
  view_records_step
];

export const default_quantitative_capture_steps: Array<Step> = [
  {
    target: '#metric-group-select',
    content:
      'To start capturing a new record, choose a Category - this will filter which metrics you can choose in the next step.',
    placement: 'top',
    locale: { skip: skip_label }
  },
  {
    target: '#metric-select',
    content: 'Select the Metric you would like to capture from the drop-down list.',
    placement: 'top',
    locale: { skip: skip_label }
  },
  {
    target: '#value-input',
    content:
      'Enter a value to capture - this value must be above or equal to 0. If you are averaging over a Reporting Period Group, remember to capture the total value and the system will automatically average it across the relevant periods.',
    placement: 'top',
    locale: { skip: skip_label }
  },
  {
    target: '#note-input',
    content: 'You can (optionally) enter any notes here for future reference.',
    placement: 'top',
    locale: { skip: skip_label }
  },
  {
    target: '#add-data-button',
    content: 'Click here to add your new metric record.',
    placement: 'top',
    placementBeacon: 'top',
    locale: { skip: skip_label }
  },
  ...quantitative_action_grid_steps,
  account_menu_hamburger_step
];

export const quantitative_capture_steps_external: Array<Step> = [
  ...default_quantitative_capture_steps.slice(0, 3),
  {
    target: '#allocation-input',
    content:
      'Enter the % of the captured value to be assigned to this company if the captured value is not already company-specific. For example if you capture 100 units as a third-party supplier, and 20 units should be allocated to this company, then enter 20% here.',
    placement: 'top',
    locale: { skip: skip_label }
  },
  ...default_quantitative_capture_steps.slice(3)
];

export const reporting_period_select_average_steps: Array<Step> = [
  {
    target: '#reporting-period-select',
    content:
      'Select the Reporting Period from the drop-down list, or choose "Average Over Group" to average your value across each period within the group.',
    placement: 'top',
    locale: { skip: skip_label }
  },
  usertype_select_step,
  view_records_step
];

export const reporting_period_select_steps: Array<Step> = [
  {
    target: '#reporting-period-select',
    content: 'Select the Reporting Period from the drop-down list.',
    placement: 'top',
    locale: { skip: skip_label }
  },
  usertype_select_step,
  view_records_step
];
