import { Box, Grid, IconButton, Tooltip, Typography } from '@mui/material';
import React from 'react';
import ReportingPeriodSelect from '../../shared/input/select/ReportingPeriodSelect';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import { ExportFilter, ExportFilterProps } from '../ExportHub';
import {
  Country,
  Division,
  Region,
  ReportingPeriod,
  ReportingPeriodGroup,
  Site,
  Standard,
  Subdivision
} from '@esg/esg-global-types';
import MetricSelect from '../../shared/input/select/MetricSelect';
import { MetricExtended } from '../../../lib/metric_capture/metric';
import AlertBanner from '../../shared/banner/AlertBanner';
import { getReportingPeriodDays } from '../../../lib/metric_capture/reporting_period';
import CountrySelect from '../../shared/input/select/CountrySelect';
import ReportingPeriodGroupSelect from '../../shared/input/select/ReportingPeriodGroupSelect';
import DivisionSelect from '../../shared/input/select/DivisionSelect';
import RegionSelect from '../../shared/input/select/RegionSelect';
import SiteSelect from '../../shared/input/select/SiteSelect';
import { SiteExtended } from '../../../lib/metric_capture/site';
import SubdivisionSelect from '../../shared/input/select/SubdivisionSelect';
import { DocumentReference } from 'firebase/firestore';

/**
 * A widget to add inclusive filters to an export of Quantitative Metric Records.
 * @param {MetricRecordEntities} export_entities Lists of entities to use as options for filters.
 * @param {ExportType} export_type Type of Export chosen by the user.
 * @param {FilterInputErrors} input_errors Object listing invalid inputs from filter selections.
 * @param {void} handleUpdateFilters Function to update the export filters in the parent.
 * @param {void} handleClearFilters Function to clear all filters in the parent.
 * @param {void} handleInputValidity Function to update input_errors object in parent.
 * @returns {JSX.Element}
 */
const QuantitativeMetricRecordFilters = ({
  export_entities,
  export_filters,
  export_type,
  input_errors,
  handleUpdateFilters,
  handleClearFilters,
  handleInputValidity
}: ExportFilterProps) => {
  const [reportingPeriodGroupFilters, setReportingPeriodGroupFilters] = React.useState<
    Array<ReportingPeriodGroup>
  >([]);

  const reporting_period_group_filter_references: Array<string> = reportingPeriodGroupFilters.map(
    (reporting_period_group: ReportingPeriodGroup) => reporting_period_group.id
  );

  const tag_limit = 3;
  const sites_extended: Array<SiteExtended> = [];

  export_entities.sites.forEach((site: Site) => {
    const site_subdivision: Subdivision | undefined = export_entities.subdivisions.find(
      (subdivision: Subdivision) => subdivision.id === site.subdivision.id
    );
    const site_division: Division | undefined = export_entities.divisions.find(
      (division: Division) => division.id === site_subdivision?.division.id
    );
    if (site_subdivision && site_division) {
      sites_extended.push({
        ...site,
        division: site_division,
        subdivision: site_subdivision
      });
    }
  });

  const getFilter = (filter_field: string): ExportFilter | undefined => {
    return export_filters.find((filter: ExportFilter) => filter.field === filter_field);
  };

  React.useEffect(() => {
    handleInputValidity('reporting_periods', 'MIN_REPORTING_PERIODS');
  }, [export_entities, export_type]);

  return (
    <Box sx={{ py: 4, px: 2 }}>
      <Box sx={{ display: 'flex' }}>
        <Typography fontSize="1.3rem" textAlign="left">
          Apply Filters to Export
        </Typography>
        <Tooltip title="Reset Filters">
          <IconButton
            onClick={() => {
              handleClearFilters();
              setReportingPeriodGroupFilters([]);
              handleInputValidity('reporting_periods', 'MIN_REPORTING_PERIODS');
            }}
            sx={{ ml: 'auto' }}
          >
            <FilterAltOffIcon />
          </IconButton>
        </Tooltip>
      </Box>
      <Grid container spacing={4} sx={{ my: 2 }}>
        <Grid item xs={11}>
          <ReportingPeriodGroupSelect
            allow_multi_select
            tag_limit={tag_limit}
            reporting_period_group_options={export_entities.reporting_period_groups}
            selected_reporting_period_group={reportingPeriodGroupFilters}
            handleChangeReportingPeriodGroups={(
              reporting_period_groups: Array<ReportingPeriodGroup> | ReportingPeriodGroup | null
            ) => {
              if (Array.isArray(reporting_period_groups)) {
                setReportingPeriodGroupFilters(reporting_period_groups);
              }
              const reporting_period_group_references: Array<string> = (
                Array.isArray(reporting_period_groups) ? reporting_period_groups : []
              ).map((reporting_period_group: ReportingPeriodGroup) => reporting_period_group.id);
              const reporting_periods_for_groups: Array<ReportingPeriod> =
                export_entities.reporting_periods.filter((reporting_period: ReportingPeriod) =>
                  reporting_period_group_references.includes(
                    reporting_period.reporting_period_group?.id ?? ''
                  )
                );
              handleUpdateFilters(
                'reporting_periods',
                reporting_periods_for_groups.map(
                  (reporting_period: ReportingPeriod) => reporting_period.reference!.path
                ),
                reporting_periods_for_groups.length === 0
              );
              reporting_periods_for_groups.length > 0 && reporting_period_groups
                ? handleInputValidity(
                    'reporting_periods',
                    getReportingPeriodDays(
                      reporting_periods_for_groups.length > 0 ? reporting_periods_for_groups : []
                    ) > 366
                      ? 'MAX_DAYS_EXCEEDED'
                      : ''
                  )
                : handleInputValidity('reporting_periods', 'MIN_REPORTING_PERIODS');
            }}
          />
        </Grid>
        <Grid item xs={11}>
          <ReportingPeriodSelect
            allow_multi_select
            tag_limit={tag_limit}
            reporting_period_options={
              reporting_period_group_filter_references.length > 0
                ? export_entities.reporting_periods.filter((reporting_period: ReportingPeriod) =>
                    reporting_period_group_filter_references.includes(
                      reporting_period.reporting_period_group?.id ?? ''
                    )
                  )
                : export_entities.reporting_periods
            }
            selected_reporting_periods={export_entities.reporting_periods.filter(
              (reporting_period: ReportingPeriod) => {
                const reporting_period_filter: ExportFilter | undefined =
                  getFilter('reporting_periods');
                return (reporting_period_filter?.references ?? []).includes(
                  reporting_period.reference!.path
                );
              }
            )}
            handleChangeReportingPeriods={(
              reporting_periods: ReportingPeriod | Array<ReportingPeriod> | null
            ) => {
              if (Array.isArray(reporting_periods)) {
                handleUpdateFilters(
                  'reporting_periods',
                  reporting_periods.map(
                    (reporting_period: ReportingPeriod) => reporting_period.reference!.path
                  ),
                  reporting_periods.length === 0
                );
                reporting_periods.length > 0
                  ? handleInputValidity(
                      'reporting_periods',
                      getReportingPeriodDays(reporting_periods) > 366 ? 'MAX_DAYS_EXCEEDED' : ''
                    )
                  : handleInputValidity('reporting_periods', 'MIN_REPORTING_PERIODS');
              }
            }}
          />
          {input_errors.reporting_periods === 'MAX_DAYS_EXCEEDED' && (
            <Box sx={{ mt: 2, mb: -5 }}>
              <AlertBanner
                severity="warning"
                message={'Total Reporting Period date range cannot exceed 366 days'}
                open
              />
            </Box>
          )}
          {input_errors.reporting_periods === 'MIN_REPORTING_PERIODS' && (
            <Box sx={{ mt: 2, mb: -5 }}>
              <AlertBanner
                severity="warning"
                message={'Please select at least one reporting period'}
                open
              />
            </Box>
          )}
        </Grid>
        <Grid item xs={11}>
          <MetricSelect
            multi_select
            tag_limit={tag_limit}
            options={export_entities.metrics.filter(
              (metric) => (metric.standard as Standard).is_quantitative
            )}
            selected_options={export_entities.metrics.filter((metric: MetricExtended) => {
              const metric_filter: ExportFilter | undefined = getFilter('metrics');
              return (metric_filter?.references ?? []).includes(metric.reference!.path);
            })}
            group_by="metric_group.name"
            handleChangeMetrics={(metrics: MetricExtended | Array<MetricExtended> | null) => {
              if (Array.isArray(metrics)) {
                handleUpdateFilters(
                  'metrics',
                  metrics.map((metric: MetricExtended) => metric.reference!.path),
                  metrics.length === 0
                );
              }
            }}
          />
        </Grid>
        <Grid item xs={11}>
          <CountrySelect
            multi_select
            tag_limit={tag_limit}
            options={export_entities.countries}
            selected_options={export_entities.countries.filter((country: Country) => {
              const country_filter: ExportFilter | undefined = getFilter('countries');
              return (country_filter?.references ?? []).includes(country.reference!.path);
            })}
            handleChangeCountries={(countries: Country | Array<Country> | null) => {
              if (Array.isArray(countries)) {
                handleUpdateFilters(
                  'countries',
                  countries.map((country: Country) => country.reference!.path),
                  countries.length === 0,
                  ['regions']
                );
              }
            }}
            variant="standard"
          />
        </Grid>
        <Grid item xs={11}>
          <RegionSelect
            tag_limit={tag_limit}
            multi_select
            options={export_entities.regions.filter((region: Region) => {
              const country_references: Array<string> = getFilter('countries')?.references ?? [];
              return (
                country_references.length === 0 ||
                country_references.includes((region.country as DocumentReference).path)
              );
            })}
            selected_options={export_entities.regions.filter((region: Region) => {
              const region_filter: ExportFilter | undefined = getFilter('regions');
              return (region_filter?.references ?? []).includes(region.reference!.path);
            })}
            handleChangeRegions={(regions: Region | Array<Region> | null) => {
              if (Array.isArray(regions)) {
                handleUpdateFilters(
                  'regions',
                  regions.map((region: Region) => region.reference!.path),
                  regions.length === 0
                );
              }
            }}
          />
        </Grid>
        <Grid item xs={11}>
          <DivisionSelect
            tag_limit={tag_limit}
            multi_select
            options={export_entities.divisions}
            selected_options={export_entities.divisions.filter((division: Division) => {
              const division_filter: ExportFilter | undefined = getFilter('divisions');
              return (division_filter?.references ?? []).includes(division.reference!.path);
            })}
            handleChangeDivisions={(divisions: Division | Array<Division> | null) => {
              if (Array.isArray(divisions)) {
                handleUpdateFilters(
                  'divisions',
                  divisions.map((division: Division) => division.reference!.path),
                  divisions.length === 0,
                  ['subdivisions', 'sites']
                );
              }
            }}
          />
        </Grid>
        <Grid item xs={11}>
          <SubdivisionSelect
            tag_limit={tag_limit}
            multi_select
            options={export_entities.subdivisions.filter((subdivision: Subdivision) => {
              const division_references: Array<string> = getFilter('divisions')?.references ?? [];
              return (
                division_references.length === 0 ||
                division_references.includes((subdivision.division as DocumentReference).path)
              );
            })}
            selected_options={export_entities.subdivisions.filter((subdivision: Subdivision) => {
              const subdivision_filter: ExportFilter | undefined = getFilter('subdivisions');
              return (subdivision_filter?.references ?? []).includes(subdivision.reference!.path);
            })}
            handleChangeSubdivisions={(subdivisions: Subdivision | Array<Subdivision> | null) => {
              if (Array.isArray(subdivisions)) {
                handleUpdateFilters(
                  'subdivisions',
                  subdivisions.map((subdivision: Subdivision) => subdivision.reference!.path),
                  subdivisions.length === 0,
                  ['sites']
                );
              }
            }}
          />
        </Grid>
        <Grid item xs={11}>
          <SiteSelect
            tag_limit={tag_limit}
            multi_select
            group_by="subdivision.name"
            options={sites_extended.filter((site: SiteExtended) => {
              const division_references: Array<string> = getFilter('divisions')?.references ?? [];
              const subdivision_references: Array<string> =
                getFilter('subdivisions')?.references ?? [];
              return (
                (subdivision_references.length === 0 ||
                  subdivision_references.includes(site.subdivision.reference!.path)) &&
                (division_references.length === 0 ||
                  division_references.includes(site.division.reference!.path))
              );
            })}
            selected_options={sites_extended.filter((site: SiteExtended) => {
              const site_filter: ExportFilter | undefined = getFilter('sites');
              return (site_filter?.references ?? []).includes(site.reference!.path);
            })}
            handleChangeSites={(sites: SiteExtended | Array<SiteExtended | Site> | Site | null) => {
              if (Array.isArray(sites)) {
                handleUpdateFilters(
                  'sites',
                  sites.map((site: SiteExtended | Site) => site.reference!.path),
                  sites.length === 0
                );
              }
            }}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default QuantitativeMetricRecordFilters;
