import {
  Division,
  ExternalCompany,
  Site,
  Subdivision,
  User,
  UserType
} from '@esg/esg-global-types';
import { getExternalCompanies } from '../lib/app/external_company';
import { getSites } from '../lib/metric_capture/site';
import { getSubdivisions } from '../lib/metric_capture/subdivision';
import { getDivisions } from '../lib/metric_capture/division';
import { MetricExtended, getMetrics } from '../lib/metric_capture/metric';
import { CompanyExtended } from '../lib/app/company';

export type ViewAccess = 'none' | 'read' | 'write' | 'admin' | null;

export const getUserGroups = (user: User) => {
  return Object.keys(user.groups);
};

export const getAdminGroups = (user: User): Array<string> => {
  return Object.keys(user.groups).filter((group_id: string) => {
    return user.groups[group_id].admin;
  });
};

export const userAllowedCompany = (user: User, company: CompanyExtended): boolean => {
  if (user.super_admin) return true;
  const admin_group_ids: Array<string> = getAdminGroups(user);
  if (admin_group_ids.includes(company.group.id)) return true;
  if (user.groups[company.group.id]?.companies?.[company.id]) return true;
  return false;
};

export const getModuleAccess = (
  user: User,
  group_id: string,
  company_id: string,
  module: string
): boolean | null => {
  if (user.super_admin) {
    return true;
  }
  if (user.groups[group_id]?.admin) {
    return true;
  }
  if (
    user.groups[group_id]?.companies?.[company_id]?.role === 'admin' &&
    module !== 'configuration'
  ) {
    return true;
  }
  const company_access = user.groups[group_id]?.companies?.[company_id]?.permissions?.find(
    (permission) => permission.startsWith(module)
  );
  return company_access ? true : false;
};

export const getViewAccess = (
  user: User,
  group_id: string,
  company_id: string,
  view: string
): ViewAccess => {
  if (user.super_admin) {
    return 'admin';
  }
  if (user.groups[group_id]?.admin) {
    return 'admin';
  }
  if (
    user.groups[group_id]?.companies?.[company_id]?.role === 'admin' &&
    view !== 'configuration'
  ) {
    return 'admin';
  }
  const company_permission = user.groups[group_id]?.companies?.[company_id]?.permissions?.find(
    (permission) => permission.startsWith(view)
  );
  return company_permission ? (company_permission.split('.').pop() as 'read' | 'write') : 'none';
};

export const getUserMetrics = async (user: User, group_id: string, company_id: string) => {
  const metrics: Array<MetricExtended> = await getMetrics(
    false,
    group_id,
    company_id,
    undefined,
    true
  );
  // If user is a super, group or company admin, return all metrics for company
  if (
    user.super_admin ||
    user.groups?.[group_id]?.admin ||
    user.groups?.[group_id]?.companies?.[company_id]?.role === 'admin'
  ) {
    return metrics;
  }

  const user_metrics: Array<MetricExtended> = [];
  const user_metric_ids: Array<string> = (
    user.groups?.[group_id]?.companies?.[company_id]?.permissions || []
  )
    .filter((permission: string) => {
      return permission.startsWith('metric.');
    })
    .map((perm: string) => perm.replace('metric.', ''));

  metrics.forEach((metric: MetricExtended) => {
    if (user_metric_ids.includes(metric.id)) user_metrics.push(metric);
  });

  return user_metrics;
};

export const getUserDivisions = async (user: User, group_id: string, company_id: string) => {
  const divisions: Array<Division> = await getDivisions(group_id, company_id);
  // If user is a super, group or company admin, return all divisions for company
  if (
    user.super_admin ||
    user.groups?.[group_id]?.admin ||
    user.groups?.[group_id]?.companies?.[company_id]?.role === 'admin'
  ) {
    return divisions;
  }

  const user_subdivisions: Array<Subdivision> = await getUserSubdivisions(
    user,
    group_id,
    company_id
  );
  const user_divisions: Array<Division> = [];
  const user_division_ids: Array<string> = user_subdivisions.map(
    (subdivision: Subdivision) => subdivision.division.id
  );

  divisions.forEach((division: Division) => {
    if (user_division_ids.includes(division.id)) user_divisions.push(division);
  });

  return user_divisions;
};

export const getUserSubdivisions = async (user: User, group_id: string, company_id: string) => {
  const subdivisions: Array<Subdivision> = await getSubdivisions(group_id, company_id);
  // If user is a super, group or company admin, return all subdivisions for company
  if (
    user.super_admin ||
    user.groups?.[group_id]?.admin ||
    user.groups?.[group_id]?.companies?.[company_id]?.role === 'admin'
  ) {
    return subdivisions;
  }

  const user_sites: Array<Site> = await getUserSites(user, group_id, company_id);
  const user_subdivisions: Array<Subdivision> = [];
  const user_subdivision_ids: Array<string> = user_sites.map((site: Site) => site.subdivision.id);

  subdivisions.forEach((subdivision: Subdivision) => {
    if (user_subdivision_ids.includes(subdivision.id)) user_subdivisions.push(subdivision);
  });

  return user_subdivisions;
};

export const getUserSites = async (user: User, group_id: string, company_id: string) => {
  const sites: Array<Site> = await getSites(group_id, company_id);
  // If user is a super, group or company admin, return all sites for company
  if (
    user.super_admin ||
    user.groups?.[group_id]?.admin ||
    user.groups?.[group_id]?.companies?.[company_id]?.role === 'admin'
  ) {
    return sites;
  }

  const user_sites: Array<Site> = [];
  const user_site_ids: Array<string> = (
    user.groups?.[group_id]?.companies?.[company_id]?.permissions || []
  )
    .filter((permission: string) => {
      return permission.startsWith('site');
    })
    .map((perm: string) => perm.replace('site.', ''));

  sites.forEach((site: Site) => {
    if (user_site_ids.includes(site.id)) user_sites.push(site);
  });

  return user_sites;
};

export const getUserExternalCompanyList = async (
  user: User,
  group_id: string,
  company_id: string
) => {
  const external_companies: Array<ExternalCompany> = await getExternalCompanies(
    group_id,
    company_id
  );
  // If user is a super, group or company admin, return all external companies for company
  if (
    user.super_admin ||
    user.groups?.[group_id]?.admin ||
    user.groups?.[group_id]?.companies?.[company_id]?.role === 'admin'
  ) {
    return external_companies;
  }

  const user_external_companies: Array<ExternalCompany> = [];
  const user_external_company_ids: Array<string> = (
    user.groups?.[group_id]?.companies?.[company_id]?.permissions || []
  )
    .filter((permission: string) => {
      return permission.startsWith('external_company');
    })
    .map((perm: string) => perm.replace('external_company.', ''));

  external_companies.forEach((external_company: ExternalCompany) => {
    if (user_external_company_ids.includes(external_company.id))
      user_external_companies.push(external_company);
  });

  return user_external_companies;
};

export const getUserTypes = () => {
  return [
    {
      id: 'internal',
      name: 'Internal'
    },
    {
      id: 'external',
      name: 'External'
    },
    {
      id: 'any',
      name: 'Combination'
    }
  ] as Array<UserType>;
};
