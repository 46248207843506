import * as React from 'react';
import { Box, Drawer, Tooltip, Typography } from '@mui/material';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import { Company, Group, Region, Country } from '@esg/esg-global-types';
import {
  RegionData,
  allowDeleteRegion,
  createRegions,
  deleteRegion,
  getRegionJoinCountries,
  region_label,
  updateRegion
} from '../../../lib/metric_capture/region';
import { FeedbackSnackbarContext } from '../../../context/FeedbackSnackbarContext';
import DeleteConfirmModal from '../../shared/modal/DeleteConfirmModal';
import EditIcon from '@mui/icons-material/Edit';
import ConfigAddWidget, { CreateInput } from '../ConfigAddWidget';
import { MetadataError } from '@ep/error-handling';
import { uuidv4 } from '@firebase/util';
import { log } from '../../../util/log';
import { DataGrid, GridActionsCellItem, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import { PanelRegionsToolbar } from './PanelRegionsToolbar';
import { PanelRegionsNoRows } from './PanelRegionsNoRows';
import { PanelRegionsLoading } from './PanelRegionsLoading';
import { GroupContext } from '../../../context/GroupContext';
import { CompanyContext } from '../../../context/CompanyContext';
import DeleteIcon from '@mui/icons-material/Delete';
import { PanelTabs, PanelTabsWrapper } from '../../shared/tab/PanelTabsWrapper';
import ConfigEditWidget, { EditInput } from '../ConfigEditWidget';
import { BatchWrite } from '../../../lib/app/db_util';
import CountrySelect from '../../shared/input/select/CountrySelect';

interface PanelRegionsProps {
  master_list?: boolean;
}

/**
 * Datagrid handling the display of configured or Master List Regions
 * @returns {JSX.Element}
 */
const PanelRegions = ({ master_list }: PanelRegionsProps) => {
  const { setFeedbackData } = React.useContext(FeedbackSnackbarContext);
  const group: Group | null = React.useContext(GroupContext);
  const company: Company | null = React.useContext(CompanyContext);
  const [actionRow, setActionRow] = React.useState<Region | null>(null);
  const [isCreateWidget, setIsCreateWidget] = React.useState<boolean>(true);
  const [regionRows, setRegionRows] = React.useState<Array<Region>>([]);
  const [displayWidgetPanelRight, setDisplayWidgetPanelRight] = React.useState<boolean>(false);
  const [openDialogDelete, setOpenDialogDelete] = React.useState<boolean>(false);
  const [allowRegionDelete, setAllowRegionDelete] = React.useState<boolean | null>(
    master_list ? true : null
  );
  const [gridLoading, setGridLoading] = React.useState<boolean>(true);

  const countryInputFactory = (
    handleInputChange: (input_id: string, value: Country | null) => void
  ): JSX.Element => {
    return (
      <CountrySelect
        master_list
        handleChangeCountries={(country: Array<Country> | Country | null) => {
          handleInputChange('country', country as Country | null);
        }}
      />
    );
  };

  // Handler functions
  const handleCreateClick = (): void => {
    setActionRow(null);
    setIsCreateWidget(true);
    setDisplayWidgetPanelRight(true);
  };

  const handleEditClick = (region: Region): void => {
    setActionRow(region);
    setIsCreateWidget(false);
    setDisplayWidgetPanelRight(true);
  };

  const handleDeleteClick = async (row: Region): Promise<void> => {
    try {
      if (!master_list && group && company) {
        setAllowRegionDelete(await allowDeleteRegion(group.id, company.id, row.id));
      }
      setActionRow(row);
      setOpenDialogDelete(true);
    } catch (err: unknown) {
      const tracking_id: string = uuidv4();
      log(
        'error',
        new MetadataError(
          err instanceof Error
            ? err.message
            : 'Error: PanelRegions failed on an unknown error while calling handleDeleteClick.',
          {
            group: group,
            company: company,
            row: row
          },
          tracking_id
        )
      );
      setFeedbackData({
        message: `Unable to delete region. Tracking ID: ${tracking_id}`,
        state: true,
        type: 'error'
      });
    }
  };

  const handleUpdateRows = (rows_data: Array<Region>): void => {
    setRegionRows(rows_data);
  };

  const handleCloseDeleteModal = (): void => {
    setOpenDialogDelete(false);
  };

  const columns: Array<GridColDef> = [
    {
      field: 'country',
      headerName: 'Country',
      headerAlign: 'left',
      align: 'left',
      valueGetter: (params: GridValueGetterParams) => {
        return params.value.name;
      },
      flex: 1
    },
    {
      field: 'name',
      headerName: 'Name',
      headerAlign: 'left',
      align: 'left',
      flex: 1
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: '',
      headerAlign: 'right',
      align: 'right',
      hideable: false,
      flex: 1,
      getActions: ({ row }) => {
        return [
          <>
            {master_list && (
              <GridActionsCellItem
                key={1}
                icon={
                  <Tooltip title="Edit Region">
                    <EditIcon color={!row.locked ? 'primary' : 'action'} />
                  </Tooltip>
                }
                size="large"
                label="Edit"
                sx={{
                  color: 'primary.main'
                }}
                onClick={() => handleEditClick(row)}
                disabled={row.locked}
              />
            )}
            <GridActionsCellItem
              key={2}
              icon={
                <Tooltip title="Delete Region">
                  <DeleteIcon color="primary" />
                </Tooltip>
              }
              size="large"
              label="Delete"
              sx={{
                color: 'primary.main'
              }}
              onClick={() => handleDeleteClick(row)}
            />
          </>
        ];
      }
    }
  ];

  const create_inputs: readonly CreateInput[] = master_list
    ? [
        {
          id: 'country',
          type: 'node',
          label: 'Country',
          inputNodeFactory: countryInputFactory
        },
        {
          id: 'name',
          type: 'text',
          label: 'Name'
        }
      ]
    : [];

  const edit_inputs: EditInput[] = master_list
    ? [
        {
          id: 'name',
          type: 'text',
          label: 'Name'
        }
      ]
    : [];

  // Row functions
  const fetchRows = async (): Promise<void> => {
    setGridLoading(true);
    try {
      // Load rows from database.
      const regions: Array<Region> = await getRegionJoinCountries(
        !!master_list,
        group?.id,
        company?.id
      );
      // Load rows to memory.
      setRegionRows(regions);
    } catch (err: unknown) {
      const tracking_id: string = uuidv4();
      log(
        'error',
        new MetadataError(
          err instanceof Error
            ? err.message
            : 'Error: PanelRegions failed on an unknown error while calling fetchRows.',
          {
            group: group,
            company: company
          },
          tracking_id
        )
      );
      setFeedbackData({
        message: `Unable to fetch regions. Tracking ID: ${tracking_id}`,
        state: true,
        type: 'error'
      });
      return;
    } finally {
      setGridLoading(false);
    }
  };

  React.useEffect(
    () => {
      try {
        (async () => {
          setRegionRows([]);
          fetchRows();
        })().catch((error) => {
          throw new Error(error);
        });
      } catch (err: unknown) {
        const tracking_id: string = uuidv4();
        log(
          'error',
          new MetadataError(
            err instanceof Error
              ? err.message
              : 'Error: PanelRegions failed on an unknown error while initialising.',
            null,
            tracking_id
          )
        );
        setFeedbackData({
          message: `An error occurred. Tracking ID: ${tracking_id}`,
          state: true,
          type: 'error'
        });
      }
      return;
    },
    master_list ? [] : [group, company]
  );

  const createRowsFromMasterList = async (region_list: Array<Region>): Promise<void> => {
    try {
      if (!master_list && group && company) {
        const new_region_writes: Array<BatchWrite> = await createRegions(
          false,
          region_list,
          group.id,
          company.id
        );
        if (new_region_writes) {
          handleUpdateRows([
            ...new_region_writes.map((new_region_write: BatchWrite) => {
              return {
                id: new_region_write.reference.id,
                deleted: new_region_write.data.deleted,
                name: new_region_write.data.name,
                country: new_region_write.data.country
              };
            }),
            ...regionRows
          ]);
        }
      }
    } catch (err: unknown) {
      const tracking_id: string = uuidv4();
      log(
        'error',
        new MetadataError(
          err instanceof Error
            ? err.message
            : 'Error: PanelRegions failed on an unknown error while calling createRowsFromMasterList.',
          {
            group: group,
            company: company,
            region_list: region_list
          },
          tracking_id
        )
      );
      setFeedbackData({
        message: `Unable to create regions. Tracking ID: ${tracking_id}`,
        state: true,
        type: 'error'
      });
    }
  };

  const createRow = async (name: string, country: Country): Promise<void> => {
    try {
      const region_data: RegionData = {
        deleted: null,
        name: name,
        country: country
      };
      const new_region_writes: Array<BatchWrite> = await createRegions(!!master_list, [
        region_data
      ]);
      if (new_region_writes) {
        handleUpdateRows([
          ...new_region_writes.map((new_region_write: BatchWrite) => {
            return {
              id: new_region_write.reference.id,
              deleted: new_region_write.data.deleted,
              name: new_region_write.data.name,
              country: country
            };
          }),
          ...regionRows
        ]);
      }
    } catch (err: unknown) {
      const tracking_id: string = uuidv4();
      log(
        'error',
        new MetadataError(
          err instanceof Error
            ? err.message
            : 'Error: PanelRegions failed on an unknown error while calling createRow.',
          {
            name: name,
            country: country
          },
          tracking_id
        )
      );
      setFeedbackData({
        message: `Unable to create regions. Tracking ID: ${tracking_id}`,
        state: true,
        type: 'error'
      });
    }
  };

  const updateRow = async (updated_row: Region, original_data: Region): Promise<void> => {
    try {
      await updateRegion(!!master_list, updated_row, original_data, group?.id, company?.id).then(
        () => {
          handleUpdateRows(
            regionRows.map((row: Region) => (updated_row.id === row.id ? { ...updated_row } : row))
          );
        }
      );
    } catch (err: unknown) {
      const tracking_id: string = uuidv4();
      log(
        'error',
        new MetadataError(
          err instanceof Error
            ? err.message
            : 'Error: PanelRegions failed on an unknown error while calling updateRow.',
          {
            master_list: master_list,
            group: group,
            company: company,
            updated_row: updated_row,
            original_data: original_data
          },
          tracking_id
        )
      );
      setFeedbackData({
        message: `Unable to update region. Tracking ID: ${tracking_id}`,
        state: true,
        type: 'error'
      });
    }
  };

  const deleteRow = async (region: Region): Promise<void> => {
    try {
      await deleteRegion(!!master_list, region.id, region.name, group?.id, company?.id).then(() => {
        setRegionRows(
          regionRows.filter((row: Region) => {
            return row.id !== region.id;
          })
        );
      });
    } catch (err: unknown) {
      const tracking_id: string = uuidv4();
      log(
        'error',
        new MetadataError(
          err instanceof Error
            ? err.message
            : 'Error: PanelRegions failed on an unknown error while calling deleteRow.',
          {
            group: group,
            company: company,
            region: region
          },
          tracking_id
        )
      );
      setFeedbackData({
        message: `Unable to remove region. Tracking ID: ${tracking_id}`,
        state: true,
        type: 'error'
      });
    } finally {
      setOpenDialogDelete(false);
    }
  };

  return (
    <>
      {/* Delete Confirmation Modal */}
      <DeleteConfirmModal
        open={openDialogDelete}
        allow_delete={allowRegionDelete}
        handleCloseDeleteModal={handleCloseDeleteModal}
        delete_label={actionRow && actionRow.name}
        handleDelete={actionRow && (() => deleteRow(actionRow))}
        allow_archive={false}
      />

      {/* Side Widget Panel */}
      <Drawer
        anchor={'right'}
        open={displayWidgetPanelRight}
        onClose={() => setDisplayWidgetPanelRight(false)}
        PaperProps={{ style: { width: '30%', padding: '1.5rem' } }}
      >
        {/* Create / Edit Widget */}
        {isCreateWidget ? (
          <>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                flexWrap: 'wrap'
              }}
            >
              <LocationOnOutlinedIcon sx={{ marginRight: '2rem' }} fontSize="large" />
              <Typography variant="h5" color="inherit" align="left">
                Add {region_label.one}
              </Typography>
            </Box>
            {master_list ? (
              <ConfigAddWidget
                create_label={region_label.one}
                create_icon={
                  <LocationOnOutlinedIcon sx={{ marginRight: '2rem' }} fontSize="large" />
                }
                create_function_inputs={create_inputs}
                handleClose={() => setDisplayWidgetPanelRight(false)}
                createFunction={(country: Country, name: string) => createRow(name, country)}
                hide_configured_entities={true}
                hide_title={true}
              />
            ) : (
              <PanelTabsWrapper>
                <PanelTabs label="Generic" disabled={false}>
                  <ConfigAddWidget
                    create_label={region_label.one}
                    create_icon={
                      <LocationOnOutlinedIcon sx={{ marginRight: '2rem' }} fontSize="large" />
                    }
                    create_function_inputs={create_inputs}
                    existing_entities={regionRows}
                    handleClose={() => setDisplayWidgetPanelRight(false)}
                    createFunction={(region_list: Array<Region>) =>
                      createRowsFromMasterList(region_list)
                    }
                    hide_configured_entities={true}
                    hide_title={true}
                  />
                </PanelTabs>
                <PanelTabs label={'Custom'} disabled={true} />
              </PanelTabsWrapper>
            )}
          </>
        ) : (
          <ConfigEditWidget
            edit_label="Region"
            edit_icon={<LocationOnOutlinedIcon sx={{ marginRight: '2rem' }} fontSize="large" />}
            handleClose={() => setDisplayWidgetPanelRight(false)}
            edit_entity={actionRow}
            edit_function_inputs={edit_inputs}
            handleEditInput={(input_id: string, value: unknown) =>
              actionRow && setActionRow({ ...actionRow, [input_id]: value })
            }
            confirmEditFunction={(updated_region: Region, original_region: Region) =>
              updateRow(updated_region, original_region)
            }
          />
        )}
      </Drawer>

      {/* Interactive Data Table */}
      <DataGrid
        autoHeight
        initialState={{
          pagination: {
            paginationModel: { pageSize: 10, page: 0 }
          },
          sorting: {
            sortModel: [{ field: 'modified', sort: 'desc' }]
          }
        }}
        hideFooter={regionRows.length > 1 ? false : true}
        columnHeaderHeight={regionRows.length < 1 ? 0 : 56}
        pageSizeOptions={[10, 25, 50, 100]}
        loading={gridLoading}
        rows={regionRows}
        columns={columns}
        disableRowSelectionOnClick
        slots={{
          toolbar: PanelRegionsToolbar,
          noRowsOverlay: PanelRegionsNoRows,
          loadingOverlay: PanelRegionsLoading
        }}
        slotProps={{ toolbar: { handleCreate: handleCreateClick } }}
        sx={{ '&, [class^=MuiDataGrid]': { border: 'none' }, width: '100%' }}
      />
    </>
  );
};

export default PanelRegions;
