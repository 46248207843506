import React from 'react';
import { Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { User } from '@esg/esg-global-types';

/**
 * A message indicating additional configuration required to use metric capturing functionality for specified context
 * @param {string} missing_entities Label of missing entities requiring configuration
 * @param {string | undefined} company_name Name of company to configure entities for
 * @param {string | undefined} group_id ID of parent group of company to use for permission checking to render config link
 * @param {User | undefined} user_info Current user to check permissions for to render link to config
 * @returns {JSX.Element}
 */
const MetricCollectionWarningMessage = (
  missing_entities: string,
  company_name: string | undefined,
  group_id: string | undefined,
  user_info: User | undefined
) => {
  const missing_config_routes: { [key: string]: string } = {
    Divisions: '/configuration/organisation',
    Subdivisions: '/configuration/organisation',
    Sites: '/configuration/organisation',
    Countries: '/configuration/geographical',
    Regions: '/configuration/geographical',
    'Reporting Periods': '/configuration/reporting-periods',
    'Reporting Period Groups': '/configuration/reporting-period-groups',
    Metrics: '/configuration/metrics',
    'External Companies': '/configuration/external-companies'
  };

  return (
    <>
      <Typography display={'inline'}>
        No {missing_entities} found for {company_name}
      </Typography>
      {group_id &&
        (user_info?.super_admin ||
          (user_info?.groups[group_id] ? (user_info?.groups[group_id].admin ?? false) : false)) && (
          <Typography display={'inline'}>
            , configure them in your
            <Link to={missing_config_routes[missing_entities]} style={{ textDecoration: 'none' }}>
              {' '}
              Settings Page
            </Link>
          </Typography>
        )}
    </>
  );
};

export default MetricCollectionWarningMessage;
