import { EntityLabel, Sector } from '@esg/esg-global-types';
import {
  DocumentData,
  DocumentReference,
  QueryDocumentSnapshot,
  QuerySnapshot,
  collection,
  doc
} from 'firebase/firestore';
import { auth, db } from '../google/firebase';
import { uuidv4 } from '@firebase/util';
import { MetadataError } from '@ep/error-handling';
import { validateMasterListComparison, validateMasterListParams } from '../../util/validation';
import { FirestoreQueryParam } from '../../@types/shared';
import {
  BatchWrite,
  deleteFirestoreDoc,
  processBatchWrites,
  readFirestoreDocs,
  updateFirestoreDoc
} from './db_util';
import { createAuditLog, generateAuditLogData, generateAuditLogDoc } from './audit';

export type SectorData = Omit<Sector, 'id'>;

// Singular and plural label for model entity.
export const sector_label: EntityLabel = {
  one: 'Sector',
  many: 'Sectors'
};

/**
 * Create a collection path string for the master list sectors or configured sectors.
 * @param {boolean} master_list Reference the master list collection.
 * @param {string} group_id When not referencing a master list collection, a group id for the configured group is required.
 * @param {string} company_id When not referencing a master list collection, a company id for the configured company is required.
 * @returns {string} Path to a sectors collection.
 */
export const createSectorCollectionPath = (
  master_list: boolean,
  group_id?: string,
  company_id?: string
): string => {
  try {
    validateMasterListParams(master_list, group_id, company_id);
    return master_list
      ? `/sector_master_list`
      : `groups/${group_id}/companies/${company_id}/sectors`;
  } catch (err: unknown) {
    const tracking_id: string = uuidv4();
    throw new MetadataError(
      err instanceof Error
        ? err.message
        : 'Error: getSectorCollectionPath failed on an unknown error.',
      {
        master_list: master_list,
        group_id: group_id,
        company_id: company_id
      },
      tracking_id
    );
  }
};

/**
 * Query all sector documents for current portal company joined with relative sector data
 * @param {boolean} master_list Return master list or configured sector documents.
 * @param {string} group_id Group object of current portal company
 * @param {string} company_id id of current portal company to reference firestore documents
 * @param {string} id query a specific sector by id.
 * @returns {Promise<Array<Sector>>}
 */
export const getSectors = async (
  master_list: boolean,
  group_id?: string,
  company_id?: string,
  id?: string
): Promise<Array<Sector>> => {
  try {
    const collection_path: string = createSectorCollectionPath(master_list, group_id, company_id);
    const query_params: Array<FirestoreQueryParam> = [
      { field_name: 'deleted', operator: '==', value: null }
    ];
    if (id !== undefined && id.length > 0)
      query_params.push({ field_name: 'id', operator: '==', value: id });
    const sector_snapshot: QuerySnapshot = await readFirestoreDocs(collection_path, query_params);
    const sectors: Array<Sector> = sector_snapshot.docs.map((sector: QueryDocumentSnapshot) => {
      const sector_data: DocumentData = sector.data();
      return {
        ...{
          id: sector.id,
          deleted: sector_data.deleted,
          name: sector_data.name,
          description: sector_data.description
        },
        // Conditionally add non-master list properties.
        ...(!master_list && {
          master_list_sector: sector_data.master_list_sector
        })
      };
    });
    return sectors;
  } catch (err: unknown) {
    const tracking_id: string = uuidv4();
    throw new MetadataError(
      err instanceof Error ? err.message : 'Error: getSectors failed on an unknown error.',
      {
        master_list: master_list,
        group_id: group_id,
        company_id: company_id
      },
      tracking_id
    );
  }
};

/**
 * Query all unconfigured sector documents for current portal company.
 * @param {Group} group_id id of current portal company.
 * @param {string} company_id id of current portal company.
 * @returns {Array<Sector>}
 */
export const getUnconfiguredSectors = async (group_id: string, company_id: string) => {
  try {
    const sector_master_list: Array<Sector> = await getSectors(true);
    const sectors: Array<Sector> = await getSectors(false, group_id, company_id);
    validateMasterListComparison(sector_master_list, sectors);
    return sector_master_list.filter((sector_master) =>
      sectors.every(
        (sector) =>
          !(sector.master_list_sector
            ? sector.master_list_sector.id.includes(sector_master.id)
            : '')
      )
    );
  } catch (err: unknown) {
    const tracking_id: string = uuidv4();
    throw new MetadataError(
      err instanceof Error
        ? err.message
        : 'Error: lib/metric_capture/sector.ts failed on an unknown error while calling getUnconfiguredSectors.',
      {
        group_id: group_id,
        company_id: company_id
      },
      tracking_id
    );
  }
};

/**
 * Create Sector data in firestore on master list or company level
 * @param {boolean} master_list Flag to create new Sector within master list or company level collection
 * @param {Array<Sector | SectorData>} sectors Sector objects to create
 * @param {string | undefined} group_id Optional ID of Group to create sectors for
 * @param {string | undefined} company_id Optional ID of Company to create sectors for
 * @returns {Promise<Array<BatchWrite>>}
 */
export const createSectors = async (
  master_list: boolean,
  sectors: Array<Sector | SectorData>,
  group_id?: string,
  company_id?: string
): Promise<Array<BatchWrite>> => {
  try {
    const collection_path: string = createSectorCollectionPath(master_list, group_id, company_id);
    const sector_writes: Array<BatchWrite> = [];
    const audit_log_batch_writes: Array<BatchWrite> = [];
    sectors.forEach((sector: Sector | SectorData) => {
      const sector_ref: DocumentReference = doc(collection(db, collection_path));
      const sector_data: SectorData = {
        deleted: null,
        name: sector.name,
        ...(!master_list &&
          'id' in sector && {
            master_list_sector: doc(collection(db, 'sector_master_list'), sector.id)
          })
      };
      sector_writes.push({ reference: sector_ref, operation: 'create', data: sector_data });

      if (!master_list && group_id && company_id) {
        const audit_log_ref: DocumentReference = generateAuditLogDoc(group_id);
        const audit_log_data = generateAuditLogData(
          sector.name,
          'create',
          '',
          sector_ref,
          auth.currentUser?.email
        );
        audit_log_batch_writes.push({
          reference: audit_log_ref,
          operation: 'create',
          data: audit_log_data
        });
      }
    });
    await processBatchWrites(sector_writes).catch((error) => {
      throw new Error(error);
    });
    await processBatchWrites(audit_log_batch_writes).catch((error) => {
      throw new Error(error);
    });

    return sector_writes;
  } catch (err: unknown) {
    const tracking_id: string = uuidv4();
    throw new MetadataError(
      err instanceof Error
        ? err.message
        : 'Error: lib/metric_capture/sector.ts failed on an unknown error while calling createSectors.',
      {
        master_list: master_list,
        group_id: group_id,
        company_id: company_id,
        sectors: sectors
      },
      tracking_id
    );
  }
};

/**
 * Update sector with relative data
 * @param {boolean} master_list Flag to update sector in master list
 * @param {Sector} updated_sector New Sector data to push into document
 * @param {Sector} original_sector Old Sector data
 * @param {string} group_id Optional ID of Group to update sector for
 * @param {string} company_id Optional ID of Company to update sector for
 * @returns {Promise<void>}
 */
export const updateSector = async (
  master_list: boolean,
  updated_sector: Sector,
  original_sector: Sector,
  group_id?: string,
  company_id?: string
): Promise<void> => {
  try {
    const collection_path: string = createSectorCollectionPath(master_list, group_id, company_id);
    const sector_data: SectorData = {
      deleted: updated_sector.deleted,
      name: updated_sector.name
    };
    const updated_sector_doc: DocumentReference = await updateFirestoreDoc(
      collection_path,
      updated_sector.id,
      sector_data
    );
    if (!master_list && group_id && company_id) {
      await createAuditLog(
        group_id,
        'update',
        JSON.stringify(original_sector),
        JSON.stringify(sector_data),
        updated_sector_doc
      );
    }
  } catch (err: unknown) {
    const tracking_id: string = uuidv4();
    throw new MetadataError(
      err instanceof Error
        ? err.message
        : 'Error: lib/metric_capture/sector.ts failed on an unknown error while calling updateSector.',
      {
        group_id: group_id,
        company_id: company_id,
        updated_sector: updated_sector,
        original_sector: original_sector
      },
      tracking_id
    );
  }
};

/**
 * Soft delete a Sector.
 * @param {string} group_id ID of Group to delete sector for
 * @param {string} company_id ID of Company to delete sector for
 * @param {string} sector_id ID of sector to delete
 * @param {string} sector_group_id ID of sector group to find delete sector in
 * @returns {void}
 */
export const deleteSector = async (
  master_list: boolean,
  sector_id: string,
  sector_name: string,
  group_id?: string,
  company_id?: string
): Promise<void> => {
  const collection_path: string = createSectorCollectionPath(master_list, group_id, company_id);
  try {
    const sector_doc: DocumentReference = await deleteFirestoreDoc(collection_path, sector_id);
    if (!master_list && group_id && company_id) {
      await createAuditLog(group_id, 'delete', sector_name, '', sector_doc);
    }
  } catch (err: unknown) {
    const tracking_id: string = uuidv4();
    throw new MetadataError(
      err instanceof Error
        ? err.message
        : 'Error: lib/metric_capture/sector.ts failed on an unknown error while calling deleteSector.',
      {
        master_list: master_list,
        group_id: group_id,
        company_id: company_id,
        sector_id: sector_id,
        sector_name: sector_name
      },
      tracking_id
    );
  }
};
