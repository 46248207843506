import * as React from 'react';
import { Box, Drawer, Tooltip, Typography } from '@mui/material';
import AssignmentIndOutlinedIcon from '@mui/icons-material/AssignmentIndOutlined';
import { Role } from '@esg/esg-global-types';
import { FeedbackSnackbarContext } from '../../../context/FeedbackSnackbarContext';
import DeleteConfirmModal from '../../shared/modal/DeleteConfirmModal';
import EditIcon from '@mui/icons-material/Edit';
import ConfigAddWidget, { CreateInput } from '../ConfigAddWidget';
import { MetadataError } from '@ep/error-handling';
import { uuidv4 } from '@firebase/util';
import { log } from '../../../util/log';
import { DataGrid, GridActionsCellItem, GridColDef } from '@mui/x-data-grid';
import { PanelRolesLoading } from './PanelRolesLoading';
import { PanelRolesNoRows } from './PanelRolesNoRows';
import { PanelRolesToolbar } from './PanelRolesToolbar';
import DeleteIcon from '@mui/icons-material/Delete';
import { DocumentReference } from 'firebase/firestore';
import ConfigEditWidget, { EditInput } from '../ConfigEditWidget';
import {
  RoleData,
  createRole,
  deleteRole,
  getRoles,
  role_label,
  updateRole
} from '../../../lib/user_management/roles';

/**
 * Datagrid handling the display of master list Roles
 * @returns {JSX.Element}
 */
const PanelRoles = () => {
  const { setFeedbackData } = React.useContext(FeedbackSnackbarContext);
  const [actionRow, setActionRow] = React.useState<Role | null>(null);
  const [isCreateWidget, setIsCreateWidget] = React.useState<boolean>(true);
  const [roleRows, setRoleRows] = React.useState<Array<Role>>([]);
  const [displayWidgetPanelRight, setDisplayWidgetPanelRight] = React.useState<boolean>(false);
  const [openDialogDelete, setOpenDialogDelete] = React.useState<boolean>(false);
  const [gridLoading, setGridLoading] = React.useState<boolean>(true);

  // Handler functions
  const handleCreateClick = (): void => {
    setActionRow(null);
    setIsCreateWidget(true);
    setDisplayWidgetPanelRight(true);
  };

  const handleEditClick = (role: Role): void => {
    setActionRow(role);
    setIsCreateWidget(false);
    setDisplayWidgetPanelRight(true);
  };

  const handleDeleteClick = async (row: Role): Promise<void> => {
    try {
      setActionRow(row);
      setOpenDialogDelete(true);
    } catch (err: unknown) {
      const tracking_id: string = uuidv4();
      log(
        'error',
        new MetadataError(
          err instanceof Error
            ? err.message
            : 'Error: PanelRoles failed on an unknown error while calling handleDeleteClick.',
          {
            row: row
          },
          tracking_id
        )
      );
      setFeedbackData({
        message: `Unable to delete role. Tracking ID: ${tracking_id}`,
        state: true,
        type: 'error'
      });
    }
  };

  const handleUpdateRows = (rows_data: Array<Role>): void => {
    setRoleRows(rows_data);
  };

  const handleCloseDeleteModal = (): void => {
    setOpenDialogDelete(false);
  };

  const columns: Array<GridColDef> = [
    {
      field: 'name',
      headerName: 'Name',
      headerAlign: 'left',
      align: 'left',
      flex: 1
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: '',
      headerAlign: 'right',
      align: 'right',
      hideable: false,
      flex: 1,
      getActions: ({ row }) => {
        return [
          <>
            <GridActionsCellItem
              key={1}
              icon={
                <Tooltip title="Edit Role">
                  <EditIcon color={!row.locked ? 'primary' : 'action'} />
                </Tooltip>
              }
              size="large"
              label="Edit"
              sx={{
                color: 'primary.main'
              }}
              onClick={() => handleEditClick(row)}
              disabled={row.locked}
            />
            <GridActionsCellItem
              key={2}
              icon={
                <Tooltip title="Delete Role">
                  <DeleteIcon color="primary" />
                </Tooltip>
              }
              size="large"
              label="Delete"
              sx={{
                color: 'primary.main'
              }}
              onClick={() => handleDeleteClick(row)}
            />
          </>
        ];
      }
    }
  ];

  const create_inputs: readonly CreateInput[] = [
    {
      id: 'name',
      type: 'text',
      label: 'Name'
    }
  ];

  const edit_inputs: EditInput[] = [
    {
      id: 'name',
      type: 'text',
      label: 'Name'
    }
  ];

  // Row functions
  const fetchRows = async (): Promise<void> => {
    setGridLoading(true);
    try {
      // Load rows from database.
      const roles: Array<Role> = await getRoles();
      // Load rows to memory.
      setRoleRows(roles);
    } catch (err: unknown) {
      const tracking_id: string = uuidv4();
      log(
        'error',
        new MetadataError(
          err instanceof Error
            ? err.message
            : 'Error: PanelRoles failed on an unknown error while calling fetchRows.',
          {},
          tracking_id
        )
      );
      setFeedbackData({
        message: `Unable to fetch roles. Tracking ID: ${tracking_id}`,
        state: true,
        type: 'error'
      });
      return;
    } finally {
      setGridLoading(false);
    }
  };

  React.useEffect(() => {
    try {
      (async () => {
        setRoleRows([]);
        fetchRows();
      })().catch((error) => {
        throw new Error(error);
      });
    } catch (err: unknown) {
      const tracking_id: string = uuidv4();
      log(
        'error',
        new MetadataError(
          err instanceof Error
            ? err.message
            : 'Error: PanelRoles failed on an unknown error while initialising.',
          null,
          tracking_id
        )
      );
      setFeedbackData({
        message: `An error occurred. Tracking ID: ${tracking_id}`,
        state: true,
        type: 'error'
      });
    }
    return;
  }, []);

  const createRow = async (
    name: string,
    description: string,
    views: Array<string>
  ): Promise<void> => {
    try {
      const role_data: RoleData = {
        deleted: null,
        name: name
      };
      const new_role: DocumentReference = await createRole(role_data);
      if (new_role) {
        handleUpdateRows([
          {
            id: new_role.id,
            ...role_data
          },
          ...roleRows
        ]);
      }
    } catch (err: unknown) {
      const tracking_id: string = uuidv4();
      log(
        'error',
        new MetadataError(
          err instanceof Error
            ? err.message
            : 'Error: PanelRoles failed on an unknown error while calling createRow.',
          {
            name: name,
            description: description,
            views: views
          },
          tracking_id
        )
      );
      setFeedbackData({
        message: `Unable to create role. Tracking ID: ${tracking_id}`,
        state: true,
        type: 'error'
      });
    }
  };

  const updateRow = async (updated_row: Role, original_data: Role): Promise<void> => {
    try {
      await updateRole(updated_row).then(() => {
        handleUpdateRows(
          roleRows.map((row: Role) => (updated_row.id === row.id ? { ...updated_row } : row))
        );
      });
    } catch (err: unknown) {
      const tracking_id: string = uuidv4();
      log(
        'error',
        new MetadataError(
          err instanceof Error
            ? err.message
            : 'Error: PanelRole failed on an unknown error while calling updateRow.',
          {
            updated_row: updated_row,
            original_data: original_data
          },
          tracking_id
        )
      );
      setFeedbackData({
        message: `Unable to update role. Tracking ID: ${tracking_id}`,
        state: true,
        type: 'error'
      });
    }
  };

  const deleteRow = async (role: Role): Promise<void> => {
    try {
      await deleteRole(role.id).then(() => {
        setRoleRows(
          roleRows.filter((row: Role) => {
            return row.id !== role.id;
          })
        );
      });
    } catch (err: unknown) {
      const tracking_id: string = uuidv4();
      log(
        'error',
        new MetadataError(
          err instanceof Error
            ? err.message
            : 'Error: PanelRoles failed on an unknown error while calling deleteRow.',
          {
            role: role
          },
          tracking_id
        )
      );
      setFeedbackData({
        message: `Unable to remove role. Tracking ID: ${tracking_id}`,
        state: true,
        type: 'error'
      });
    } finally {
      setOpenDialogDelete(false);
    }
  };

  return (
    <>
      {/* Delete Confirmation Modal */}
      <DeleteConfirmModal
        open={openDialogDelete}
        allow_delete={true}
        handleCloseDeleteModal={handleCloseDeleteModal}
        delete_label={actionRow && actionRow.name}
        handleDelete={actionRow && (() => deleteRow(actionRow))}
        allow_archive={false}
      />

      {/* Side Widget Panel */}
      <Drawer
        anchor={'right'}
        open={displayWidgetPanelRight}
        onClose={() => setDisplayWidgetPanelRight(false)}
        PaperProps={{ style: { width: '30%', padding: '1.5rem' } }}
      >
        {/* Create / Edit Widget */}
        {isCreateWidget ? (
          <>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                flexWrap: 'wrap'
              }}
            >
              <AssignmentIndOutlinedIcon sx={{ marginRight: '2rem' }} fontSize="large" />
              <Typography variant="h5" color="inherit" align="left">
                Add {`${role_label.one}`}
              </Typography>
            </Box>
            <ConfigAddWidget
              create_label={role_label.one}
              create_icon={
                <AssignmentIndOutlinedIcon sx={{ marginRight: '2rem' }} fontSize="large" />
              }
              create_function_inputs={create_inputs}
              handleClose={() => setDisplayWidgetPanelRight(false)}
              createFunction={(name: string, description: string, views: Array<string>) =>
                createRow(name, description, views)
              }
              hide_configured_entities={true}
              hide_title={true}
            />
          </>
        ) : (
          <ConfigEditWidget
            edit_label="Role"
            edit_icon={<AssignmentIndOutlinedIcon sx={{ marginRight: '2rem' }} fontSize="large" />}
            handleClose={() => setDisplayWidgetPanelRight(false)}
            edit_entity={actionRow}
            edit_function_inputs={edit_inputs}
            handleEditInput={(input_id: string, value: unknown) =>
              actionRow && setActionRow({ ...actionRow, [input_id]: value })
            }
            confirmEditFunction={(updated_role: Role, original_role: Role) =>
              updateRow(updated_role, original_role)
            }
          />
        )}
      </Drawer>

      {/* Interactive Data Table */}
      <DataGrid
        autoHeight
        rowHeight={75}
        initialState={{
          pagination: {
            paginationModel: { pageSize: 10, page: 0 }
          },
          sorting: {
            sortModel: [{ field: 'modified', sort: 'desc' }]
          }
        }}
        hideFooter={roleRows.length > 1 ? false : true}
        columnHeaderHeight={roleRows.length < 1 ? 0 : 56}
        pageSizeOptions={[10, 25, 50, 100]}
        loading={gridLoading}
        rows={roleRows}
        columns={columns}
        disableRowSelectionOnClick
        slots={{
          toolbar: PanelRolesToolbar,
          noRowsOverlay: PanelRolesNoRows,
          loadingOverlay: PanelRolesLoading
        }}
        slotProps={{ toolbar: { handleCreate: handleCreateClick } }}
        sx={{ '&, [class^=MuiDataGrid]': { border: 'none' }, width: '100%' }}
      />
    </>
  );
};

export default PanelRoles;
