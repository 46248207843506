import React from 'react';
import { Box, Grid, Skeleton } from '@mui/material';
import { CaptureContext, MetricRecordEntities } from '../../@types/shared';
import { Site, Company, User, Group } from '@esg/esg-global-types';
import { CompanyContext } from '../../context/CompanyContext';
import { DocumentReference } from 'firebase/firestore';
import { UserContext } from '../../context/UserContext';
import { ViewAccess, getViewAccess } from '../../util/user_access';
import AccessDenied from '../shared/AccessDenied';
import { getMetricRecordEntities } from '../../util/metric_record_entities';
import { FeedbackSnackbarContext } from '../../context/FeedbackSnackbarContext';
import MetricRecordHub from '../../components/metric_collection_data_management/MetricRecordHub';
import LoadingScreen from '../../components/shared/LoadingScreen';
import { GroupContext } from '../../context/GroupContext';
import { MetadataError } from '@ep/error-handling';
import { uuidv4 } from '@firebase/util';
import { log } from '../../util/log';
import MetricRecordCaptureContext from '../../components/metric_collection_data_management/MetricRecordCaptureContext';
import Joyride, { Step, CallBackProps } from 'react-joyride';
import {
  default_steps,
  default_steps_qualitative,
  default_quantitative_capture_steps,
  quantitative_capture_steps_external,
  reporting_period_select_steps,
  reporting_period_select_average_steps,
  account_menu_hamburger_step
} from '../../components/metric_collection_data_management/TourSteps';
import { findTourIndexByTargetName } from '../../lib/app_tours/metric_record_capture_tour_util';
import Cookies from 'js-cookie';
import { useTheme } from '@mui/material';

/**
 * View for handling Qualitative and Quantitative Metric Record capturing.
 * @returns {JSX.Element}
 */
const MetricCollectionDataManagement = () => {
  const app_theme = useTheme();
  const { setFeedbackData } = React.useContext(FeedbackSnackbarContext);
  const group: Group | null = React.useContext(GroupContext);
  const company: Company | null = React.useContext(CompanyContext);
  const user_info: User | null = React.useContext(UserContext);
  const [access, setAccess] = React.useState<ViewAccess>(null);
  const [showMetricRecords, setShowMetricRecords] = React.useState<boolean>(false);
  const [loadingEntities, setLoadingEntities] = React.useState<boolean>(true);
  const [captureEntities, setCaptureEntities] = React.useState<MetricRecordEntities>({
    countries: [],
    regions: [],
    divisions: [],
    subdivisions: [],
    sites: [],
    reporting_period_groups: [],
    reporting_periods: [],
    metrics: [],
    user_types: [],
    external_companies: [],
    emission_factors: []
  });
  const [captureContext, setCaptureContext] = React.useState<CaptureContext>({
    country: null,
    region: null,
    division: null,
    subdivision: null,
    site: null,
    reporting_period_group: null,
    reporting_period: null,
    user_type: null,
    external_company: null,
    level: '',
    standard: null
  });
  const [runTour, setRunTour] = React.useState<boolean>(false);
  const [tourSteps, setTourSteps] = React.useState<Array<Step>>(default_steps);
  const [tourStepIndex, setTourStepIndex] = React.useState<number>(0);

  const handleTourStepsChange = (new_context: CaptureContext) => {
    try {
      let new_steps = [...default_steps];
      if (new_context.standard?.is_quantitative) {
        if (new_context.reporting_period_group?.id !== 'all') {
          new_steps = [...new_steps, ...reporting_period_select_average_steps];
        } else {
          new_steps = [...new_steps, ...reporting_period_select_steps];
        }
        if (new_context.level !== 'import') {
          new_context.user_type && new_context.user_type.id === 'external'
            ? (new_steps = [...new_steps, ...quantitative_capture_steps_external])
            : (new_steps = [...new_steps, ...default_quantitative_capture_steps]);
        } else {
          new_steps = [...new_steps, account_menu_hamburger_step];
        }
      } else {
        new_steps = [...new_steps, ...default_steps_qualitative, account_menu_hamburger_step];
      }
      setTourSteps(new_steps);
    } catch (err: unknown) {
      const tracking_id: string = uuidv4();
      log(
        'error',
        new MetadataError(
          err instanceof Error
            ? err.message
            : 'Error: MetricCollectionDataManagement failed on an unknown error while calling handleTourStepsChange.',
          null,
          tracking_id
        )
      );
    }
  };

  const handleJoyrideCallback = (data: CallBackProps) => {
    const { action, index, type } = data;
    if (type === 'step:after') {
      if (action === 'prev') {
        if (index === findTourIndexByTargetName(tourSteps, '#delete-metric-record'))
          setTourStepIndex(findTourIndexByTargetName(tourSteps, '#edit-metric-record'));
        if (
          captureContext.level === 'import' &&
          index === findTourIndexByTargetName(tourSteps, '#reporting-period-group-select')
        )
          setTourStepIndex(findTourIndexByTargetName(tourSteps, '#capture-level-tab-0'));
        else setTourStepIndex(index - 1);
      } else if (action === 'next') {
        index === findTourIndexByTargetName(tourSteps, '#edit-metric-record')
          ? setTourStepIndex(findTourIndexByTargetName(tourSteps, '#delete-metric-record'))
          : setTourStepIndex(index + 1);

        index === findTourIndexByTargetName(tourSteps, '#start-capture-tour') &&
        captureContext.standard
          ? setTourStepIndex(findTourIndexByTargetName(tourSteps, '#capture-level-tab-0'))
          : setTourStepIndex(index + 1);
      }
    }
    if (type === 'tour:end') {
      if (action === 'skip' || action === 'next') {
        Cookies.set('disable-capture-tour', 'true');
        setRunTour(false);
      }
    }
  };

  const handleShowMetricRecords = (): void => {
    setShowMetricRecords(true);
    if (runTour) {
      setRunTour(false);
      setTimeout(() => {
        if (captureContext.standard?.is_quantitative && captureContext.level !== 'import') {
          setTourStepIndex(findTourIndexByTargetName(tourSteps, '#metric-group-select'));
        } else {
          setTourStepIndex(findTourIndexByTargetName(tourSteps, '#account-settings-hamburger'));
        }
        setRunTour(true);
      }, 100);
    }
  };

  const fetchCaptureEntities = async () => {
    setLoadingEntities(true);
    if (group && company && user_info) {
      const capture_entities: MetricRecordEntities | void = await getMetricRecordEntities(
        group.id,
        company.id,
        user_info
      ).catch((err: unknown) => {
        const tracking_id: string = uuidv4();
        log(
          'error',
          new MetadataError(
            err instanceof Error
              ? err.message
              : 'Error: MetricCollectionDataManagement failed while calling getMetricRecordEntities',
            {
              group: group,
              company: company,
              user_info: user_info
            },
            tracking_id
          )
        );
        setFeedbackData({
          message: `Unable to fetch capture entities. Tracking ID: ${tracking_id}`,
          state: true,
          type: 'error'
        });
      });
      if (capture_entities) {
        setCaptureEntities({
          countries: capture_entities.countries,
          regions: capture_entities.regions,
          divisions: capture_entities.divisions,
          subdivisions: capture_entities.subdivisions,
          sites: capture_entities.sites.map((site: Site) => {
            return { ...site, region: { ...site.region, id: site.region.id } as DocumentReference };
          }),
          reporting_period_groups: capture_entities.reporting_period_groups,
          reporting_periods: capture_entities.reporting_periods,
          metrics: capture_entities.metrics,
          user_types: capture_entities.user_types.filter((item) => item.id !== 'any'),
          external_companies: capture_entities.external_companies,
          emission_factors: capture_entities.emission_factors
        });
      }
    }
    setLoadingEntities(false);
  };

  React.useEffect(() => {
    handleContextChange({
      country: null,
      region: null,
      division: null,
      subdivision: null,
      site: null,
      reporting_period_group: null,
      reporting_period: null,
      user_type: null,
      external_company: null,
      level: '',
      standard: null
    });
    (async () => {
      setLoadingEntities(true);
      setCaptureEntities({
        countries: [],
        regions: [],
        divisions: [],
        subdivisions: [],
        sites: [],
        reporting_period_groups: [],
        reporting_periods: [],
        metrics: [],
        user_types: [],
        external_companies: [],
        emission_factors: []
      });
      await fetchCaptureEntities();
      setShowMetricRecords(false);
      setTourStepIndex(0);
    })();
  }, [company, group]);

  const handleContextChange = (new_context: CaptureContext) => {
    // Reset the Company Name select field when the User Type select field has changed to Internal.
    if (new_context && new_context.user_type?.id === 'internal')
      new_context.external_company = null;
    handleTourStepsChange(new_context);
    setCaptureContext({
      ...captureContext,
      ...new_context
    });
    setShowMetricRecords(false);
  };

  React.useEffect(() => {
    setAccess(
      group && company && user_info
        ? getViewAccess(user_info, group.id, company.id, 'metric_collection.management')
        : null
    );
    if (!Cookies.get('disable-capture-tour')) {
      setRunTour(true);
    }
  }, [user_info, company]);

  return !user_info || access === null ? (
    <LoadingScreen />
  ) : access !== 'none' ? (
    <Box
      sx={{
        paddingY: 2
      }}
    >
      <Grid container spacing={4}>
        <Grid item xs={12}>
          {loadingEntities || !company ? (
            <Skeleton height={250} width={'100%'} />
          ) : (
            <>
              <Joyride
                steps={tourSteps}
                stepIndex={tourStepIndex}
                callback={handleJoyrideCallback}
                run={runTour}
                disableScrolling
                disableCloseOnEsc
                disableOverlay
                continuous={[
                  findTourIndexByTargetName(tourSteps, '#capture-by-heading'),
                  findTourIndexByTargetName(tourSteps, '#edit-metric-record'),
                  findTourIndexByTargetName(tourSteps, '#upload-supporting-docs'),
                  findTourIndexByTargetName(tourSteps, '#delete-metric-record'),
                  findTourIndexByTargetName(tourSteps, '#account-settings-hamburger'),
                  findTourIndexByTargetName(tourSteps, '#start-capture-tour')
                ].includes(tourStepIndex)}
                hideBackButton={[
                  findTourIndexByTargetName(tourSteps, '#save-record-changes'),
                  findTourIndexByTargetName(tourSteps, '#metric-group-select')
                ].includes(tourStepIndex)}
                showSkipButton
                styles={{
                  options: {
                    primaryColor: app_theme.palette.primary.main
                  }
                }}
              />
              <MetricRecordCaptureContext
                capture_context={captureContext}
                capture_entities={captureEntities}
                loading_entities={loadingEntities}
                run_tour={runTour}
                handleContextChange={handleContextChange}
                handleShowMetricRecords={handleShowMetricRecords}
                tour_steps={tourSteps}
                current_tour_index={tourStepIndex}
                setTourStepIndex={setTourStepIndex}
                setRunTour={setRunTour}
              />
            </>
          )}
        </Grid>
        <Grid item xs={12}>
          {showMetricRecords && (
            <MetricRecordHub
              capture_context={captureContext}
              capture_entities={captureEntities}
              tour_steps={tourSteps}
              run_tour={runTour}
              setTourStepIndex={setTourStepIndex}
              setRunTour={setRunTour}
            />
          )}
        </Grid>
      </Grid>
    </Box>
  ) : (
    <AccessDenied />
  );
};

export default MetricCollectionDataManagement;
