import React from 'react';
import {
  TextField,
  Button,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Box,
  Typography
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

interface TextListInputProps {
  input_label?: string;
  list_label?: string;
  initial_value?: Array<string>;
  handleListChange: (items: Array<string>) => void;
}

/**
 * Input to create and edit a list of text items
 * @param {string | undefined} input_label Label of the input to add new text items
 * @param {string | undefined} list_label Label of the current list of text items
 * @param {Array<string> | undefined} initial_value Initial list of text items if being edited
 * @param {void} handleListChange Use the updated list of text items in callback function
 * @returns {JSX.Element}
 */
const TextListInput = ({
  input_label,
  list_label,
  initial_value,
  handleListChange
}: TextListInputProps) => {
  const [inputValue, setInputValue] = React.useState<string>('');
  const [items, setItems] = React.useState<Array<string>>(initial_value ?? []);

  const handleAddItem = () => {
    if (inputValue.trim()) {
      const new_items: Array<string> = [inputValue, ...items];
      setItems(new_items);
      handleListChange(new_items);
      setInputValue('');
    }
  };

  const handleDeleteItem = (delete_item: string) => {
    const new_items: Array<string> = items.filter((item: string) => item !== delete_item);
    setItems(new_items);
    handleListChange(new_items);
  };

  return (
    <Box>
      <TextField
        label={input_label ?? 'Enter New Value'}
        value={inputValue}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => setInputValue(event.target.value)}
        fullWidth
      />

      <Button variant="contained" onClick={handleAddItem} sx={{ my: 4 }}>
        Add to List
      </Button>
      <Typography sx={{ fontSize: '1.2rem' }}>{list_label ?? 'Items'}</Typography>
      <List sx={{ mt: 2 }}>
        {items.map((item: string) => (
          <ListItem key={item}>
            <ListItemText primary={item} />
            <IconButton edge="end" onClick={() => handleDeleteItem(item)}>
              <DeleteIcon />
            </IconButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

export default TextListInput;
