import React from 'react';
import { Box, Typography } from '@mui/material';
import SearchOffIcon from '@mui/icons-material/SearchOff';

export const PanelRegionsNoRows = () => {
  return (
    <Box
      sx={{
        height: 100,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        color: 'primary.main'
      }}
    >
      <SearchOffIcon fontSize="large" />
      <Typography variant="body1" component="h2">
        No Regions found.
      </Typography>
    </Box>
  );
};
