import * as React from 'react';
import { Box, Drawer, Tooltip, Typography } from '@mui/material';
import AddModeratorIcon from '@mui/icons-material/AddModerator';
import { Company, Group, Sector, Standard } from '@esg/esg-global-types';
import {
  StandardData,
  allowDeleteStandard,
  createStandards,
  deleteStandardWithMetrics,
  getStandards,
  getStandardsMasterList,
  standard_label,
  updateStandard
} from '../../../lib/app/standard';
import { FeedbackSnackbarContext } from '../../../context/FeedbackSnackbarContext';
import DeleteConfirmModal from '../../shared/modal/DeleteConfirmModal';
import EditIcon from '@mui/icons-material/Edit';
import ConfigAddWidget, { CreateInput } from '../ConfigAddWidget';
import { MetadataError } from '@ep/error-handling';
import { uuidv4 } from '@firebase/util';
import { log } from '../../../util/log';
import {
  DataGrid,
  GridActionsCellItem,
  GridColDef,
  GridValueFormatterParams,
  GridValueGetterParams
} from '@mui/x-data-grid';
import moment from 'moment';
import { PanelStandardsToolbar } from './PanelStandardsToolbar';
import { PanelStandardsNoRows } from './PanelStandardsNoRows';
import { PanelStandardsLoading } from './PanelStandardsLoading';
import { GroupContext } from '../../../context/GroupContext';
import { CompanyContext } from '../../../context/CompanyContext';
import DeleteIcon from '@mui/icons-material/Delete';
import StandardSelect from '../../shared/input/select/StandardSelect';
import { PanelTabs, PanelTabsWrapper } from '../../shared/tab/PanelTabsWrapper';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import SectorSelect from '../../shared/input/select/SectorSelect';
import ConfigEditWidget, { EditInput } from '../ConfigEditWidget';
import { BatchWrite } from '../../../lib/app/db_util';

interface PanelStandardsProps {
  master_list?: boolean;
}

/**
 * Datagrid handling the display of configured or Master List Standards
 * @returns {JSX.Element}
 */
const PanelStandards = ({ master_list }: PanelStandardsProps) => {
  const { setFeedbackData } = React.useContext(FeedbackSnackbarContext);
  const group: Group | null = React.useContext(GroupContext);
  const company: Company | null = React.useContext(CompanyContext);
  const [actionRow, setActionRow] = React.useState<Standard | null>(null);
  const [isCreateWidget, setIsCreateWidget] = React.useState<boolean>(true);
  const [standardRows, setStandardRows] = React.useState<Array<Standard>>([]);
  const [displayWidgetPanelRight, setDisplayWidgetPanelRight] = React.useState<boolean>(false);
  const [openDialogDelete, setOpenDialogDelete] = React.useState<boolean>(false);
  const [allowStandardDelete, setAllowStandardDelete] = React.useState<boolean | null>(
    master_list ? true : null
  );
  const [gridLoading, setGridLoading] = React.useState<boolean>(true);

  const standardsInputFactory = (
    handleInputChange: (input_id: string, value: Standard | Array<Standard> | null) => void
  ): JSX.Element => {
    return (
      <StandardSelect
        group_id={group ? group.id : ''}
        company_id={company ? company.id : ''}
        master_list={false}
        multi_select={true}
        unconfigured_only={true}
        group_by="category"
        handleChangeStandards={(standards) => {
          handleInputChange('standards', standards);
        }}
      />
    );
  };

  const sectorInputFactory = (
    handleInputChange: (input_id: string, value: Sector | null) => void
  ): JSX.Element => {
    return (
      <SectorSelect
        master_list
        handleChangeSectors={(sector: Array<Sector> | Sector | null) => {
          handleInputChange('sector', sector as Sector | null);
        }}
      />
    );
  };

  // Handler functions
  const handleCreateClick = (): void => {
    setActionRow(null);
    setIsCreateWidget(true);
    setDisplayWidgetPanelRight(true);
  };

  const handleEditClick = (standard: Standard): void => {
    setActionRow(standard);
    setIsCreateWidget(false);
    setDisplayWidgetPanelRight(true);
  };

  const handleDeleteClick = async (row: Standard): Promise<void> => {
    try {
      if (!master_list && group && company) {
        setAllowStandardDelete(await allowDeleteStandard(group.id, company.id, row.id));
      }
      setActionRow(row);
      setOpenDialogDelete(true);
    } catch (err: unknown) {
      const tracking_id: string = uuidv4();
      log(
        'error',
        new MetadataError(
          err instanceof Error
            ? err.message
            : 'Error: PanelStandards failed on an unknown error while calling handleDeleteClick.',
          {
            group: group,
            company: company,
            row: row
          },
          tracking_id
        )
      );
      setFeedbackData({
        message: `Unable to delete standard. Tracking ID: ${tracking_id}`,
        state: true,
        type: 'error'
      });
    }
  };

  const handleUpdateRows = (rows_data: Array<Standard>): void => {
    setStandardRows(rows_data);
  };

  const handleCloseDeleteModal = (): void => {
    setOpenDialogDelete(false);
  };

  const columns: Array<GridColDef> = [
    {
      field: 'name',
      headerName: 'Name',
      headerAlign: 'left',
      align: 'left',
      flex: 1
    },
    {
      field: 'is_quantitative',
      headerName: 'Type',
      headerAlign: 'left',
      align: 'left',
      flex: 1,
      valueGetter: (params: GridValueGetterParams) => {
        return params.value ? 'Quantitative' : 'Qualitative';
      }
    },
    {
      field: 'category',
      headerName: 'Category',
      headerAlign: 'left',
      align: 'left',
      flex: 1
    },
    {
      field: 'version',
      headerName: 'Version',
      headerAlign: 'left',
      align: 'left',
      flex: 1
    },
    {
      field: 'require_emission_factor',
      headerName: 'Emission Factors',
      headerAlign: 'left',
      align: 'left',
      flex: 1,
      renderCell: (params) => {
        return params.value ? <CheckIcon /> : <CloseIcon />;
      }
    },
    {
      field: 'require_site_level',
      headerName: 'Site Level',
      headerAlign: 'left',
      align: 'left',
      flex: 1,
      renderCell: (params) => {
        return params.value ? <CheckIcon /> : <CloseIcon />;
      }
    },
    {
      field: 'created',
      headerName: 'Added',
      headerAlign: 'left',
      align: 'left',
      flex: 1,
      valueFormatter: (params: GridValueFormatterParams) => {
        return moment(params.value).format('DD MMM YYYY').toString();
      }
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: '',
      headerAlign: 'right',
      align: 'right',
      hideable: false,
      flex: 1,
      getActions: ({ row }) => {
        return [
          <>
            {master_list && (
              <GridActionsCellItem
                key={1}
                icon={
                  <Tooltip title="Edit Standard">
                    <EditIcon color={!row.locked ? 'primary' : 'action'} />
                  </Tooltip>
                }
                size="large"
                label="Edit"
                sx={{
                  color: 'primary.main'
                }}
                onClick={() => handleEditClick(row)}
                disabled={row.locked}
              />
            )}
            <GridActionsCellItem
              key={2}
              icon={
                <Tooltip title="Delete Standard">
                  <DeleteIcon color="primary" />
                </Tooltip>
              }
              size="large"
              label="Delete"
              sx={{
                color: 'primary.main'
              }}
              onClick={() => handleDeleteClick(row)}
            />
          </>
        ];
      }
    }
  ];

  const create_inputs: readonly CreateInput[] = master_list
    ? [
        {
          id: 'sector',
          type: 'node',
          label: 'Sector',
          inputNodeFactory: sectorInputFactory
        },
        {
          id: 'name',
          type: 'text',
          label: 'Name'
        },
        {
          id: 'category',
          type: 'text',
          label: 'Category'
        },
        {
          id: 'version',
          type: 'text',
          label: 'Version'
        },
        {
          id: 'is_quantitative',
          type: 'boolean',
          label: 'Standard is Quantitative',
          defaultValue: false
        },
        {
          id: 'require_emission_factor',
          type: 'boolean',
          label: 'Standard requires Emission Factors',
          defaultValue: false
        },
        {
          id: 'require_site_level',
          type: 'boolean',
          label: 'Standard is Site Level',
          defaultValue: false
        }
      ]
    : [
        {
          id: 'standards',
          type: 'node',
          label: 'Standards',
          inputNodeFactory: standardsInputFactory
        }
      ];

  const edit_inputs: EditInput[] = master_list
    ? [
        {
          id: 'name',
          type: 'text',
          label: 'Name'
        },
        {
          id: 'category',
          type: 'text',
          label: 'Category'
        },
        {
          id: 'version',
          type: 'text',
          label: 'Version'
        },
        {
          id: 'is_quantitative',
          type: 'boolean',
          label: 'Standard is Quantitative'
        },
        {
          id: 'require_emission_factor',
          type: 'boolean',
          label: 'Standard requires Emission Factors'
        },
        {
          id: 'require_site_level',
          type: 'boolean',
          label: 'Standard is Site Level'
        }
      ]
    : [];

  // Row functions
  const fetchRows = async (): Promise<void> => {
    setGridLoading(true);
    try {
      // Load rows from database.
      const standards: Array<Standard> = master_list
        ? await getStandardsMasterList()
        : group && company
          ? await getStandards(group.id, company.id)
          : [];
      // Load rows to memory.
      setStandardRows(standards);
    } catch (err: unknown) {
      const tracking_id: string = uuidv4();
      log(
        'error',
        new MetadataError(
          err instanceof Error
            ? err.message
            : 'Error: PanelStandards failed on an unknown error while calling fetchRows.',
          {
            group: group,
            company: company
          },
          tracking_id
        )
      );
      setFeedbackData({
        message: `Unable to fetch standards. Tracking ID: ${tracking_id}`,
        state: true,
        type: 'error'
      });
      return;
    } finally {
      setGridLoading(false);
    }
  };

  React.useEffect(
    () => {
      try {
        (async () => {
          setStandardRows([]);
          fetchRows();
        })().catch((error) => {
          throw new Error(error);
        });
      } catch (err: unknown) {
        const tracking_id: string = uuidv4();
        log(
          'error',
          new MetadataError(
            err instanceof Error
              ? err.message
              : 'Error: PanelStandards failed on an unknown error while initialising.',
            null,
            tracking_id
          )
        );
        setFeedbackData({
          message: `An error occurred. Tracking ID: ${tracking_id}`,
          state: true,
          type: 'error'
        });
      }
      return;
    },
    master_list ? [] : [group, company]
  );

  const createRowsFromMasterList = async (standard_list: Array<Standard>): Promise<void> => {
    try {
      if (!master_list && group && company) {
        const new_standard_writes: Array<BatchWrite> = await createStandards(
          false,
          standard_list,
          group.id,
          company.id
        );
        if (new_standard_writes) {
          handleUpdateRows([
            ...new_standard_writes.map((new_standard_write: BatchWrite) => {
              return {
                id: new_standard_write.reference.id,
                created: new_standard_write.data.created,
                deleted: new_standard_write.data.deleted,
                version: new_standard_write.data.version,
                name: new_standard_write.data.name,
                category: new_standard_write.data.category,
                sector: new_standard_write.data.sector,
                master_list_standard: new_standard_write.data.master_list_standard,
                is_quantitative: new_standard_write.data.is_quantitative,
                require_emission_factor: new_standard_write.data.require_emission_factor,
                require_site_level: new_standard_write.data.require_site_level,
                reference: new_standard_write.reference
              };
            }),
            ...standardRows
          ]);
        }
      }
    } catch (err: unknown) {
      const tracking_id: string = uuidv4();
      log(
        'error',
        new MetadataError(
          err instanceof Error
            ? err.message
            : 'Error: PanelStandards failed on an unknown error while calling createRowsFromMasterList.',
          {
            group: group,
            company: company,
            standard_list: standard_list
          },
          tracking_id
        )
      );
      setFeedbackData({
        message: `Unable to create standards. Tracking ID: ${tracking_id}`,
        state: true,
        type: 'error'
      });
    }
  };

  const createRow = async (
    name: string,
    category: string,
    version: string,
    is_quantitative: boolean,
    require_emission_factor: boolean,
    require_site_level: boolean,
    sector: Sector
  ): Promise<void> => {
    try {
      const standard_data: StandardData = {
        created: new Date(),
        deleted: null,
        name: name,
        category: category,
        version: version,
        is_quantitative: is_quantitative,
        require_emission_factor: require_emission_factor,
        require_site_level: require_site_level,
        sector: sector
      };
      const new_standard_writes: Array<BatchWrite> = await createStandards(!!master_list, [
        standard_data
      ]);
      if (new_standard_writes) {
        handleUpdateRows([
          ...new_standard_writes.map((new_standard_write: BatchWrite) => {
            return {
              id: new_standard_write.reference.id,
              created: new_standard_write.data.created,
              deleted: new_standard_write.data.deleted,
              version: new_standard_write.data.version,
              name: new_standard_write.data.name,
              category: new_standard_write.data.category,
              sector: new_standard_write.data.sector,
              master_list_standard: new_standard_write.data.master_list_standard,
              is_quantitative: new_standard_write.data.is_quantitative,
              require_emission_factor: new_standard_write.data.require_emission_factor,
              require_site_level: new_standard_write.data.require_site_level,
              reference: new_standard_write.reference
            };
          }),
          ...standardRows
        ]);
      }
    } catch (err: unknown) {
      const tracking_id: string = uuidv4();
      log(
        'error',
        new MetadataError(
          err instanceof Error
            ? err.message
            : 'Error: PanelStandards failed on an unknown error while calling createRow.',
          {
            name: name,
            category: category,
            version: version,
            is_quantitative: is_quantitative,
            require_emission_factor: require_emission_factor,
            require_site_level: require_site_level
          },
          tracking_id
        )
      );
      setFeedbackData({
        message: `Unable to create standards. Tracking ID: ${tracking_id}`,
        state: true,
        type: 'error'
      });
    }
  };

  const updateRow = async (updated_row: Standard, original_data: Standard): Promise<void> => {
    try {
      await updateStandard(!!master_list, updated_row, original_data, group?.id, company?.id).then(
        () => {
          handleUpdateRows(
            standardRows.map((row: Standard) =>
              updated_row.id === row.id ? { ...updated_row } : row
            )
          );
        }
      );
    } catch (err: unknown) {
      const tracking_id: string = uuidv4();
      log(
        'error',
        new MetadataError(
          err instanceof Error
            ? err.message
            : 'Error: PanelStandards failed on an unknown error while calling updateRow.',
          {
            master_list: master_list,
            group: group,
            company: company,
            updated_row: updated_row,
            original_data: original_data
          },
          tracking_id
        )
      );
      setFeedbackData({
        message: `Unable to update standard. Tracking ID: ${tracking_id}`,
        state: true,
        type: 'error'
      });
    }
  };

  const deleteRow = async (standard: Standard): Promise<void> => {
    try {
      await deleteStandardWithMetrics(
        !!master_list,
        standard.id,
        standard.name,
        group?.id,
        company?.id
      ).then(() => {
        setStandardRows(
          standardRows.filter((row: Standard) => {
            return row.id !== standard.id;
          })
        );
      });
    } catch (err: unknown) {
      const tracking_id: string = uuidv4();
      log(
        'error',
        new MetadataError(
          err instanceof Error
            ? err.message
            : 'Error: PanelStandards failed on an unknown error while calling deleteRow.',
          {
            group: group,
            company: company,
            standard: standard
          },
          tracking_id
        )
      );
      setFeedbackData({
        message: `Unable to remove standard. Tracking ID: ${tracking_id}`,
        state: true,
        type: 'error'
      });
    } finally {
      setOpenDialogDelete(false);
    }
  };

  return (
    <>
      {/* Delete Confirmation Modal */}
      <DeleteConfirmModal
        open={openDialogDelete}
        allow_delete={allowStandardDelete}
        delete_warning="Any Metrics and Emission Factors related to this Standard will also be deleted"
        handleCloseDeleteModal={handleCloseDeleteModal}
        delete_label={actionRow && actionRow.name}
        handleDelete={actionRow && (() => deleteRow(actionRow))}
        allow_archive={false}
      />

      {/* Side Widget Panel */}
      <Drawer
        anchor={'right'}
        open={displayWidgetPanelRight}
        onClose={() => setDisplayWidgetPanelRight(false)}
        PaperProps={{ style: { width: '30%', padding: '1.5rem' } }}
      >
        {/* Create / Edit Widget */}
        {isCreateWidget ? (
          <>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                flexWrap: 'wrap'
              }}
            >
              <AddModeratorIcon sx={{ marginRight: '2rem' }} fontSize="large" />
              <Typography variant="h5" color="inherit" align="left">
                Add {(master_list ? 'Master List ' : '') + standard_label.one}
              </Typography>
            </Box>
            {master_list ? (
              <ConfigAddWidget
                create_label={standard_label.one}
                create_icon={<AddModeratorIcon sx={{ marginRight: '2rem' }} fontSize="large" />}
                create_function_inputs={create_inputs}
                handleClose={() => setDisplayWidgetPanelRight(false)}
                createFunction={(
                  sector: Sector,
                  name: string,
                  category: string,
                  version: string,
                  is_quantitative: boolean,
                  require_emission_factor: boolean,
                  require_site_level: boolean
                ) =>
                  createRow(
                    name,
                    category,
                    version,
                    is_quantitative,
                    require_emission_factor,
                    require_site_level,
                    sector
                  )
                }
                hide_configured_entities={true}
                hide_title={true}
              />
            ) : (
              <PanelTabsWrapper>
                <PanelTabs label="Generic" disabled={false}>
                  <ConfigAddWidget
                    create_label={standard_label.one}
                    create_icon={<AddModeratorIcon sx={{ marginRight: '2rem' }} fontSize="large" />}
                    create_function_inputs={create_inputs}
                    existing_entities={standardRows}
                    handleClose={() => setDisplayWidgetPanelRight(false)}
                    createFunction={(standard_list: Array<Standard>) =>
                      createRowsFromMasterList(standard_list)
                    }
                    hide_configured_entities={true}
                    hide_title={true}
                  />
                </PanelTabs>
                <PanelTabs label={'Custom'} disabled={true} />
              </PanelTabsWrapper>
            )}
          </>
        ) : (
          <ConfigEditWidget
            edit_label="Standard"
            edit_icon={<AddModeratorIcon sx={{ marginRight: '2rem' }} fontSize="large" />}
            handleClose={() => setDisplayWidgetPanelRight(false)}
            edit_entity={actionRow}
            edit_function_inputs={edit_inputs}
            handleEditInput={(input_id: string, value: unknown) =>
              actionRow && setActionRow({ ...actionRow, [input_id]: value })
            }
            confirmEditFunction={(updated_standard: Standard, original_standard: Standard) =>
              updateRow(updated_standard, original_standard)
            }
          />
        )}
      </Drawer>

      {/* Interactive Data Table */}
      <DataGrid
        autoHeight
        initialState={{
          pagination: {
            paginationModel: { pageSize: 10, page: 0 }
          },
          sorting: {
            sortModel: [{ field: 'modified', sort: 'desc' }]
          }
        }}
        hideFooter={standardRows.length > 1 ? false : true}
        columnHeaderHeight={standardRows.length < 1 ? 0 : 56}
        pageSizeOptions={[10, 25, 50, 100]}
        loading={gridLoading}
        rows={standardRows}
        columns={columns}
        disableRowSelectionOnClick
        slots={{
          toolbar: PanelStandardsToolbar,
          noRowsOverlay: PanelStandardsNoRows,
          loadingOverlay: PanelStandardsLoading
        }}
        slotProps={{ toolbar: { handleCreate: handleCreateClick } }}
        sx={{ '&, [class^=MuiDataGrid]': { border: 'none' }, width: '100%' }}
      />
    </>
  );
};

export default PanelStandards;
