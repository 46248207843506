import React, { ReactNode } from 'react';
import {
  Box,
  Button,
  Grid,
  IconButton,
  SelectChangeEvent,
  Tab,
  Tabs,
  Typography
} from '@mui/material';
import { CaptureContext, MetricRecordEntities } from '../../@types/shared';
import MetricContextSelect from './MetricContextSelect';
import {
  Company,
  Division,
  Country,
  Group,
  Region,
  ReportingPeriod,
  ReportingPeriodGroup,
  Site,
  Standard,
  Subdivision,
  User
} from '@esg/esg-global-types';
import ReportingPeriodGroupSelect from '../shared/input/select/ReportingPeriodGroupSelect';
import moment from 'moment';
import {
  findHighestEndDateInGroup,
  findLowestStartDateInGroup
} from '../../lib/metric_capture/reporting_period_group';
import ReportingPeriodSelect from '../shared/input/select/ReportingPeriodSelect';
import { reportingPeriodFilterByGroup } from '../../lib/metric_capture/reporting_period';
import { GroupContext } from '../../context/GroupContext';
import { CompanyContext } from '../../context/CompanyContext';
import AlertBanner from '../shared/banner/AlertBanner';
import MetricCollectionWarningMessage from '../../views/metric_collection/MetricCollectionWarningMessage';
import { UserContext } from '../../context/UserContext';
import ExpandMore from '@mui/icons-material/ExpandMore';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import AccountTreeOutlinedIcon from '@mui/icons-material/AccountTreeOutlined';
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import StandardSelect from '../shared/input/select/StandardSelect';
import AddModeratorIcon from '@mui/icons-material/AddModerator';
import LockIcon from '@mui/icons-material/Lock';
import DivisionSelect from '../shared/input/select/DivisionSelect';
import RegionSelect from '../shared/input/select/RegionSelect';
import SiteSelect from '../shared/input/select/SiteSelect';
import { SiteExtended } from '../../lib/metric_capture/site';
import CountrySelect from '../shared/input/select/CountrySelect';
import SubdivisionSelect from '../shared/input/select/SubdivisionSelect';
import { Step } from 'react-joyride';
import { findTourIndexByTargetName } from '../../lib/app_tours/metric_record_capture_tour_util';
import HelpIcon from '@mui/icons-material/Help';
import Cookies from 'js-cookie';

interface CaptureLevel {
  id: string;
  label: string;
  input_fields: Array<string>;
  disabled: boolean;
}

interface CollapsedInfo {
  id: string;
  value: string | null;
  icon: React.ReactNode;
  end_icon?: React.ReactNode;
}

/**
 * A collapsable list of inputs that defines the context of metric records to be captured.
 * @param {CaptureContext} capture_context Object of context fields to be manipulated in parent
 * @param {MetricRecordEntities} capture_entities List of options user may set per context input
 * @param {boolean} loading_entities Flag to check if entity choices are still being fetched from db
 * @param {void} handleContextChange Handler function to update the capture context object in parent
 * @param {void} handleMetricsLoad Function executed on button click to show metric record grid in parent
 * @returns {JSX.Element}
 */
const MetricRecordCaptureContext = ({
  capture_context,
  capture_entities,
  loading_entities,
  tour_steps,
  run_tour,
  current_tour_index,
  handleContextChange,
  handleShowMetricRecords,
  setTourStepIndex,
  setRunTour
}: {
  capture_context: CaptureContext;
  capture_entities: MetricRecordEntities;
  loading_entities: boolean;
  tour_steps?: Array<Step>;
  run_tour?: boolean;
  current_tour_index?: number;
  handleContextChange: (new_context: CaptureContext) => void;
  handleShowMetricRecords: () => void;
  setTourStepIndex?: (step_index: number) => void;
  setRunTour?: (status: boolean) => void;
}) => {
  const user_info: User | null = React.useContext(UserContext);
  const group: Group | null = React.useContext(GroupContext);
  const company: Company | null = React.useContext(CompanyContext);
  const [tab, setTab] = React.useState<number>(0);
  const [collapse, setCollapse] = React.useState<boolean>(false);

  const capture_levels: Array<CaptureLevel> = [
    {
      id: 'company',
      label: 'Company',
      input_fields: ['reporting_period_group'],
      disabled: !capture_context.standard || capture_context.standard.is_quantitative
    },
    {
      id: 'national',
      label: 'Country',
      input_fields: ['country', 'reporting_period_group', 'reporting_period'],
      disabled:
        !capture_context.standard || capture_context.standard?.require_site_level ? true : false
    },
    {
      id: 'regional',
      label: 'Region',
      input_fields: ['country', 'region', 'reporting_period_group', 'reporting_period'],
      disabled:
        !capture_context.standard || capture_context.standard?.require_site_level ? true : false
    },
    {
      id: 'divisional',
      label: 'Division',
      input_fields: ['division', 'reporting_period_group', 'reporting_period'],
      disabled:
        !capture_context.standard || capture_context.standard?.require_site_level ? true : false
    },
    {
      id: 'subdivisional',
      label: 'Subdivision',
      input_fields: ['division', 'subdivision', 'reporting_period_group', 'reporting_period'],
      disabled:
        !capture_context.standard || capture_context.standard?.require_site_level ? true : false
    },
    {
      id: 'site',
      label: 'Site',
      input_fields: [
        'division',
        'subdivision',
        'site',
        'reporting_period_group',
        'reporting_period'
      ],
      disabled: !capture_context.standard ? true : false
    },
    {
      id: 'import',
      label: 'Import',
      input_fields: ['reporting_period_group', 'reporting_period'],
      disabled: !capture_context.standard ? true : false
    }
  ];

  const getStandardCaptureLevels = (standard: Standard): Array<CaptureLevel> => {
    return capture_levels.filter((level: CaptureLevel) => {
      return standard.is_quantitative
        ? standard.require_site_level
          ? level.id === 'site' || level.id === 'import'
          : level.id !== 'company'
        : level.id === 'company';
    });
  };

  const default_reporting_period_groups: Array<ReportingPeriodGroup> =
    capture_context.level === 'company'
      ? []
      : [
          {
            deleted: null,
            id: 'all',
            name: 'Capture By Reporting Period'
          }
        ];

  const averaged_reporting_period: ReportingPeriod = {
    id: 'all',
    start:
      capture_context.reporting_period_group && capture_context.reporting_period_group.id !== 'all'
        ? findLowestStartDateInGroup(
            capture_entities.reporting_period_groups,
            capture_entities.reporting_periods,
            capture_context.reporting_period_group
          )
        : moment(),
    end:
      capture_context.reporting_period_group && capture_context.reporting_period_group.id !== 'all'
        ? findHighestEndDateInGroup(
            capture_entities.reporting_period_groups,
            capture_entities.reporting_periods,
            capture_context.reporting_period_group
          )
        : moment(),
    deleted: null,
    locked: false,
    name: 'Average Over Group'
  };

  const current_capture_level: CaptureLevel | null = capture_context.standard
    ? getStandardCaptureLevels(capture_context.standard)[tab]
    : null;

  const current_input_fields: Array<string> = current_capture_level
    ? current_capture_level.input_fields
    : [];

  if (
    user_info &&
    group &&
    (user_info.type === 'any' || user_info.super_admin || user_info.groups[group.id].admin) &&
    capture_context.level !== 'company'
  ) {
    current_input_fields.push('user_type');
  }

  if (capture_context.user_type?.id === 'external' || user_info?.type === 'external') {
    current_input_fields.push('external_company');
  }

  const input_valid: boolean = current_input_fields.every((input_field: string) => {
    return capture_context[input_field] !== null;
  });

  const generateCollapsedInfo = (
    id: string,
    icon: React.ReactNode,
    input_fields: Array<string>
  ): CollapsedInfo => {
    let most_granular_value: string | null = null;
    let end_icon: ReactNode | undefined = undefined;
    for (const field of input_fields) {
      if (
        capture_context[field] &&
        capture_context[field].name &&
        capture_context[field].id !== 'all' &&
        !(field === 'reporting_period' && !capture_context.standard?.is_quantitative)
      ) {
        most_granular_value = capture_context[field].name;
      }
      end_icon = capture_context[field]?.locked ? (
        <LockIcon sx={{ ml: 'auto', color: 'primary', fontSize: '1.3rem' }} />
      ) : undefined;
    }
    return {
      id: id,
      icon: icon,
      value: most_granular_value,
      end_icon: end_icon
    };
  };

  const collapsed_info: Array<CollapsedInfo> = [
    {
      id: 'standard',
      icon: <AddModeratorIcon />,
      value: capture_context.standard?.name ? capture_context.standard?.name : 'Standard'
    },
    generateCollapsedInfo('location', <LocationOnOutlinedIcon />, ['country', 'region']),
    generateCollapsedInfo('organisation', <AccountTreeOutlinedIcon />, [
      'division',
      'subdivision',
      'site'
    ]),
    generateCollapsedInfo('reporting_period', <AccessAlarmIcon />, [
      'reporting_period_group',
      'reporting_period'
    ]),
    {
      id: 'source',
      icon: <AccountCircleOutlinedIcon />,
      value: capture_context.external_company ? capture_context.external_company.name : 'Internal'
    }
  ];

  let missing_entities = '';
  switch (current_capture_level?.id) {
    case 'company':
      if (capture_entities.reporting_period_groups.length === 0)
        [(missing_entities = 'Reporting Period Groups')];
      break;
    case 'national':
      if (capture_entities.countries.length === 0) {
        missing_entities = 'Countries';
      }
      break;
    case 'regional':
      if (capture_entities.regions.length === 0) {
        missing_entities = 'Regions';
      }
      break;
    case 'divisional':
      if (capture_entities.divisions.length === 0) {
        missing_entities = 'Divisions';
      }
      break;
    case 'subdivisional':
      if (capture_entities.subdivisions.length === 0) {
        missing_entities = 'Subdivisions';
      }
      break;
    case 'site':
      if (capture_entities.sites.length === 0) {
        missing_entities = 'Sites';
      }
      break;
    case 'import':
      if (capture_entities.metrics.length === 0) {
        missing_entities = 'Metrics';
      }
      break;
    default:
      break;
  }
  if (capture_entities.reporting_periods.length === 0) {
    missing_entities = 'Reporting Periods';
  }

  const handleTabChange = (event: React.SyntheticEvent, new_tab_value: number): void => {
    const new_context = {
      country: null,
      region: null,
      division: null,
      subdivision: null,
      site: null,
      reporting_period_group: null,
      reporting_period: null,
      user_type: null,
      external_company: null,
      level: capture_context.standard
        ? getStandardCaptureLevels(capture_context.standard)[new_tab_value].id
        : '',
      standard: capture_context.standard
    };
    handleContextChange(new_context);
    setTab(new_tab_value);
    if (setRunTour && run_tour && tour_steps) {
      setRunTour(false);
      setTimeout(() => {
        setTourStepIndex &&
          setTourStepIndex(
            findTourIndexByTargetName(
              tour_steps,
              new_context.level === 'import'
                ? '#reporting-period-group-select'
                : '#capture-input-grid-item-0'
            )
          );
        setRunTour(true);
      }, 50);
    }
  };

  return (
    <Box
      sx={{
        backgroundColor: '#FAFAFA',
        padding: 4,
        textAlign: 'start',
        borderRadius: '1rem',
        my: 2
      }}
    >
      <Grid container spacing={4}>
        {collapse ? (
          <>
            <Grid item xs={11} sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
              {collapsed_info
                .filter((input_info: CollapsedInfo) => input_info.value !== null)
                .map((input_info: CollapsedInfo) => {
                  return (
                    <Box
                      key={input_info.id}
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        color: '#37474F',
                        border: '1px solid #37474F',
                        borderRadius: '5px',
                        gap: 1,
                        py: 1,
                        px: 2,
                        mx: 2,
                        minWidth: '200px'
                      }}
                    >
                      {input_info.icon}
                      <Typography>{input_info.value}</Typography>
                      {input_info.end_icon}
                    </Box>
                  );
                })}
            </Grid>
            <Grid item xs={1} sx={{ display: 'flex', alignItems: 'center' }}>
              <IconButton
                sx={{ color: 'black' }}
                onClick={() => {
                  if (run_tour) {
                    setRunTour && setRunTour(false);
                    setCollapse(false);
                    setTimeout(() => {
                      tour_steps &&
                        setTourStepIndex &&
                        setTourStepIndex(
                          findTourIndexByTargetName(tour_steps, '#capture-input-grid-item-0')
                        );
                      setRunTour && setRunTour(true);
                    }, 100);
                  } else {
                    setCollapse(false);
                  }
                }}
              >
                <ExpandMore fontSize="large" />
              </IconButton>
            </Grid>
          </>
        ) : (
          <>
            <Grid item xs={11}>
              <Typography variant="h5" id="capture-by-heading">
                Capture by
              </Typography>
            </Grid>
            <Grid item xs={1}>
              <IconButton
                onClick={() => {
                  Cookies.remove('disable-capture-tour');
                  setRunTour && setRunTour(true);
                  setTourStepIndex &&
                    tour_steps &&
                    setTourStepIndex(findTourIndexByTargetName(tour_steps, '#capture-by-heading'));
                }}
              >
                <HelpIcon
                  id="start-capture-tour"
                  fontSize="large"
                  titleAccess="Help: Start Guided Tour"
                />
              </IconButton>
            </Grid>
            <Grid item xs={3} id={'standard-select-grid-item'}>
              <StandardSelect
                group_id={group ? group.id : ''}
                company_id={company ? company.id : ''}
                selected_options={capture_context.standard}
                handleChangeStandards={(standard) => {
                  const standard_casted = standard as Standard | null;
                  if (
                    tour_steps &&
                    run_tour &&
                    setRunTour &&
                    current_tour_index !==
                      findTourIndexByTargetName(tour_steps, '#capture-by-heading')
                  ) {
                    if (standard) {
                      setTourStepIndex &&
                        tour_steps &&
                        setTourStepIndex(
                          findTourIndexByTargetName(tour_steps, '#capture-level-tab-0')
                        );
                    } else {
                      setRunTour(false);
                      setTimeout(() => {
                        setTourStepIndex &&
                          tour_steps &&
                          setTourStepIndex(
                            findTourIndexByTargetName(tour_steps, '#standard-select')
                          );
                        setRunTour(true);
                      }, 100);
                    }
                  }
                  if (capture_context.standard?.id !== (standard as Standard)?.id) {
                    handleContextChange({
                      country: null,
                      region: null,
                      division: null,
                      subdivision: null,
                      site: null,
                      reporting_period_group: null,
                      reporting_period: null,
                      user_type: null,
                      external_company: null,
                      level: standard_casted ? getStandardCaptureLevels(standard_casted)[0].id : '',
                      standard: standard_casted
                    });
                    setTab(0);
                  }
                }}
                start_adornment={<AddModeratorIcon />}
                auto_select={true}
              />
            </Grid>
            {capture_context.standard && (
              <>
                <Grid item xs={12}>
                  <Tabs
                    value={tab}
                    onChange={handleTabChange}
                    aria-label="capture-context-tabs"
                    id="capture-context-tabs"
                  >
                    {getStandardCaptureLevels(capture_context.standard).map(
                      (capture_level: CaptureLevel, index: number) => {
                        return (
                          <Tab
                            id={`capture-level-tab-${index}`}
                            onClick={(event) => {
                              if (index === 0) handleTabChange(event, 0);
                            }}
                            key={capture_level.id}
                            label={capture_level.label}
                            value={index}
                            disabled={capture_level.disabled}
                          />
                        );
                      }
                    )}
                  </Tabs>
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={3} sx={{ my: 1 }} id="metric-context-select-grid">
                    {current_input_fields.map((capture_input: string, index: number) => {
                      return (
                        <Grid
                          key={capture_input}
                          item
                          xs={2.4}
                          id={`capture-input-grid-item-${index}`}
                        >
                          {capture_input === 'country' && (
                            <CountrySelect
                              options={capture_entities.countries}
                              selected_options={capture_context.country}
                              handleChangeCountries={(
                                countries: Country | Array<Country> | null
                              ) => {
                                if (!Array.isArray(countries)) {
                                  if (capture_context.level === 'national') {
                                    setTourStepIndex &&
                                      tour_steps &&
                                      setTourStepIndex(
                                        findTourIndexByTargetName(
                                          tour_steps,
                                          '#reporting-period-group-select'
                                        )
                                      );
                                  }
                                  handleContextChange({
                                    ...capture_context,
                                    country: countries,
                                    region: null
                                  });
                                }
                              }}
                              variant="standard"
                            />
                          )}
                          {capture_input === 'region' && (
                            <RegionSelect
                              auto_select
                              options={capture_entities.regions.filter((region: Region) => {
                                return capture_context.country
                                  ? region.country.id === capture_context.country.id
                                  : false;
                              })}
                              selected_options={capture_context.region}
                              disabled={
                                !capture_context.country ||
                                capture_entities.regions.length === 0 ||
                                current_capture_level?.disabled
                              }
                              handleChangeRegions={(regions: Region | Array<Region> | null) => {
                                if (!Array.isArray(regions)) {
                                  if (capture_context.level === 'regional')
                                    setTourStepIndex &&
                                      tour_steps &&
                                      setTourStepIndex(
                                        findTourIndexByTargetName(
                                          tour_steps,
                                          '#reporting-period-group-select'
                                        )
                                      );
                                  handleContextChange({
                                    ...capture_context,
                                    region: regions
                                  });
                                }
                              }}
                            />
                          )}
                          {capture_input === 'division' && (
                            <DivisionSelect
                              auto_select
                              options={capture_entities.divisions}
                              selected_options={capture_context.division}
                              disabled={
                                capture_entities.divisions.length === 0 ||
                                current_capture_level?.disabled
                              }
                              handleChangeDivisions={(
                                divisions: Division | Array<Division> | null
                              ) => {
                                if (!Array.isArray(divisions)) {
                                  if (capture_context.level === 'divisional')
                                    setTourStepIndex &&
                                      tour_steps &&
                                      setTourStepIndex(
                                        findTourIndexByTargetName(
                                          tour_steps,
                                          '#reporting-period-group-select'
                                        )
                                      );
                                  handleContextChange({
                                    ...capture_context,
                                    division: divisions,
                                    subdivision: null,
                                    site: null
                                  });
                                }
                              }}
                            />
                          )}
                          {capture_input === 'subdivision' && (
                            <SubdivisionSelect
                              auto_select
                              options={capture_entities.subdivisions.filter(
                                (subdivision: Subdivision) => {
                                  return capture_context.division
                                    ? subdivision.division.id === capture_context.division.id
                                    : false;
                                }
                              )}
                              selected_options={capture_context.subdivision}
                              handleChangeSubdivisions={(
                                subdivisions: Subdivision | Array<Subdivision> | null
                              ) => {
                                if (!Array.isArray(subdivisions)) {
                                  if (capture_context.level === 'subdivisional')
                                    setTourStepIndex &&
                                      tour_steps &&
                                      setTourStepIndex(
                                        findTourIndexByTargetName(
                                          tour_steps,
                                          '#reporting-period-group-select'
                                        )
                                      );
                                  handleContextChange({
                                    ...capture_context,
                                    subdivision: subdivisions,
                                    site: null
                                  });
                                }
                              }}
                              variant="standard"
                            />
                          )}
                          {capture_input === 'site' && (
                            <SiteSelect
                              auto_select
                              options={capture_entities.sites.filter((site: Site) => {
                                return capture_context.subdivision
                                  ? site.subdivision.id === capture_context.subdivision.id
                                  : false;
                              })}
                              selected_options={capture_context.site}
                              handleChangeSites={(
                                sites: SiteExtended | Site | Array<SiteExtended | Site> | null
                              ) => {
                                if (!Array.isArray(sites)) {
                                  if (capture_context.level === 'site')
                                    setTourStepIndex &&
                                      tour_steps &&
                                      setTourStepIndex(
                                        findTourIndexByTargetName(
                                          tour_steps,
                                          '#reporting-period-group-select'
                                        )
                                      );
                                  handleContextChange({
                                    ...capture_context,
                                    site: sites
                                  });
                                }
                              }}
                            />
                          )}
                          {capture_input === 'reporting_period_group' && (
                            <ReportingPeriodGroupSelect
                              disabled={
                                (!!current_input_fields[index - 1] &&
                                  !capture_context[current_input_fields[index - 1]]) ||
                                current_capture_level?.disabled
                              }
                              selected_reporting_period_group={
                                capture_context.reporting_period_group
                              }
                              reporting_period_group_options={default_reporting_period_groups.concat(
                                capture_entities.reporting_period_groups
                              )}
                              handleChangeReportingPeriodGroups={(
                                value: Array<ReportingPeriodGroup> | ReportingPeriodGroup | null
                              ) => {
                                if (!Array.isArray(value)) {
                                  handleContextChange({
                                    ...capture_context,
                                    reporting_period_group: value,
                                    reporting_period: null
                                  });
                                  capture_context.level !== 'company'
                                    ? setTourStepIndex &&
                                      tour_steps &&
                                      setTourStepIndex(
                                        findTourIndexByTargetName(
                                          tour_steps,
                                          '#reporting-period-select'
                                        )
                                      )
                                    : setTourStepIndex &&
                                      tour_steps &&
                                      setTourStepIndex(
                                        findTourIndexByTargetName(tour_steps, '#view-data-button')
                                      );
                                }
                              }}
                            />
                          )}
                          {capture_input === 'reporting_period' && (
                            <ReportingPeriodSelect
                              selected_reporting_periods={capture_context.reporting_period}
                              reporting_period_options={
                                capture_context.reporting_period_group?.id === 'all'
                                  ? reportingPeriodFilterByGroup(
                                      capture_context.reporting_period_group?.id ?? 'all',
                                      capture_entities.reporting_periods
                                    )
                                  : [averaged_reporting_period as ReportingPeriod].concat(
                                      reportingPeriodFilterByGroup(
                                        capture_context.reporting_period_group?.id ?? 'all',
                                        capture_entities.reporting_periods
                                      )
                                    )
                              }
                              disabled={
                                !capture_context.reporting_period_group ||
                                capture_entities.reporting_periods.length === 0 ||
                                current_capture_level?.disabled
                              }
                              handleChangeReportingPeriods={(value) => {
                                if (setTourStepIndex && tour_steps) {
                                  capture_context.user_type
                                    ? setTourStepIndex(
                                        findTourIndexByTargetName(tour_steps, '#view-data-button')
                                      )
                                    : setTourStepIndex(
                                        findTourIndexByTargetName(tour_steps, '#user-type-select')
                                      );
                                }
                                handleContextChange({
                                  ...capture_context,
                                  reporting_period: value as ReportingPeriod
                                });
                              }}
                            />
                          )}
                          {capture_input === 'user_type' && (
                            <MetricContextSelect
                              id="user-type-select"
                              label="Capture As"
                              value={
                                capture_context.user_type
                                  ? JSON.stringify(capture_context.user_type)
                                  : ''
                              }
                              disabled={
                                !capture_context.reporting_period ||
                                capture_entities.user_types.length === 0 ||
                                current_capture_level?.disabled
                              }
                              choices={capture_entities.user_types}
                              onChangeHandler={(event: SelectChangeEvent) => {
                                handleContextChange({
                                  ...capture_context,
                                  user_type: JSON.parse(event.target.value)
                                });
                                if (JSON.parse(event.target.value).id === 'internal')
                                  setTourStepIndex &&
                                    tour_steps &&
                                    setTourStepIndex(
                                      tour_steps.findIndex((element) => {
                                        return element.target === '#view-data-button';
                                      })
                                    );
                              }}
                            />
                          )}
                          {capture_input === 'external_company' && (
                            <MetricContextSelect
                              id="external-company-select"
                              label="External Company"
                              value={
                                capture_context.external_company
                                  ? JSON.stringify(capture_context.external_company)
                                  : ''
                              }
                              disabled={
                                capture_entities.external_companies.length === 0 ||
                                capture_context.user_type?.id === 'internal' ||
                                !capture_context.reporting_period ||
                                current_capture_level?.disabled
                              }
                              choices={capture_entities.external_companies}
                              onChangeHandler={(event: SelectChangeEvent<string>) => {
                                setTourStepIndex &&
                                  tour_steps &&
                                  setTourStepIndex(
                                    findTourIndexByTargetName(tour_steps, '#view-data-button')
                                  );
                                handleContextChange({
                                  ...capture_context,
                                  external_company: JSON.parse(event.target.value)
                                });
                              }}
                            />
                          )}
                        </Grid>
                      );
                    })}
                    <Grid
                      item
                      xs={2.4}
                      sx={{
                        marginTop: 1
                      }}
                    >
                      <Button
                        id="view-data-button"
                        variant="contained"
                        disabled={!input_valid}
                        onClick={() => {
                          handleShowMetricRecords();
                          setCollapse(true);
                        }}
                      >
                        {current_capture_level?.id === 'import'
                          ? 'View Imports'
                          : 'View Metric Records'}
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </>
            )}

            {missing_entities && !loading_entities && company && user_info && (
              <Grid item xs={12}>
                <AlertBanner
                  severity="warning"
                  message={MetricCollectionWarningMessage(
                    missing_entities,
                    company?.name,
                    group?.id,
                    user_info
                  )}
                  open
                  width_perc={100}
                />
              </Grid>
            )}
            {!missing_entities &&
              !loading_entities &&
              company &&
              capture_entities.external_companies.length === 0 &&
              user_info &&
              capture_context.user_type?.id === 'external' && (
                <Grid item xs={12}>
                  <AlertBanner
                    severity="warning"
                    message={MetricCollectionWarningMessage(
                      'External Companies',
                      company?.name,
                      group?.id,
                      user_info
                    )}
                    open
                    width_perc={100}
                  />
                </Grid>
              )}
          </>
        )}
        {capture_context.reporting_period && capture_context.reporting_period.id === 'all' && (
          <Grid item xs={12}>
            <AlertBanner
              severity="warning"
              message={
                'You are capturing on a reporting period group level, all captured data will be split evenly between each period in the reporting group.'
              }
              open
              width_perc={100}
            />
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default MetricRecordCaptureContext;
