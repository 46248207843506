import {
  QuerySnapshot,
  QueryDocumentSnapshot,
  DocumentData,
  DocumentReference,
  CollectionReference,
  collection,
  doc,
  runTransaction,
  Transaction,
  DocumentSnapshot,
  getDocs
} from 'firebase/firestore';
import {
  MetricGroup,
  Metric,
  EntityLabel,
  Standard,
  EmissionFactor,
  EmissionFactorData
} from '@esg/esg-global-types';
import { FirestoreQueryParam } from '../../@types/shared';
import {
  BatchWrite,
  createFirestoreDoc,
  deleteFirestoreDoc,
  processBatchWrites,
  readFirestoreDocs,
  updateFirestoreDoc
} from '../app/db_util';
import { uuidv4 } from '@firebase/util';
import { MetadataError } from '@ep/error-handling';
import {
  createMetricGroupCollectionPath,
  getMetricGroupByName,
  getMetricGroups
} from './metric_group';
import { validateMasterListComparison, validateMasterListParams } from '../../util/validation';
import { CachedStandard, getStandards, getStandardsMasterList, refStandard } from '../app/standard';
import { db } from '../google/firebase';
import { createAuditLog } from '../app/audit';
import { refCompanyDoc } from '../app/company';
import { getMetricRecords } from './metric_record';
import moment from 'moment';
import { refEmissionFactor } from '../app/emission_factor';

export interface MetricExtended extends Metric {
  metric_group: MetricGroup;
}

export interface StandardNameToReferenceMap {
  [name: string]: DocumentReference;
}
export interface MetricGroupNameToIdMap {
  [name: string]: string;
}

export interface MetricData extends Omit<Metric, 'id'> {
  metric_group?: MetricGroup;
}

// Singular and plural label for model entity.
export const metric_label: EntityLabel = {
  one: 'Metric',
  many: 'Metrics'
};

/**
 * Creates a database reference for a Metric entity, either master list or for a configured company.
 * @param {string | undefined} id Id for a specific document. An undefined value is used to reference the collection to create a new document.
 * @param {string} metric_group_id The metric group to which a metric belongs or should belong.
 * @param {string} group_id When not referencing a master list document or collection, a group id for the configured company is required.
 * @param {string} company_id When not referencing a master list document or collection, a company id for the configured company is required.
 * @returns {DocumentReference} The reference of the document or collection.
 */
export const refMetric = (
  master_list: boolean,
  id: string | undefined,
  metric_group_id: string,
  group_id?: string,
  company_id?: string
): DocumentReference => {
  const collection_path: string = createMetricCollectionPath(
    master_list,
    metric_group_id,
    group_id,
    company_id
  );
  return id ? doc(collection(db, collection_path), id) : doc(collection(db, collection_path));
};

/**
 * Create a collection path string for the master list metrics or configured metrics.
 * @param {boolean} master_list Reference the master list collection.
 * @param {string} metric_group_id Metric group id to which a metric belongs.
 * @param {string} group_id When not referencing a master list collection, a group id for the configured company is required.
 * @param {string} company_id When not referencing a master list collection, a company id for the configured company is required.
 * @returns {string} Path to a metrics collection.
 */
export const createMetricCollectionPath = (
  master_list: boolean,
  metric_group_id: string,
  group_id?: string,
  company_id?: string
): string => {
  try {
    validateMasterListParams(master_list, group_id, company_id);
    return master_list
      ? `/metric_group_master_list/${metric_group_id}/metrics`
      : `groups/${group_id}/companies/${company_id}/metric_groups/${metric_group_id}/metrics`;
  } catch (err: unknown) {
    const tracking_id: string = uuidv4();
    throw new MetadataError(
      err instanceof Error
        ? err.message
        : 'Error: getMetricCollectionPath failed on an unknown error.',
      {
        master_list: master_list,
        group_id: group_id,
        company_id: company_id
      },
      tracking_id
    );
  }
};

/**
 * Creates a standardised map of an MetricExtended object from a Firestore snapshot, aiming to follow the DRY principle.
 * @param {QuerySnapshot} snapshot Query result from Firestore.
 * @param {boolean} master_list Conditionally include non-master list properties.
 * @param {MetricGroup} metric_group A fully mapped MetricGroup object to be added as part of MetricExtended.
 * @returns {Promise<Array<MetricExtended>>} List of mapped metric objects that includes a fully mapped metric group object.
 */
export const mapMetricExtendedFromSnapshot = async (
  snapshot: QuerySnapshot,
  master_list: boolean,
  metric_group: MetricGroup
): Promise<Array<MetricExtended>> => {
  try {
    const metrics: Array<MetricExtended> = snapshot.docs.map((metric: QueryDocumentSnapshot) => {
      const metric_data: DocumentData = metric.data();
      return {
        ...{
          id: metric.id,
          deleted: metric_data.deleted,
          name: metric_data.name,
          description: metric_data.description,
          unit: metric_data.unit,
          scope: metric_data.scope,
          metric_group: metric_group,
          standard: metric_data.standard // TODO: Optionally map full Standard object (as per getMetrics) and replace the mapping logic in getMetrics with mapMetricExtendedFromSnapshot.
        },
        // Conditionally add non-master list properties to metric object.
        ...(!master_list &&
          metric_data.master_list_metric && {
            master_list_metric: metric_data.master_list_metric
          })
      };
    });
    return metrics;
  } catch (err: unknown) {
    const tracking_id: string = uuidv4();
    throw new MetadataError(
      err instanceof Error
        ? err.message
        : 'Error: mapMetricExtendedFromSnapshot failed on an unknown error.',
      {
        snapshot: snapshot,
        master_list: master_list
      },
      tracking_id
    );
  }
};

/**
 * Query all metric documents for specified group and company joined with relative Metric Group, and optional Standard, data.
 * @param {boolean} master_list Return master list or configured metric documents.
 * @param {string} group_id ID of configured group.
 * @param {string} company_id ID of configured company.
 * @param {Array<string>} standard_ids Query metrics within specified Standard id's.
 * @param {Array<string>} metric_group_ids Query metrics within specified Metric Group ids.
 * @param {boolean} get_relative_standard Optionally query relative Standard data associated with each Metric.
 * @returns {Promise<Array<MetricExtended>>}
 */
export const getMetrics = async (
  master_list: boolean,
  group_id?: string,
  company_id?: string,
  standard_ids?: Array<string>,
  get_relative_standard?: boolean,
  metric_group_ids?: Array<string>
): Promise<Array<MetricExtended>> => {
  try {
    validateMasterListParams(master_list, group_id, company_id);
    // Query metric groups from Firestore.
    let metric_groups: Array<MetricGroup> = master_list
      ? await getMetricGroups(true)
      : await getMetricGroups(false, group_id, company_id);
    if (metric_group_ids) {
      metric_groups = metric_groups.filter((metric_group: MetricGroup) =>
        metric_group_ids.includes(metric_group.id)
      );
    }
    const metrics: Array<MetricExtended> = [];
    const metric_group_promises: Array<Promise<void>> = metric_groups.map(
      async (metric_group: MetricGroup) => {
        const collection_path: string = createMetricCollectionPath(
          master_list,
          metric_group.id,
          group_id,
          company_id
        );
        const query_params: Array<FirestoreQueryParam> = [
          { field_name: 'deleted', operator: '==', value: null }
        ];
        // Define query parameters for fetching metrics with a relationship to the specified standard id's.
        if (standard_ids !== undefined && standard_ids.length > 0) {
          const standards: Array<DocumentReference> = [];
          standard_ids.forEach((id: string) => {
            standards.push(refStandard(master_list, id, group_id, company_id));
          });
          query_params.push({ field_name: 'standard', operator: 'in', value: standards });
        }
        // Create standards cache to reduce the amount of queries to the Standards collection.
        const metric_standard_cache: CachedStandard = {};
        if (master_list) {
          const metric_standards = await getStandardsMasterList();
          metric_standards.map((standard: Standard) => {
            metric_standard_cache[standard.id] = standard;
          });
        } else if (company_id && group_id) {
          const metric_standards = await getStandards(group_id, company_id);
          metric_standards.map((standard: Standard) => {
            metric_standard_cache[standard.id] = standard;
          });
        }
        // Query metrics from Firestore.
        const metric_snapshot: QuerySnapshot = await readFirestoreDocs(
          collection_path,
          query_params
        );
        const metric_promises: Array<Promise<void>> = metric_snapshot.docs.map(
          async (metric: QueryDocumentSnapshot) => {
            const metric_data: DocumentData = metric.data();
            // Build generic metric object.
            const metric_data_mapped = {
              ...{
                id: metric.id,
                deleted: metric_data.deleted,
                name: metric_data.name,
                description: metric_data.description,
                unit: metric_data.unit,
                scope: metric_data.scope,
                metric_group: metric_group,
                reference: metric.ref
              },
              // Conditionally add non-master list properties to metric object.
              ...(!master_list &&
                metric_data.master_list_metric && {
                  master_list_metric: metric_data.master_list_metric
                })
            };
            // Append a Standard object to the Metrics array without querying the Standards collection for each Metric.
            if (get_relative_standard) {
              const metric_standard_id = metric_data.standard.id;
              const cached_metric_standard: Standard = metric_standard_cache[metric_standard_id];
              if (cached_metric_standard) {
                metric_data_mapped['standard'] = cached_metric_standard;
                metrics.push(metric_data_mapped);
              } else {
                const standard_ref: DocumentReference = refStandard(
                  master_list,
                  metric_standard_id,
                  group_id,
                  company_id
                );
                const metric_standards: Array<Standard> = master_list
                  ? await getStandardsMasterList()
                  : await getStandards(group_id || '', company_id || '', undefined, [standard_ref]);
                metric_snapshot.forEach((metric: QueryDocumentSnapshot) => {
                  metric_standard_cache[metric.id] = metric.data() as Standard;
                });
                metric_data_mapped['standard'] = metric_standards[0];
                // Push only metrics for non-deleted standards.
                if (metric_data_mapped['standard']) metrics.push(metric_data_mapped);
              }
            } else {
              metric_data_mapped['standard'] = metric_data.standard;
              metrics.push(metric_data_mapped);
            }
          }
        );
        await Promise.all(metric_promises);
      }
    );
    await Promise.all(metric_group_promises);
    return metrics;
  } catch (err: unknown) {
    const tracking_id: string = uuidv4();
    throw new MetadataError(
      err instanceof Error ? err.message : 'Error: getMetrics failed on an unknown error.',
      {
        group_id: group_id,
        company_id: company_id
      },
      tracking_id
    );
  }
};

/**
 * Query all unconfigured metric documents for specified group and company joined with an optional relative Standard object.
 * @param {string} group_id ID of configured group.
 * @param {string} company_id ID of configured company.
 * @param {Array<string>} standard_ids Query metrics within specified Standard id's.
 * @param {boolean} get_relative_standard Optionally query relative Standard data associated with each Metric.
 * @returns {Promise<Array<MetricExtended>>}
 */
export const getUnconfiguredMetrics = async (
  group_id: string,
  company_id: string,
  standard_ids?: Array<string>,
  get_relative_standard?: boolean
): Promise<Array<MetricExtended>> => {
  try {
    const metric_master_list: Array<MetricExtended> = await getMetrics(
      true,
      undefined,
      undefined,
      standard_ids,
      get_relative_standard
    );
    const metrics: Array<MetricExtended> = await getMetrics(
      false,
      group_id,
      company_id,
      undefined,
      get_relative_standard
    );
    validateMasterListComparison(metric_master_list, metrics);
    // Get the difference between the master and configured Metric list.
    const unconfigured_metrics: Array<MetricExtended> = metric_master_list.filter(
      (metric_master: MetricExtended) =>
        metrics.every(
          (metric: MetricExtended) =>
            !(metric.master_list_metric?.id
              ? metric.master_list_metric?.id.includes(metric_master.id)
              : '')
        )
    );
    return unconfigured_metrics;
  } catch (err: unknown) {
    const tracking_id: string = uuidv4();
    throw new MetadataError(
      err instanceof Error
        ? err.message
        : 'Error: getUnconfiguredMetrics failed on an unknown error.',
      {
        group_id: group_id,
        company_id: company_id
      },
      tracking_id
    );
  }
};

/**
 * Create Metric data in firestore on master list or company level
 * @param {boolean} master_list Flag to create new Metric within master list or company level collection
 * @param {Array<MetricExtended | MetricData>} metrics list of new metrics to be added to the database.
 * @param {boolean} emission_factors Optionally add default emission factors to the new metrics.
 * @param {string | undefined} group_id Optional ID of Group to create metrics for
 * @param {string | undefined} company_id Optional ID of Company to create metrics for
 * @returns {Promise<Array<BatchWrite>>}
 */
export const createMetrics = async (
  master_list: boolean,
  metrics: Array<MetricExtended | MetricData>,
  emission_factors?: Array<EmissionFactor>,
  group_id?: string,
  company_id?: string
): Promise<Array<BatchWrite>> => {
  // TODO: Add check to ensure we cannot add a metric with a name that already exist.
  try {
    {
      const standards: Array<Standard> =
        !master_list && group_id && company_id
          ? await getStandards(group_id, company_id)
          : await getStandardsMasterList();
      const standard_reference_map: StandardNameToReferenceMap = {};
      standards.map((standard: Standard) => {
        standard_reference_map[standard.name] = standard.reference;
      });
      const metric_groups: Array<MetricGroup> = await getMetricGroups(
        master_list,
        group_id,
        company_id
      );
      const metric_group_id_map: MetricGroupNameToIdMap = {};
      metric_groups.map((metric_group: MetricGroup) => {
        metric_group_id_map[metric_group.name] = metric_group.id;
      });

      const metric_writes: Array<BatchWrite> = [];
      const emission_factor_writes: Array<BatchWrite> = [];

      for (const metric of metrics) {
        if (metric.metric_group && metric.standard) {
          const metric_standard: Standard = metric.standard as Standard;
          const master_list_metric_collection: CollectionReference = collection(
            db,
            `/metric_group_master_list/${metric.metric_group.id}/metrics`
          );
          const master_list_metric_reference: DocumentReference | null =
            !master_list && 'id' in metric ? doc(master_list_metric_collection, metric.id) : null;

          if (!metric_group_id_map[metric.metric_group.name]) {
            const metric_group_collection_path: string = createMetricGroupCollectionPath(
              master_list,
              group_id,
              company_id
            );
            const new_metric_group_doc: DocumentReference = await createFirestoreDoc(
              metric_group_collection_path,
              {
                deleted: null,
                description: metric.metric_group.description,
                ...(!master_list && {
                  master_list_metric_group: doc(
                    db,
                    `metric_group_master_list/${metric.metric_group.id}`
                  ),
                  name: metric.metric_group.name
                })
              }
            );
            metric_group_id_map[metric.metric_group.name] = new_metric_group_doc.id;
          }
          const metric_collection_path: string = createMetricCollectionPath(
            master_list,
            metric_group_id_map[metric.metric_group.name],
            group_id,
            company_id
          );
          const metrics_ref: DocumentReference = doc(collection(db, metric_collection_path));
          const metric_data: MetricData = {
            deleted: metric.deleted,
            description: metric.description,
            ...(!master_list &&
              master_list_metric_reference && { master_list_metric: master_list_metric_reference }),
            name: metric.name,
            scope: metric.scope,
            standard: standard_reference_map[metric_standard.name],
            unit: metric.unit
          };
          metric_writes.push({ reference: metrics_ref, operation: 'create', data: metric_data });
          if (
            !master_list &&
            group_id &&
            company_id &&
            metric_standard.is_quantitative &&
            emission_factors &&
            'id' in metric
          ) {
            const matched_emission_factor: EmissionFactor | undefined = emission_factors.find(
              (emission_factor: EmissionFactor) => emission_factor.metric.id === metric.id
            );
            if (matched_emission_factor) {
              const emission_factor_data: EmissionFactorData = {
                deleted: null,
                end_date: matched_emission_factor.end_date,
                factor: matched_emission_factor.factor,
                source: matched_emission_factor.source,
                start_date: matched_emission_factor.start_date
              };
              const emission_factor_ref: DocumentReference = doc(
                collection(
                  db,
                  `/groups/${group_id}/companies/${company_id}/metric_groups/${metric_group_id_map[metric.metric_group.name]}/metrics/${metrics_ref.id}/emission_factors`
                )
              );

              emission_factor_writes.push({
                reference: emission_factor_ref,
                operation: 'create',
                data: emission_factor_data
              });
            }
          }
        }
      }
      await processBatchWrites(metric_writes);
      await processBatchWrites(emission_factor_writes);
      return metric_writes;
    }
  } catch (err: unknown) {
    const tracking_id: string = uuidv4();
    throw new MetadataError(
      err instanceof Error
        ? err.message
        : 'Error: lib/metric_capture/metric.ts failed on an unknown error while calling createMetrics.',
      {
        metrics: metrics,
        group_id,
        company_id,
        emission_factors: emission_factors
      },
      tracking_id
    );
  }
};

/**
 * Update metric with relative data
 * @param {boolean} master_list Flag to update metric in master list
 * @param {MetricExtended} updated_metric New metric data to push into document
 * @param {MetricExtended} original_metric Old metric data
 * @param {string} group_id Optional ID of Group to update metric for
 * @param {string} company_id Optional ID of Company to update metric for
 * @returns {void}
 */
export const updateMetric = async (
  master_list: boolean,
  updated_metric: MetricExtended,
  original_metric: MetricExtended,
  group_id?: string,
  company_id?: string
): Promise<void> => {
  try {
    const collection_path = createMetricCollectionPath(
      master_list,
      updated_metric.metric_group.id,
      group_id,
      company_id
    );
    const metric_data: MetricData = {
      deleted: updated_metric.deleted,
      name: updated_metric.name,
      description: updated_metric.description,
      unit: updated_metric.unit,
      scope: updated_metric.scope,
      standard: doc(
        db,
        master_list
          ? `standard_master_list/${updated_metric.standard.id}`
          : `groups/${group_id}/companies/${company_id}/standards/${updated_metric.standard.id}`
      )
    };
    const updated_metric_doc: DocumentReference = await updateFirestoreDoc(
      collection_path,
      updated_metric.id,
      metric_data
    );
    if (!master_list && group_id && company_id) {
      await createAuditLog(
        group_id,
        'update',
        JSON.stringify(original_metric),
        JSON.stringify(metric_data),
        updated_metric_doc
      );
    }
  } catch (err: unknown) {
    const tracking_id: string = uuidv4();
    throw new MetadataError(
      err instanceof Error
        ? err.message
        : 'Error: lib/metric_capture/metric.ts failed on an unknown error while calling updateMetric.',
      {
        group_id: group_id,
        company_id: company_id,
        updated_metric: updated_metric,
        original_metric: original_metric
      },
      tracking_id
    );
  }
};

/**
 * Soft delete a Metric.
 * @param {string} group_id ID of Group to delete metric for
 * @param {string} company_id ID of Company to delete metric for
 * @param {string} metric_id ID of metric to delete
 * @param {string} metric_group_id ID of metric group to find delete metric in
 * @returns {void}
 */
export const deleteMetric = async (
  group_id: string,
  company_id: string,
  metric_id: string,
  metric_group_id: string,
  metric_name: string
): Promise<void> => {
  const collection_path = `groups/${group_id}/companies/${company_id}/metric_groups/${metric_group_id}/metrics`;
  try {
    const metric_doc: DocumentReference = await deleteFirestoreDoc(collection_path, metric_id);
    await createAuditLog(group_id, 'delete', metric_name, '', metric_doc);
  } catch (err: unknown) {
    const tracking_id: string = uuidv4();
    throw new MetadataError(
      err instanceof Error
        ? err.message
        : 'Error: lib/metric_capture/metric.ts failed on an unknown error while calling deleteMetric.',
      {
        group_id: group_id,
        company_id: company_id,
        metric_id: metric_id,
        metric_group_id: metric_group_id,
        metric_name: metric_name
      },
      tracking_id
    );
  }
};

/**
 * Soft delete Metric and any nested Emission Factors in Firestore transaction
 * @param {boolean} master_list Flag to delete entities from their respective master lists
 * @param {string | undefined} group_id Optional Group to delete metric from
 * @param {string | undefined} company_id Optional Company to delete metric from
 * @param {string} metric_id ID of metric to delete
 * @param {string} metric_group_id ID of metric group to find metric in
 * @param {string} metric_name Name of metric to delete
 * @return {void}
 */
export const deleteMetricWithEmissionFactors = async (
  master_list: boolean,
  metric_id: string,
  metric_group_id: string,
  metric_name: string,
  group_id?: string,
  company_id?: string
): Promise<void> => {
  try {
    const metric_doc: DocumentReference = refMetric(
      master_list,
      metric_id,
      metric_group_id,
      group_id,
      company_id
    );
    const emission_factor_collection: CollectionReference = collection(
      metric_doc,
      'emission_factors'
    );
    const emission_factor_docs: QuerySnapshot = await getDocs(emission_factor_collection);

    await runTransaction(db, async (transaction: Transaction) => {
      const metric: DocumentSnapshot = await transaction.get(metric_doc);
      if (!metric.exists()) throw 'Could not find Metric document to delete.';
      const deleted_timestamp: Date = new Date();
      if (!master_list && group_id && company_id) {
        await createAuditLog(
          group_id,
          'update',
          metric.data().deleted,
          deleted_timestamp.toString(),
          metric_doc
        );
      }
      transaction.update(metric_doc, { deleted: deleted_timestamp });
      emission_factor_docs.docs.forEach((emission_factor: DocumentSnapshot) => {
        transaction.update(emission_factor.ref, { deleted: deleted_timestamp });
      });
    });
  } catch (err: unknown) {
    const tracking_id: string = uuidv4();
    throw new MetadataError(
      err instanceof Error
        ? err.message
        : 'Error: lib/metric_capture/metric.ts failed on an unknown error while calling deleteMetricWithEmissionFactors.',
      {
        group_id: group_id,
        company_id: company_id,
        metric_id: metric_id,
        metric_group_id: metric_group_id,
        metric_name: metric_name
      },
      tracking_id
    );
  }
};

/**
 * Check if a Metric can be safely deleted.
 * @param {string} group_id ID of Group to check Metric for
 * @param {string} company_id ID of Company to check Metric for
 * @param {string} metric_id ID of metric to check
 * @returns {boolean}
 */
export const allowDeleteMetric = async (
  group_id: string,
  company_id: string,
  metric_id: string,
  metric_group_id: string
): Promise<boolean> => {
  try {
    const query_params: Array<FirestoreQueryParam> = [
      {
        field_name: 'metric',
        operator: '==',
        value: doc(
          collection(
            refCompanyDoc(group_id, company_id),
            `metric_groups/${metric_group_id}/metrics`
          ),
          metric_id
        )
      }
    ];
    const metric_records = await getMetricRecords(group_id, company_id, query_params);
    const allow_delete = metric_records.length === 0;
    return allow_delete;
  } catch (err) {
    const error = `Error while checking Metric for deletion: ${JSON.stringify({
      message: err instanceof Error ? err.message : '',
      stacktrace: err instanceof Error ? err.stack : ''
    })}`;
    throw new Error(`Error: allowDeleteMetric: ${JSON.stringify(error)}.`);
  }
};

/**
 * Query a metric by metric name and metric group name.
 * @param {boolean} master_list Return master list or configured metric documents.
 * @param {string} metric_group_name Name of the metric's group.
 * @param {string} metric_name Name of the metric to be queried.
 * @param {string} group_id When not referencing a master list document or collection, a group id for the configured company is required.
 * @param {string} company_id When not referencing a master list document or collection, a company id for the configured company is required.
 * @param {boolean} deleted Query only deleted or non-deleted metrics. Defaults to both deleted and non-deleted metrics.
 * @returns {Promise<MetricExtended | undefined>} A single mapped metric record.
 */
export const getMetricByName = async (
  master_list: boolean,
  metric_group_name: string,
  metric_name: string,
  group_id?: string,
  company_id?: string,
  deleted?: boolean
): Promise<MetricExtended | undefined> => {
  // Query metric group by name from Firestore.
  const metric_group: MetricGroup | undefined = await getMetricGroupByName(
    master_list,
    metric_group_name,
    group_id,
    company_id
  );
  // No Metric Group found for Metric
  if (!metric_group) {
    return undefined;
  }
  // Query metric by name from Firestore.
  const collection_path: string = createMetricCollectionPath(
    master_list,
    metric_group.id,
    group_id,
    company_id
  );
  const query_params: Array<FirestoreQueryParam> = [
    { field_name: 'name', operator: '==', value: metric_name }
  ];
  // Conditionally query deleted or non-deleted metrics from Firestore.
  if (deleted !== undefined)
    query_params.push({
      field_name: 'deleted',
      operator: deleted === true ? '!=' : '==',
      value: null
    });
  const metric_snapshot: QuerySnapshot = await readFirestoreDocs(collection_path, query_params);
  const metrics: Array<MetricExtended> = await mapMetricExtendedFromSnapshot(
    metric_snapshot,
    master_list,
    metric_group
  );
  if (metrics.length > 1)
    throw new Error(
      `Error: getMetricByName: Found more than one metric for ${metric_name} in the group of ${metric_group_name}: ${JSON.stringify(metrics)}.`
    );
  const metric: MetricExtended = metrics[0];
  return metric;
};

/**
 * Undo a soft deleted Metric.
 * @param {string} group_id ID of Group to undelete metric for.
 * @param {string} company_id ID of Company to undelete metric for.
 * @param {string} metric_id ID of metric to undelete.
 * @param {string} metric_group_id ID of metric group to find undelete metric in
 * @returns {void}
 */
export const undeleteMetricWithEmissionFactors = async (
  group_id: string,
  company_id: string,
  metric_id: string,
  metric_group_id: string,
  emission_factor_create?: EmissionFactor
): Promise<void> => {
  try {
    const metric_doc: DocumentReference = refMetric(
      false,
      metric_id,
      metric_group_id,
      group_id,
      company_id
    );
    const emission_factor_collection: CollectionReference = collection(
      metric_doc,
      'emission_factors'
    );
    const emission_factor_docs: QuerySnapshot = await getDocs(emission_factor_collection);
    await runTransaction(db, async (transaction: Transaction) => {
      const metric: DocumentSnapshot = await transaction.get(metric_doc);
      if (!metric.exists()) throw 'Could not find Metric document to delete.';
      transaction.update(metric_doc, { deleted: null });
      await createAuditLog(group_id, 'update', metric.data().deleted, '', metric_doc);
      // Undelete existing emission factor.
      emission_factor_docs.docs.map(async (emission_factor: DocumentSnapshot) => {
        transaction.update(emission_factor.ref, { deleted: null });
      });
      // Create non-existing metric
      if (emission_factor_create) {
        const matched_emission_factor: DocumentSnapshot | undefined =
          emission_factor_docs.docs.find((emission_factor: DocumentSnapshot) => {
            const emission_factor_data: DocumentData | undefined = emission_factor.data();
            return (
              emission_factor_data &&
              emission_factor_create &&
              String(emission_factor_data.start_date.toDate()) ===
                String(emission_factor_create.start_date) &&
              String(emission_factor_data.end_date.toDate()) ===
                String(emission_factor_create.end_date) &&
              emission_factor_data.factor === emission_factor_create.factor
            );
          });
        if (emission_factor_docs.docs.length === 0 || !matched_emission_factor) {
          const emission_factor_create_ref: DocumentReference = refEmissionFactor(
            undefined,
            metric_group_id,
            metric_id,
            group_id,
            company_id
          );
          transaction.set(emission_factor_create_ref, {
            start_date: moment(emission_factor_create.start_date).toDate(),
            end_date: moment(emission_factor_create.end_date).toDate(),
            factor: emission_factor_create.factor,
            source: emission_factor_create.source,
            deleted: null
          });
        }
      }
    });
  } catch (err: unknown) {
    const tracking_id: string = uuidv4();
    throw new MetadataError(
      err instanceof Error
        ? err.message
        : 'Error: lib/metric_capture/metric.ts failed on an unknown error while calling undeleteMetricWithEmissionFactors.',
      {
        group_id: group_id,
        company_id: company_id,
        metric_id: metric_id,
        metric_group_id: metric_group_id
      },
      tracking_id
    );
  }
};
