import {
  Box,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography
} from '@mui/material';
import React from 'react';
import { MenuItem, PanelLeftMenuProps } from './PanelLeftMenuWrapper';
import LastPageOutlinedIcon from '@mui/icons-material/LastPageOutlined';
import FirstPageOutlinedIcon from '@mui/icons-material/FirstPageOutlined';

/**
 * Collapseable left menu navigation panel
 * @param {string} header Title of the left menu
 * @param {Array<MenuItem>} items Navigation items available to the user
 * @param {void} handleMenuSelect Send selected navigation item back to parent
 * @returns {JSX.Element}
 */
const PanelLeftMenu = ({ header, items, handleMenuSelect }: PanelLeftMenuProps) => {
  const [expanded, setExpanded] = React.useState<boolean>(true);

  return (
    <Box
      sx={{
        ...{ ml: -3, mt: -3, mb: -3, mr: 3 },
        minHeight: '100vh',
        backgroundColor: '#FAFAFA',
        border: '1px solid rgba(0, 0, 0, 0.25)',
        minWidth: '100px',
        maxWidth: '320px',
        width: expanded ? '30%' : '5%',
        transition: 'width 0.3s ease',
        overflow: 'hidden'
      }}
    >
      <Box
        sx={{
          mx: 4,
          my: 3,
          display: 'flex',
          alignItems: 'center',
          justifyContent: expanded ? 'start' : 'center'
        }}
      >
        {expanded && (
          <>
            <Typography sx={{ fontSize: '1.3rem', whiteSpace: 'nowrap' }}>{header}</Typography>
          </>
        )}
        <IconButton onClick={() => setExpanded(!expanded)} sx={{ ml: 'auto' }}>
          {expanded ? <FirstPageOutlinedIcon /> : <LastPageOutlinedIcon />}
        </IconButton>
      </Box>
      <Divider />
      <Box>
        <List>
          {items.map((item: MenuItem) => (
            <ListItem key={item.label} disablePadding sx={{ minHeight: '65px' }}>
              <ListItemButton
                sx={{ justifyContent: 'center' }}
                onClick={() => handleMenuSelect(item)}
              >
                <ListItemIcon sx={{ justifyContent: 'center' }}>{item.icon}</ListItemIcon>
                {expanded && <ListItemText sx={{ whiteSpace: 'nowrap' }} primary={item.label} />}
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Box>
    </Box>
  );
};

export default PanelLeftMenu;
