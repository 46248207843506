import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  TextField,
  Typography
} from '@mui/material';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import React, { ReactNode } from 'react';
import { FeedbackSnackbarContext } from '../../context/FeedbackSnackbarContext';
import { Moment } from 'moment';
import moment from 'moment';
import { DocumentReference } from 'firebase/firestore';
import { Region } from '@esg/esg-global-types';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { hasBannedCharacters } from '../../lib/validation/text_validation';
import { MetadataError } from '@ep/error-handling';
import { uuidv4 } from '@firebase/util';
import { log } from '../../util/log';

export interface EditInput {
  id: string;
  type: 'text' | 'select' | 'date' | 'node' | 'number' | 'boolean';
  label: string;
  defaultValue?: string | number | Moment | DocumentReference | boolean | Region;
  hideInput?: boolean;
  inputNodeFactory?: (handleInputChange: (input_id: string, value: unknown) => void) => ReactNode;
}

/**
 * Generic widget for filling in dynamic inputs required for editing an entity in the database
 * @param {string} edit_label Title of the widget shown at the top
 * @param {ReactNode} create_icon Icon to display next to title
 * @param {Array<EditInput>} edit_function_inputs List of input fields to fill in for the editing function to use
 * @param {any} edit_entity Entity being editing in the widget
 * @param {number} grid_spacing Optional spacing to add between each input field component
 * @param {void} handleClose Close the widget
 * @param {void} handleEditInput Update the entity being edited by the change functions of the inputs
 * @param {void} confirmEditFunction Use the input field data to edit the entity in the database
 * @returns {JSX.Element}
 */
const ConfigEditWidget = ({
  edit_label,
  edit_icon,
  edit_function_inputs,
  edit_entity,
  grid_spacing,
  handleClose,
  handleEditInput,
  confirmEditFunction
}: {
  edit_label: string;
  edit_icon: ReactNode;
  edit_function_inputs: Array<EditInput>;
  edit_entity: any;
  grid_spacing?: number;
  handleClose: () => void;
  handleEditInput: (input_id: string, value: any) => void;
  confirmEditFunction: ((...args: any) => void) | null;
}) => {
  const [editLoading, setEditLoading] = React.useState<boolean>(false);
  const [originalData] = React.useState(edit_entity);
  const [invalidInput, setInvalidInput] = React.useState<boolean>(false);
  const { setFeedbackData } = React.useContext(FeedbackSnackbarContext);

  React.useEffect(() => {
    if (edit_entity) {
      if ('name' in edit_entity) {
        const { name } = edit_entity;
        setInvalidInput(hasBannedCharacters(name) || name.length < 1);
      }
      if ('source' in edit_entity) {
        const { source } = edit_entity;
        setInvalidInput(hasBannedCharacters(source) || source.length < 1);
      }
    }
  }, [edit_entity]);

  const handleEditClick = async () => {
    setEditLoading(true);
    try {
      if (confirmEditFunction) {
        await confirmEditFunction(edit_entity, originalData);
      }
      handleClose();
    } catch (err: unknown) {
      const tracking_id: string = uuidv4();
      log(
        'error',
        new MetadataError(
          err instanceof Error
            ? err.message
            : 'Error: ConfigEditWidget failed on an unknown error while calling handleEditClick.',
          {
            edit_entity: edit_entity
          },
          tracking_id
        )
      );
      setFeedbackData({
        message: `Unable to update entity. Tracking ID: ${tracking_id}`,
        state: true,
        type: 'error'
      });
    } finally {
      setEditLoading(false);
    }
    return;
  };
  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <Box sx={{ p: 2 }}>
        <Grid container spacing={grid_spacing ? grid_spacing : 6}>
          <Grid item xs={10}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                flexWrap: 'wrap'
              }}
            >
              {edit_icon}
              <Typography variant="h5" color="inherit" align="left">
                Edit {edit_label}
              </Typography>
            </Box>
          </Grid>
          {edit_function_inputs
            .filter((input: EditInput) => !input.hideInput)
            .map((input: EditInput) => {
              return (
                <Grid item xs={12} key={input.id}>
                  {input.type === 'text' && (
                    <TextField
                      error={invalidInput}
                      fullWidth
                      id={input.id}
                      label={input.label}
                      defaultValue={edit_entity ? edit_entity[input.id] : ''}
                      variant="standard"
                      onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
                        handleEditInput(input.id, event.target.value);
                      }}
                    />
                  )}
                  {input.type === 'number' && (
                    <TextField
                      inputProps={{ type: 'number' }}
                      fullWidth
                      id={input.id}
                      label={input.label}
                      defaultValue={edit_entity ? edit_entity[input.id] : ''}
                      variant="standard"
                      onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
                        handleEditInput(input.id, event.target.value);
                      }}
                    />
                  )}
                  {input.type === 'date' && (
                    <DatePicker
                      label={input.label}
                      defaultValue={edit_entity ? moment(edit_entity[input.id]) : null}
                      onChange={(new_date) => handleEditInput(input.id, new_date)}
                      sx={{ width: '100%' }}
                    />
                  )}
                  {input.type === 'boolean' && (
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={edit_entity[input.id] ? true : false}
                          value={edit_entity[input.id] ? true : false}
                          color="primary"
                          onChange={(event) => {
                            handleEditInput(input.id, event.target.checked);
                          }}
                        />
                      }
                      label={input.label}
                    />
                  )}
                  {input.type === 'node' &&
                    input.inputNodeFactory &&
                    input.inputNodeFactory(handleEditInput)}
                </Grid>
              );
            })}

          <Grid item xs={12} alignItems={'right'}>
            <Box m={1} display="flex" justifyContent="flex-end" alignItems="flex-end">
              <Button
                variant="text"
                startIcon={<ChevronLeftIcon />}
                color="primary"
                onClick={() => {
                  handleClose();
                }}
              >
                Back
              </Button>
              &nbsp;
              <LoadingButton
                disabled={invalidInput}
                variant="contained"
                loading={editLoading}
                color="primary"
                onClick={async () => {
                  await handleEditClick();
                }}
              >
                Update
              </LoadingButton>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </LocalizationProvider>
  );
};

export default ConfigEditWidget;
