import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { QuantitativeMetricRecordGridRow } from '../metric_records/quantitative/MetricRecordGridQuantitative';
import { Company, File as DBFile, Group } from '@esg/esg-global-types';
import { MuiFileInput } from 'mui-file-input';
import { Box, Button, DialogActions, Tooltip } from '@mui/material';
import { getDuplicateFile, createFile, downloadFile } from '../../../lib/metric_capture/file';
import { LoadingButton } from '@mui/lab';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { FeedbackSnackbarContext } from '../../../context/FeedbackSnackbarContext';
import { GroupContext } from '../../../context/GroupContext';
import { CompanyContext } from '../../../context/CompanyContext';
import axios from 'axios';
import { API_ENDPOINT, GCP_FILE_UPLOAD_BUCKET } from '../../../env';
import { generateFileUploadRequest } from '../../../lib/file_upload/uploadRequestPrep';
import DownloadIcon from '@mui/icons-material/Download';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import AlertBanner from '../../shared/banner/AlertBanner';
import { MetadataError } from '@ep/error-handling';
import { uuidv4 } from '@firebase/util';
import { log } from '../../../util/log';
import { QualitativeMetricRecordGridRow } from '../metric_records/qualitative/MetricRecordGridQualitative';

/**
 * A modal allowing the user to upload supporting documents for a Metric Record
 * @param {boolean} open The display state of the modal.
 * @param {QualitativeMetricRecordGridRow | QuantitativeMetricRecordGridRow | null} metric_record The metric record to upload docs for
 * @param {string} metric_record_title The display name of the metric record
 * @param {void} handleCloseDocsModal Handler to close the modal
 * @param {void} handleAddSupportingDoc Handler function to add a supporting document to a metric record row in parent grid
 * @returns {JSX.Element}
 */
const UploadSupportingDocsModal = ({
  open,
  metric_record,
  metric_record_title,
  handleCloseDocsModal,
  handleAddSupportingDoc
}: {
  open: boolean;
  metric_record: QualitativeMetricRecordGridRow | QuantitativeMetricRecordGridRow | null;
  metric_record_title: string;
  handleCloseDocsModal: () => void;
  handleAddSupportingDoc: (row_id: string, new_doc: DBFile) => void;
}) => {
  const { setFeedbackData } = React.useContext(FeedbackSnackbarContext);
  const group: Group | null = React.useContext(GroupContext);
  const company: Company | null = React.useContext(CompanyContext);
  const [file, setFile] = React.useState<File | null>(null);
  const [existingDuplicateFile, setExistingDuplicateFile] = React.useState<DBFile | null>(null);
  const [uploadLoading, setUploadLoading] = React.useState<boolean>(false);
  const [downloadLoading, setDownloadLoading] = React.useState<boolean>(false);

  const handleFileChange = (new_file: File | null): void => {
    setFile(new_file);
  };
  const handleCloseModal = (): void => {
    setFile(null);
    setExistingDuplicateFile(null);
    handleCloseDocsModal && handleCloseDocsModal();
  };

  const uploadSupportingDoc = async (supporting_doc: File): Promise<void> => {
    setUploadLoading(true);
    try {
      if (group && company && metric_record) {
        if (existingDuplicateFile) {
          await createFile(
            group.id,
            company.id,
            existingDuplicateFile.name,
            metric_record.id,
            existingDuplicateFile.file_path
          ).then((supporting_doc: DBFile) => {
            const new_supporting_doc: DBFile = {
              id: supporting_doc.id,
              deleted: null,
              file_path: supporting_doc.file_path,
              metric_record: supporting_doc.metric_record,
              name: supporting_doc.name,
              type: `.${supporting_doc.name.split('.')[1].toLowerCase()}`,
              uploaded: new Date()
            };
            handleAddSupportingDoc(metric_record.id, new_supporting_doc);
          });
        } else {
          const supporting_doc_name = `${supporting_doc.name.replace(/\s/g, '_')}`.toLowerCase();
          const duplicate_file: DBFile | null = await getDuplicateFile(
            group.id,
            company.id,
            supporting_doc_name,
            metric_record.id
          );
          if (duplicate_file) {
            if (
              duplicate_file.metric_record &&
              duplicate_file.metric_record.id === metric_record.id
            ) {
              setFile(null);
              throw new Error('duplicate-file-metric-record');
            } else {
              setExistingDuplicateFile(duplicate_file);
              return;
            }
          } else {
            const file_upload = generateFileUploadRequest(
              supporting_doc,
              supporting_doc_name,
              atob(GCP_FILE_UPLOAD_BUCKET),
              `${group.id}/${company.id}/supporting_documents`,
              'false'
            );
            await axios({
              method: 'post',
              url: `${API_ENDPOINT}/upload_file`,
              data: file_upload
            }).then(async (upload_response) => {
              await createFile(
                group.id,
                company.id,
                supporting_doc_name,
                metric_record!.id,
                upload_response.data.url
              ).then((supporting_doc: DBFile) => {
                const new_supporting_doc: DBFile = {
                  id: supporting_doc.id,
                  deleted: null,
                  file_path: upload_response.data.url,
                  metric_record: supporting_doc.metric_record,
                  name: supporting_doc_name,
                  type: `.${supporting_doc_name.split('.')[1].toLowerCase()}`,
                  uploaded: new Date()
                };
                handleAddSupportingDoc(metric_record.id, new_supporting_doc);
              });
            });
          }
        }
        handleCloseModal();
      }
    } catch (err: unknown) {
      let message = `Error uploading Supporting Doc, please try again later or contact Energy Partners`;
      if (err instanceof Error && err.message === 'duplicate-file-metric-record') {
        message = `File name: ${file?.name} already exists on Metric Record`;
      }
      const tracking_id: string = uuidv4();
      log(
        'error',
        new MetadataError(
          err instanceof Error
            ? err.message
            : 'Error: UploadSupportDocsModal failed on an unknown error while calling uploadSupportDoc.',
          null,
          tracking_id
        )
      );
      setFeedbackData({
        message: `${message}. Tracking ID: ${tracking_id}`,
        state: true,
        type: 'error'
      });
    } finally {
      setUploadLoading(false);
    }
  };

  const downloadExistingFile = async (supporting_doc: DBFile): Promise<void> => {
    try {
      setDownloadLoading(true);
      await downloadFile(supporting_doc);
    } catch (err: unknown) {
      const tracking_id: string = uuidv4();
      log(
        'error',
        new MetadataError(
          err instanceof Error
            ? err.message
            : 'Error: UploadSupportingDocsModal failed on an unknown error while calling downloadExistingFile.',
          {
            supporting_doc: supporting_doc
          },
          tracking_id
        )
      );
      setFeedbackData({
        message: `Unable to download supporting doc. Tracking ID: ${tracking_id}`,
        state: true,
        type: 'error'
      });
    } finally {
      setDownloadLoading(false);
    }
  };

  return (
    <Dialog open={open} onClose={handleCloseModal}>
      <DialogTitle>Upload Supporting Document</DialogTitle>
      <DialogContent>
        {existingDuplicateFile && file ? (
          <>
            <Box sx={{ my: 4 }}>
              <AlertBanner
                severity="info"
                open
                message={`${file.name} has already been uploaded for ${company ? company.name : 'Company'}`}
              />
            </Box>
            <DialogContentText>
              Go back and rename the new file or add the existing file as a Supporting Document to:{' '}
              {metric_record_title}
            </DialogContentText>
          </>
        ) : (
          <>
            <DialogContentText>{metric_record_title}</DialogContentText>
            <MuiFileInput
              value={file}
              onChange={handleFileChange}
              title="Document"
              label="Document"
              sx={{ marginY: 8 }}
            />
          </>
        )}
        <DialogActions sx={{ mt: 4 }}>
          <Button variant="text" size="medium" onClick={handleCloseModal}>
            Cancel
          </Button>
          {existingDuplicateFile && (
            <>
              <Tooltip title="Choose another file" placement="top">
                <Button
                  variant="text"
                  size="medium"
                  startIcon={<ChevronLeftIcon />}
                  onClick={() => setExistingDuplicateFile(null)}
                >
                  Back
                </Button>
              </Tooltip>
              <Tooltip title="Preview existing file content" placement="top">
                <LoadingButton
                  variant="text"
                  size="medium"
                  startIcon={<DownloadIcon />}
                  loading={downloadLoading}
                  onClick={() => {
                    downloadExistingFile(existingDuplicateFile);
                  }}
                  color="primary"
                >
                  Download
                </LoadingButton>
              </Tooltip>
            </>
          )}
          <LoadingButton
            variant="contained"
            size="medium"
            startIcon={<FileUploadIcon />}
            loading={uploadLoading}
            onClick={async () => {
              file && (await uploadSupportingDoc(file));
            }}
            color="primary"
            disabled={!file && !existingDuplicateFile}
          >
            Upload
          </LoadingButton>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

export default UploadSupportingDocsModal;
