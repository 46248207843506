import { uuidv4 } from '@firebase/util';
import { createAuditLog, generateAuditLogData, generateAuditLogDoc } from '../app/audit';
import {
  BatchWrite,
  createFirestoreDoc,
  deleteFirestoreDoc,
  processBatchWrites,
  readFirestoreDocs,
  updateFirestoreDoc
} from '../app/db_util';
import { MetadataError } from '@ep/error-handling';
import { ReportingPeriod, ReportingPeriodGroup } from '@esg/esg-global-types';
import {
  DocumentReference,
  QueryDocumentSnapshot,
  QuerySnapshot,
  deleteField,
  doc
} from 'firebase/firestore';
import { auth, db } from '../google/firebase';
import moment, { Moment } from 'moment';

export interface ReportingPeriodGroupExtended extends ReportingPeriodGroup {
  reporting_periods: Array<ReportingPeriod>;
}

/**
 * Function to query all reporting period groups for a given company
 * @param {string} group_id Group to query reporting period groups for
 * @param {string} company_id Company to query reporting period groups for
 * @returns {Array<ReportingPeriodGroup>}
 */
export const getReportingPeriodGroups = async (group_id: string, company_id: string) => {
  const collection_path = `groups/${group_id}/companies/${company_id}/reporting_period_groups`;
  try {
    const reporting_period_groups_snapshot: QuerySnapshot = await readFirestoreDocs(
      collection_path,
      [{ field_name: 'deleted', operator: '==', value: null }]
    );
    const reporting_period_groups: Array<ReportingPeriodGroup> =
      reporting_period_groups_snapshot.docs.map((reporting_period_group: QueryDocumentSnapshot) => {
        return {
          id: reporting_period_group.id,
          name: reporting_period_group.data().name,
          deleted: reporting_period_group.data().deleted,
          reference: reporting_period_group.ref
        };
      });
    return reporting_period_groups;
  } catch (err: unknown) {
    const tracking_id: string = uuidv4();
    throw new MetadataError(
      err instanceof Error
        ? err.message
        : 'Error: lib/metric_capture/reporting_period_group.ts failed on an unknown error while calling getReportingPeriodGroups.',
      {
        group_id: group_id,
        company_id: company_id
      },
      tracking_id
    );
  }
};

/**
 * Function to create Reporting Period Group with relative data
 * @param {string} group_id ID of Group which Reporting Period Group is being created for
 * @param {string} company_id ID of Company which Reporting Period Group is being created for
 * @param {string} reporting_period_group_name Name of new Reporting Period Group
 * @param {Array<ReportingPeriod>} assigned_reporting_periods List of reporting periods to assign to new reporting period group
 * @returns {DocumentReference}
 */
export const createReportingPeriodGroup = async (
  group_id: string,
  company_id: string,
  reporting_period_group_name: string,
  assigned_reporting_periods: Array<ReportingPeriod>
) => {
  try {
    const collection_path = `groups/${group_id}/companies/${company_id}/reporting_period_groups`;
    const reporting_period_group_data: Omit<ReportingPeriodGroup, 'id'> = {
      deleted: null,
      name: reporting_period_group_name
    };
    const new_reporting_period_group: DocumentReference = await createFirestoreDoc(
      collection_path,
      reporting_period_group_data
    );
    await assignReportingPeriodGroup(
      group_id,
      company_id,
      assigned_reporting_periods,
      new_reporting_period_group.id
    );
    await createAuditLog(
      group_id,
      'create',
      '',
      reporting_period_group_name,
      new_reporting_period_group
    );
    return new_reporting_period_group;
  } catch (err: unknown) {
    const tracking_id: string = uuidv4();
    throw new MetadataError(
      err instanceof Error
        ? err.message
        : 'Error: lib/metric_capture/reporting_period_group.ts failed on an unknown error while calling createReportingPeriodGroup.',
      {
        group_id: group_id,
        company_id: company_id,
        reporting_period_group_name: reporting_period_group_name,
        assigned_reporting_periods: assigned_reporting_periods
      },
      tracking_id
    );
  }
};

/**
 * Function to update reporting period group with relative data
 * @param {string} group_id ID of Group to update reporting period group for
 * @param {string} company_id ID of Company to update reporting period group for
 * @param {ReportingPeriodGroup} updated_reporting_period_group New Reporting Period Group data to push into document
 * @returns {DocumentReference}
 */
export const updateReportingPeriodGroup = async (
  group_id: string,
  company_id: string,
  updated_reporting_period_group: ReportingPeriodGroupExtended,
  original_reporting_period_group: ReportingPeriodGroupExtended
) => {
  try {
    const collection_path = `groups/${group_id}/companies/${company_id}/reporting_period_groups`;
    const reporting_period_group_data: Omit<ReportingPeriodGroup, 'id'> = {
      deleted: null,
      name: updated_reporting_period_group.name
    };
    const updated_reporting_period_group_doc: DocumentReference = await updateFirestoreDoc(
      collection_path,
      updated_reporting_period_group.id,
      reporting_period_group_data
    );
    const added_reporting_periods: Array<ReportingPeriod> =
      updated_reporting_period_group.reporting_periods.filter(
        (new_reporting_period) =>
          !original_reporting_period_group.reporting_periods.find(
            (old_reporting_period) => old_reporting_period.id === new_reporting_period.id
          )
      );
    const removed_reporting_periods: Array<ReportingPeriod> =
      original_reporting_period_group.reporting_periods.filter(
        (old_reporting_period) =>
          !updated_reporting_period_group.reporting_periods.find(
            (new_reporting_period) => old_reporting_period.id === new_reporting_period.id
          )
      );
    if (added_reporting_periods.length > 0) {
      await assignReportingPeriodGroup(
        group_id,
        company_id,
        added_reporting_periods,
        updated_reporting_period_group.id
      );
    }
    if (removed_reporting_periods.length > 0) {
      await assignReportingPeriodGroup(group_id, company_id, removed_reporting_periods);
    }
    await createAuditLog(
      group_id,
      'update',
      updated_reporting_period_group.name,
      original_reporting_period_group.name,
      updated_reporting_period_group_doc
    );
    return updated_reporting_period_group_doc;
  } catch (err: unknown) {
    const tracking_id: string = uuidv4();
    throw new MetadataError(
      err instanceof Error
        ? err.message
        : 'Error: lib/metric_capture/reporting_period_group.ts failed on an unknown error while calling updateReportingPeriodGroup.',
      {
        group_id: group_id,
        company_id: company_id,
        updated_reporting_period_group: updated_reporting_period_group,
        original_reporting_period_group: original_reporting_period_group
      },
      tracking_id
    );
  }
};

/**
 * Function to delete Reporting Period Group
 * @param {string} group_id ID of Group to delete Reporting Period Group for
 * @param {string} company_id ID of Company to delete Reporting Period Group for
 * @param {string} reporting_period_group_id ID of Reporting Period Group to delete
 * @param {Array<ReportingPeriod>} unassigned_reporting_periods Reporting Periods which will be unassigned after reporting period group is deleted
 * @returns {void}
 */
export const deleteReportingPeriodGroup = async (
  group_id: string,
  company_id: string,
  reporting_period_group_id: string,
  reporting_period_group_name: string,
  unassigned_reporting_periods: Array<ReportingPeriod>
) => {
  try {
    const reporting_period_group_collection = `groups/${group_id}/companies/${company_id}/reporting_period_groups`;
    const deleted_doc: DocumentReference = await deleteFirestoreDoc(
      reporting_period_group_collection,
      reporting_period_group_id
    );
    await assignReportingPeriodGroup(group_id, company_id, unassigned_reporting_periods);
    await createAuditLog(group_id, 'delete', reporting_period_group_name, '', deleted_doc);
    return;
  } catch (err: unknown) {
    const tracking_id: string = uuidv4();
    throw new MetadataError(
      err instanceof Error
        ? err.message
        : 'Error: lib/metric_capture/reporting_period_group.ts failed on an unknown error while calling deleteReportingPeriodGroup.',
      {
        group_id: group_id,
        company_id: company_id,
        reporting_period_group_id: reporting_period_group_id,
        reporting_period_group_name: reporting_period_group_name,
        unassigned_reporting_periods: unassigned_reporting_periods
      },
      tracking_id
    );
  }
};

/**
 * Function to assign or unassign a given Reporting Period Group to a list of Reporting Periods
 * @param {string} group_id ID of Group containing Reporting Periods
 * @param {string} company_id ID of Company containing Reporting Periods
 * @param {string | undefined} reporting_period_group_id ID of Reporting Period Groups to assign to Reporting Periods if present or unassign if not
 * @param {Array<ReportingPeriod>} reporting_periods List of Reporting Periods being updated
 * @returns {Promise<Array<BatchWrite>>}
 */
export const assignReportingPeriodGroup = async (
  group_id: string,
  company_id: string,
  reporting_periods: Array<ReportingPeriod>,
  reporting_period_group_id?: string
) => {
  try {
    const reporting_period_group_doc: DocumentReference | null = reporting_period_group_id
      ? doc(
          db,
          `groups/${group_id}/companies/${company_id}/reporting_period_groups/${reporting_period_group_id}`
        )
      : null;
    const reporting_period_group_writes: Array<BatchWrite> = [];
    const audit_log_batch_writes: Array<BatchWrite> = [];
    reporting_periods.forEach((reporting_period: ReportingPeriod) => {
      const reporting_period_ref: DocumentReference = doc(
        db,
        `groups/${group_id}/companies/${company_id}/reporting_periods/${reporting_period.id}`
      );
      const write_data = {
        reporting_period_group: reporting_period_group_doc
          ? reporting_period_group_doc
          : deleteField()
      };
      reporting_period_group_writes.push({
        reference: reporting_period_ref,
        operation: 'update',
        data: write_data
      });
      const audit_log_ref: DocumentReference = generateAuditLogDoc(group_id);
      const audit_log_data = generateAuditLogData(
        reporting_period.name,
        'update',
        '',
        reporting_period_ref,
        auth.currentUser?.email
      );
      audit_log_batch_writes.push({
        reference: audit_log_ref,
        operation: 'create',
        data: audit_log_data
      });
    });
    const write_promises: Array<Promise<void>> = [
      processBatchWrites(reporting_period_group_writes).catch((error) => {
        throw new Error(error);
      }),
      processBatchWrites(audit_log_batch_writes).catch((error) => {
        throw new Error(error);
      })
    ];
    await Promise.all(write_promises);
    return reporting_period_group_writes;
  } catch (err: unknown) {
    const tracking_id: string = uuidv4();
    throw new MetadataError(
      err instanceof Error
        ? err.message
        : 'Error: lib/metric_capture/reporting_period_group.ts failed on an unknown error while calling assignReportingPeriodGroup.',
      {
        group_id: group_id,
        company_id: company_id,
        reporting_periods: reporting_periods,
        reporting_period_group_id: reporting_period_group_id
      },
      tracking_id
    );
  }
};

/**
 * Function to append a list of assigned reporting periods to each reporting period group object's data
 * @param {Array<ReportingPeriodGroup>} reporting_period_groups List of Reporting Period Groups to append to
 * @param {Array<ReportingPeriod>} reporting_periods List of reporting periods to reference per group
 * @returns {Array<ReportingPeriodGroupExtended>}
 */
export const joinReportingPeriodGroupsReportingPeriods = (
  reporting_period_groups: Array<ReportingPeriodGroup>,
  reporting_periods: Array<ReportingPeriod>
) => {
  const joined_reporting_period_groups: Array<ReportingPeriodGroupExtended> =
    reporting_period_groups.map((reporting_period_group: ReportingPeriodGroup) => {
      return {
        ...reporting_period_group,
        reporting_periods: reporting_periods.filter((reporting_period: ReportingPeriod) => {
          return reporting_period.reporting_period_group?.id === reporting_period_group.id;
        })
      };
    });
  return joined_reporting_period_groups;
};

/**
 * Function to find the lowest start date in a group of reporting periods
 * @param {Array<ReportingPeriodGroup>} reporting_period_groups List of Reporting Period Groups to append to
 * @param {Array<ReportingPeriod>} reporting_periods List of reporting periods to reference per group
 * @param {ReportingPeriodGroup} test_group Current group you want to find lowest start date for
 * @returns {Moment}
 */
export const findLowestStartDateInGroup = (
  reporting_period_groups: Array<ReportingPeriodGroup>,
  reporting_periods: Array<ReportingPeriod>,
  test_group: ReportingPeriodGroup
) => {
  try {
    const joined_reporting_group: Array<ReportingPeriodGroupExtended> =
      joinReportingPeriodGroupsReportingPeriods(reporting_period_groups, reporting_periods).filter(
        (joined_data) => {
          return joined_data.id === test_group.id;
        }
      );
    let lowest_start: Moment = moment('9999-01-01');
    joined_reporting_group[0].reporting_periods.forEach((reporting_period) => {
      if (reporting_period.start < lowest_start) {
        lowest_start = reporting_period.start;
      }
    });
    return lowest_start;
  } catch (err: unknown) {
    const tracking_id: string = uuidv4();
    throw new MetadataError(
      err instanceof Error
        ? err.message
        : 'Error: lib/metric_capture/reporting_period_group.ts failed on an unknown error while calling findLowestSTartDateInGroup.',
      {
        reporting_period_groups: reporting_period_groups,
        reporting_periods: reporting_periods,
        test_group: test_group
      },
      tracking_id
    );
  }
};

/**
 * Function to find the highest end date in a group of reporting periods
 * @param {Array<ReportingPeriodGroup>} reporting_period_groups List of Reporting Period Groups to append to
 * @param {Array<ReportingPeriod>} reporting_periods List of reporting periods to reference per group
 * @param {ReportingPeriodGroup} test_group Current group you want to find lowest start date for
 * @returns {Moment}
 */

export const findHighestEndDateInGroup = (
  reporting_period_groups: Array<ReportingPeriodGroup>,
  reporting_periods: Array<ReportingPeriod>,
  test_group: ReportingPeriodGroup
) => {
  try {
    const joined_reporting_group: Array<ReportingPeriodGroupExtended> =
      joinReportingPeriodGroupsReportingPeriods(reporting_period_groups, reporting_periods).filter(
        (joined_data) => {
          return joined_data.id === test_group.id;
        }
      );
    let highest_end: Moment = moment('1970-01-01');
    joined_reporting_group[0].reporting_periods.forEach((reporting_period) => {
      if (reporting_period.end > highest_end) {
        highest_end = reporting_period.end;
      }
    });
    return highest_end;
  } catch (err: unknown) {
    const tracking_id: string = uuidv4();
    throw new MetadataError(
      err instanceof Error
        ? err.message
        : 'Error: lib/metric_capture/reporting_period_group.ts failed on an unknown error while calling findHighestEndDateInGroup.',
      {
        reporting_period_groups: reporting_period_groups,
        reporting_periods: reporting_periods,
        test_group: test_group
      },
      tracking_id
    );
  }
};
