import {
  writeBatch,
  DocumentReference,
  CollectionReference,
  collection,
  Query,
  query,
  where,
  QuerySnapshot,
  getDocs,
  addDoc,
  doc,
  updateDoc,
  DocumentData
} from 'firebase/firestore';
import { db } from '../google/firebase';
import { MetadataError } from '@ep/error-handling';
import { uuidv4 } from '@firebase/util';
import { log } from '../../util/log';
import { FirestoreQueryParam } from '../../@types/shared';

export interface BatchWrite {
  reference: DocumentReference;
  operation: 'create' | 'update' | 'delete';
  data: DocumentData;
}

/**
 * General function to query documents from a collection in firestore
 * @param {string} collection_path Path to collection from root of firestore
 * @param {Array<FirestoreQueryParam>} query_params Filter parameters to apply to query
 * @returns {Promise<QuerySnapshot>}
 */
export const readFirestoreDocs = async (
  collection_path: string,
  query_params: Array<FirestoreQueryParam>
) => {
  const collection_ref: CollectionReference = collection(db, collection_path);
  const collection_query: Query = query(
    collection_ref,
    ...query_params.map((query_param: FirestoreQueryParam) => {
      return where(query_param.field_name, query_param.operator, query_param.value);
    })
  );
  try {
    const query_snapshot: QuerySnapshot = await getDocs(collection_query);
    return query_snapshot;
  } catch (err: unknown) {
    const tracking_id: string = uuidv4();
    log(
      'error',
      new MetadataError(
        err instanceof Error
          ? err.message
          : 'Error: lib/app/db_util.ts failed on an unknown error while calling readFirestoreDocs.',
        {
          collection_path: collection_path,
          query_params: query_params
        },
        tracking_id
      )
    );
    throw err;
  }
};

/**
 * General function to create a document in a collection in firestore
 * @param {string} collection_path Path to collection from root of firestore
 * @param {DocumentData} doc_data Data to create document with
 * @returns {Promise<DocumentReference>}
 */
export const createFirestoreDoc = async (collection_path: string, doc_data: DocumentData) => {
  const collection_ref: CollectionReference = collection(db, collection_path);
  try {
    const new_document = await addDoc(collection_ref, doc_data);
    return new_document;
  } catch (err: unknown) {
    const tracking_id: string = uuidv4();
    log(
      'error',
      new MetadataError(
        err instanceof Error
          ? err.message
          : 'Error: lib/app/db_util.ts failed on an unknown error while calling createFirestoreDoc.',
        {
          collection_path: collection_path,
          doc_data: doc_data
        },
        tracking_id
      )
    );
    throw err;
  }
};

/**
 * General function to update a document in a collection in firestore
 * @param {string} collection_path Path to collection from root of firestore
 * @param {string} document_id ID of document to update
 * @param {DocumentData} doc_data Data to update document with
 * @returns {Promise<DocumentReference>}
 */
export const updateFirestoreDoc = async (
  collection_path: string,
  document_id: string,
  doc_data: DocumentData
) => {
  const collection_ref: CollectionReference = collection(db, collection_path);
  const document_ref: DocumentReference = doc(collection_ref, document_id);
  try {
    await updateDoc(document_ref, doc_data);
    return document_ref;
  } catch (err: unknown) {
    const tracking_id: string = uuidv4();
    log(
      'error',
      new MetadataError(
        err instanceof Error
          ? err.message
          : 'Error: lib/app/db_util.ts failed on an unknown error while calling updateFirestoreDoc.',
        {
          collection_path: collection_path,
          document_id: document_id,
          doc_data: doc_data
        },
        tracking_id
      )
    );
    throw err;
  }
};

/**
 * General function to soft delete a document in a collection in firestore
 * @param {string} collection_path Path to collection from root of firestore
 * @param {string} document_id ID of document to delete
 * @returns {Promise<DocumentReference>}
 */
export const deleteFirestoreDoc = async (collection_path: string, document_id: string) => {
  const collection_ref: CollectionReference = collection(db, collection_path);
  const document_ref: DocumentReference = doc(collection_ref, document_id);
  try {
    await updateDoc(document_ref, { deleted: new Date() });
    return document_ref;
  } catch (err: unknown) {
    const tracking_id: string = uuidv4();
    log(
      'error',
      new MetadataError(
        err instanceof Error
          ? err.message
          : 'Error: lib/app/db_util.ts failed on an unknown error while calling deleteFirestoreDoc.',
        {
          collection_path: collection_path,
          document_id: document_id
        },
        tracking_id
      )
    );
    throw err;
  }
};

/**
 * General function to undo a soft deleted document in a firestore collection.
 * @param {string} collection_path Path to collection from root of firestore
 * @param {string} document_id ID of document to undelete
 * @returns {Promise<DocumentReference>}
 */
export const undeleteFirestoreDoc = async (
  collection_path: string,
  document_id: string
): Promise<DocumentReference> => {
  const collection_ref: CollectionReference = collection(db, collection_path);
  const document_ref: DocumentReference = doc(collection_ref, document_id);
  try {
    await updateDoc(document_ref, { deleted: null });
    return document_ref;
  } catch (err: unknown) {
    const tracking_id: string = uuidv4();
    log(
      'error',
      new MetadataError(
        err instanceof Error
          ? err.message
          : 'Error: lib/app/db_util.ts failed on an unknown error while calling undeleteFirestoreDoc.',
        {
          collection_path: collection_path,
          document_id: document_id
        },
        tracking_id
      )
    );
    throw err;
  }
};

/**
 * Generic function to write data to firestore documents in batched calls.
 * @param {Array<BatchWrite>} writes List of create, update or delete writes to execute with relative data
 * @returns {void}
 */
export const processBatchWrites = async (writes: Array<BatchWrite>) => {
  const batch_writes: Array<Promise<void>> = [];
  let batch = writeBatch(db);
  try {
    let operation_counter = 0;
    for (const write of writes) {
      if (write.operation === 'create') {
        batch.set(write.reference, write.data);
      } else {
        batch.update(
          write.reference,
          write.operation === 'delete' ? { deleted: new Date() } : write.data
        );
      }
      operation_counter++;
      if (operation_counter === 499) {
        batch_writes.push(batch.commit());
        batch = writeBatch(db);
        operation_counter = 0;
      }
    }
    if (operation_counter % 499 !== 0) {
      batch_writes.push(batch.commit());
    }
    await Promise.all(batch_writes);
  } catch (err: unknown) {
    const tracking_id: string = uuidv4();
    log(
      'error',
      new MetadataError(
        err instanceof Error
          ? err.message
          : 'Error: lib/app/db_util.ts failed on an unknown error while calling processBatchWrites.',
        {
          writes: writes
        },
        tracking_id
      )
    );
  }
};
