import React from 'react';
import {
  Tooltip,
  Grid,
  FormControl,
  InputLabel,
  Select,
  SelectChangeEvent,
  MenuItem,
  TextField,
  Typography,
  IconButton
} from '@mui/material';
import CancelIcon from '@mui/icons-material/Close';
import { GridToolbarContainer } from '@mui/x-data-grid';
import AddIcon from '@mui/icons-material/Add';
import {
  Company,
  EmissionFactor,
  Group,
  MetricGroup,
  MetricRecord,
  ReportingPeriod
} from '@esg/esg-global-types';
import { QuantitativeMetricRecordGridRow } from './MetricRecordGridQuantitative';
import { CaptureContext } from '../../../../@types/shared';
import { GroupContext } from '../../../../context/GroupContext';
import { CompanyContext } from '../../../../context/CompanyContext';
import { DocumentReference } from 'firebase/firestore';
import {
  createAverageMetricRecord,
  createMetricRecord
} from '../../../../lib/metric_capture/metric_record';
import { getEmissionFactorForReportingPeriod } from '../../../../lib/app/emission_factor';
import { FeedbackSnackbarContext } from '../../../../context/FeedbackSnackbarContext';
import { uuidv4 } from '@firebase/util';
import { log } from '../../../../util/log';
import { MetadataError } from '@ep/error-handling';
import { LoadingButton } from '@mui/lab';
import { MetricExtended } from '../../../../lib/metric_capture/metric';
import { Step } from 'react-joyride';
import { findTourIndexByTargetName } from '../../../../lib/app_tours/metric_record_capture_tour_util';

const MetricRecordGridQuantitativeToolbar = ({
  metrics,
  capture_context,
  metric_record_rows,
  tour_steps,
  run_tour,
  handleUpdateMetricRecordRows,
  setTourStepIndex,
  setRunTour
}: {
  metrics: Array<MetricExtended>;
  capture_context: CaptureContext;
  metric_record_rows: Array<QuantitativeMetricRecordGridRow>;
  tour_steps?: Array<Step>;
  run_tour?: boolean;
  handleUpdateMetricRecordRows: (
    new_metric_records: Array<QuantitativeMetricRecordGridRow>
  ) => void;
  setTourStepIndex?: (step_index: number) => void;
  setRunTour?: (state: boolean) => void;
}) => {
  const group: Group | null = React.useContext(GroupContext);
  const company: Company | null = React.useContext(CompanyContext);
  const { setFeedbackData } = React.useContext(FeedbackSnackbarContext);
  const [category, setCategory] = React.useState<MetricGroup | null>(null);
  const [metric, setMetric] = React.useState<MetricExtended | null>(null);
  const [emissionFactor, setEmissionFactor] = React.useState<DocumentReference | null | undefined>(
    undefined
  );
  const [value, setValue] = React.useState<string>('0');
  const [allocation, setAllocation] = React.useState<number>(100);
  const [note, setNote] = React.useState<string>('');
  const [addLoading, setAddLoading] = React.useState<boolean>(false);

  const categories: Array<MetricGroup> = metrics.reduce(
    (accumulator: Array<MetricGroup>, metric: MetricExtended) => {
      if (
        !accumulator.some((metric_group: MetricGroup) => metric_group.id === metric.metric_group.id)
      ) {
        accumulator.push({
          id: metric.metric_group.id,
          name: metric.metric_group.name,
          description: metric.metric_group.description,
          deleted: null
        });
      }
      return accumulator;
    },
    []
  );

  const metric_options: Array<MetricExtended> = metrics.filter(
    (metric) => category && metric.metric_group.id === category.id
  );

  const resetInputs = () => {
    setCategory(null);
    setMetric(null);
    setEmissionFactor(undefined);
    setValue('0');
    setAllocation(100);
    setNote('');
  };

  const validateMetricRecord = (): boolean => {
    return (
      metric !== null &&
      (!capture_context.require_emission_factor ||
        (emissionFactor !== undefined && emissionFactor !== null)) &&
      !isNaN(Number(value)) &&
      Number(value) >= 0 &&
      allocation >= 0 &&
      allocation <= 100
    );
  };

  const handleAddMetricRecord = async () => {
    try {
      if (group && company && metric && validateMetricRecord()) {
        setAddLoading(true);
        if (capture_context.reporting_period?.id === 'all') {
          const averaged_metric_records: Array<MetricRecord> = await createAverageMetricRecord(
            group.id,
            company.id,
            capture_context,
            metric,
            parseFloat(Number(value).toFixed(10)),
            {
              ...(capture_context.standard?.require_emission_factor &&
                emissionFactor && {
                  emission_factor: emissionFactor
                }),
              proportion: allocation / 100,
              note: note,
              ...(capture_context.standard?.require_emission_factor &&
                metric.scope && {
                  scope: capture_context.external_company ? 3 : metric.scope
                })
            }
          );
          const new_metric_record_rows: Array<QuantitativeMetricRecordGridRow> =
            averaged_metric_records.map((metric_record: MetricRecord) => {
              return {
                id: metric_record.id,
                category: metric.metric_group,
                emission_factor: emissionFactor ? emissionFactor : undefined,
                metric: metric,
                value: (Number(value) / averaged_metric_records.length).toFixed(10),
                unit: metric.unit ? metric.unit : '',
                proportion: allocation,
                note: note,
                scope: capture_context.external_company ? 3 : metric.scope ? metric.scope : 1,
                external_company: capture_context.external_company,
                docs: false,
                reporting_period: (metric_record.reporting_period as ReportingPeriod).name,
                is_averaged: true
              };
            });
          handleUpdateMetricRecordRows([...new_metric_record_rows, ...metric_record_rows]);
        } else {
          const new_metric_record: DocumentReference | undefined = await createMetricRecord(
            group.id,
            company.id,
            capture_context,
            metric,
            Number(value),
            {
              ...(capture_context.standard?.require_emission_factor &&
                emissionFactor && {
                  emission_factor: emissionFactor
                }),
              proportion: allocation / 100,
              note: note,
              ...(capture_context.standard?.require_emission_factor &&
                metric.scope && {
                  scope: capture_context.external_company ? 3 : metric.scope
                })
            }
          );
          if (new_metric_record) {
            const new_metric_record_row: QuantitativeMetricRecordGridRow = {
              id: new_metric_record.id,
              category: metric.metric_group,
              metric: metric,
              unit: metric.unit ? metric.unit : '',
              emission_factor: emissionFactor ? emissionFactor : undefined,
              value: parseFloat(Number(value).toFixed(10)).toString(),
              proportion: allocation,
              note: note,
              scope: capture_context.external_company ? 3 : metric.scope ? metric.scope : 1,
              external_company: capture_context.external_company,
              docs: false,
              reporting_period: capture_context.reporting_period?.name ?? '',
              is_averaged: false
            };
            handleUpdateMetricRecordRows([new_metric_record_row, ...metric_record_rows]);
          }
        }
        if (run_tour && setRunTour) {
          setRunTour(false);
          setTimeout(() => {
            setTourStepIndex &&
              tour_steps &&
              setTourStepIndex(findTourIndexByTargetName(tour_steps, '#upload-supporting-docs'));
            setRunTour(true);
          }, 200);
        } else {
          setTourStepIndex &&
            tour_steps &&
            setTourStepIndex(findTourIndexByTargetName(tour_steps, '#upload-supporting-docs'));
        }
      }
    } catch (err: unknown) {
      const tracking_id: string = uuidv4();
      log(
        'error',
        new MetadataError(
          err instanceof Error
            ? err.message
            : 'Error: ExportsHub failed on an unknown error while calling handleAddMetricRecord.',
          {
            group: group,
            company: company,
            capture_context: capture_context,
            metric: metric,
            value: value,
            emission_factor: emissionFactor,
            allocation: allocation,
            note: note
          },
          tracking_id
        )
      );
      setFeedbackData({
        message: `Unable to add metric record. Tracking ID: ${tracking_id}`,
        state: true,
        type: 'error'
      });
    } finally {
      setAddLoading(false);
      resetInputs();
    }
  };

  const handleChangeMetricGroup = (metric_group: MetricGroup | null) => {
    setCategory(metric_group);
    setMetric(null);
  };

  const handleChangeMetric = (metric: MetricExtended | null) => {
    setMetric(metric);
  };

  const handleChangeValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    const valid_decimal_number = /^\d*\.?\d*$/;
    if (valid_decimal_number.test(event.target.value)) {
      setValue(event.target.value);
    }
  };

  const handleChangeAllocation = (allocation: number) => {
    setAllocation(allocation);
  };

  const handleChangeNote = (note: string) => {
    setNote(note);
  };

  React.useEffect(() => {
    (async () => {
      if (
        group &&
        company &&
        capture_context.reporting_period &&
        metric &&
        capture_context.standard?.require_emission_factor
      ) {
        try {
          const emission_factor: EmissionFactor | null = await getEmissionFactorForReportingPeriod(
            group.id,
            company.id,
            metric.metric_group.id,
            metric.id,
            capture_context.reporting_period
          );
          setEmissionFactor(emission_factor ? emission_factor.ref : null);
          if (!emission_factor?.ref) {
            setFeedbackData({
              message: `No valid Emission Factor configured for ${metric.name}, contact an Admin of ${group.name}`,
              state: true,
              type: 'error'
            });
          }
        } catch (err: unknown) {
          const tracking_id: string = uuidv4();
          log(
            'error',
            new MetadataError(
              err instanceof Error
                ? err.message
                : 'Error: MetricRecordGridToolbar failed on an unknown error while initialising.',
              {
                'group.id': group.id,
                'company.id': company.id,
                metric_group_id: metric.metric_group.id,
                'metric.id': metric.id,
                'capture_context.reporting_period': capture_context.reporting_period
              },
              tracking_id
            )
          );
          setFeedbackData({
            message: `Error fetching Emission Factor for ${metric.name}. Tracking ID: ${tracking_id}`,
            state: true,
            type: 'error'
          });
        }
      }
    })();
  }, [metric]);

  return (
    <>
      <GridToolbarContainer sx={{ mb: 4, p: 0 }}>
        <Grid container spacing={0.5} sx={{ alignItems: 'center', p: 0 }}>
          <Grid item flex={1.2} overflow={'hidden'}>
            <FormControl fullWidth id={'metric-group-select'}>
              <InputLabel>Category</InputLabel>
              <Select
                value={category ? JSON.stringify(category) : ''}
                onChange={(event: SelectChangeEvent) => {
                  setTourStepIndex &&
                    tour_steps &&
                    setTourStepIndex(findTourIndexByTargetName(tour_steps, '#metric-select'));
                  handleChangeMetricGroup(JSON.parse(event.target.value));
                }}
                inputProps={{ 'aria-label': 'Without label' }}
                variant="outlined"
                sx={{ backgroundColor: 'white' }}
                label="Category"
              >
                {categories.map((metric_group: MetricGroup) => {
                  return (
                    <MenuItem key={metric_group.id} value={JSON.stringify(metric_group)}>
                      {metric_group.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item flex={1.2} overflow={'hidden'}>
            <FormControl fullWidth error={emissionFactor === null} id={'metric-select'}>
              <InputLabel>Metric</InputLabel>
              <Select
                disabled={!category}
                value={metric ? JSON.stringify(metric) : ''}
                onChange={(event: SelectChangeEvent) => {
                  setTourStepIndex &&
                    tour_steps &&
                    setTourStepIndex(findTourIndexByTargetName(tour_steps, '#value-input'));
                  handleChangeMetric(JSON.parse(event.target.value));
                }}
                inputProps={{ 'aria-label': 'Without label' }}
                variant="outlined"
                sx={{ backgroundColor: 'white' }}
                label="Metric"
              >
                {metric_options.map((metric: MetricExtended) => {
                  return (
                    <MenuItem key={metric.id} value={JSON.stringify(metric)}>
                      {metric.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item flex={0.75}>
            <TextField
              id="value-input"
              disabled={!metric}
              InputProps={{ inputProps: { min: 0 } }}
              label="Value"
              variant="outlined"
              sx={{ backgroundColor: 'white' }}
              value={value}
              onChange={handleChangeValue}
              onBlur={() => {
                if (capture_context.user_type.id === 'external')
                  setTourStepIndex &&
                    tour_steps &&
                    setTourStepIndex(findTourIndexByTargetName(tour_steps, '#allocation-input'));
                else
                  setTourStepIndex &&
                    tour_steps &&
                    setTourStepIndex(findTourIndexByTargetName(tour_steps, '#note-input'));
              }}
            />
          </Grid>
          <Grid item flex={0.7} textAlign={'center'}>
            <InputLabel>Unit</InputLabel>
            <Typography>{metric ? metric.unit : ''}</Typography>
          </Grid>
          <Grid item flex={0.9}>
            <TextField
              id="allocation-input"
              disabled={!metric || !capture_context.external_company}
              type="number"
              fullWidth
              error={allocation < 0 || allocation > 100}
              InputProps={{ inputProps: { min: 0, max: 100 } }}
              label="Client Allocation (%)"
              variant="outlined"
              sx={{ backgroundColor: 'white' }}
              value={allocation}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                handleChangeAllocation(Number(event.target.value))
              }
              onBlur={() =>
                setTourStepIndex &&
                tour_steps &&
                setTourStepIndex(findTourIndexByTargetName(tour_steps, '#note-input'))
              }
            />
          </Grid>
          <Grid item flex={1}>
            <TextField
              id="note-input"
              disabled={!metric}
              label="Note"
              value={note}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                handleChangeNote(event.target.value)
              }
              onBlur={() =>
                setTourStepIndex &&
                tour_steps &&
                setTourStepIndex(findTourIndexByTargetName(tour_steps, '#add-data-button'))
              }
              variant="outlined"
              sx={{ backgroundColor: 'white' }}
            />
          </Grid>
          <Grid item flex={2.5}>
            <Tooltip title="Clear Inputs" key={2}>
              <IconButton id="clear-inputs-button" color="inherit" onClick={resetInputs}>
                <CancelIcon />
              </IconButton>
            </Tooltip>
            <LoadingButton
              id="add-data-button"
              loading={addLoading}
              disabled={!validateMetricRecord()}
              variant="contained"
              size="large"
              startIcon={<AddIcon />}
              onClick={handleAddMetricRecord}
              sx={{ mr: 2, ml: 4 }}
            >
              Add
            </LoadingButton>
          </Grid>
        </Grid>
      </GridToolbarContainer>
    </>
  );
};

export default MetricRecordGridQuantitativeToolbar;
