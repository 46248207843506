import React from 'react';
import {
  Autocomplete,
  AutocompleteRenderInputParams,
  Box,
  Checkbox,
  TextField
} from '@mui/material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { Division } from '@esg/esg-global-types';
import { getDivisions, division_label } from '../../../../lib/metric_capture/division';

interface DivisionSelectProps {
  handleChangeDivisions?: (divisions: Array<Division> | Division | null) => void;
  options?: Array<Division>;
  selected_options?: Array<Division> | Division | null;
  disabled?: boolean;
  input_label?: string;
  multi_select?: boolean;
  tag_limit?: number;
  group_id?: string;
  company_id?: string;
  loading?: boolean;
  variant?: 'standard' | 'outlined';
  auto_select?: boolean;
}
// Checkbox icons
const check_icon_blank = <CheckBoxOutlineBlankIcon fontSize="small" />;
const check_icon_filled = <CheckBoxIcon fontSize="small" />;
/**
 * Division selection auto-complete component that handles a variety of built-in scenarios.
 * @param {Array<Division> | Division | null} props.handleChangeDivisions Callback function to notify the parent component when the selected options have changed.
 * @param {Array<Division>} props.options Custom list of options provided by the parent component. Defaults to none.
 * @param {Array<Division> | Division | null} props.selected_options Custom list of selected options provided by the parent component. Changes component from an uncontrolled to controlled.
 * @param {boolean} props.disabled Renders the component in a disabled state along with any other provided components. Defaults to enabled.
 * @param {string} props.input_label Sets a custom component input label. Defaults to the singular version of the main entity name.
 * @param {boolean} props.multi_select Allow multi option selection and keeps the select open while selecting. Defaults to a single select and closes after selecting an option.
 * @param {number} props.tag_limit Sets a limit on the amount of tags rendered in the input. Defaults to unlimited.
 * @param {string} props.group_id Query configured divisions for the provided group. Works in conjunction with props.company_id.
 * @param {string} props.company_id Query configured divisions for the provided company. Works in conjunction with props.group_id.
 * @param {boolean} props.loading A flag to set the autocomplete into a loading state for things such as fetching entries from database.
 * @param {string} props.variant An optional styling parameter for the textbox input of the autocomplete
 * @param {boolean} props.auto_select Pre-populate the autocomplete input when only one division is available.
 */
const DivisionSelect = (props: DivisionSelectProps) => {
  const [optionList, setOptions] = React.useState<Array<Division>>([]);
  const [selectedOptions, setSelectedOptions] = React.useState<Array<Division> | Division | null>(
    props.multi_select ? [] : null
  );
  // Define autocomplete options
  React.useEffect(() => {
    (async () => {
      const options: Array<Division> = props.options
        ? // Divisions supplied by the parent component.
          props.options
        : props.group_id && props.company_id
          ? await getDivisions(props.group_id, props.company_id)
          : [];
      if (options) setOptions(options);
      // When auto-select is true, automatically populate the autocomplete when only one division is present.
      if (props.auto_select && options.length === 1 && options[0] !== selectedOptions) {
        setSelectedOptions(options[0]);
        props.handleChangeDivisions && props.handleChangeDivisions(options[0]);
      }
    })();
  }, [props.group_id, props.company_id, props.options]);
  // Define selected options
  React.useEffect(() => {
    setSelectedOptions(
      props.multi_select
        ? Array.isArray(props.selected_options)
          ? props.selected_options
          : []
        : props.selected_options
          ? props.selected_options
          : null
    );
  }, [props.options, props.selected_options]);

  return (
    <Box id="division-select">
      <Autocomplete
        fullWidth
        disabled={props.disabled}
        loading={props.loading}
        limitTags={props.tag_limit}
        options={optionList}
        value={selectedOptions}
        getOptionLabel={(option: Division) => {
          return option.name;
        }}
        disableCloseOnSelect={props.multi_select}
        isOptionEqualToValue={(option: Division, value: Division) => option.id === value.id}
        multiple={props.multi_select}
        noOptionsText={`No ${division_label.many.toLowerCase()}`}
        renderOption={(render_props, option: Division, { selected }) => (
          <Box
            component="li"
            sx={{ display: 'flex', alignItems: 'center' }}
            {...render_props}
            key={option.id}
          >
            {props.multi_select && (
              <Checkbox
                icon={check_icon_blank}
                checkedIcon={check_icon_filled}
                style={{ marginRight: 8 }}
                checked={selected}
              />
            )}
            {option.name}
          </Box>
        )}
        renderInput={(params: AutocompleteRenderInputParams) => (
          <TextField
            {...params}
            label={props.input_label ?? division_label.one}
            variant={props.variant ?? 'standard'}
          />
        )}
        onChange={(
          event: React.SyntheticEvent<Element, Event>,
          value: Array<Division> | Division | null
        ) => {
          setSelectedOptions(value);
          props.handleChangeDivisions && props.handleChangeDivisions(value);
        }}
      />
    </Box>
  );
};

export default DivisionSelect;
