import { Box } from '@mui/material';
import React from 'react';
import PanelLeftMenu from './PanelLeftMenu';

export interface MenuItem {
  id: string;
  icon: React.ReactNode;
  label: string;
}

export interface PanelLeftMenuProps {
  header: string;
  items: Array<MenuItem>;
  handleMenuSelect: (option: MenuItem) => void;
}

/**
 * Wrapper component for displaying the left menu panel next to a given component
 * @param {ReactNode} children Element to display in main section of view next to left menu panel
 * @param {string} header Title of the left menu
 * @param {Array<MenuItem>} items Navigation items available to the user
 * @param {void} handleMenuSelect Send selected navigation item back to parent
 * @returns {JSX.Element}
 */
const PanelLeftMenuWrapper = ({
  children,
  ...props
}: PanelLeftMenuProps & { children: React.ReactNode }) => {
  return (
    <Box sx={{ display: 'flex' }}>
      <PanelLeftMenu {...props} />
      <Box sx={{ flexGrow: 1, overflow: 'hidden' }}>{children}</Box>
    </Box>
  );
};

export default PanelLeftMenuWrapper;
