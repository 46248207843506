import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import DefaultContent from '../shared/template/DefaultContent';
import { Routes, Route, Navigate, useNavigate } from 'react-router-dom';
import MetricCollectionDashboard from '../../views/metric_collection/MetricCollectionDashboard';
import HomeDashboard from '../../views/landing/HomeDashboard';
import MetricCollectionDataManagement from '../../views/metric_collection/MetricCollectionDataManagement';
import MetricCollectionAuditLogs from '../../views/metric_collection/MetricCollectionAuditLogs';
import ReadinessDashboard from '../../views/readiness/ReadinessDashboard';
import AssessmentDashboard from '../../views/assessment/AssessmentDashboard';
import ResourceDashboard from '../../views/resource/ResourceDashboard';
import ReportDashboard from '../../views/report/ReportDashboard';
import ContactPage from '../../views/contact/ContactPage';
import InvalidURL from '../../views/shared/InvalidURL';
import { auth } from '../../lib/google/firebase';
import { onAuthStateChanged } from 'firebase/auth';
import { Snackbar, useTheme } from '@mui/material';
import { User } from '@esg/esg-global-types';
import ThankYouPage from '../../views/contact/ThankYouPage';
import NavBarWeb from './NavBarWeb';
import NavBarApp from './NavBarApp';
import CloseIcon from '@mui/icons-material/Close';
import { CompanyContext } from '../../context/CompanyContext';
import { UserContext } from '../../context/UserContext';
import HomeDashboardApp from '../../views/landing/HomeDashboardApp';
import { Company, Group } from '@esg/esg-global-types';
import FooterWebsite from './FooterWebsite';
import UserManagementView from '../../views/user_management/UserManagementView';
import { getUserDetails, updateUser } from '../../lib/user_management/users';
import FeedbackSnackbarProvider from '../../context/FeedbackSnackbarContext';
import FeedbackSnackbar from './FeedbackSnackbar';
import { FeedbackSnackbarContext } from '../../context/FeedbackSnackbarContext';
import CookieConsentDrawer from './CookieConsentDrawer';
import Redirect from './Redirect';
import RawExport from '../../views/report/RawExport';
import { GroupContext } from '../../context/GroupContext';
import { log } from '../../util/log';
import { MetadataError } from '@ep/error-handling';
import { uuidv4 } from '@firebase/util';
import AuditLogs from '../../views/audit_logs/AuditLogs';
import ConfigurationDashboard from '../../views/configuration/ConfigurationDashboard';
import ConfigurationPersonalisation from '../../views/configuration/ConfigurationPersonalisation';
import ConfigurationGeographical from '../../views/configuration/ConfigurationGeographical';
import ConfigurationOrganisation from '../../views/configuration/ConfigurationOrganisation';
import ConfigurationExternalCompanies from '../../views/configuration/ConfigurationExternalCompanies';
import ConfigurationReportingPeriods from '../../views/configuration/ConfigurationReportingPeriods';
import ConfigurationReportingPeriodGroups from '../../views/configuration/ConfigurationReportingPeriodGroups';
import ConfigurationStandards from '../../views/configuration/ConfigurationStandards';
import ConfigurationMetrics from '../../views/configuration/ConfigurationMetrics';
import ConfigurationOtherConfiguration from '../../views/configuration/ConfigurationOtherConfiguration';
import ConfigurationEmissionFactors from '../../views/configuration/ConfigurationEmissionFactors';
import GroupManagementView from '../../views/group_management/GroupManagement';
import GroupView from '../../views/group_management/GroupView';
import { UserInfo } from '../../@types/shared';
import { CompanyExtended, getCompanyJoinGroups } from '../../lib/app/company';
import MasterViewDashboard from '../../views/master_view/MasterViewDashboard';

const DrawerBody = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
  open?: boolean;
}>(({ theme, open }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    }),
    marginLeft: 0
  })
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end'
}));

const AppBase = () => {
  const theme = useTheme();
  const { setFeedbackData } = React.useContext(FeedbackSnackbarContext);
  const [open] = React.useState(false);
  const [userData, setUserData] = React.useState<User | null>(null);
  const [authenticated, setAuthenticated] = React.useState(false);
  const [companies, setCompanies] = React.useState<Array<CompanyExtended>>([]);
  const [company, setCompany] = React.useState<Company | null>(null);
  const [group, setGroup] = React.useState<Group | null>(null);
  const [open_snackbar_welcome_message, setOpenSnackbarWelcomeMessage] = React.useState(false);
  const [open_snackbar_company_select, setOpenSnackbarCompanySelect] = React.useState(false);
  const [openAssist, setOpenAssist] = React.useState(false);
  const navigate = useNavigate();

  const handleSnackbarCloseWelcomeMessage = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbarWelcomeMessage(false);
  };

  const handleGroupChange = (group: Group | null) => {
    try {
      if (group) {
        localStorage.setItem('current_group', group.id);
        setGroup(group);
      }
    } catch (err: unknown) {
      const tracking_id: string = uuidv4();
      log(
        'error',
        new MetadataError(
          err instanceof Error
            ? err.message
            : 'Error: Appbase failed on an unknown error while calling handleGroupChange.',
          { group: group },
          tracking_id
        )
      );
      setFeedbackData({
        message: `Failed to change group. Tracking ID: ${tracking_id}`,
        state: true,
        type: 'error'
      });
    }
  };

  const addGroupCompany = (new_company: CompanyExtended): void => {
    setCompanies([new_company, ...companies]);
  };

  const handleCompanyChange = (company: Company | null) => {
    try {
      if (company) {
        setOpenSnackbarCompanySelect(true);
        localStorage.setItem('current_company', company.id);
        setCompany(company);
        theme.palette.primary.main = company ? company.configuration.primary_color : 'white';
        if (company.configuration.secondary_color) {
          theme.palette.secondary.main = company.configuration.secondary_color;
        }
      }
    } catch (err: unknown) {
      const tracking_id: string = uuidv4();
      log(
        'error',
        new MetadataError(
          err instanceof Error
            ? err.message
            : 'Error: Appbase failed on an unknown error while calling handleCompanyChange.',
          { company: company },
          tracking_id
        )
      );
      setFeedbackData({
        message: `Failed to change company. Tracking ID: ${tracking_id}`,
        state: true,
        type: 'error'
      });
    }
  };

  const resetAppContext = () => {
    setUserData(null);
    setGroup(null);
    setCompany(null);
  };

  const handleDrawerCloseAssist = () => {
    setOpenAssist(false);
  };

  const handleSnackbarCloseCompanySelect = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnackbarCompanySelect(false);
  };

  React.useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      try {
        if (user) {
          const user_info: UserInfo = user;
          if (user_info.emailVerified && user_info.reloadUserInfo?.mfaInfo !== undefined) {
            setAuthenticated(true);
          } else if (!user_info.emailVerified) {
            auth.signOut();
          }
        } else {
          navigate('/');
          setAuthenticated(false);
        }
      } catch (err: unknown) {
        const tracking_id: string = uuidv4();
        log(
          'error',
          new MetadataError(
            err instanceof Error
              ? err.message
              : 'Error: Appbase failed on an unknown error while initialising.',
            { user: user },
            tracking_id
          )
        );
        setFeedbackData({
          message: `Failed to verify user. Tracking ID: ${tracking_id}`,
          state: true,
          type: 'error'
        });
      }
    });

    return unsubscribe;
  }, []);

  React.useEffect(() => {
    (async () => {
      try {
        if (auth.currentUser) {
          await getUserDetails(auth.currentUser.uid)
            .then((user_data: User) => {
              setUserData({ ...user_data });
            })
            .catch((err) => {
              throw new Error(err);
            });
        }
      } catch (err: unknown) {
        const tracking_id: string = uuidv4();
        log(
          'error',
          new MetadataError(
            err instanceof Error
              ? err.message
              : 'Error: Appbase failed on an unknown error while initialising.',
            { 'auth.currentUser': auth.currentUser },
            tracking_id
          )
        );
        setFeedbackData({
          message: `Application failed to load user. Tracking ID: ${tracking_id}`,
          state: true,
          type: 'error'
        });
      }
      return null;
    })();
  }, [authenticated]);

  const fetchCompanies = async (): Promise<Array<CompanyExtended> | undefined> => {
    try {
      const companies: Array<CompanyExtended> = await getCompanyJoinGroups();
      return companies;
    } catch (err: unknown) {
      const tracking_id: string = uuidv4();
      log(
        'error',
        new MetadataError(
          err instanceof Error
            ? err.message
            : 'Error: AppBase failed on an unknown error while calling fetchGroupCompanies.',
          tracking_id
        )
      );
      setFeedbackData({
        message: `Failed to fetch Groups, Tracking ID: ${tracking_id}`,
        state: true,
        type: 'error'
      });
    }
  };

  React.useEffect(() => {
    (async () => {
      try {
        if (authenticated && userData) {
          updateUser(userData.id, { ...userData, last_accessed: new Date() });
          const companies: Array<CompanyExtended> | undefined = await fetchCompanies();
          if (companies) {
            setCompanies(companies);
          }
        }
      } catch (err: unknown) {
        const tracking_id: string = uuidv4();
        log(
          'error',
          new MetadataError(
            err instanceof Error
              ? err.message
              : 'Error: AppBase failed on an unknown error while initialising.',
            tracking_id
          )
        );
        setFeedbackData({
          message: `Failed to initialise Groups, Tracking ID: ${tracking_id}`,
          state: true,
          type: 'error'
        });
      }
      return;
    })();
  }, [userData]);

  const snackbar_action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleSnackbarCloseCompanySelect}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  return (
    <FeedbackSnackbarProvider>
      <UserContext.Provider value={userData}>
        <GroupContext.Provider value={group}>
          <CompanyContext.Provider value={company}>
            <Redirect />
            <Box sx={{ display: 'flex', minHeight: '100vh', flexDirection: 'column' }}>
              {!authenticated && <NavBarWeb setAuthenticated={setAuthenticated} />}

              {authenticated && (
                <NavBarApp
                  companies={companies}
                  handleGroupChange={handleGroupChange}
                  handleCompanyChange={handleCompanyChange}
                  resetAppContext={() => resetAppContext()}
                />
              )}

              {/* App Content Screen */}
              <DrawerBody open={open}>
                <DrawerHeader />
                <Routes>
                  <Route
                    path="/"
                    element={authenticated ? <Navigate to="/metrics/manage" /> : <HomeDashboard />}
                  />
                  <Route path="/dashboard" element={<HomeDashboardApp />} />
                  <Route
                    path="/contact/"
                    element={authenticated ? <Navigate to="/metrics/manage" /> : <ContactPage />}
                  />
                  <Route
                    path="/thank-you"
                    element={authenticated ? <Navigate to="/metrics/manage" /> : <ThankYouPage />}
                  />
                  <Route path="/metrics/" element={<MetricCollectionDashboard />} />
                  <Route path="/metrics/manage" element={<MetricCollectionDataManagement />} />
                  <Route path="/metrics/audit" element={<MetricCollectionAuditLogs />} />
                  <Route path="/readiness/" element={<ReadinessDashboard />} />
                  <Route path="/assessment/" element={<AssessmentDashboard />} />
                  <Route path="/resource/" element={<ResourceDashboard />} />
                  <Route path="/report/" element={<ReportDashboard />} />
                  <Route path="/report/export" element={<RawExport />} />
                  <Route path="/configuration/dashboard" element={<ConfigurationDashboard />} />
                  <Route
                    path="/configuration/personalisation"
                    element={<ConfigurationPersonalisation />}
                  />
                  <Route
                    path="/configuration/geographical"
                    element={<ConfigurationGeographical />}
                  />
                  <Route
                    path="/configuration/organisation"
                    element={<ConfigurationOrganisation />}
                  />
                  <Route
                    path="/configuration/external-companies"
                    element={<ConfigurationExternalCompanies />}
                  />
                  <Route
                    path="/configuration/reporting-periods"
                    element={<ConfigurationReportingPeriods />}
                  />
                  <Route
                    path="/configuration/reporting-period-groups"
                    element={<ConfigurationReportingPeriodGroups />}
                  />
                  <Route path="/configuration/standards" element={<ConfigurationStandards />} />
                  <Route path="/configuration/metrics" element={<ConfigurationMetrics />} />
                  <Route
                    path="/configuration/other-configuration"
                    element={<ConfigurationOtherConfiguration />}
                  />
                  <Route
                    path="/configuration/emission-factors"
                    element={<ConfigurationEmissionFactors />}
                  />
                  <Route path="/settings/group-management" element={<GroupManagementView />} />
                  <Route
                    path="/settings/group-management/view"
                    element={
                      <GroupView
                        addGroupCompany={addGroupCompany}
                        handleGroupChange={handleGroupChange}
                        handleCompanyChange={handleCompanyChange}
                      />
                    }
                  />
                  <Route path="/settings/user-management" element={<UserManagementView />} />
                  <Route path="/metric-record-activity" element={<AuditLogs />} />
                  <Route path="/settings/master/dashboard" element={<MasterViewDashboard />} />
                  <Route
                    path="web/reporting"
                    element={authenticated ? <Navigate to="/metrics/manage" /> : <p>Report</p>}
                  />
                  <Route
                    path="web/readiness"
                    element={authenticated ? <Navigate to="/metrics/manage" /> : <p>Readiness</p>}
                  />
                  <Route
                    path="web/resources"
                    element={authenticated ? <Navigate to="/metrics/manage" /> : <p>Resources</p>}
                  />
                  <Route
                    path="web/assessment"
                    element={authenticated ? <Navigate to="/metrics/manage" /> : <p>Assessment</p>}
                  />
                  <Route
                    path="web/data"
                    element={authenticated ? <Navigate to="/metrics/manage" /> : <p>Data</p>}
                  />
                  <Route path="/*" element={<InvalidURL />} />
                </Routes>
              </DrawerBody>

              {/* Assistance Drawer */}
              <Drawer anchor="right" open={openAssist} onClose={handleDrawerCloseAssist}>
                <Toolbar variant="dense">
                  <Typography variant="h6" color="inherit" component="div">
                    Assistance
                  </Typography>
                </Toolbar>
                <Box
                  sx={{
                    width: 300,
                    backgroundColor: 'white',
                    padding: 3
                  }}
                >
                  <DefaultContent></DefaultContent>
                </Box>
              </Drawer>
              <Snackbar
                open={open_snackbar_company_select}
                autoHideDuration={6000}
                onClose={handleSnackbarCloseCompanySelect}
                message={`Viewing as ${company?.name ? company?.name : 'Default'}`}
                action={snackbar_action}
              />

              {!authenticated && <FooterWebsite />}
            </Box>
            <CookieConsentDrawer />
            <Snackbar
              open={open_snackbar_welcome_message}
              autoHideDuration={6000}
              onClose={handleSnackbarCloseWelcomeMessage}
              message={'User signed in.'}
              action={snackbar_action}
            />
            <FeedbackSnackbar />
          </CompanyContext.Provider>
        </GroupContext.Provider>
      </UserContext.Provider>
    </FeedbackSnackbarProvider>
  );
};

export default AppBase;
