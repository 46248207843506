import * as React from 'react';
import { UserContext } from '../../context/UserContext';
import { User } from '@esg/esg-global-types';
import AccessDenied from '../shared/AccessDenied';
import LoadingScreen from '../../components/shared/LoadingScreen';
import { getAdminGroups } from '../../util/user_access';
import PanelLeftMenuWrapper, { MenuItem } from '../../components/shared/nav/PanelLeftMenuWrapper';
import ChecklistRtlIcon from '@mui/icons-material/ChecklistRtl';
import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined';
import DataSaverOffOutlinedIcon from '@mui/icons-material/DataSaverOffOutlined';
import AirIcon from '@mui/icons-material/Air';
import PaidOutlinedIcon from '@mui/icons-material/PaidOutlined';
import SegmentIcon from '@mui/icons-material/Segment';
import AssignmentIndOutlinedIcon from '@mui/icons-material/AssignmentIndOutlined';
import BusinessCenterOutlinedIcon from '@mui/icons-material/BusinessCenterOutlined';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import EmojiFlagsOutlinedIcon from '@mui/icons-material/EmojiFlagsOutlined';
import { Box, Typography } from '@mui/material';
import PanelStandards from '../../components/configuration/standards/PanelStandards';
import PanelMetricGroups from '../../components/configuration/metric_groups/PanelMetricGroups';
import PanelMetrics from '../../components/configuration/metrics/PanelMetrics';
import PanelEmissionFactors from '../../components/configuration/emission_factors/PanelEmissionFactors';
import PanelSubscriptions from '../../components/configuration/subscriptions/PanelSubscriptions';
import PanelModules from '../../components/configuration/modules/PanelModules';
import PanelRoles from '../../components/configuration/roles/PanelRoles';
import PanelSectors from '../../components/configuration/sectors/PanelSectors';
import PanelCountries from '../../components/configuration/countries/PanelCountries';
import PanelRegions from '../../components/configuration/regions/PanelRegions';

interface ViewMap {
  [id: string]: () => React.ReactNode;
}

const menu_items: Array<MenuItem> = [
  {
    id: 'countries',
    icon: <EmojiFlagsOutlinedIcon />,
    label: 'Countries'
  },
  {
    id: 'regions',
    icon: <LocationOnOutlinedIcon />,
    label: 'Regions'
  },
  {
    id: 'standards',
    icon: <ChecklistRtlIcon />,
    label: 'Standards'
  },
  {
    id: 'metric_groups',
    icon: <DataSaverOffOutlinedIcon />,
    label: 'Metric Groups'
  },
  {
    id: 'metrics',
    icon: <AssessmentOutlinedIcon />,
    label: 'Metrics'
  },
  {
    id: 'emission_factors',
    icon: <AirIcon />,
    label: 'Emission Factors'
  },
  {
    id: 'sectors',
    icon: <BusinessCenterOutlinedIcon />,
    label: 'Sectors'
  },
  {
    id: 'modules',
    icon: <SegmentIcon />,
    label: 'Modules'
  },
  {
    id: 'roles',
    icon: <AssignmentIndOutlinedIcon />,
    label: 'Roles'
  },
  {
    id: 'subscriptions',
    icon: <PaidOutlinedIcon />,
    label: 'Subscriptions'
  }
];

const view_elements: ViewMap = {
  countries: () => <PanelCountries master_list />,
  regions: () => <PanelRegions master_list />,
  standards: () => <PanelStandards master_list />,
  metric_groups: () => <PanelMetricGroups master_list />,
  metrics: () => <PanelMetrics master_list />,
  emission_factors: () => <PanelEmissionFactors master_list />,
  sectors: () => <PanelSectors master_list />,
  modules: () => <PanelModules />,
  roles: () => <PanelRoles />,
  subscriptions: () => <PanelSubscriptions />
};

/**
 * View for displaying master list database data allowing for creating, updating and deleting
 * @returns {JSX.Element}
 */
const MasterViewDashboard = () => {
  const user_info: User | null = React.useContext(UserContext);
  const [selectedView, setSelectedView] = React.useState<MenuItem>(menu_items[0]);

  const handleChangeSelectedView = (view: MenuItem): void => {
    setSelectedView(view);
  };

  return user_info === null ? (
    <LoadingScreen />
  ) : user_info.super_admin || getAdminGroups(user_info).length > 0 ? (
    <PanelLeftMenuWrapper
      header="MASTER LISTS"
      items={menu_items}
      handleMenuSelect={handleChangeSelectedView}
    >
      <Box sx={{ mx: 4 }}>
        <Box sx={{ display: 'flex', gap: 2, my: 6 }}>
          <Typography fontSize={'1.5rem'}>{selectedView.label}</Typography>
        </Box>
        {view_elements[selectedView.id] && view_elements[selectedView.id]()}
      </Box>
    </PanelLeftMenuWrapper>
  ) : (
    <AccessDenied />
  );
};

export default MasterViewDashboard;
