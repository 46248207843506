import {
  QuerySnapshot,
  DocumentData,
  QueryDocumentSnapshot,
  DocumentReference,
  collection,
  doc
} from 'firebase/firestore';
import { Country, EntityLabel } from '@esg/esg-global-types';
import { validateMasterListParams } from '../../util/validation';
import { FirestoreQueryParam } from '../../@types/shared';
import {
  BatchWrite,
  deleteFirestoreDoc,
  processBatchWrites,
  readFirestoreDocs,
  updateFirestoreDoc
} from '../app/db_util';
import { uuidv4 } from '@firebase/util';
import { MetadataError } from '@ep/error-handling';
import { auth, db } from '../google/firebase';
import { createAuditLog, generateAuditLogData, generateAuditLogDoc } from '../app/audit';

export type CountryData = Omit<Country, 'id'>;

// Singular and plural label for model entity.
export const country_label: EntityLabel = {
  one: 'Country',
  many: 'Countries'
};

/**
 * Create a collection path string for the master list countries or configured countries.
 * @param {boolean} master_list Reference the master list collection.
 * @param {string} group_id When not referencing a master list collection, a group id for the configured group is required.
 * @param {string} company_id When not referencing a master list collection, a company id for the configured company is required.
 * @returns {string} Path to a countries collection.
 */
export const createCountryCollectionPath = (
  master_list: boolean,
  group_id?: string,
  company_id?: string
): string => {
  try {
    validateMasterListParams(master_list, group_id, company_id);
    return master_list
      ? `/country_master_list`
      : `groups/${group_id}/companies/${company_id}/countries`;
  } catch (err: unknown) {
    const tracking_id: string = uuidv4();
    throw new MetadataError(
      err instanceof Error
        ? err.message
        : 'Error: createCountryCollectionPath failed on an unknown error.',
      {
        master_list: master_list,
        group_id: group_id,
        company_id: company_id
      },
      tracking_id
    );
  }
};

/**
 * Query all country documents for current portal company joined with relative country data
 * @param {boolean} master_list Return master list or configured country documents.
 * @param {string} group_id Group object of current portal company
 * @param {string} company_id id of current portal company to reference firestore documents
 * @param {string} id query a specific country by id.
 * @returns {Promise<Array<Country>>}
 */
export const getCountries = async (
  master_list: boolean,
  group_id?: string,
  company_id?: string,
  id?: string
): Promise<Array<Country>> => {
  try {
    const collection_path: string = createCountryCollectionPath(master_list, group_id, company_id);
    const query_params: Array<FirestoreQueryParam> = [
      { field_name: 'deleted', operator: '==', value: null }
    ];
    if (id !== undefined && id.length > 0)
      query_params.push({ field_name: 'id', operator: '==', value: id });
    const country_snapshot: QuerySnapshot = await readFirestoreDocs(collection_path, query_params);
    const countries: Array<Country> = country_snapshot.docs.map(
      (country: QueryDocumentSnapshot) => {
        const country_data: DocumentData = country.data();
        return {
          ...{
            id: country.id,
            deleted: country_data.deleted,
            code: country_data.code,
            name: country_data.name,
            continent: country_data.continent,
            phone_code: country_data.phone_code,
            phone_regex: country_data.phone_regex,
            reference: country.ref
          }
        };
      }
    );
    return countries;
  } catch (err: unknown) {
    const tracking_id: string = uuidv4();
    throw new MetadataError(
      err instanceof Error ? err.message : 'Error: getCountries failed on an unknown error.',
      {
        master_list: master_list,
        group_id: group_id,
        company_id: company_id
      },
      tracking_id
    );
  }
};

/**
 * Get the relevant Country object by international phone number from a list of countries.
 * @param {string} phone_number Phone number provided in an internation format starting with + followed by the country dialing code.
 * @param {Array<Country>} country_list A list of Country objects to validate the phone number against.
 * @returns {Country | null} Null is returned when no Country object was found within the provided list.
 */
export const getCountryFromListByPhone = (
  phone_number: string,
  country_list: Array<Country>
): Country | null => {
  const filtered_countries: Array<Country> =
    country_list && country_list.length > 1
      ? country_list.filter((country: Country) => {
          return country && country.phone_code
            ? phone_number.startsWith('+' + country.phone_code)
            : false;
        })
      : [];
  return filtered_countries.length === 1 ? filtered_countries[0] : null;
};

/**
 * Get a Flag CDN URI to a flag icon.
 * @param {string} country_code ISO 3166 country code.
 * @returns {string}
 */
export const generateFlagURI = (country_code: string): string =>
  `https://flagcdn.com/${country_code.toLowerCase()}.svg`;

/**
 * Create Country data in firestore on master list or company level
 * @param {boolean} master_list Flag to create new Country within master list or company level collection
 * @param {Array<Country | CountryData>} countries Country objects to create
 * @param {string | undefined} group_id Optional ID of Group to create countries for
 * @param {string | undefined} company_id Optional ID of Company to create countries for
 * @returns {Promise<Array<BatchWrite>>}
 */
export const createCountries = async (
  master_list: boolean,
  countries: Array<Country | CountryData>,
  group_id?: string,
  company_id?: string
): Promise<Array<BatchWrite>> => {
  try {
    const collection_path: string = createCountryCollectionPath(master_list, group_id, company_id);
    const country_writes: Array<BatchWrite> = [];
    const audit_log_batch_writes: Array<BatchWrite> = [];
    countries.forEach((country: Country | CountryData) => {
      const country_ref: DocumentReference = doc(
        collection(db, collection_path),
        'id' in country ? country.id : country.code.toUpperCase()
      );
      const country_data: CountryData = {
        deleted: null,
        name: country.name,
        continent: country.continent,
        code: country.code,
        phone_code: country.phone_code,
        phone_regex: country.phone_regex
      };
      country_writes.push({ reference: country_ref, operation: 'create', data: country_data });

      if (!master_list && group_id && company_id) {
        const audit_log_ref: DocumentReference = generateAuditLogDoc(group_id);
        const audit_log_data = generateAuditLogData(
          country.name,
          'create',
          '',
          country_ref,
          auth.currentUser?.email
        );
        audit_log_batch_writes.push({
          reference: audit_log_ref,
          operation: 'create',
          data: audit_log_data
        });
      }
    });
    await processBatchWrites(country_writes).catch((error) => {
      throw new Error(error);
    });
    await processBatchWrites(audit_log_batch_writes).catch((error) => {
      throw new Error(error);
    });

    return country_writes;
  } catch (err: unknown) {
    const tracking_id: string = uuidv4();
    throw new MetadataError(
      err instanceof Error
        ? err.message
        : 'Error: lib/metric_capture/country.ts failed on an unknown error while calling createCountries.',
      {
        master_list: master_list,
        group_id: group_id,
        company_id: company_id,
        countries: countries
      },
      tracking_id
    );
  }
};

/**
 * Update country with relative data
 * @param {boolean} master_list Flag to update country in master list
 * @param {Country} updated_country New Country data to push into document
 * @param {Country} original_country Old Country data
 * @param {string} group_id Optional ID of Group to update country for
 * @param {string} company_id Optional ID of Company to update country for
 * @returns {void}
 */
export const updateCountry = async (
  master_list: boolean,
  updated_country: Country,
  original_country: Country,
  group_id?: string,
  company_id?: string
): Promise<void> => {
  try {
    const collection_path = createCountryCollectionPath(master_list, group_id, company_id);
    const country_data: CountryData = {
      deleted: updated_country.deleted,
      name: updated_country.name,
      continent: updated_country.continent,
      code: updated_country.code,
      phone_code: updated_country.phone_code,
      phone_regex: updated_country.phone_regex
    };
    const updated_country_doc: DocumentReference = await updateFirestoreDoc(
      collection_path,
      updated_country.id,
      country_data
    );
    if (!master_list && group_id && company_id) {
      await createAuditLog(
        group_id,
        'update',
        JSON.stringify(original_country),
        JSON.stringify(country_data),
        updated_country_doc
      );
    }
  } catch (err: unknown) {
    const tracking_id: string = uuidv4();
    throw new MetadataError(
      err instanceof Error
        ? err.message
        : 'Error: lib/metric_capture/country.ts failed on an unknown error while calling updateCountry.',
      {
        group_id: group_id,
        company_id: company_id,
        updated_country: updated_country,
        original_country: original_country
      },
      tracking_id
    );
  }
};

/**
 * Soft delete a Country.
 * @param {string} group_id ID of Group to delete country for
 * @param {string} company_id ID of Company to delete country for
 * @param {string} country_id ID of country to delete
 * @param {string} country_group_id ID of country group to find delete country in
 * @returns {void}
 */
export const deleteCountry = async (
  master_list: boolean,
  country_id: string,
  country_name: string,
  group_id?: string,
  company_id?: string
): Promise<void> => {
  const collection_path = createCountryCollectionPath(master_list, group_id, company_id);
  try {
    const country_doc: DocumentReference = await deleteFirestoreDoc(collection_path, country_id);
    if (!master_list && group_id && company_id) {
      await createAuditLog(group_id, 'delete', country_name, '', country_doc);
    }
  } catch (err: unknown) {
    const tracking_id: string = uuidv4();
    throw new MetadataError(
      err instanceof Error
        ? err.message
        : 'Error: lib/country_capture/country.ts failed on an unknown error while calling deleteCountry.',
      {
        master_list: master_list,
        group_id: group_id,
        company_id: company_id,
        country_id: country_id,
        country_name: country_name
      },
      tracking_id
    );
  }
};
