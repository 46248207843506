import { EntityLabel, Module } from '@esg/esg-global-types';
import { FirestoreQueryParam } from '../../@types/shared';
import {
  DocumentData,
  DocumentReference,
  QueryDocumentSnapshot,
  QuerySnapshot,
  doc,
  setDoc
} from 'firebase/firestore';
import { deleteFirestoreDoc, readFirestoreDocs, updateFirestoreDoc } from './db_util';
import { uuidv4 } from '@firebase/util';
import { MetadataError } from '@ep/error-handling';
import { db } from '../google/firebase';

export type ModuleData = Omit<Module, 'id'>;

// Singular and plural label for model entity.
export const module_label: EntityLabel = {
  one: 'Module',
  many: 'Modules'
};

/**
 * Query all module documents for master list
 * @param {string} id Optional query for a specific module document
 * @returns {Promise<Array<Module>>}
 */
export const getModules = async (id?: string): Promise<Array<Module>> => {
  try {
    const collection_path = `module_master_list`;
    const query_params: Array<FirestoreQueryParam> = [
      { field_name: 'deleted', operator: '==', value: null }
    ];
    if (id !== undefined && id.length > 0)
      query_params.push({ field_name: 'id', operator: '==', value: id });
    const module_snapshot: QuerySnapshot = await readFirestoreDocs(collection_path, query_params);
    const modules: Array<Module> = module_snapshot.docs.map((module: QueryDocumentSnapshot) => {
      const module_data: DocumentData = module.data();
      return {
        id: module.id,
        deleted: module_data.deleted,
        name: module_data.name,
        description: module_data.description,
        views: module_data.views
      };
    });
    return modules;
  } catch (err: unknown) {
    const tracking_id: string = uuidv4();
    throw new MetadataError(
      err instanceof Error ? err.message : 'Error: getModules failed on an unknown error.',
      {
        id: id
      },
      tracking_id
    );
  }
};

/**
 * Create Module with relative data
 * @param {ModuleData} module Data of new Module to add to doc
 * @returns {DocumentReference}
 */
export const createModule = async (module: ModuleData): Promise<DocumentReference> => {
  try {
    const module_id: string = module.name.replaceAll(' ', '_').toLowerCase();
    const module_doc: DocumentReference = doc(db, `module_master_list/${module_id}`);
    await setDoc(module_doc, module);
    return module_doc;
  } catch (err: unknown) {
    const tracking_id: string = uuidv4();
    throw new MetadataError(
      err instanceof Error
        ? err.message
        : 'Error: lib/metric_capture/module.ts failed on an unknown error while calling createModule.',
      {
        module: module
      },
      tracking_id
    );
  }
};

/**
 * Update Module with relative data
 * @param {Module} updated_module New module data to push into document
 * @returns {void}
 */
export const updateModule = async (updated_module: Module): Promise<void> => {
  try {
    const collection_path = 'module_master_list';
    const module_data: ModuleData = {
      deleted: updated_module.deleted,
      name: updated_module.name,
      description: updated_module.description,
      views: updated_module.views
    };
    await updateFirestoreDoc(collection_path, updated_module.id, module_data);
  } catch (err: unknown) {
    const tracking_id: string = uuidv4();
    throw new MetadataError(
      err instanceof Error
        ? err.message
        : 'Error: lib/metric_capture/module.ts failed on an unknown error while calling updateModule.',
      {
        updated_module: updated_module
      },
      tracking_id
    );
  }
};

/**
 * Soft delete a Module.
 * @param {string} module_id ID of module to delete
 * @returns {Promise<void>}
 */
export const deleteModule = async (module_id: string): Promise<void> => {
  const collection_path = `module_master_list`;
  try {
    await deleteFirestoreDoc(collection_path, module_id);
  } catch (err: unknown) {
    const tracking_id: string = uuidv4();
    throw new MetadataError(
      err instanceof Error
        ? err.message
        : 'Error: lib/metric_capture/module.ts failed on an unknown error while calling deleteModule.',
      {
        module_id: module_id
      },
      tracking_id
    );
  }
};
