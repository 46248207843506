import * as React from 'react';
import { Box, Drawer, Tooltip, Typography } from '@mui/material';
import MonetizationOnOutlinedIcon from '@mui/icons-material/MonetizationOnOutlined';
import { Subscription } from '@esg/esg-global-types';
import { FeedbackSnackbarContext } from '../../../context/FeedbackSnackbarContext';
import DeleteConfirmModal from '../../shared/modal/DeleteConfirmModal';
import EditIcon from '@mui/icons-material/Edit';
import ConfigAddWidget, { CreateInput } from '../ConfigAddWidget';
import { MetadataError } from '@ep/error-handling';
import { uuidv4 } from '@firebase/util';
import { log } from '../../../util/log';
import { DataGrid, GridActionsCellItem, GridColDef } from '@mui/x-data-grid';
import { PanelSubscriptionsLoading } from './PanelSubscriptionsLoading';
import { PanelSubscriptionsNoRows } from './PanelSubscriptionsNoRows';
import { PanelSubscriptionsToolbar } from './PanelSubscriptionsToolbar';
import DeleteIcon from '@mui/icons-material/Delete';
import { DocumentReference } from 'firebase/firestore';
import ConfigEditWidget, { EditInput } from '../ConfigEditWidget';
import {
  SubscriptionData,
  createSubscription,
  deleteSubscription,
  getSubscriptions,
  subscription_label,
  updateSubscription
} from '../../../lib/app/subscription';

/**
 * Datagrid handling the display of master list Subscriptions
 * @returns {JSX.Element}
 */
const PanelSubscriptions = () => {
  const { setFeedbackData } = React.useContext(FeedbackSnackbarContext);
  const [actionRow, setActionRow] = React.useState<Subscription | null>(null);
  const [isCreateWidget, setIsCreateWidget] = React.useState<boolean>(true);
  const [subscriptionRows, setSubscriptionRows] = React.useState<Array<Subscription>>([]);
  const [displayWidgetPanelRight, setDisplayWidgetPanelRight] = React.useState<boolean>(false);
  const [openDialogDelete, setOpenDialogDelete] = React.useState<boolean>(false);
  const [gridLoading, setGridLoading] = React.useState<boolean>(true);

  // Handler functions
  const handleCreateClick = (): void => {
    setActionRow(null);
    setIsCreateWidget(true);
    setDisplayWidgetPanelRight(true);
  };

  const handleEditClick = (subscription: Subscription): void => {
    setActionRow(subscription);
    setIsCreateWidget(false);
    setDisplayWidgetPanelRight(true);
  };

  const handleDeleteClick = async (row: Subscription): Promise<void> => {
    try {
      setActionRow(row);
      setOpenDialogDelete(true);
    } catch (err: unknown) {
      const tracking_id: string = uuidv4();
      log(
        'error',
        new MetadataError(
          err instanceof Error
            ? err.message
            : 'Error: PanelSubscriptions failed on an unknown error while calling handleDeleteClick.',
          {
            row: row
          },
          tracking_id
        )
      );
      setFeedbackData({
        message: `Unable to delete subscription. Tracking ID: ${tracking_id}`,
        state: true,
        type: 'error'
      });
    }
  };

  const handleUpdateRows = (rows_data: Array<Subscription>): void => {
    setSubscriptionRows(rows_data);
  };

  const handleCloseDeleteModal = (): void => {
    setOpenDialogDelete(false);
  };

  const columns: Array<GridColDef> = [
    {
      field: 'name',
      headerName: 'Name',
      headerAlign: 'left',
      align: 'left',
      flex: 1
    },
    {
      field: 'price',
      headerName: 'Price',
      headerAlign: 'left',
      type: 'number',
      align: 'left',
      flex: 1
    },
    {
      field: 'user_limit',
      headerName: 'User Limit',
      headerAlign: 'left',
      type: 'number',
      align: 'left',
      flex: 1
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: '',
      headerAlign: 'right',
      align: 'right',
      hideable: false,
      flex: 1,
      getActions: ({ row }) => {
        return [
          <>
            <GridActionsCellItem
              key={1}
              icon={
                <Tooltip title="Edit Subscription">
                  <EditIcon color={!row.locked ? 'primary' : 'action'} />
                </Tooltip>
              }
              size="large"
              label="Edit"
              sx={{
                color: 'primary.main'
              }}
              onClick={() => handleEditClick(row)}
              disabled={row.locked}
            />
            <GridActionsCellItem
              key={2}
              icon={
                <Tooltip title="Delete Subscription">
                  <DeleteIcon color="primary" />
                </Tooltip>
              }
              size="large"
              label="Delete"
              sx={{
                color: 'primary.main'
              }}
              onClick={() => handleDeleteClick(row)}
            />
          </>
        ];
      }
    }
  ];

  const create_inputs: readonly CreateInput[] = [
    {
      id: 'name',
      type: 'text',
      label: 'Name'
    },
    {
      id: 'price',
      type: 'number',
      label: 'Price'
    },
    {
      id: 'user_limit',
      type: 'number',
      label: 'User Limit'
    }
  ];

  const edit_inputs: EditInput[] = [
    {
      id: 'name',
      type: 'text',
      label: 'Name'
    },
    {
      id: 'price',
      type: 'number',
      label: 'Price'
    },
    {
      id: 'user_limit',
      type: 'number',
      label: 'User Limit'
    }
  ];

  // Row functions
  const fetchRows = async (): Promise<void> => {
    setGridLoading(true);
    try {
      // Load rows from database.
      const subscriptions: Array<Subscription> = await getSubscriptions();
      // Load rows to memory.
      setSubscriptionRows(subscriptions);
    } catch (err: unknown) {
      const tracking_id: string = uuidv4();
      log(
        'error',
        new MetadataError(
          err instanceof Error
            ? err.message
            : 'Error: PanelSubscriptions failed on an unknown error while calling fetchRows.',
          {},
          tracking_id
        )
      );
      setFeedbackData({
        message: `Unable to fetch subscriptions. Tracking ID: ${tracking_id}`,
        state: true,
        type: 'error'
      });
      return;
    } finally {
      setGridLoading(false);
    }
  };

  React.useEffect(() => {
    try {
      (async () => {
        setSubscriptionRows([]);
        fetchRows();
      })().catch((error) => {
        throw new Error(error);
      });
    } catch (err: unknown) {
      const tracking_id: string = uuidv4();
      log(
        'error',
        new MetadataError(
          err instanceof Error
            ? err.message
            : 'Error: PanelSubscriptions failed on an unknown error while initialising.',
          null,
          tracking_id
        )
      );
      setFeedbackData({
        message: `An error occurred. Tracking ID: ${tracking_id}`,
        state: true,
        type: 'error'
      });
    }
    return;
  }, []);

  const createRow = async (name: string, price: number, user_limit: number): Promise<void> => {
    try {
      const subscription_data: SubscriptionData = {
        deleted: null,
        name: name,
        price: Number(price),
        user_limit: Number(user_limit)
      };
      const new_subscription: DocumentReference = await createSubscription(subscription_data);
      if (new_subscription) {
        handleUpdateRows([
          {
            id: new_subscription.id,
            ...subscription_data
          },
          ...subscriptionRows
        ]);
      }
    } catch (err: unknown) {
      const tracking_id: string = uuidv4();
      log(
        'error',
        new MetadataError(
          err instanceof Error
            ? err.message
            : 'Error: PanelSubscriptions failed on an unknown error while calling createRow.',
          {
            name: name,
            price: price,
            user_limit: user_limit
          },
          tracking_id
        )
      );
      setFeedbackData({
        message: `Unable to create subscription. Tracking ID: ${tracking_id}`,
        state: true,
        type: 'error'
      });
    }
  };

  const updateRow = async (
    updated_row: Subscription,
    original_data: Subscription
  ): Promise<void> => {
    try {
      await updateSubscription(updated_row).then(() => {
        handleUpdateRows(
          subscriptionRows.map((row: Subscription) =>
            updated_row.id === row.id ? { ...updated_row } : row
          )
        );
      });
    } catch (err: unknown) {
      const tracking_id: string = uuidv4();
      log(
        'error',
        new MetadataError(
          err instanceof Error
            ? err.message
            : 'Error: PanelSubscription failed on an unknown error while calling updateRow.',
          {
            updated_row: updated_row,
            original_data: original_data
          },
          tracking_id
        )
      );
      setFeedbackData({
        message: `Unable to update subscription. Tracking ID: ${tracking_id}`,
        state: true,
        type: 'error'
      });
    }
  };

  const deleteRow = async (subscription: Subscription): Promise<void> => {
    try {
      await deleteSubscription(subscription.id).then(() => {
        setSubscriptionRows(
          subscriptionRows.filter((row: Subscription) => {
            return row.id !== subscription.id;
          })
        );
      });
    } catch (err: unknown) {
      const tracking_id: string = uuidv4();
      log(
        'error',
        new MetadataError(
          err instanceof Error
            ? err.message
            : 'Error: PanelSubscriptions failed on an unknown error while calling deleteRow.',
          {
            subscription: subscription
          },
          tracking_id
        )
      );
      setFeedbackData({
        message: `Unable to remove subscription. Tracking ID: ${tracking_id}`,
        state: true,
        type: 'error'
      });
    } finally {
      setOpenDialogDelete(false);
    }
  };

  return (
    <>
      {/* Delete Confirmation Modal */}
      <DeleteConfirmModal
        open={openDialogDelete}
        allow_delete={true}
        handleCloseDeleteModal={handleCloseDeleteModal}
        delete_label={actionRow && actionRow.name}
        handleDelete={actionRow && (() => deleteRow(actionRow))}
        allow_archive={false}
      />

      {/* Side Widget Panel */}
      <Drawer
        anchor={'right'}
        open={displayWidgetPanelRight}
        onClose={() => setDisplayWidgetPanelRight(false)}
        PaperProps={{ style: { width: '30%', padding: '1.5rem' } }}
      >
        {/* Create / Edit Widget */}
        {isCreateWidget ? (
          <>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                flexWrap: 'wrap'
              }}
            >
              <MonetizationOnOutlinedIcon sx={{ marginRight: '2rem' }} fontSize="large" />
              <Typography variant="h5" color="inherit" align="left">
                Add {`${subscription_label.one}`}
              </Typography>
            </Box>
            <ConfigAddWidget
              create_label={subscription_label.one}
              create_icon={
                <MonetizationOnOutlinedIcon sx={{ marginRight: '2rem' }} fontSize="large" />
              }
              create_function_inputs={create_inputs}
              handleClose={() => setDisplayWidgetPanelRight(false)}
              createFunction={(name: string, price: number, user_limit: number) =>
                createRow(name, price, user_limit)
              }
              hide_configured_entities={true}
              hide_title={true}
            />
          </>
        ) : (
          <ConfigEditWidget
            edit_label="Subscription"
            edit_icon={<MonetizationOnOutlinedIcon sx={{ marginRight: '2rem' }} fontSize="large" />}
            handleClose={() => setDisplayWidgetPanelRight(false)}
            edit_entity={actionRow}
            edit_function_inputs={edit_inputs}
            handleEditInput={(input_id: string, value: unknown) =>
              actionRow && setActionRow({ ...actionRow, [input_id]: value })
            }
            confirmEditFunction={(
              updated_subscription: Subscription,
              original_subscription: Subscription
            ) => updateRow(updated_subscription, original_subscription)}
          />
        )}
      </Drawer>

      {/* Interactive Data Table */}
      <DataGrid
        autoHeight
        initialState={{
          pagination: {
            paginationModel: { pageSize: 10, page: 0 }
          },
          sorting: {
            sortModel: [{ field: 'modified', sort: 'desc' }]
          }
        }}
        hideFooter={subscriptionRows.length > 1 ? false : true}
        columnHeaderHeight={subscriptionRows.length < 1 ? 0 : 56}
        pageSizeOptions={[10, 25, 50, 100]}
        loading={gridLoading}
        rows={subscriptionRows}
        columns={columns}
        disableRowSelectionOnClick
        slots={{
          toolbar: PanelSubscriptionsToolbar,
          noRowsOverlay: PanelSubscriptionsNoRows,
          loadingOverlay: PanelSubscriptionsLoading
        }}
        slotProps={{ toolbar: { handleCreate: handleCreateClick } }}
        sx={{ '&, [class^=MuiDataGrid]': { border: 'none' }, width: '100%' }}
      />
    </>
  );
};

export default PanelSubscriptions;
