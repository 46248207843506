import * as React from 'react';
import { Box, Drawer, Tooltip, Typography } from '@mui/material';
import EmojiFlagsOutlinedIcon from '@mui/icons-material/EmojiFlagsOutlined';
import { Company, Group, Country } from '@esg/esg-global-types';
import {
  CountryData,
  createCountries,
  deleteCountry,
  getCountries,
  country_label,
  updateCountry
} from '../../../lib/metric_capture/country';
import { FeedbackSnackbarContext } from '../../../context/FeedbackSnackbarContext';
import DeleteConfirmModal from '../../shared/modal/DeleteConfirmModal';
import EditIcon from '@mui/icons-material/Edit';
import ConfigAddWidget, { CreateInput } from '../ConfigAddWidget';
import { MetadataError } from '@ep/error-handling';
import { uuidv4 } from '@firebase/util';
import { log } from '../../../util/log';
import { DataGrid, GridActionsCellItem, GridColDef } from '@mui/x-data-grid';
import { PanelCountriesToolbar } from './PanelCountriesToolbar';
import { PanelCountriesNoRows } from './PanelCountriesNoRows';
import { PanelCountriesLoading } from './PanelCountriesLoading';
import { GroupContext } from '../../../context/GroupContext';
import { CompanyContext } from '../../../context/CompanyContext';
import DeleteIcon from '@mui/icons-material/Delete';
import ConfigEditWidget, { EditInput } from '../ConfigEditWidget';
import { BatchWrite } from '../../../lib/app/db_util';

interface PanelCountriesProps {
  master_list?: boolean;
}

/**
 * Datagrid handling the display of configured or Master List Countries
 * @returns {JSX.Element}
 */
const PanelCountries = ({ master_list }: PanelCountriesProps) => {
  const { setFeedbackData } = React.useContext(FeedbackSnackbarContext);
  const group: Group | null = React.useContext(GroupContext);
  const company: Company | null = React.useContext(CompanyContext);
  const [actionRow, setActionRow] = React.useState<Country | null>(null);
  const [isCreateWidget, setIsCreateWidget] = React.useState<boolean>(true);
  const [countryRows, setCountryRows] = React.useState<Array<Country>>([]);
  const [displayWidgetPanelRight, setDisplayWidgetPanelRight] = React.useState<boolean>(false);
  const [openDialogDelete, setOpenDialogDelete] = React.useState<boolean>(false);
  const [gridLoading, setGridLoading] = React.useState<boolean>(true);

  const flagFactory = (country_code: string) => {
    return <img src={`https://flagcdn.com/w20/${String(country_code).toLowerCase()}.png`} />;
  };

  // Handler functions
  const handleCreateClick = (): void => {
    setActionRow(null);
    setIsCreateWidget(true);
    setDisplayWidgetPanelRight(true);
  };

  const handleEditClick = (country: Country): void => {
    setActionRow(country);
    setIsCreateWidget(false);
    setDisplayWidgetPanelRight(true);
  };

  const handleDeleteClick = async (row: Country): Promise<void> => {
    try {
      setActionRow(row);
      setOpenDialogDelete(true);
    } catch (err: unknown) {
      const tracking_id: string = uuidv4();
      log(
        'error',
        new MetadataError(
          err instanceof Error
            ? err.message
            : 'Error: PanelCountries failed on an unknown error while calling handleDeleteClick.',
          {
            group: group,
            company: company,
            row: row
          },
          tracking_id
        )
      );
      setFeedbackData({
        message: `Unable to delete country. Tracking ID: ${tracking_id}`,
        state: true,
        type: 'error'
      });
    }
  };

  const handleUpdateRows = (rows_data: Array<Country>): void => {
    setCountryRows(rows_data);
  };

  const handleCloseDeleteModal = (): void => {
    setOpenDialogDelete(false);
  };

  const columns: Array<GridColDef> = [
    {
      field: 'flag',
      headerName: 'Flag',
      headerAlign: 'left',
      align: 'left',
      flex: 1,
      maxWidth: 90,
      renderCell: (params) => {
        return flagFactory(params.row.code);
      }
    },
    {
      field: 'code',
      headerName: 'Code',
      headerAlign: 'left',
      align: 'left',
      flex: 1
    },
    {
      field: 'continent',
      headerName: 'Continent',
      headerAlign: 'left',
      align: 'left',
      flex: 1
    },
    {
      field: 'name',
      headerName: 'Name',
      headerAlign: 'left',
      align: 'left',
      flex: 1
    },
    {
      field: 'phone_code',
      headerName: 'Phone Code',
      headerAlign: 'left',
      align: 'left',
      flex: 1
    },
    {
      field: 'phone_regex',
      headerName: 'Phone Regex Validation',
      headerAlign: 'left',
      align: 'left',
      flex: 1
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: '',
      headerAlign: 'right',
      align: 'right',
      hideable: false,
      flex: 1,
      getActions: ({ row }) => {
        return [
          <>
            {master_list && (
              <GridActionsCellItem
                key={1}
                icon={
                  <Tooltip title="Edit Country">
                    <EditIcon color={!row.locked ? 'primary' : 'action'} />
                  </Tooltip>
                }
                size="large"
                label="Edit"
                sx={{
                  color: 'primary.main'
                }}
                onClick={() => handleEditClick(row)}
                disabled={row.locked}
              />
            )}
            <GridActionsCellItem
              key={2}
              icon={
                <Tooltip title="Delete Country">
                  <DeleteIcon color="primary" />
                </Tooltip>
              }
              size="large"
              label="Delete"
              sx={{
                color: 'primary.main'
              }}
              onClick={() => handleDeleteClick(row)}
            />
          </>
        ];
      }
    }
  ];

  const create_inputs: readonly CreateInput[] = master_list
    ? [
        {
          id: 'name',
          type: 'text',
          label: 'Name'
        },
        {
          id: 'continent',
          type: 'text',
          label: 'Continent'
        },
        {
          id: 'code',
          type: 'text',
          label: 'Code'
        },
        {
          id: 'phone_code',
          type: 'text',
          label: 'Phone Code'
        },
        {
          id: 'phone_regex',
          type: 'text',
          label: 'Phone Regex Validation'
        }
      ]
    : [];

  const edit_inputs: EditInput[] = master_list
    ? [
        {
          id: 'name',
          type: 'text',
          label: 'Name'
        },
        {
          id: 'continent',
          type: 'text',
          label: 'Continent'
        },
        {
          id: 'code',
          type: 'text',
          label: 'Code'
        },
        {
          id: 'phone_code',
          type: 'text',
          label: 'Phone Code'
        },
        {
          id: 'phone_regex',
          type: 'text',
          label: 'Phone Regex Validation'
        }
      ]
    : [];

  // Row functions
  const fetchRows = async (): Promise<void> => {
    setGridLoading(true);
    try {
      // Load rows from database.
      const countries: Array<Country> = master_list
        ? await getCountries(master_list)
        : group && company
          ? await getCountries(false, group.id, company.id)
          : [];
      // Load rows to memory.
      setCountryRows(countries);
    } catch (err: unknown) {
      const tracking_id: string = uuidv4();
      log(
        'error',
        new MetadataError(
          err instanceof Error
            ? err.message
            : 'Error: PanelCountries failed on an unknown error while calling fetchRows.',
          {
            group: group,
            company: company
          },
          tracking_id
        )
      );
      setFeedbackData({
        message: `Unable to fetch countries. Tracking ID: ${tracking_id}`,
        state: true,
        type: 'error'
      });
      return;
    } finally {
      setGridLoading(false);
    }
  };

  React.useEffect(
    () => {
      try {
        (async () => {
          setCountryRows([]);
          fetchRows();
        })().catch((error) => {
          throw new Error(error);
        });
      } catch (err: unknown) {
        const tracking_id: string = uuidv4();
        log(
          'error',
          new MetadataError(
            err instanceof Error
              ? err.message
              : 'Error: PanelCountries failed on an unknown error while initialising.',
            null,
            tracking_id
          )
        );
        setFeedbackData({
          message: `An error occurred. Tracking ID: ${tracking_id}`,
          state: true,
          type: 'error'
        });
      }
      return;
    },
    master_list ? [] : [group, company]
  );

  const createRow = async (
    name: string,
    continent: string,
    code: string,
    phone_code: string,
    phone_regex: string
  ): Promise<void> => {
    try {
      const country_data: CountryData = {
        deleted: null,
        name: name,
        continent: continent,
        code: code,
        phone_code: phone_code,
        phone_regex: phone_regex
      };
      const new_country_writes: Array<BatchWrite> = await createCountries(!!master_list, [
        country_data
      ]);
      if (new_country_writes) {
        handleUpdateRows([
          ...new_country_writes.map((new_country_write: BatchWrite) => {
            return {
              id: new_country_write.reference.id,
              deleted: new_country_write.data.deleted,
              name: new_country_write.data.name,
              continent: new_country_write.data.continent,
              code: new_country_write.data.code,
              phone_code: new_country_write.data.phone_code,
              phone_regex: new_country_write.data.phone_regex
            };
          }),
          ...countryRows
        ]);
      }
    } catch (err: unknown) {
      const tracking_id: string = uuidv4();
      log(
        'error',
        new MetadataError(
          err instanceof Error
            ? err.message
            : 'Error: PanelCountries failed on an unknown error while calling createRow.',
          {
            name: name
          },
          tracking_id
        )
      );
      setFeedbackData({
        message: `Unable to create countries. Tracking ID: ${tracking_id}`,
        state: true,
        type: 'error'
      });
    }
  };

  const updateRow = async (updated_row: Country, original_data: Country): Promise<void> => {
    try {
      await updateCountry(!!master_list, updated_row, original_data, group?.id, company?.id).then(
        () => {
          handleUpdateRows(
            countryRows.map((row: Country) =>
              updated_row.id === row.id ? { ...updated_row } : row
            )
          );
        }
      );
    } catch (err: unknown) {
      const tracking_id: string = uuidv4();
      log(
        'error',
        new MetadataError(
          err instanceof Error
            ? err.message
            : 'Error: PanelCountries failed on an unknown error while calling updateRow.',
          {
            master_list: master_list,
            group: group,
            company: company,
            updated_row: updated_row,
            original_data: original_data
          },
          tracking_id
        )
      );
      setFeedbackData({
        message: `Unable to update country. Tracking ID: ${tracking_id}`,
        state: true,
        type: 'error'
      });
    }
  };

  const deleteRow = async (country: Country): Promise<void> => {
    try {
      await deleteCountry(!!master_list, country.id, country.name, group?.id, company?.id).then(
        () => {
          setCountryRows(
            countryRows.filter((row: Country) => {
              return row.id !== country.id;
            })
          );
        }
      );
    } catch (err: unknown) {
      const tracking_id: string = uuidv4();
      log(
        'error',
        new MetadataError(
          err instanceof Error
            ? err.message
            : 'Error: PanelCountries failed on an unknown error while calling deleteRow.',
          {
            group: group,
            company: company,
            country: country
          },
          tracking_id
        )
      );
      setFeedbackData({
        message: `Unable to remove country. Tracking ID: ${tracking_id}`,
        state: true,
        type: 'error'
      });
    } finally {
      setOpenDialogDelete(false);
    }
  };

  return (
    <>
      {/* Delete Confirmation Modal */}
      <DeleteConfirmModal
        open={openDialogDelete}
        allow_delete={true}
        delete_warning="Any Metrics and Emission Factors related to this Country will also be deleted"
        handleCloseDeleteModal={handleCloseDeleteModal}
        delete_label={actionRow && actionRow.name}
        handleDelete={actionRow && (() => deleteRow(actionRow))}
        allow_archive={false}
      />

      {/* Side Widget Panel */}
      <Drawer
        anchor={'right'}
        open={displayWidgetPanelRight}
        onClose={() => setDisplayWidgetPanelRight(false)}
        PaperProps={{ style: { width: '30%', padding: '1.5rem' } }}
      >
        {/* Create / Edit Widget */}
        {isCreateWidget ? (
          <>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                flexWrap: 'wrap'
              }}
            >
              <EmojiFlagsOutlinedIcon sx={{ marginRight: '2rem' }} fontSize="large" />
              <Typography variant="h5" color="inherit" align="left">
                Add {country_label.one}
              </Typography>
            </Box>
            {master_list && (
              <ConfigAddWidget
                create_label={country_label.one}
                create_icon={
                  <EmojiFlagsOutlinedIcon sx={{ marginRight: '2rem' }} fontSize="large" />
                }
                create_function_inputs={create_inputs}
                handleClose={() => setDisplayWidgetPanelRight(false)}
                createFunction={(
                  name: string,
                  continent: string,
                  code: string,
                  phone_code: string,
                  phone_regex: string
                ) => createRow(name, continent, code, phone_code, phone_regex)}
                hide_configured_entities={true}
                hide_title={true}
              />
            )}
          </>
        ) : (
          <ConfigEditWidget
            edit_label="Country"
            edit_icon={<EmojiFlagsOutlinedIcon sx={{ marginRight: '2rem' }} fontSize="large" />}
            handleClose={() => setDisplayWidgetPanelRight(false)}
            edit_entity={actionRow}
            edit_function_inputs={edit_inputs}
            handleEditInput={(input_id: string, value: unknown) =>
              actionRow && setActionRow({ ...actionRow, [input_id]: value })
            }
            confirmEditFunction={(updated_country: Country, original_country: Country) =>
              updateRow(updated_country, original_country)
            }
          />
        )}
      </Drawer>

      {/* Interactive Data Table */}
      <DataGrid
        autoHeight
        initialState={{
          pagination: {
            paginationModel: { pageSize: 10, page: 0 }
          },
          sorting: {
            sortModel: [{ field: 'modified', sort: 'desc' }]
          }
        }}
        hideFooter={countryRows.length > 1 ? false : true}
        columnHeaderHeight={countryRows.length < 1 ? 0 : 56}
        pageSizeOptions={[10, 25, 50, 100]}
        loading={gridLoading}
        rows={countryRows}
        columns={columns}
        disableRowSelectionOnClick
        slots={{
          toolbar: PanelCountriesToolbar,
          noRowsOverlay: PanelCountriesNoRows,
          loadingOverlay: PanelCountriesLoading
        }}
        slotProps={{ toolbar: { handleCreate: handleCreateClick } }}
        sx={{ '&, [class^=MuiDataGrid]': { border: 'none' }, width: '100%' }}
      />
    </>
  );
};

export default PanelCountries;
