import React from 'react';
import {
  Autocomplete,
  AutocompleteRenderGroupParams,
  AutocompleteRenderInputParams,
  Box,
  Checkbox,
  TextField
} from '@mui/material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { resolveObjectFromString } from '../../../../util/validation';
import { SiteExtended, getSitesJoinParents, site_label } from '../../../../lib/metric_capture/site';
import { Site } from '@esg/esg-global-types';

interface SiteSelectProps {
  handleChangeSites?: (sites: Array<SiteExtended | Site> | SiteExtended | Site | null) => void;
  options?: Array<SiteExtended | Site>;
  selected_options?: Array<SiteExtended | Site> | SiteExtended | Site | null;
  disabled?: boolean;
  input_label?: string;
  group_by?: 'name' | 'subdivision.name' | 'division.name';
  multi_select?: boolean;
  tag_limit?: number;
  group_id?: string;
  company_id?: string;
  loading?: boolean;
  auto_select?: boolean;
}

// Checkbox icons
const check_icon_blank = <CheckBoxOutlineBlankIcon fontSize="small" />;
const check_icon_filled = <CheckBoxIcon fontSize="small" />;

/**
 * Site selection auto-complete component that handles a variety of built-in scenarios.
 * @param {Array<SiteExtended | Site> | SiteExtended | Site | null} props.handleChangeSites Callback function to notify the parent component when the selected options have changed.
 * @param {Array<SiteExtended | Site>} props.options Custom list of options provided by the parent component. Defaults to none.
 * @param {Array<SiteExtended | Site> | SiteExtended | Site | null} props.selected_options Custom list of selected options provided by the parent component. Changes component from an uncontrolled to controlled.
 * @param {boolean} props.disabled Renders the component in a disabled state along with any other provided components. Defaults to enabled.
 * @param {string} props.input_label Sets a custom component input label. Defaults to the singular version of the main entity name.
 * @param {string} props.group_by Group the options by any one of a variety of grouping options. Defaults to ungrouped.
 * @param {boolean} props.multi_select Allow multi option selection and keeps the select open while selecting. Defaults to a single select and closes after selecting an option.
 * @param {number} props.tag_limit Sets a limit on the amount of tags rendered in the input. Defaults to unlimited.
 * @param {string} props.group_id Query configured sites for the provided group. Works in conjunction with props.company_id. Defaults to empty list.
 * @param {string} props.company_id Query configured sites for the provided company. Works in conjunction with props.group_id. Defaults to empty list.
 * @param {boolean} props.loading A flag to set the autocomplete into a loading state for things such as fetching entries from database.
 * @param {boolean} props.auto_select Pre-populate the autocomplete input when only one site is available.
 */
const SiteSelect = (props: SiteSelectProps) => {
  const [optionList, setOptions] = React.useState<Array<SiteExtended | Site>>([]);
  const [selectedOptions, setSelectedOptions] = React.useState<
    Array<SiteExtended | Site> | SiteExtended | Site | null
  >(props.multi_select ? [] : null);

  // Define autocomplete options
  React.useEffect(() => {
    (async () => {
      const options: Array<SiteExtended | Site> = props.options
        ? // Sites supplied by the parent component.
          props.options
        : props.group_id && props.company_id
          ? await getSitesJoinParents(props.group_id, props.company_id)
          : [];
      if (options)
        setOptions(
          options.sort((site_a: SiteExtended | Site, site_b: SiteExtended | Site) => {
            const site_name_a: string = resolveObjectFromString(props.group_by ?? '', site_a);
            const site_name_b: string = resolveObjectFromString(props.group_by ?? '', site_b);
            return site_name_a < site_name_b ? -1 : site_name_b > site_name_a ? 1 : 0;
          })
        );
      // When auto-select is true, automatically populate the autocomplete when only one standard is present.
      if (props.auto_select && options.length === 1 && options[0] !== selectedOptions) {
        setSelectedOptions(props.multi_select ? [options[0]] : options[0]);
        props.handleChangeSites &&
          props.handleChangeSites(props.multi_select ? [options[0]] : options[0]);
      }
    })();
  }, [props.group_id, props.company_id, props.options]);

  // Define selected options
  React.useEffect(() => {
    setSelectedOptions(
      props.multi_select
        ? Array.isArray(props.selected_options)
          ? props.selected_options
          : []
        : props.selected_options
          ? props.selected_options
          : null
    );
  }, [props.options, props.selected_options]);

  // Group selection
  const selected_options_array: Array<SiteExtended | Site> =
    selectedOptions && !Array.isArray(selectedOptions)
      ? [selectedOptions]
      : selectedOptions && Array.isArray(selectedOptions)
        ? selectedOptions
        : [];

  const checkGroup = (group_key: string): boolean => {
    const group_length: number = optionList.filter(
      (option: SiteExtended | Site) =>
        (props.group_by ? resolveObjectFromString(props.group_by, option) : '') === group_key
    ).length;
    const selected_group_length: number = selected_options_array.filter(
      (option: SiteExtended | Site) =>
        (props.group_by ? resolveObjectFromString(props.group_by, option) : '') === group_key
    ).length;
    return group_length === selected_group_length;
  };

  const changeGroup = (group_key: string): void => {
    const grouped_options: Array<SiteExtended | Site> = optionList.filter(
      (option: SiteExtended | Site) =>
        (props.group_by ? resolveObjectFromString(props.group_by, option) : '') === group_key
    );
    const selected_group_options: Array<SiteExtended | Site> = selected_options_array.filter(
      (option: SiteExtended | Site) =>
        (props.group_by ? resolveObjectFromString(props.group_by, option) : '') === group_key
    );
    if (selected_group_options.length > 0) {
      const selected_without_group: Array<SiteExtended | Site> = (
        Array.isArray(selectedOptions) ? selectedOptions : []
      ).filter(
        (option: SiteExtended | Site) =>
          (props.group_by ? resolveObjectFromString(props.group_by, option) : '') !== group_key
      );
      setSelectedOptions(selected_without_group);
      props.handleChangeSites && props.handleChangeSites(selected_without_group);
    } else {
      const selected_with_group: Array<SiteExtended | Site> = [
        ...(Array.isArray(selectedOptions) ? selectedOptions : []),
        ...grouped_options
      ];
      setSelectedOptions(selected_with_group);
      props.handleChangeSites && props.handleChangeSites(selected_with_group);
    }
  };

  return (
    <Box id="site-select">
      <Autocomplete
        fullWidth
        disabled={props.disabled}
        loading={props.loading}
        limitTags={props.tag_limit}
        options={optionList}
        groupBy={
          props.group_by
            ? (option: SiteExtended | Site) =>
                props.group_by ? resolveObjectFromString(props.group_by, option) : ''
            : undefined
        }
        value={selectedOptions}
        getOptionLabel={(option: SiteExtended | Site) => {
          return option?.name ?? '';
        }}
        disableCloseOnSelect={props.multi_select}
        isOptionEqualToValue={(option: SiteExtended | Site, value: SiteExtended | Site) =>
          option.id === value.id
        }
        multiple={props.multi_select}
        noOptionsText={`No ${site_label.many.toLowerCase()}`}
        renderOption={(render_props, option: SiteExtended | Site, { selected }) => (
          <Box
            component="li"
            sx={{ display: 'flex', alignItems: 'center' }}
            {...render_props}
            key={option.id}
          >
            {props.multi_select && (
              <Checkbox
                icon={check_icon_blank}
                checkedIcon={check_icon_filled}
                style={{ marginRight: 8 }}
                checked={selected}
              />
            )}
            {option.name}
          </Box>
        )}
        renderGroup={
          props.multi_select
            ? (params: AutocompleteRenderGroupParams) => (
                <Box component="div" key={params.key}>
                  <Box
                    component="li"
                    style={{
                      fontSize: '0.85rem',
                      color: '#616161',
                      fontWeight: 500
                    }}
                  >
                    <Checkbox
                      icon={check_icon_blank}
                      checkedIcon={check_icon_filled}
                      checked={checkGroup(params.group)}
                      onChange={() => {
                        changeGroup(params.group);
                      }}
                    />
                    {params.group}
                  </Box>
                  <span>{params.children}</span>
                </Box>
              )
            : undefined
        }
        renderInput={(params: AutocompleteRenderInputParams) => (
          <TextField {...params} label={props.input_label ?? site_label.one} variant="standard" />
        )}
        onChange={(
          event: React.SyntheticEvent<Element, Event>,
          value: Array<SiteExtended | Site> | SiteExtended | Site | null
        ) => {
          setSelectedOptions(value);
          props.handleChangeSites && props.handleChangeSites(value);
        }}
      />
    </Box>
  );
};

export default SiteSelect;
