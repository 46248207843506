import { Box, Grid, IconButton, Tooltip, Typography } from '@mui/material';
import React from 'react';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import { ExportFilterProps } from '../ExportHub';
import { ReportingPeriodGroup } from '@esg/esg-global-types';
import ReportingPeriodGroupSelect from '../../shared/input/select/ReportingPeriodGroupSelect';
import AlertBanner from '../../shared/banner/AlertBanner';

/**
 * A widget to add inclusive filters to an export Qualitative Metric Records.
 * @param {MetricRecordEntities} export_entities Lists of entities to use as options for filters.
 * @param {ExportType} export_type Type of Export chosen by the user.
 * @param {FilterInputErrors} input_errors Object listing invalid inputs from filter selections.
 * @param {void} handleUpdateFilters Function to update the export filters in the parent.
 * @param {void} handleClearFilters Function to clear all filters in the parent.
 * @param {void} handleInputValidity Function to update input_errors object in parent.
 * @returns {JSX.Element}
 */
const QualitativeMetricRecordFilters = ({
  export_entities,
  export_type,
  input_errors,
  handleUpdateFilters,
  handleClearFilters,
  handleInputValidity
}: ExportFilterProps) => {
  const [reportingPeriodGroupFilters, setReportingPeriodGroupFilters] = React.useState<
    Array<ReportingPeriodGroup>
  >([]);

  const tag_limit = 3;

  React.useEffect(() => {
    handleInputValidity(
      'reporting_period_groups',
      reportingPeriodGroupFilters.length > 0 ? '' : 'MIN_REPORTING_PERIOD_GROUPS'
    );
  }, [export_entities, export_type]);

  return (
    <Box sx={{ py: 4, px: 2 }}>
      <Box sx={{ display: 'flex' }}>
        <Typography fontSize="1.3rem" textAlign="left">
          Apply Filters to Export
        </Typography>
        <Tooltip title="Reset Filters">
          <IconButton
            onClick={() => {
              handleClearFilters();
              setReportingPeriodGroupFilters([]);
              handleInputValidity('reporting_period_groups', 'MIN_REPORTING_PERIOD_GROUPS');
            }}
            sx={{ ml: 'auto' }}
          >
            <FilterAltOffIcon />
          </IconButton>
        </Tooltip>
      </Box>
      <Grid container spacing={4} sx={{ my: 2 }}>
        <Grid item xs={11}>
          <ReportingPeriodGroupSelect
            allow_multi_select
            tag_limit={tag_limit}
            reporting_period_group_options={export_entities.reporting_period_groups}
            selected_reporting_period_group={reportingPeriodGroupFilters}
            handleChangeReportingPeriodGroups={(
              reporting_period_groups: Array<ReportingPeriodGroup> | ReportingPeriodGroup | null
            ) => {
              if (Array.isArray(reporting_period_groups)) {
                setReportingPeriodGroupFilters(reporting_period_groups);
                handleUpdateFilters(
                  'reporting_period_groups',
                  reporting_period_groups.map(
                    (reporting_period_group: ReportingPeriodGroup) =>
                      reporting_period_group.reference!.path
                  ),
                  reporting_period_groups.length === 0
                );
                handleInputValidity(
                  'reporting_period_groups',
                  reporting_period_groups.length > 0 ? '' : 'MIN_REPORTING_PERIOD_GROUPS'
                );
              }
            }}
          />
          {input_errors.reporting_period_groups === 'MIN_REPORTING_PERIOD_GROUPS' && (
            <Box sx={{ mt: 2, mb: -5 }}>
              <AlertBanner
                severity="warning"
                message={'Please select at least one reporting period group'}
                open
              />
            </Box>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default QualitativeMetricRecordFilters;
