import {
  collection,
  getDocs,
  query,
  CollectionReference,
  Query,
  QuerySnapshot,
  where,
  doc,
  updateDoc,
  deleteField
} from 'firebase/firestore';
import { refCompanyDoc } from '../app/company';
import {
  Division,
  EntityLabel,
  MetricRecord,
  Region,
  ReportingPeriod,
  Site,
  Subdivision
} from '@esg/esg-global-types';
import { db } from '../google/firebase';
import { createAuditLog } from '../app/audit';
import { getReportingPeriods } from './reporting_period';
import { getMetricRecords } from './metric_record';
import { FirestoreQueryParam } from '../../@types/shared';
import { getSubdivisions } from './subdivision';
import { getDivisions } from './division';
import { uuidv4 } from '@firebase/util';
import { log } from '../../util/log';
import { MetadataError } from '@ep/error-handling';
import { createFirestoreDoc, deleteFirestoreDoc, updateFirestoreDoc } from '../app/db_util';
import { isValidLatitude, isValidLongitude } from '../validation/geo_validation';

export interface SiteExtended extends Site {
  division: Division;
  subdivision: Subdivision;
}

export const site_label: EntityLabel = {
  one: 'Site',
  many: 'Sites'
};

/**
 * Function to query all site documents for current portal company
 * @param {Group} group Group object of current portal company
 * @param {string} company_id id of current portal company to reference firestore documents
 * @returns {Array<Site>}
 */
export const getSites = async (group_id: string, company_id: string) => {
  const sites_collection: CollectionReference = collection(
    refCompanyDoc(group_id, company_id),
    'sites'
  );
  const sites_query: Query = query(sites_collection, where('deleted', '==', null));
  try {
    const sites_docs: QuerySnapshot = await getDocs(sites_query);
    const sites: Array<Site> = sites_docs.docs.map((site) => {
      return {
        id: site.id,
        deleted: site.data().deleted,
        name: site.data().name,
        subdivision: site.data().subdivision,
        region: site.data().region,
        archived: site.data().archived,
        latitude: site.data().latitude,
        longitude: site.data().longitude,
        reference: site.ref
      };
    });
    return sites;
  } catch (err) {
    const error = `Error while getting Countries from Firebase: ${JSON.stringify({
      message: err instanceof Error ? err.message : '',
      stacktrace: err instanceof Error ? err.stack : '',
      variables: {
        sites_collection: sites_collection,
        sites_query: sites_query
      }
    })}`;
    throw new Error(`Error: getSites: ${JSON.stringify(error)}.`);
  }
};

/**
 * Function to query all site documents for current portal company joined with relative division and subdivision data
 * @param {Group} group Group object of current portal company
 * @param {string} company_id id of current portal company to reference firestore documents
 * @returns {Array<SiteExtended>}
 */
export const getSitesJoinParents = async (group_id: string, company_id: string) => {
  try {
    const sites_arr: Array<SiteExtended> = [];
    const [divisions, subdivisions, sites]: [Array<Division>, Array<Subdivision>, Array<Site>] =
      await Promise.all([
        getDivisions(group_id, company_id),
        getSubdivisions(group_id, company_id),
        getSites(group_id, company_id)
      ]);
    sites.forEach((site: Site) => {
      const subdivision = subdivisions.find(
        (subdivision: Subdivision) => subdivision.id === site.subdivision.id
      );
      const division = subdivision
        ? divisions.find((division: Division) => division.id === subdivision.division.id)
        : null;
      division &&
        subdivision &&
        sites_arr.push({
          ...site,
          division: division,
          subdivision: subdivision
        });
    });
    return sites_arr;
  } catch (err: unknown) {
    const tracking_id: string = uuidv4();
    log(
      'error',
      new MetadataError(
        err instanceof Error
          ? err.message
          : 'Error: lib/metric_capture/site.ts failed on an unknown error while calling getSitesJoinParents.',
        {
          group_id: group_id,
          company_id: company_id
        },
        tracking_id
      )
    );
    throw new Error(`Error: getSitesJoinParents: ${JSON.stringify(err)}.`);
  }
};

/**
 * Function to create site with relative data
 * @param {string} group_id ID of Group which Site is being created for
 * @param {string} company_id ID of Company which Site is being created for
 * @param {string} site_name Name of new Subdivision
 * @param {Subdivision} subdivision Group Subdivision of Site
 * @param {Region} region Group Region of Site
 * @returns {DocumentReference}
 */
export const createSite = async (
  group_id: string,
  company_id: string,
  site_name: string,
  subdivision: Subdivision,
  region: Region,
  latitude: string,
  longitude: string
) => {
  try {
    if (
      group_id &&
      company_id &&
      site_name &&
      subdivision &&
      region &&
      isValidLatitude(latitude) &&
      isValidLongitude(longitude)
    ) {
      const site_doc = await createFirestoreDoc(
        `/groups/${group_id}/companies/${company_id}/sites`,
        {
          deleted: null,
          name: site_name,
          subdivision: doc(
            collection(db, `/groups/${group_id}/companies/${company_id}/subdivisions`),
            subdivision.id
          ),
          region: doc(
            collection(db, `/groups/${group_id}/companies/${company_id}/regions`),
            region.id
          ),
          latitude: latitude,
          longitude: longitude
        }
      );
      await createAuditLog(group_id, 'create', '', site_name, site_doc);
      return site_doc;
    } else {
      throw new MetadataError('Invalid parameters provided.', {
        group_id: group_id,
        company_id: company_id,
        site_name: site_name,
        subdivision: subdivision,
        region: region,
        latitude_str: latitude,
        longitude_str: longitude
      });
    }
  } catch (err) {
    const error = `Error while creating Site in Firebase: ${JSON.stringify({
      message: err instanceof Error ? err.message : '',
      stacktrace: err instanceof Error ? err.stack : ''
    })}`;
    throw new Error(`Error: createSite: ${JSON.stringify(error)}.`);
  }
};

/**
 * Function to update site with relative data
 * @param {string} group_id ID of Group to update site for
 * @param {string} company_id ID of Company to update site for
 * @param {Site} updated_site New Site data to push into document
 * @returns {void}
 */
export const updateSite = async (
  group_id: string,
  company_id: string,
  updated_site: Site,
  original_data: Site
) => {
  try {
    if (
      group_id &&
      company_id &&
      updated_site.name &&
      updated_site.subdivision &&
      updated_site.region &&
      isValidLatitude(updated_site.latitude) &&
      isValidLongitude(updated_site.longitude)
    ) {
      const site_doc = await updateFirestoreDoc(
        `/groups/${group_id}/companies/${company_id}/sites`,
        updated_site.id,
        {
          deleted: updated_site.deleted,
          name: updated_site.name,
          region: doc(
            collection(db, `/groups/${group_id}/companies/${company_id}/regions`),
            updated_site.region.id
          ),
          latitude: updated_site.latitude,
          longitude: updated_site.longitude
        }
      );
      await createAuditLog(group_id, 'update', original_data.name, updated_site.name, site_doc);
    } else {
      throw new MetadataError('Invalid parameters provided.', {
        group_id: group_id,
        company_id: company_id,
        site_name: updated_site.name,
        subdivision: updated_site.subdivision,
        region: updated_site.region,
        latitude_str: updated_site.latitude,
        longitude_str: updated_site.longitude
      });
    }
  } catch (err) {
    const error = `Error while udpating Site in Firebase: ${JSON.stringify({
      message: err instanceof Error ? err.message : '',
      stacktrace: err instanceof Error ? err.stack : ''
    })}`;
    throw new Error(`Error: updateSite: ${JSON.stringify(error)}.`);
  }
};

/**
 * Function to delete site
 * @param {string} group_id ID of Group to delete site for
 * @param {string} company_id ID of Company to delete site for
 * @param {string} site_id ID of site to delete
 * @returns {void}
 */
export const deleteSite = async (
  group_id: string,
  company_id: string,
  site_id: string,
  site_name: string
) => {
  try {
    if (group_id && company_id && site_id) {
      const site_doc = await deleteFirestoreDoc(
        `/groups/${group_id}/companies/${company_id}/sites`,
        site_id
      );
      await createAuditLog(group_id, 'delete', site_name, '', site_doc);
    }
    return;
  } catch (err) {
    const error = `Error while deleting Site in Firebase: ${JSON.stringify({
      message: err instanceof Error ? err.message : '',
      stacktrace: err instanceof Error ? err.stack : ''
    })}`;
    throw new Error(`Error: deleteSite: ${JSON.stringify(error)}.`);
  }
};

/**
 * Function to archive site
 * @param {string} group_id ID of Group to archive site for
 * @param {string} company_id ID of Company to archive site for
 * @param {string} site_id ID of site to archive
 * @returns {void}
 */
export const archiveSite = async (group_id: string, company_id: string, site_id: string) => {
  try {
    if (group_id && company_id && site_id) {
      const site_doc = await updateFirestoreDoc(
        `/groups/${group_id}/companies/${company_id}/sites`,
        site_id,
        {
          archived: new Date()
        }
      );
      await createAuditLog(group_id, 'archive', '', '', site_doc);
    }
    return;
  } catch (err) {
    const error = `Error while archiving Site in Firebase: ${JSON.stringify({
      message: err instanceof Error ? err.message : '',
      stacktrace: err instanceof Error ? err.stack : ''
    })}`;
    throw new Error(`Error: archiveSite: ${JSON.stringify(error)}.`);
  }
};

/**
 * Function to archive site
 * @param {string} group_id ID of Group to unarchive site for
 * @param {string} company_id ID of Company to unarchive site for
 * @param {string} site_id ID of site to unarchive
 * @returns {void}
 */
export const unarchiveSite = async (group_id: string, company_id: string, site_id: string) => {
  try {
    if (group_id && company_id && site_id) {
      const site_doc = doc(collection(refCompanyDoc(group_id, company_id), `sites`), site_id);
      await updateDoc(site_doc, {
        archived: deleteField()
      });
      await createAuditLog(group_id, 'archive', '', 'null', site_doc);
    }
    return;
  } catch (err) {
    const error = `Error while unarchiving Site in Firebase: ${JSON.stringify({
      message: err instanceof Error ? err.message : '',
      stacktrace: err instanceof Error ? err.stack : ''
    })}`;
    throw new Error(`Error: unarchiveSite: ${JSON.stringify(error)}.`);
  }
};

/**
 * Function to check if a Site can be safely deleted
 * @param {string} group_id ID of Group to check Site for
 * @param {string} company_id ID of Company to check Site for
 * @param {string} subdivision_id ID of site to check
 * @returns {boolean}
 */
export const allowDeleteSite = async (group_id: string, company_id: string, site_id: string) => {
  try {
    const reporting_periods = await getReportingPeriods(group_id, company_id, true);
    if (reporting_periods.length === 0) return true;
    const reporting_period_ids = reporting_periods.map(
      (reporting_period: ReportingPeriod) => reporting_period.id
    );
    const query_params: Array<FirestoreQueryParam> = [
      {
        field_name: 'site',
        operator: '==',
        value: doc(collection(refCompanyDoc(group_id, company_id), 'sites'), site_id)
      }
    ];
    const metric_records = await getMetricRecords(group_id, company_id, query_params);
    const allow_delete = !metric_records.some((metric_record: MetricRecord) => {
      if (reporting_period_ids.includes(metric_record.reporting_period?.id ?? '')) {
        return true;
      }
    });
    return allow_delete;
  } catch (err) {
    const error = `Error while checking Site for deletion: ${JSON.stringify({
      message: err instanceof Error ? err.message : '',
      stacktrace: err instanceof Error ? err.stack : ''
    })}`;
    throw new Error(`Error: allowDeleteSite: ${JSON.stringify(error)}.`);
  }
};
