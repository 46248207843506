import * as React from 'react';
import { Box, Drawer, Tooltip, Typography } from '@mui/material';
import DataSaverOffOutlinedIcon from '@mui/icons-material/DataSaverOffOutlined';
import { Company, Group, MetricGroup } from '@esg/esg-global-types';
import { FeedbackSnackbarContext } from '../../../context/FeedbackSnackbarContext';
import DeleteConfirmModal from '../../shared/modal/DeleteConfirmModal';
import EditIcon from '@mui/icons-material/Edit';
import ConfigCreateWidget, { CreateInput } from '../ConfigAddWidget';
import { MetadataError } from '@ep/error-handling';
import { uuidv4 } from '@firebase/util';
import { log } from '../../../util/log';
import { DataGrid, GridActionsCellItem, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { PanelMetricGroupsToolbar } from './PanelMetricGroupsToolbar';
import { PanelMetricGroupsNoRows } from './PanelMetricGroupsNoRows';
import { PanelMetricGroupsLoading } from './PanelMetricGroupsLoading';
import { GroupContext } from '../../../context/GroupContext';
import { CompanyContext } from '../../../context/CompanyContext';
import DeleteIcon from '@mui/icons-material/Delete';
import { DocumentReference } from 'firebase/firestore';
import ConfigEditWidget, { EditInput } from '../ConfigEditWidget';
import {
  MetricGroupData,
  createMetricGroup,
  deleteMetricGroupWithMetrics,
  getMetricGroups,
  metric_group_label,
  updateMetricGroup
} from '../../../lib/metric_capture/metric_group';

interface PanelMetricGroupsProps {
  master_list?: boolean;
}

/**
 * Datagrid handling the display of configured or master list Metric Groups
 * @returns {JSX.Element}
 */
const PanelMetricGroups = ({ master_list }: PanelMetricGroupsProps) => {
  const { setFeedbackData } = React.useContext(FeedbackSnackbarContext);
  const group: Group | null = React.useContext(GroupContext);
  const company: Company | null = React.useContext(CompanyContext);
  const [actionRow, setActionRow] = React.useState<MetricGroup | null>(null);
  const [isCreateWidget, setIsCreateWidget] = React.useState<boolean>(true);
  const [metricGroupRows, setMetricGroupRows] = React.useState<Array<MetricGroup>>([]);
  const [displayWidgetPanelRight, setDisplayWidgetPanelRight] = React.useState<boolean>(false);
  const [openDialogDelete, setOpenDialogDelete] = React.useState<boolean>(false);
  const [gridLoading, setGridLoading] = React.useState<boolean>(true);

  // Handler functions
  const handleCreateClick = (): void => {
    setActionRow(null);
    setIsCreateWidget(true);
    setDisplayWidgetPanelRight(true);
  };

  const handleEditClick = (metric_group: MetricGroup): void => {
    setActionRow(metric_group);
    setIsCreateWidget(false);
    setDisplayWidgetPanelRight(true);
  };

  const handleDeleteClick = async (row: MetricGroup): Promise<void> => {
    try {
      setActionRow(row);
      setOpenDialogDelete(true);
    } catch (err: unknown) {
      const tracking_id: string = uuidv4();
      log(
        'error',
        new MetadataError(
          err instanceof Error
            ? err.message
            : 'Error: PanelMetricGroups failed on an unknown error while calling handleDeleteClick.',
          {
            group: group,
            company: company,
            row: row
          },
          tracking_id
        )
      );
      setFeedbackData({
        message: `Unable to delete metric group. Tracking ID: ${tracking_id}`,
        state: true,
        type: 'error'
      });
    }
  };

  const handleUpdateRows = (rows_data: Array<MetricGroup>): void => {
    setMetricGroupRows(rows_data);
  };

  const handleCloseDeleteModal = (): void => {
    setOpenDialogDelete(false);
  };

  const columns: Array<GridColDef> = [
    {
      field: 'name',
      headerName: 'Name',
      headerAlign: 'left',
      align: 'left',
      flex: 1
    },
    {
      field: 'description',
      headerName: 'Description',
      headerAlign: 'left',
      align: 'left',
      renderCell: (params: GridRenderCellParams) => (
        <Box sx={{ py: 2, maxHeight: '100px' }}>
          <Typography
            sx={{
              textAlign: 'left',
              fontSize: '0.9rem',
              whiteSpace: 'normal',
              wordWrap: 'break-word',
              maxHeight: '90px',
              overflow: 'hidden',
              textOverflow: 'ellipsis'
            }}
          >
            {params.value}
          </Typography>
        </Box>
      ),
      flex: 1
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: '',
      headerAlign: 'right',
      align: 'right',
      hideable: false,
      flex: 1,
      getActions: ({ row }) => {
        return [
          <>
            {master_list && (
              <GridActionsCellItem
                key={1}
                icon={
                  <Tooltip title="Edit Metric Group">
                    <EditIcon color={!row.locked ? 'primary' : 'action'} />
                  </Tooltip>
                }
                size="large"
                label="Edit"
                sx={{
                  color: 'primary.main'
                }}
                onClick={() => handleEditClick(row)}
                disabled={row.locked}
              />
            )}
            <GridActionsCellItem
              key={2}
              icon={
                <Tooltip title="Delete Metric Group">
                  <DeleteIcon color="primary" />
                </Tooltip>
              }
              size="large"
              label="Delete"
              sx={{
                color: 'primary.main'
              }}
              onClick={() => handleDeleteClick(row)}
            />
          </>
        ];
      }
    }
  ];

  const create_inputs: readonly CreateInput[] = master_list
    ? [
        {
          id: 'name',
          type: 'text',
          label: 'Name'
        },
        {
          id: 'description',
          type: 'text',
          label: 'Description'
        }
      ]
    : [];

  const edit_inputs: EditInput[] = master_list
    ? [
        {
          id: 'name',
          type: 'text',
          label: 'Name'
        },
        {
          id: 'description',
          type: 'text',
          label: 'Description'
        }
      ]
    : [];

  // Row functions
  const fetchRows = async (): Promise<void> => {
    setGridLoading(true);
    try {
      // Load rows from database.
      const metric_groups: Array<MetricGroup> = await getMetricGroups(
        !!master_list,
        group?.id,
        company?.id
      );
      // Load rows to memory.
      setMetricGroupRows(metric_groups);
    } catch (err: unknown) {
      const tracking_id: string = uuidv4();
      log(
        'error',
        new MetadataError(
          err instanceof Error
            ? err.message
            : 'Error: PanelMetricGroups failed on an unknown error while calling fetchRows.',
          {
            group: group,
            company: company
          },
          tracking_id
        )
      );
      setFeedbackData({
        message: `Unable to fetch metric groups. Tracking ID: ${tracking_id}`,
        state: true,
        type: 'error'
      });
      return;
    } finally {
      setGridLoading(false);
    }
  };

  React.useEffect(
    () => {
      try {
        (async () => {
          setMetricGroupRows([]);
          fetchRows();
        })().catch((error) => {
          throw new Error(error);
        });
      } catch (err: unknown) {
        const tracking_id: string = uuidv4();
        log(
          'error',
          new MetadataError(
            err instanceof Error
              ? err.message
              : 'Error: PanelMetricGroups failed on an unknown error while initialising.',
            null,
            tracking_id
          )
        );
        setFeedbackData({
          message: `An error occurred. Tracking ID: ${tracking_id}`,
          state: true,
          type: 'error'
        });
      }
      return;
    },
    master_list ? [] : [group, company]
  );

  const createRow = async (name: string, description: string): Promise<void> => {
    try {
      const metric_group_data: MetricGroupData = {
        deleted: null,
        name: name,
        description: description
      };
      const new_metric_group: DocumentReference = await createMetricGroup(
        !!master_list,
        metric_group_data
      );
      if (new_metric_group) {
        handleUpdateRows([
          {
            id: new_metric_group.id,
            ...metric_group_data
          },
          ...metricGroupRows
        ]);
      }
    } catch (err: unknown) {
      const tracking_id: string = uuidv4();
      log(
        'error',
        new MetadataError(
          err instanceof Error
            ? err.message
            : 'Error: PanelMetricGroups failed on an unknown error while calling createRow.',
          {
            name: name,
            description: description
          },
          tracking_id
        )
      );
      setFeedbackData({
        message: `Unable to create metric group. Tracking ID: ${tracking_id}`,
        state: true,
        type: 'error'
      });
    }
  };

  const updateRow = async (updated_row: MetricGroup, original_data: MetricGroup): Promise<void> => {
    try {
      await updateMetricGroup(
        !!master_list,
        updated_row,
        original_data,
        group?.id,
        company?.id
      ).then(() => {
        handleUpdateRows(
          metricGroupRows.map((row: MetricGroup) =>
            updated_row.id === row.id ? { ...updated_row } : row
          )
        );
      });
    } catch (err: unknown) {
      const tracking_id: string = uuidv4();
      log(
        'error',
        new MetadataError(
          err instanceof Error
            ? err.message
            : 'Error: PanelMetricGroups failed on an unknown error while calling updateRow.',
          {
            master_list: master_list,
            group: group,
            company: company,
            updated_row: updated_row,
            original_data: original_data
          },
          tracking_id
        )
      );
      setFeedbackData({
        message: `Unable to update metric group. Tracking ID: ${tracking_id}`,
        state: true,
        type: 'error'
      });
    }
  };

  const deleteRow = async (metric_group: MetricGroup): Promise<void> => {
    try {
      await deleteMetricGroupWithMetrics(
        !!master_list,
        metric_group.id,
        group?.id,
        company?.id
      ).then(() => {
        setMetricGroupRows(
          metricGroupRows.filter((row: MetricGroup) => {
            return row.id !== metric_group.id;
          })
        );
      });
    } catch (err: unknown) {
      const tracking_id: string = uuidv4();
      log(
        'error',
        new MetadataError(
          err instanceof Error
            ? err.message
            : 'Error: PanelMetricGroups failed on an unknown error while calling deleteRow.',
          {
            group: group,
            company: company,
            metric_group: metric_group
          },
          tracking_id
        )
      );
      setFeedbackData({
        message: `Unable to remove metric group. Tracking ID: ${tracking_id}`,
        state: true,
        type: 'error'
      });
    } finally {
      setOpenDialogDelete(false);
    }
  };

  return (
    <>
      {/* Delete Confirmation Modal */}
      <DeleteConfirmModal
        open={openDialogDelete}
        allow_delete={true}
        delete_warning="Any Metrics and Emission Factors related to this Metric Group will also be deleted"
        handleCloseDeleteModal={handleCloseDeleteModal}
        delete_label={actionRow && actionRow.name}
        handleDelete={actionRow && (() => deleteRow(actionRow))}
        allow_archive={false}
      />

      {/* Side Widget Panel */}
      <Drawer
        anchor={'right'}
        open={displayWidgetPanelRight}
        onClose={() => setDisplayWidgetPanelRight(false)}
        PaperProps={{ style: { width: '30%', padding: '1.5rem' } }}
      >
        {/* Create / Edit Widget */}
        {isCreateWidget ? (
          <>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                flexWrap: 'wrap'
              }}
            >
              <DataSaverOffOutlinedIcon sx={{ marginRight: '2rem' }} fontSize="large" />
              <Typography variant="h5" color="inherit" align="left">
                Add {metric_group_label.one}
              </Typography>
            </Box>
            {master_list && (
              <ConfigCreateWidget
                create_label={metric_group_label.one}
                create_icon={
                  <DataSaverOffOutlinedIcon sx={{ marginRight: '2rem' }} fontSize="large" />
                }
                create_function_inputs={create_inputs}
                handleClose={() => setDisplayWidgetPanelRight(false)}
                createFunction={(name: string, description: string) => createRow(name, description)}
                hide_configured_entities={true}
                hide_title={true}
              />
            )}
          </>
        ) : (
          <ConfigEditWidget
            edit_label="Metric Group"
            edit_icon={<DataSaverOffOutlinedIcon sx={{ marginRight: '2rem' }} fontSize="large" />}
            handleClose={() => setDisplayWidgetPanelRight(false)}
            edit_entity={actionRow}
            edit_function_inputs={edit_inputs}
            handleEditInput={(input_id: string, value: unknown) =>
              actionRow && setActionRow({ ...actionRow, [input_id]: value })
            }
            confirmEditFunction={(
              updated_metric_group: MetricGroup,
              original_metric_group: MetricGroup
            ) => updateRow(updated_metric_group, original_metric_group)}
          />
        )}
      </Drawer>

      {/* Interactive Data Table */}
      <DataGrid
        autoHeight
        rowHeight={75}
        initialState={{
          pagination: {
            paginationModel: { pageSize: 10, page: 0 }
          },
          sorting: {
            sortModel: [{ field: 'modified', sort: 'desc' }]
          }
        }}
        hideFooter={metricGroupRows.length > 1 ? false : true}
        columnHeaderHeight={metricGroupRows.length < 1 ? 0 : 56}
        pageSizeOptions={[10, 25, 50, 100]}
        loading={gridLoading}
        rows={metricGroupRows}
        columns={columns}
        disableRowSelectionOnClick
        slots={{
          toolbar: PanelMetricGroupsToolbar,
          noRowsOverlay: PanelMetricGroupsNoRows,
          loadingOverlay: PanelMetricGroupsLoading
        }}
        slotProps={{ toolbar: { handleCreate: handleCreateClick } }}
        sx={{ '&, [class^=MuiDataGrid]': { border: 'none' }, width: '100%' }}
      />
    </>
  );
};

export default PanelMetricGroups;
