import React from 'react';
import {
  Autocomplete,
  AutocompleteRenderInputParams,
  Box,
  Checkbox,
  InputAdornment,
  SvgIconProps,
  TextField
} from '@mui/material';
import { MetricGroup } from '@esg/esg-global-types';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { getMetricGroups, metric_group_label } from '../../../../lib/metric_capture/metric_group';

interface MetricGroupSelectProps {
  handleChangeMetricGroups?: (metric_groups: Array<MetricGroup> | MetricGroup | null) => void;
  class_name?: string;
  options?: Array<MetricGroup>;
  selected_options?: Array<MetricGroup> | MetricGroup | null;
  disabled?: boolean;
  input_label?: string;
  multi_select?: boolean;
  tag_limit?: number;
  group_id?: string;
  company_id?: string;
  master_list?: boolean;
  start_adornment?: React.ReactElement<SvgIconProps>;
  auto_select?: boolean;
  onClick?: () => void;
}

// Checkbox icons
const check_icon_blank = <CheckBoxOutlineBlankIcon fontSize="small" />;
const check_icon_filled = <CheckBoxIcon fontSize="small" />;

/**
 * Metric Group selection auto-complete component that handles a variety of built-in scenarios.
 * @param {Array<MetricGroup> | MetricGroup | null} props.handleChangeMetricGroup Callback function to notify the parent component when the selected options have changed.
 * @param {Array<MetricGroup>} props.options Custom list of options provided by the parent component. Defaults to none.
 * @param {Array<MetricGroup> | MetricGroup | null} props.selected_options Custom list of selected options provided by the parent component. Changes component from an uncontrolled to controlled.
 * @param {boolean} props.disabled Renders the component in a disabled state along with any other provided components. Defaults to enabled.
 * @param {string} props.input_label Sets a custom component input label. Defaults to the singular version of the main entity name.
 * @param {boolean} props.multi_select Allow multi option selection and keeps the select open while selecting. Defaults to a single select and closes after selecting an option.
 * @param {number} props.tag_limit Sets a limit on the amount of tags rendered in the input. Defaults to unlimited.
 * @param {string} props.group_id Query configured metric groups for the provided group. Works in conjunction with props.company_id.
 * @param {string} props.company_id Query configured metric groups for the provided company. Works in conjunction with props.group_id.
 * @param {boolean} props.master_list Forcefully query metric groups from the master list. Does not require group_id and company_id. Defaults to false.
 * @param {React.ReactElement<SvgIconProps>} props.start_adornment Set an input icon for the autocomplete.
 * @param {boolean} props.auto_select Pre-populate the autocomplete input when only one metric group is available.
 */
const MetricGroupSelect = (props: MetricGroupSelectProps) => {
  const [optionList, setOptions] = React.useState<Array<MetricGroup>>([]);
  const [selectedOptions, setSelectedOptions] = React.useState<
    Array<MetricGroup> | MetricGroup | null
  >(props.multi_select ? [] : null);

  // Define autocomplete options
  React.useEffect(() => {
    (async () => {
      const metric_groups: Array<MetricGroup> = props.options
        ? props.options
        : await getMetricGroups(props.master_list ?? false, props.group_id, props.company_id);
      if (metric_groups) {
        setOptions(metric_groups);
      }
      // When auto-select is true, automatically populate the autocomplete when only one metric group is present.
      if (props.auto_select && metric_groups.length === 1) {
        setSelectedOptions(metric_groups[0]);
        props.handleChangeMetricGroups && props.handleChangeMetricGroups(metric_groups[0]);
      }
    })();
  }, [props.group_id, props.company_id]);

  // Define selected options
  React.useEffect(() => {
    setSelectedOptions(
      props.multi_select
        ? Array.isArray(props.selected_options)
          ? props.selected_options
          : []
        : props.selected_options
          ? props.selected_options
          : null
    );
  }, [props.options]);

  return (
    <Box id="metric-group-select" onClick={props.onClick}>
      <Autocomplete
        fullWidth
        disabled={props.disabled}
        limitTags={props.tag_limit}
        options={optionList}
        value={selectedOptions}
        getOptionLabel={(option: MetricGroup) => {
          return option.name;
        }}
        disableCloseOnSelect={props.multi_select}
        isOptionEqualToValue={(option: MetricGroup, value: MetricGroup) => option.id === value.id}
        multiple={props.multi_select}
        noOptionsText={`No ${metric_group_label.many.toLowerCase()}`}
        renderOption={(render_props, option: MetricGroup, { selected }) => (
          <Box
            component="li"
            sx={{ display: 'flex', alignItems: 'center' }}
            {...render_props}
            key={option.id}
          >
            {props.multi_select && (
              <Checkbox
                icon={check_icon_blank}
                checkedIcon={check_icon_filled}
                style={{ marginRight: 8 }}
                checked={selected}
              />
            )}
            {option.name}
          </Box>
        )}
        renderInput={(params: AutocompleteRenderInputParams) => (
          <TextField
            {...params}
            label={props.input_label ?? metric_group_label.one}
            variant="standard"
            InputProps={{
              ...params.InputProps,
              startAdornment: props.start_adornment ? (
                <>
                  <InputAdornment position="start">{props.start_adornment}</InputAdornment>
                  {params.InputProps.startAdornment}
                </>
              ) : (
                <>{params.InputProps.startAdornment}</>
              )
            }}
          />
        )}
        onChange={(
          event: React.SyntheticEvent<Element, Event>,
          value: Array<MetricGroup> | MetricGroup | null
        ) => {
          setSelectedOptions(value);
          props.handleChangeMetricGroups && props.handleChangeMetricGroups(value);
        }}
      />
    </Box>
  );
};

export default MetricGroupSelect;
