import * as React from 'react';
import Box from '@mui/material/Box';
import { DataGrid, GridActionsCellItem, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { FileExport } from './ExportHub';
import DownloadIcon from '@mui/icons-material/Download';
import { Tooltip } from '@mui/material';
import { downloadFile } from '../../lib/metric_capture/file';
import { Company, File } from '@esg/esg-global-types';
import { ExportFileTableNoRows } from './ExportFileTableNoRows';
import { CompanyContext } from '../../context/CompanyContext';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import LoopIcon from '@mui/icons-material/Loop';

interface ExportFileGridPropTypes {
  file_export_rows: Array<FileExport>;
}

/**
 * A table widget for displaying all pending, failed and completed generated raw exports.
 * @param {Array<FileExport>} file_export_rows Object of all file export objects.
 * @returns {JSX.Element}
 */

export const ExportFileTable = ({ file_export_rows }: ExportFileGridPropTypes) => {
  const [gridLoading, setGridLoading] = React.useState<boolean>(false);
  const company: Company | null = React.useContext(CompanyContext);

  const columns: Array<GridColDef> = [
    {
      field: 'generated',
      headerName: 'Generated',
      flex: 1
    },
    {
      field: 'status',
      headerName: 'Status',
      align: 'center',
      headerAlign: 'center',
      sortable: false,
      filterable: false,
      hideable: false,
      renderCell: (params: GridRenderCellParams) => {
        return (
          (params.row.status === 'Pending' && (
            <Tooltip title="Export Generation In Progress" key={1}>
              <LoopIcon color="primary" />
            </Tooltip>
          )) ||
          (params.row.status === 'Failed' && (
            <Tooltip title="Export Generation Failed" key={2}>
              <WarningAmberIcon color="error" />
            </Tooltip>
          )) ||
          (params.row.status === 'Completed' && (
            <Tooltip title="Export Generation Completed" key={3}>
              <CheckCircleOutlineIcon color="success" />
            </Tooltip>
          ))
        );
      },
      flex: 1
    },
    {
      field: 'actions',
      type: 'actions',
      align: 'center',
      flex: 1,
      getActions: ({ row }) => {
        if (row.status === 'Completed') {
          return [
            <Tooltip title="Download File" key={4}>
              <GridActionsCellItem
                icon={<DownloadIcon sx={{ fontSize: '1.5rem' }} />}
                id="download-export"
                label="Download"
                onClick={async () => {
                  setGridLoading(true);
                  const file: File = {
                    id: row.id,
                    deleted: null,
                    name: `${company?.id ?? 'company'}-${row.export_type}-${row.generated}`,
                    type: row.export_type,
                    uploaded: row.created,
                    file_path: row.file_url
                  };
                  await downloadFile(file);
                  setGridLoading(false);
                }}
              />
            </Tooltip>
          ];
        } else {
          return [];
        }
      }
    }
  ];

  return (
    <Box>
      <DataGrid
        loading={gridLoading}
        autoHeight
        rows={file_export_rows}
        columns={columns}
        disableColumnMenu
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 8
            }
          },
          sorting: {
            sortModel: [{ field: 'generated', sort: 'desc' }]
          }
        }}
        pageSizeOptions={[8]}
        disableRowSelectionOnClick
        slots={{
          noRowsOverlay: ExportFileTableNoRows
        }}
        sx={{
          '&, [class^=MuiDataGrid]': { border: 'none' }
        }}
      />
    </Box>
  );
};
