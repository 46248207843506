import React from 'react';
import {
  Autocomplete,
  AutocompleteRenderGroupParams,
  AutocompleteRenderInputParams,
  Box,
  Checkbox,
  TextField
} from '@mui/material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { resolveObjectFromString } from '../../../../util/validation';
import { Subdivision } from '@esg/esg-global-types';
import { getSubdivisions, subdivision_label } from '../../../../lib/metric_capture/subdivision';

interface SubdivisionSelectProps {
  handleChangeSubdivisions?: (subdivisions: Array<Subdivision> | Subdivision | null) => void;
  options?: Array<Subdivision>;
  selected_options?: Array<Subdivision> | Subdivision | null;
  disabled?: boolean;
  input_label?: string;
  group_by?: 'division.name';
  multi_select?: boolean;
  tag_limit?: number;
  group_id?: string;
  company_id?: string;
  loading?: boolean;
  variant?: 'standard' | 'outlined';
  auto_select?: boolean;
}

// Checkbox icons
const check_icon_blank = <CheckBoxOutlineBlankIcon fontSize="small" />;
const check_icon_filled = <CheckBoxIcon fontSize="small" />;

/**
 * Subdivision selection auto-complete component that handles a variety of built-in scenarios.
 * @param {Array<Subdivision> | Subdivision | null} props.handleChangeSubdivisions Callback function to notify the parent component when the selected options have changed.
 * @param {Array<Subdivision>} props.options Custom list of options provided by the parent component. Defaults to none.
 * @param {Array<Subdivision> | Subdivision | null} props.selected_options Custom list of selected options provided by the parent component. Changes component from an uncontrolled to controlled.
 * @param {boolean} props.disabled Renders the component in a disabled state along with any other provided components. Defaults to enabled.
 * @param {string} props.input_label Sets a custom component input label. Defaults to the singular version of the main entity name.
 * @param {string} props.group_by Group the options by any one of a variety of grouping options. Defaults to ungrouped.
 * @param {boolean} props.multi_select Allow multi option selection and keeps the select open while selecting. Defaults to a single select and closes after selecting an option.
 * @param {number} props.tag_limit Sets a limit on the amount of tags rendered in the input. Defaults to unlimited.
 * @param {string} props.group_id Query configured subdivisions for the provided group. Works in conjunction with props.company_id.
 * @param {string} props.company_id Query configured subdivisions for the provided company. Works in conjunction with props.group_id.
 * @param {boolean} props.loading A flag to set the autocomplete into a loading state for things such as fetching entries from database.
 * @param {string} props.variant An optional styling parameter for the textbox input of the autocomplete
 * @param {boolean} props.auto_select Pre-populate the autocomplete input when only one subdivision is available.
 */
const SubdivisionSelect = (props: SubdivisionSelectProps) => {
  const [optionList, setOptions] = React.useState<Array<Subdivision>>([]);
  const [selectedOptions, setSelectedOptions] = React.useState<
    Array<Subdivision> | Subdivision | null
  >(props.multi_select ? [] : null);

  // Define autocomplete options
  React.useEffect(() => {
    (async () => {
      const options: Array<Subdivision> = props.options
        ? // Subdivisions supplied by the parent component.
          props.options
        : props.group_id && props.company_id
          ? await getSubdivisions(props.group_id, props.company_id)
          : [];
      if (options) setOptions(options);
      // When auto-select is true, automatically populate the autocomplete when only one subdivision is present.
      if (props.auto_select && options.length === 1 && options[0] !== selectedOptions) {
        setSelectedOptions(options[0]);
        props.handleChangeSubdivisions && props.handleChangeSubdivisions(options[0]);
      }
    })();
  }, [props.group_id, props.company_id, props.options]);

  // Define selected options
  React.useEffect(() => {
    setSelectedOptions(
      props.multi_select
        ? Array.isArray(props.selected_options)
          ? props.selected_options
          : []
        : props.selected_options
          ? props.selected_options
          : null
    );
  }, [props.options, props.selected_options]);

  // Group selection
  const selected_options_array: Array<Subdivision> =
    selectedOptions && !Array.isArray(selectedOptions)
      ? [selectedOptions]
      : selectedOptions && Array.isArray(selectedOptions)
        ? selectedOptions
        : [];

  const checkGroup = (group_key: string): boolean => {
    const group_length: number = optionList.filter(
      (option: Subdivision) =>
        (props.group_by ? resolveObjectFromString(props.group_by, option) : '') === group_key
    ).length;
    const selected_group_length: number = selected_options_array.filter(
      (option: Subdivision) =>
        (props.group_by ? resolveObjectFromString(props.group_by, option) : '') === group_key
    ).length;
    return group_length === selected_group_length;
  };

  const changeGroup = (group_key: string): void => {
    const grouped_options: Array<Subdivision> = optionList.filter(
      (option: Subdivision) =>
        (props.group_by ? resolveObjectFromString(props.group_by, option) : '') === group_key
    );
    const selected_group_options: Array<Subdivision> = selected_options_array.filter(
      (option: Subdivision) =>
        (props.group_by ? resolveObjectFromString(props.group_by, option) : '') === group_key
    );
    if (selected_group_options.length > 0) {
      const selected_without_group: Array<Subdivision> = (
        Array.isArray(selectedOptions) ? selectedOptions : []
      ).filter(
        (option: Subdivision) =>
          (props.group_by ? resolveObjectFromString(props.group_by, option) : '') !== group_key
      );
      setSelectedOptions(selected_without_group);
      props.handleChangeSubdivisions && props.handleChangeSubdivisions(selected_without_group);
    } else {
      const selected_with_group: Array<Subdivision> = [
        ...(Array.isArray(selectedOptions) ? selectedOptions : []),
        ...grouped_options
      ];
      setSelectedOptions(selected_with_group);
      props.handleChangeSubdivisions && props.handleChangeSubdivisions(selected_with_group);
    }
  };

  return (
    <Box id="subdivision-select">
      <Autocomplete
        fullWidth
        disabled={props.disabled}
        loading={props.loading}
        limitTags={props.tag_limit}
        options={optionList}
        groupBy={
          props.group_by
            ? (option: Subdivision) =>
                props.group_by ? resolveObjectFromString(props.group_by, option) : ''
            : undefined
        }
        value={selectedOptions}
        getOptionLabel={(option: Subdivision) => {
          return option.name;
        }}
        disableCloseOnSelect={props.multi_select}
        isOptionEqualToValue={(option: Subdivision, value: Subdivision) => option.id === value.id}
        multiple={props.multi_select}
        noOptionsText={`No ${subdivision_label.many.toLowerCase()}`}
        renderOption={(render_props, option: Subdivision, { selected }) => (
          <Box
            component="li"
            sx={{ display: 'flex', alignItems: 'center' }}
            {...render_props}
            key={option.id}
          >
            {props.multi_select && (
              <Checkbox
                icon={check_icon_blank}
                checkedIcon={check_icon_filled}
                style={{ marginRight: 8 }}
                checked={selected}
              />
            )}
            {option.name}
          </Box>
        )}
        renderGroup={
          props.multi_select
            ? (params: AutocompleteRenderGroupParams) => (
                <Box component="div" key={params.key}>
                  <Box
                    component="li"
                    style={{
                      fontSize: '0.85rem',
                      color: '#616161',
                      fontWeight: 500
                    }}
                  >
                    <Checkbox
                      icon={check_icon_blank}
                      checkedIcon={check_icon_filled}
                      checked={checkGroup(params.group)}
                      onChange={() => {
                        changeGroup(params.group);
                      }}
                    />
                    {params.group}
                  </Box>
                  <span>{params.children}</span>
                </Box>
              )
            : undefined
        }
        renderInput={(params: AutocompleteRenderInputParams) => (
          <TextField
            {...params}
            label={props.input_label ?? subdivision_label.one}
            variant={props.variant ?? 'standard'}
          />
        )}
        onChange={(
          event: React.SyntheticEvent<Element, Event>,
          value: Array<Subdivision> | Subdivision | null
        ) => {
          setSelectedOptions(value);
          props.handleChangeSubdivisions && props.handleChangeSubdivisions(value);
        }}
      />
    </Box>
  );
};

export default SubdivisionSelect;
