import * as React from 'react';
import Box from '@mui/material/Box';
import { Grid, TextField, Tooltip, Typography } from '@mui/material';
import { MuiColorInput } from 'mui-color-input';
import FileUpload from '../../shared/input/file/FileUpload';
import BusinessIcon from '@mui/icons-material/Business';
import ColorLensIcon from '@mui/icons-material/ColorLens';
import { Group, Sector, Standard } from '@esg/esg-global-types';
import { createCompany } from '../../../lib/app/company';
import { LoadingButton } from '@mui/lab';
import { FeedbackSnackbarContext } from '../../../context/FeedbackSnackbarContext';
import { CompanyExtended } from '../../../lib/app/company';
import { hasBannedCharacters } from '../../../lib/validation/text_validation';
import { MetadataError } from '@ep/error-handling';
import { uuidv4 } from '@firebase/util';
import { log } from '../../../util/log';
import { AxiosResponse } from 'axios';
import SectorSelect from '../../shared/input/select/SectorSelect';
import StandardSelect from '../../shared/input/select/StandardSelect';

/**
 * Widget for creating new company entities for a group in the app
 * @param {Group} group Group to create new Company under
 * @param {void} handleCompanyToParent Use the new company data in a callback function
 * @returns {JSX.Element}
 */
const AddCompanyWidget = ({
  group,
  handleCompanyToParent
}: {
  group: Group;
  handleCompanyToParent: (new_company: CompanyExtended) => void;
}) => {
  const { setFeedbackData } = React.useContext(FeedbackSnackbarContext);
  const [companyName, setCompanyName] = React.useState('');
  const [primaryColor, setPrimaryColor] = React.useState('');
  const [secondaryColor, setSecondaryColor] = React.useState('');
  const [logoFilePath, setLogoFilePath] = React.useState('');
  const [standardList, setStandardList] = React.useState(Array<Standard>);
  const [sectorList, setSectorList] = React.useState(Array<Sector>);
  const [addLoading, setAddLoading] = React.useState<boolean>(false);

  const invalidCompanyName = companyName ? hasBannedCharacters(companyName) : false;

  const handlePrimaryColorChange = (hex_color: React.SetStateAction<string>) => {
    setPrimaryColor(hex_color);
  };
  const handleSecondaryColorChange = (hex_color: React.SetStateAction<string>) => {
    setSecondaryColor(hex_color);
  };
  const handleCreateCompany = async () => {
    try {
      if (companyName !== '') {
        setAddLoading(true);
        const new_company = await createCompany(
          group.id,
          companyName,
          logoFilePath,
          primaryColor,
          secondaryColor,
          sectorList,
          standardList
        ).catch((error) => {
          throw new Error(error);
        });
        if (new_company) {
          handleCompanyToParent({
            id: new_company.id,
            name: companyName,
            active: true,
            deleted: null,
            configuration: {
              logo: logoFilePath,
              primary_color: primaryColor,
              secondary_color: secondaryColor
            },
            group: {
              id: group.id,
              name: group.name,
              active: group.active
            }
          });
        }
        setAddLoading(false);
        setCompanyName('');
        setPrimaryColor('');
        setSecondaryColor('');
        setLogoFilePath('');
      }
    } catch (err: unknown) {
      const tracking_id: string = uuidv4();
      log(
        'error',
        new MetadataError(
          err instanceof Error
            ? err.message
            : 'Error: AddCompanyWidget failed on an unknown error while calling handleCreateCompany.',
          {
            companyName: companyName
          },
          tracking_id
        )
      );
      setFeedbackData({
        message: `Error creating company. Tracking ID: ${tracking_id}`,
        state: true,
        type: 'error'
      });
    } finally {
      setAddLoading(false);
    }
  };

  const logoFilePathFromChild = (uploaded_file: AxiosResponse) => {
    setLogoFilePath(uploaded_file.data.url);
  };

  const sectorListFromChild = (sectors: React.SetStateAction<Array<Sector>>) => {
    setSectorList(sectors);
  };

  const standardListFromChild = (standards: React.SetStateAction<Array<Standard>>) => {
    setStandardList(standards);
  };

  return (
    <Box sx={{ p: 2 }}>
      <Grid container spacing={4}>
        <Grid item xs={10}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              flexWrap: 'wrap'
            }}
          >
            <BusinessIcon sx={{ marginRight: '2rem' }} fontSize="large" />
            <Typography variant="h5" color="inherit" align="left">
              Add Company
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <TextField
            error={invalidCompanyName}
            fullWidth
            id="company-name-input"
            label="Name"
            variant="standard"
            value={companyName}
            onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
              setCompanyName(event.target.value);
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <SectorSelect
            master_list
            multi_select
            handleChangeSectors={(sectors: Sector | Array<Sector> | null) => {
              if (Array.isArray(sectors)) {
                sectorListFromChild(sectors);
              }
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <StandardSelect
            master_list
            multi_select
            handleChangeStandards={(standards: Standard | Array<Standard> | null) => {
              if (Array.isArray(standards)) {
                standardListFromChild(standards);
              }
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              flexWrap: 'wrap',
              color: 'grey'
            }}
          >
            <ColorLensIcon sx={{ marginRight: '1rem' }} fontSize="medium" />
            <Typography variant="subtitle1" color="inherit" align="left">
              Theme
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Tooltip title="Upload company logo">
            <Box>
              <FileUpload
                uploadedFileToParent={logoFilePathFromChild}
                file_type={'configuration'}
                company_name={companyName}
                group_id={group.id}
              />
            </Box>
          </Tooltip>
        </Grid>

        <Grid item xs={6}>
          <Typography variant="subtitle2" color="grey" align="left" paddingBottom={'10px'}>
            Primary color
          </Typography>
          <Tooltip title="Choose theme's primary color">
            <MuiColorInput
              value={primaryColor}
              format="hex"
              fullWidth
              onChange={handlePrimaryColorChange}
            />
          </Tooltip>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="subtitle2" color="grey" align="left" paddingBottom={'10px'}>
            Secondary color
          </Typography>
          <Tooltip title="Choose theme's secondary color">
            <MuiColorInput
              value={secondaryColor}
              format="hex"
              fullWidth
              onChange={handleSecondaryColorChange}
            />
          </Tooltip>
        </Grid>

        <Grid item xs={12} alignItems={'right'}>
          <Box m={1} display="flex" justifyContent="flex-end" alignItems="flex-end">
            <LoadingButton
              loading={addLoading}
              disabled={!companyName || invalidCompanyName}
              variant="contained"
              color="primary"
              onClick={handleCreateCompany}
            >
              Create
            </LoadingButton>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AddCompanyWidget;
