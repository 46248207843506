import {
  collection,
  getDocs,
  query,
  CollectionReference,
  Query,
  QuerySnapshot,
  where,
  addDoc,
  doc,
  updateDoc,
  DocumentReference,
  deleteField
} from 'firebase/firestore';
import { refCompanyDoc } from '../app/company';
import {
  Division,
  EntityLabel,
  MetricRecord,
  ReportingPeriod,
  Site,
  Subdivision
} from '@esg/esg-global-types';
import { db } from '../google/firebase';
import { createAuditLog } from '../app/audit';
import { BatchWrite, processBatchWrites } from '../app/db_util';
import { getReportingPeriods } from './reporting_period';
import { getMetricRecords } from './metric_record';
import { FirestoreQueryParam } from '../../@types/shared';

// Singular and plural label for model entity.
export const subdivision_label: EntityLabel = {
  one: 'Subdivision',
  many: 'Subdivisions'
};

/**
 * Function to query all subdivision documents for current portal company
 * @param {Group} group Group object of current portal company
 * @param {string} company_id id of current portal company to reference firestore documents
 * @returns {Array<Subdivision>}
 */
export const getSubdivisions = async (group_id: string, company_id: string) => {
  const subdivisions_collection: CollectionReference = collection(
    refCompanyDoc(group_id, company_id),
    'subdivisions'
  );
  const subdivisions_query: Query = query(subdivisions_collection, where('deleted', '==', null));
  try {
    const subdivision_docs: QuerySnapshot = await getDocs(subdivisions_query);
    const subdivisions: Array<Subdivision> = subdivision_docs.docs.map((subdivision) => {
      return {
        id: subdivision.id,
        deleted: subdivision.data().deleted,
        name: subdivision.data().name,
        division: subdivision.data().division,
        archived: subdivision.data().archived,
        reference: subdivision.ref
      };
    });
    return subdivisions;
  } catch (err) {
    const error = `Error while getting subdivisions from Firebase: ${JSON.stringify({
      message: err instanceof Error ? err.message : '',
      stacktrace: err instanceof Error ? err.stack : '',
      variables: {
        subdivisions_collection: subdivisions_collection,
        subdivisions_query: subdivisions_query
      }
    })}`;
    throw new Error(`Error: getSubdivisions: ${JSON.stringify(error)}.`);
  }
};

/**
 * Function to create subdivision with relative data
 * @param {string} group_id ID of Group which Subdivision is being created for
 * @param {string} company_id ID of Company which Subdivision is being created for
 * @param {string} subdivision_name Name of new Subdivision
 * @param {Division} group_division Parent Division of Subdivision
 * @returns {DocumentReference}
 */
export const createSubdivision = async (
  group_id: string,
  company_id: string,
  subdivision_name: string,
  division: Division
) => {
  try {
    if (group_id && company_id && subdivision_name && division) {
      const company_doc = refCompanyDoc(group_id, company_id);
      const subdivisions_collection: CollectionReference = collection(company_doc, 'subdivisions');
      const new_subdivision = await addDoc(subdivisions_collection, {
        deleted: null,
        name: subdivision_name,
        division: doc(
          collection(db, `/groups/${group_id}/companies/${company_id}/divisions`),
          division.id
        )
      });
      await createAuditLog(group_id, 'create', '', subdivision_name, new_subdivision);
      return new_subdivision;
    }
  } catch (err) {
    const error = `Error while creating Subdivision in Firebase: ${JSON.stringify({
      message: err instanceof Error ? err.message : '',
      stacktrace: err instanceof Error ? err.stack : ''
    })}`;
    throw new Error(`Error: createSubdivision: ${JSON.stringify(error)}.`);
  }
};

/**
 * Function to update subdivision with relative data
 * @param {string} group_id ID of Group to update subdivision for
 * @param {string} company_id ID of Company to update subdivision for
 * @param {Subdivision} updated_subdivision New Subdivision data to push into document
 * @returns {void}
 */
export const updateSubdivision = async (
  group_id: string,
  company_id: string,
  updated_subdivision: Subdivision,
  original_data: Subdivision
) => {
  try {
    if (group_id && company_id) {
      const company_doc = refCompanyDoc(group_id, company_id);
      const subdivision_doc = doc(collection(company_doc, 'subdivisions'), updated_subdivision.id);
      await updateDoc(subdivision_doc, {
        deleted: updated_subdivision.deleted,
        name: updated_subdivision.name
      });
      await createAuditLog(
        group_id,
        'update',
        original_data.name,
        updated_subdivision.name,
        subdivision_doc
      );
    }
  } catch (err) {
    const error = `Error while udpating Subdivision in Firebase: ${JSON.stringify({
      message: err instanceof Error ? err.message : '',
      stacktrace: err instanceof Error ? err.stack : ''
    })}`;
    throw new Error(`Error: updateSubdivision: ${JSON.stringify(error)}.`);
  }
};

/**
 * Function to delete subdivision
 * @param {string} group_id ID of Group to delete subdivision for
 * @param {string} company_id ID of Company to delete subdivision for
 * @param {string} subdivision_id ID of subdivision to delete
 * @param {Array<Site>} delete_sites Sites to cascade delete with subdivision
 * @returns {void}
 */
export const deleteSubdivision = async (
  group_id: string,
  company_id: string,
  subdivision_id: string,
  subdivision_name: string,
  delete_sites?: Array<Site>
) => {
  try {
    if (group_id && company_id && subdivision_id) {
      const cascade_delete_writes: Array<BatchWrite> = [];
      const subdivision_doc = doc(
        collection(refCompanyDoc(group_id, company_id), `subdivisions`),
        subdivision_id
      );
      await updateDoc(subdivision_doc, {
        deleted: new Date()
      });
      if (delete_sites) {
        delete_sites.forEach((site: Site) => {
          const site_ref: DocumentReference = doc(
            db,
            `groups/${group_id}/companies/${company_id}/sites/${site.id}`
          );
          cascade_delete_writes.push({ reference: site_ref, operation: 'delete', data: {} });
        });
      }
      await processBatchWrites(cascade_delete_writes);
      await createAuditLog(group_id, 'delete', subdivision_name, '', subdivision_doc);
    }
    return;
  } catch (err) {
    const error = `Error while deleting Subdivision in Firebase: ${JSON.stringify({
      message: err instanceof Error ? err.message : '',
      stacktrace: err instanceof Error ? err.stack : ''
    })}`;
    throw new Error(`Error: deleteSubdivision: ${JSON.stringify(error)}.`);
  }
};

/**
 * Function to archive subdivision
 * @param {string} group_id ID of Group to archive subdivision for
 * @param {string} company_id ID of Company to archive subdivision for
 * @param {string} subdivision_id ID of subdivision to archive
 * @param {Array<Site>} archive_sites Sites to cascade archive with subdivision
 * @returns {void}
 */
export const archiveSubdivision = async (
  group_id: string,
  company_id: string,
  subdivision_id: string,
  archive_sites?: Array<Site>
) => {
  try {
    if (group_id && company_id && subdivision_id) {
      const cascade_archive_writes: Array<BatchWrite> = [];
      const subdivision_doc = doc(
        collection(refCompanyDoc(group_id, company_id), `subdivisions`),
        subdivision_id
      );
      await updateDoc(subdivision_doc, {
        archived: new Date()
      });
      if (archive_sites) {
        archive_sites.forEach((site: Site) => {
          const site_ref: DocumentReference = doc(
            db,
            `groups/${group_id}/companies/${company_id}/sites/${site.id}`
          );
          cascade_archive_writes.push({
            reference: site_ref,
            operation: 'update',
            data: { archived: new Date() }
          });
        });
      }
      await processBatchWrites(cascade_archive_writes);
      await createAuditLog(group_id, 'archive', '', '', subdivision_doc);
    }
    return;
  } catch (err) {
    const error = `Error while archiving Subdivision in Firebase: ${JSON.stringify({
      message: err instanceof Error ? err.message : '',
      stacktrace: err instanceof Error ? err.stack : ''
    })}`;
    throw new Error(`Error: archiveSubdivision: ${JSON.stringify(error)}.`);
  }
};

/**
 * Function to unarchive subdivision
 * @param {string} group_id ID of Group to unarchive subdivision for
 * @param {string} company_id ID of Company to unarchive subdivision for
 * @param {string} subdivision_id ID of subdivision to unarchive
 * @param {Array<Site>} archive_sites Sites to cascade unarchive with subdivision
 * @returns {void}
 */
export const unarchiveSubdivision = async (
  group_id: string,
  company_id: string,
  subdivision_id: string,
  unarchive_sites?: Array<Site>
) => {
  try {
    if (group_id && company_id && subdivision_id) {
      const cascade_unarchive_writes: Array<BatchWrite> = [];
      const subdivision_doc = doc(
        collection(refCompanyDoc(group_id, company_id), `subdivisions`),
        subdivision_id
      );
      await updateDoc(subdivision_doc, {
        archived: deleteField()
      });
      if (unarchive_sites) {
        unarchive_sites.forEach((site: Site) => {
          const site_ref: DocumentReference = doc(
            db,
            `groups/${group_id}/companies/${company_id}/sites/${site.id}`
          );
          cascade_unarchive_writes.push({
            reference: site_ref,
            operation: 'update',
            data: { archived: deleteField() }
          });
        });
      }
      await processBatchWrites(cascade_unarchive_writes);
      await createAuditLog(group_id, 'archive', '', 'null', subdivision_doc);
    }
    return;
  } catch (err) {
    const error = `Error while unarchiving Subdivision in Firebase: ${JSON.stringify({
      message: err instanceof Error ? err.message : '',
      stacktrace: err instanceof Error ? err.stack : ''
    })}`;
    throw new Error(`Error: unarchiveSubdivision: ${JSON.stringify(error)}.`);
  }
};

/**
 * Function to check if a Subdivision can be safely deleted
 * @param {string} group_id ID of Group to check Subdivision for
 * @param {string} company_id ID of Company to check Subdivision for
 * @param {string} subdivision_id ID of subdivision to check
 * @returns {boolean}
 */
export const allowDeleteSubdivision = async (
  group_id: string,
  company_id: string,
  subdivision_id: string
) => {
  try {
    const reporting_periods = await getReportingPeriods(group_id, company_id, true);
    if (reporting_periods.length === 0) return true;
    const reporting_period_ids = reporting_periods.map(
      (reporting_period: ReportingPeriod) => reporting_period.id
    );
    const query_params: Array<FirestoreQueryParam> = [
      {
        field_name: 'subdivision',
        operator: '==',
        value: doc(collection(refCompanyDoc(group_id, company_id), 'subdivisions'), subdivision_id)
      }
    ];
    const metric_records = await getMetricRecords(group_id, company_id, query_params);
    const allow_delete = !metric_records.some((metric_record: MetricRecord) => {
      if (reporting_period_ids.includes(metric_record.reporting_period?.id ?? '')) {
        return true;
      }
    });
    return allow_delete;
  } catch (err) {
    const error = `Error while checking Subdivision for Deletion: ${JSON.stringify({
      message: err instanceof Error ? err.message : '',
      stacktrace: err instanceof Error ? err.stack : ''
    })}`;
    throw new Error(`Error: allowDeleteSubdivision: ${JSON.stringify(error)}.`);
  }
};
