import {
  collection,
  QuerySnapshot,
  doc,
  DocumentReference,
  updateDoc,
  QueryDocumentSnapshot,
  DocumentData,
  runTransaction,
  Transaction,
  DocumentSnapshot
} from 'firebase/firestore';
import { db } from '../google/firebase';
import { refCompanyDoc } from './company';
import { Standard, EntityLabel, MetricRecord, EmissionFactor } from '@esg/esg-global-types';
import { BatchWrite, processBatchWrites, readFirestoreDocs, updateFirestoreDoc } from './db_util';
import { createAuditLog } from './audit';
import { auth } from '../google/firebase';
import { generateAuditLogData, generateAuditLogDoc } from './audit';
import { MetadataError } from '@ep/error-handling';
import { uuidv4 } from '@firebase/util';
import { FirestoreQueryParam } from '../../@types/shared';
import { validateMasterListComparison, validateMasterListParams } from '../../util/validation';
import { getMetricRecords } from '../metric_capture/metric_record';
import { MetricExtended, getMetrics, refMetric } from '../metric_capture/metric';
import { getEmissionFactorsForMetrics } from './emission_factor';

export type StandardData = Omit<Standard, 'id' | 'reference'>;

export interface CachedStandard {
  [key: string]: Standard;
}

// Singular and plural label for model entity.
export const standard_label: EntityLabel = {
  one: 'Standard',
  many: 'Standards'
};

/**
 * Create Firestore database reference for either configured Standard or Master List Standard.
 * @param {boolean} master_list Reference standard in master list.
 * @param {string} id ID of object to reference.
 * @param {Group} group_id ID of configured group.
 * @param {string} company_id ID of configured company.
 * @returns {DocumentReference}
 */
export const refStandard = (
  master_list: boolean,
  id: string,
  group_id?: string,
  company_id?: string
): DocumentReference =>
  doc(
    collection(
      db,
      !master_list && group_id && company_id
        ? `groups/${group_id}/companies/${company_id}/standards`
        : 'standard_master_list'
    ),
    id
  );

/**
 * Create a collection path string for the master list standards or configured standards.
 * @param {boolean} master_list Reference the master list collection.
 * @param {string} group_id When not referencing a master list collection, a group id for the configured group is required.
 * @param {string} company_id When not referencing a master list collection, a company id for the configured company is required.
 * @returns {string} Path to a standards collection.
 */
export const createStandardCollectionPath = (
  master_list: boolean,
  group_id?: string,
  company_id?: string
): string => {
  try {
    validateMasterListParams(master_list, group_id, company_id);
    return master_list
      ? `/standard_master_list`
      : `groups/${group_id}/companies/${company_id}/standards`;
  } catch (err: unknown) {
    const tracking_id: string = uuidv4();
    throw new MetadataError(
      err instanceof Error
        ? err.message
        : 'Error: getStandardCollectionPath failed on an unknown error.',
      {
        master_list: master_list,
        group_id: group_id,
        company_id: company_id
      },
      tracking_id
    );
  }
};

/**
 * Query standard documents for specified group and company.
 * @param {Group} group_id ID of configured group.
 * @param {string} company_id ID of configured company.
 * @param {boolean} is_quantitative retrieve only quantitative or only qualitative documents.
 * @returns {Promise<Array<Standard>>}
 */
export const getStandards = async (
  group_id: string,
  company_id: string,
  is_quantitative?: boolean,
  references?: Array<DocumentReference>
): Promise<Array<Standard>> => {
  const collection_path = `groups/${group_id}/companies/${company_id}/standards`;
  const query_params: Array<FirestoreQueryParam> = [
    { field_name: 'deleted', operator: '==', value: null }
  ];
  if (is_quantitative !== undefined)
    query_params.push({ field_name: 'is_quantitative', operator: '==', value: is_quantitative });
  if (references !== undefined && references.length > 0)
    query_params.push({ field_name: '__name__', operator: 'in', value: references });
  try {
    const standards_snapshot: QuerySnapshot = await readFirestoreDocs(
      collection_path,
      query_params
    );
    const standards: Array<Standard> = standards_snapshot.docs.map(
      (standard: QueryDocumentSnapshot) => {
        const standard_data: DocumentData = standard.data();
        return {
          id: standard.id,
          deleted: standard_data.deleted,
          name: standard_data.name,
          created: standard_data.created.toDate(),
          version: standard_data.version,
          category: standard_data.category,
          sector: standard_data.sector,
          is_quantitative: standard_data.is_quantitative,
          require_emission_factor: standard_data.require_emission_factor,
          require_site_level: standard_data.require_site_level,
          master_list_standard: standard_data.master_list_standard,
          reference: standard.ref
        };
      }
    );
    return standards;
  } catch (err: unknown) {
    const tracking_id: string = uuidv4();
    throw new MetadataError(
      err instanceof Error
        ? err.message
        : 'Error: lib/app/standard.ts failed on an unknown error while calling getStandards.',
      {
        collection_path: collection_path,
        group_id: group_id,
        company_id: company_id
      },
      tracking_id
    );
  }
};

/**
 * Query all unconfigured standard documents for current portal company.
 * @param {Group} group_id id of current portal company.
 * @param {string} company_id id of current portal company.
 * @param {boolean} is_quantitative retrieve only quantitative or only qualitative documents.
 * @returns {Array<Standard>}
 */
export const getUnconfiguredStandards = async (
  group_id: string,
  company_id: string,
  is_quantitative?: boolean
) => {
  try {
    const standard_master_list: Array<Standard> = await getStandardsMasterList(is_quantitative);
    const standards: Array<Standard> = await getStandards(group_id, company_id, is_quantitative);
    validateMasterListComparison(standard_master_list, standards);
    return standard_master_list.filter((standard_master) =>
      standards.every(
        (standard) =>
          !(standard.master_list_standard
            ? standard.master_list_standard.id.includes(standard_master.id)
            : '')
      )
    );
  } catch (err: unknown) {
    const tracking_id: string = uuidv4();
    throw new MetadataError(
      err instanceof Error
        ? err.message
        : 'Error: lib/google/standard.ts failed on an unknown error while calling getUnconfiguredStandards.',
      {
        group_id: group_id,
        company_id: company_id
      },
      tracking_id
    );
  }
};

/**
 * Query all standard documents from master list
 * @param {boolean} is_quantitative Query only quantitative or quantitative Standards from master list.
 * @returns {Promise<Array<Standard>>}
 */
export const getStandardsMasterList = async (
  is_quantitative?: boolean
): Promise<Array<Standard>> => {
  const collection_path = `standard_master_list`;
  const query_params: Array<FirestoreQueryParam> = [
    { field_name: 'deleted', operator: '==', value: null }
  ];
  if (is_quantitative !== undefined)
    query_params.push({ field_name: 'is_quantitative', operator: '==', value: is_quantitative });
  try {
    const standards_snapshot: QuerySnapshot = await readFirestoreDocs(
      collection_path,
      query_params
    );
    const standards: Array<Standard> = standards_snapshot.docs.map(
      (standard: QueryDocumentSnapshot) => {
        const standard_data: DocumentData = standard.data();
        return {
          id: standard.id,
          created: standard_data.created.toDate(),
          deleted: standard_data.deleted,
          version: standard_data.version,
          name: standard_data.name,
          category: standard_data.category,
          sector: standard_data.sector,
          is_quantitative: standard_data.is_quantitative,
          require_emission_factor: standard_data.require_emission_factor,
          require_site_level: standard_data.require_site_level,
          reference: standard.ref
        };
      }
    );
    return standards;
  } catch (err: unknown) {
    const tracking_id: string = uuidv4();
    throw new MetadataError(
      err instanceof Error
        ? err.message
        : 'Error: lib/app/standard.ts failed on an unknown error while calling getStandardsMasterList.',
      {
        collection_path: collection_path,
        is_quantitative: is_quantitative
      },
      tracking_id
    );
  }
};

/**
 * Create Standard data in firestore on master list or company level
 * @param {boolean} master_list Flag to create new Standard within master list or company level collection
 * @param {Array<Standard | StandardData>} standards Standard objects to create
 * @param {string | undefined} group_id Optional ID of Group to create standards for
 * @param {string | undefined} company_id Optional ID of Company to create standards for
 * @returns {Promise<Array<BatchWrite>>}
 */
export const createStandards = async (
  master_list: boolean,
  standards: Array<Standard | StandardData>,
  group_id?: string,
  company_id?: string
): Promise<Array<BatchWrite>> => {
  try {
    const collection_path: string = createStandardCollectionPath(master_list, group_id, company_id);
    const standard_writes: Array<BatchWrite> = [];
    const audit_log_batch_writes: Array<BatchWrite> = [];
    standards.forEach((standard: Standard | StandardData) => {
      const standard_ref: DocumentReference = doc(collection(db, collection_path));
      if (standard.sector) {
        standard.sector = doc(
          db,
          master_list
            ? `sector_master_list/${standard.sector.id}`
            : `groups/${group_id}/companies/${company_id}/sectors/${standard.sector.id}`
        );
      }
      const standard_data: StandardData = {
        category: standard.category,
        created: new Date(),
        deleted: null,
        name: standard.name,
        sector: standard.sector,
        version: standard.version,
        is_quantitative: standard.is_quantitative,
        require_emission_factor: standard.require_emission_factor,
        require_site_level: standard.require_site_level,
        ...(!master_list &&
          'id' in standard && {
            master_list_standard: doc(collection(db, 'standard_master_list'), standard.id)
          })
      };
      standard_writes.push({ reference: standard_ref, operation: 'create', data: standard_data });

      if (!master_list && group_id && company_id) {
        const audit_log_ref: DocumentReference = generateAuditLogDoc(group_id);
        const audit_log_data = generateAuditLogData(
          standard.name,
          'create',
          '',
          standard_ref,
          auth.currentUser?.email
        );
        audit_log_batch_writes.push({
          reference: audit_log_ref,
          operation: 'create',
          data: audit_log_data
        });
      }
    });
    await processBatchWrites(standard_writes).catch((error) => {
      throw new Error(error);
    });
    await processBatchWrites(audit_log_batch_writes).catch((error) => {
      throw new Error(error);
    });

    return standard_writes;
  } catch (err: unknown) {
    const tracking_id: string = uuidv4();
    throw new MetadataError(
      err instanceof Error
        ? err.message
        : 'Error: lib/metric_capture/standard.ts failed on an unknown error while calling createStandards.',
      {
        master_list: master_list,
        group_id: group_id,
        company_id: company_id,
        standards: standards
      },
      tracking_id
    );
  }
};

/**
 * Update standard with relative data
 * @param {boolean} master_list Flag to update standard in master list
 * @param {Standard} updated_standard New Standard data to push into document
 * @param {Standard} original_standard Old Standard data
 * @param {string} group_id Optional ID of Group to update standard for
 * @param {string} company_id Optional ID of Company to update standard for
 * @returns {void}
 */
export const updateStandard = async (
  master_list: boolean,
  updated_standard: Standard,
  original_standard: Standard,
  group_id?: string,
  company_id?: string
): Promise<void> => {
  try {
    const collection_path = createStandardCollectionPath(master_list, group_id, company_id);
    const standard_data: StandardData = {
      created: updated_standard.created,
      deleted: updated_standard.deleted,
      name: updated_standard.name,
      category: updated_standard.category,
      version: updated_standard.version,
      sector: doc(
        db,
        master_list
          ? `sector_master_list/${updated_standard.sector.id}`
          : `groups/${group_id}/companies/${company_id}/sectors/${updated_standard.sector.id}`
      ),
      is_quantitative: updated_standard.is_quantitative,
      require_emission_factor: updated_standard.require_emission_factor,
      require_site_level: updated_standard.require_site_level
    };
    const updated_standard_doc: DocumentReference = await updateFirestoreDoc(
      collection_path,
      updated_standard.id,
      standard_data
    );
    if (!master_list && group_id && company_id) {
      await createAuditLog(
        group_id,
        'update',
        JSON.stringify(original_standard),
        JSON.stringify(standard_data),
        updated_standard_doc
      );
    }
  } catch (err: unknown) {
    const tracking_id: string = uuidv4();
    throw new MetadataError(
      err instanceof Error
        ? err.message
        : 'Error: lib/metric_capture/standard.ts failed on an unknown error while calling updateStandard.',
      {
        group_id: group_id,
        company_id: company_id,
        updated_standard: updated_standard,
        original_standard: original_standard
      },
      tracking_id
    );
  }
};

/**
 * Delete company Standard
 * @param {string} group_id ID of Group to delete standard for
 * @param {string} company_id ID of Company to delete standard for
 * @param {string} standard_id ID of standard to delete
 * @returns {Promise<void>}
 */
export const deleteStandard = async (
  group_id: string,
  company_id: string,
  standard_id: string,
  standard_name: string
): Promise<void> => {
  try {
    if (group_id && company_id && standard_id) {
      const standard_doc = doc(
        collection(refCompanyDoc(group_id, company_id), `standards`),
        standard_id
      );
      await updateDoc(standard_doc, {
        deleted: new Date()
      });
      await createAuditLog(group_id, 'delete', standard_name, '', standard_doc);
    }
    return;
  } catch (err) {
    const error = `Error while deleting Standard from Firebase: ${JSON.stringify({
      message: err instanceof Error ? err.message : '',
      stacktrace: err instanceof Error ? err.stack : ''
    })}`;
    throw new Error(`Error: deleteStandard: ${JSON.stringify(error)}.`);
  }
};

/**
 * Soft delete a Standard and any related Metrics and Emission Factors in Firestore transaction
 * @param {boolean} master_list Flag to delete entities from their respective master lists
 * @param {string | undefined} group_id Optional Group to delete standard from
 * @param {string | undefined} company_id Optional Company to delete standard from
 * @param {string} standard_id ID of standard to delete
 * @param {string} standard_name Name of standard being deleted
 * @return {void}
 */
export const deleteStandardWithMetrics = async (
  master_list: boolean,
  standard_id: string,
  standard_name: string,
  group_id?: string,
  company_id?: string
): Promise<void> => {
  try {
    const standard_doc: DocumentReference = refStandard(
      master_list,
      standard_id,
      group_id,
      company_id
    );
    const standard_metrics: Array<MetricExtended> = await getMetrics(
      master_list,
      group_id,
      company_id,
      [standard_id]
    );
    const emission_factors: Array<EmissionFactor> = await getEmissionFactorsForMetrics(
      master_list,
      standard_metrics,
      group_id,
      company_id
    );

    await runTransaction(db, async (transaction: Transaction) => {
      const standard_snapshot: DocumentSnapshot = await transaction.get(standard_doc);
      if (!standard_snapshot.exists()) {
        throw 'Could not find Standard document to delete';
      }
      transaction.update(standard_doc, { deleted: new Date() });
      standard_metrics.forEach((metric: MetricExtended) => {
        transaction.update(
          refMetric(master_list, metric.id, metric.metric_group.id, group_id, company_id),
          {
            deleted: new Date()
          }
        );
      });
      emission_factors.forEach((emission_factor: EmissionFactor) => {
        emission_factor.ref && transaction.update(emission_factor.ref, { deleted: new Date() });
      });
    });
  } catch (err: unknown) {
    const tracking_id: string = uuidv4();
    throw new MetadataError(
      err instanceof Error
        ? err.message
        : 'Error: lib/metric_capture/standard.ts failed on an unknown error while calling deleteStandardWithMetrics.',
      {
        group_id: group_id,
        company_id: company_id,
        standard_id: standard_id,
        standard_name: standard_name
      },
      tracking_id
    );
  }
};

/**
 * Check if a Standard can be safely deleted
 * @param {string} group_id ID of Group to check Standard for
 * @param {string} company_id ID of Company to check Standard for
 * @param {string} standard_id ID of standard to check
 * @returns {Promise<boolean>}
 */
export const allowDeleteStandard = async (
  group_id: string,
  company_id: string,
  standard_id: string
): Promise<boolean> => {
  try {
    const query_params: Array<FirestoreQueryParam> = [
      {
        field_name: 'standard',
        operator: '==',
        value: doc(collection(refCompanyDoc(group_id, company_id), `standards`), standard_id)
      }
    ];
    const metric_records: Array<MetricRecord> = await getMetricRecords(
      group_id,
      company_id,
      query_params
    );
    const allow_delete: boolean = metric_records.length === 0;
    return allow_delete;
  } catch (err: unknown) {
    const tracking_id: string = uuidv4();
    throw new MetadataError(
      err instanceof Error
        ? err.message
        : 'Error: lib/app/standard.ts failed on an unknown error while calling allowDeleteStandard.',
      {
        standard_id: standard_id,
        group_id: group_id,
        company_id: company_id
      },
      tracking_id
    );
  }
};
