import React from 'react';
import {
  Autocomplete,
  AutocompleteRenderInputParams,
  Box,
  Grid,
  TextField,
  Typography
} from '@mui/material';
import { ExportType } from './ExportHub';
import { LoadingButton } from '@mui/lab';
import SystemUpdateAltIcon from '@mui/icons-material/SystemUpdateAlt';

/**
 * A widget to set the desired type of export and export the data itself.
 * @param {void} handleChangeExportType Handler function to update the export type in the parent.
 * @param {void} handleExport Function executed on button click to export data to user.
 * @returns {JSX.Element}
 */
const RawExportContext = ({
  export_types,
  selected_export_type,
  loading,
  input_valid,
  handleChangeExportType,
  handleExport
}: {
  export_types: Array<ExportType>;
  selected_export_type: ExportType | null;
  loading: boolean;
  input_valid: boolean;
  handleChangeExportType: (export_type: ExportType | null) => void;
  handleExport: () => Promise<void>;
}) => {
  return (
    <Box
      sx={{
        backgroundColor: '#FAFAFA',
        padding: 4,
        textAlign: 'start',
        borderRadius: '1rem',
        my: 2
      }}
    >
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Typography fontSize="1.3rem">Export Raw Data</Typography>
        </Grid>
        <Grid item xs={6}>
          <Autocomplete
            id="export-type-select"
            fullWidth
            sx={{ maxWidth: '430px' }}
            options={export_types}
            value={selected_export_type}
            getOptionLabel={(option: ExportType) => {
              return option.label;
            }}
            isOptionEqualToValue={(option: ExportType, value: ExportType) => option.id === value.id}
            renderOption={(render_props, option: ExportType) => (
              <Box
                component="li"
                sx={{ display: 'flex', alignItems: 'center' }}
                {...render_props}
                key={option.id}
              >
                {option.label}
              </Box>
            )}
            renderInput={(params: AutocompleteRenderInputParams) => (
              <TextField {...params} label="Export Type" variant="standard" />
            )}
            onChange={(event: React.SyntheticEvent<Element, Event>, value: ExportType | null) => {
              handleChangeExportType(value);
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <LoadingButton
            loading={loading}
            variant="contained"
            sx={{ py: 2, px: 4, fontSize: 16 }}
            startIcon={<SystemUpdateAltIcon />}
            onClick={handleExport}
            disabled={!selected_export_type || !input_valid}
          >
            EXPORT
          </LoadingButton>
        </Grid>
      </Grid>
    </Box>
  );
};

export default RawExportContext;
