import {
  collection,
  getDocs,
  query,
  doc,
  CollectionReference,
  Query,
  QuerySnapshot,
  QueryDocumentSnapshot,
  where,
  updateDoc,
  setDoc,
  DocumentReference,
  orderBy,
  DocumentData
} from 'firebase/firestore';
import { refCompanyDoc } from '../app/company';
import {
  EmissionFactor,
  EmissionFactorCreate,
  EmissionFactorData,
  ReportingPeriod,
  Standard
} from '@esg/esg-global-types';
import { auth, db } from '../google/firebase';
import { createAuditLog, generateAuditLogData, generateAuditLogDoc } from './audit';
import moment from 'moment';
import { uuidv4 } from '@firebase/util';
import { log } from '../../util/log';
import { MetadataError } from '@ep/error-handling';
import { FirestoreQueryParam } from '../../@types/shared';
import { getMetricRecords } from '../metric_capture/metric_record';
import { BatchWrite, processBatchWrites, readFirestoreDocs } from './db_util';
import { MetricExtended } from '../metric_capture/metric';
import { validateMasterListParams } from '../../util/validation';

interface EmissionFactorGroup {
  [key: string]: Array<EmissionFactor>;
}

export interface EmissionFactorStandard extends EmissionFactor {
  standard: Standard;
}

/**
 * Creates a database reference for a Emission Factor entity, either master list or for a configured company.
 * @param {string | undefined} id Id for a specific document. An undefined value is used to reference the collection to create a new document.
 * @param {string} metric_group_id The metric group to which a metric belongs to.
 * @param {string} metric_id The metric to which an emission factor belongs or should belong.
 * @param {string} group_id When not referencing a master list document or collection, a group id for the configured company is required.
 * @param {string} company_id When not referencing a master list document or collection, a company id for the configured company is required.
 * @returns {DocumentReference} The reference of the document or collection.
 */
export const refEmissionFactor = (
  id: string | undefined,
  metric_group_id: string,
  metric_id: string,
  group_id?: string,
  company_id?: string
): DocumentReference => {
  const collection_path: string = createEmissionFactorCollectionPath(
    group_id || company_id ? false : true,
    metric_group_id,
    metric_id,
    group_id,
    company_id
  );
  return id ? doc(collection(db, collection_path), id) : doc(collection(db, collection_path));
};

/**
 * Create a collection path string for the master list or configured emission factors.
 * @param {boolean} master_list Reference the master list collection.
 * @param {string} metric_group_id Metric group id to which a metric belongs.
 * @param {string} metric_id Metric id to which an emission factor belongs.
 * @param {string} group_id When not referencing a master list collection, a group id for the configured company is required.
 * @param {string} company_id When not referencing a master list collection, a company id for the configured company is required.
 * @returns {string} Path to a metric groups collection.
 */
export const createEmissionFactorCollectionPath = (
  master_list: boolean,
  metric_group_id: string,
  metric_id: string,
  group_id?: string,
  company_id?: string
): string => {
  try {
    validateMasterListParams(master_list, group_id, company_id);
    return master_list
      ? `/metric_group_master_list/${metric_group_id}/metrics/${metric_id}/emission_factors`
      : `groups/${group_id}/companies/${company_id}/metric_groups/${metric_group_id}/metrics/${metric_id}/emission_factors`;
  } catch (err: unknown) {
    const tracking_id: string = uuidv4();
    throw new MetadataError(
      err instanceof Error
        ? err.message
        : 'Error: createEmissionFactorCollectionPath failed on an unknown error.',
      {
        master_list: master_list,
        group_id: group_id,
        company_id: company_id
      },
      tracking_id
    );
  }
};

/**
 * Query all emission factor data.
 * @param {Parent} group_id group i of selected company
 * @param {string} company_id id of selected company
 * @returns {Array<EmissionFactor>}
 */
export const getEmissionFactors = async (
  group_id?: string,
  company_id?: string
): Promise<Array<EmissionFactor>> => {
  const emission_factors_arr: Array<EmissionFactor> = [];
  const metric_groups_collection: CollectionReference =
    group_id && company_id
      ? collection(refCompanyDoc(group_id, company_id), 'metric_groups')
      : collection(db, 'metric_group_master_list');
  const metric_groups_query: Query = query(metric_groups_collection, where('deleted', '==', null));
  try {
    const metric_group_docs: QuerySnapshot = await getDocs(metric_groups_query);
    const metric_group_promises: Array<Promise<void>> = metric_group_docs.docs.map(
      async (metric_group: QueryDocumentSnapshot) => {
        const metric_collection: CollectionReference = collection(
          doc(metric_groups_collection, metric_group.id),
          'metrics'
        );
        const metrics_query: Query = query(metric_collection, where('deleted', '==', null));
        const metric_docs: QuerySnapshot = await getDocs(metrics_query);
        const metric_promises: Array<Promise<void>> = metric_docs.docs.map(
          async (metric: QueryDocumentSnapshot) => {
            const emission_factor_collection: CollectionReference = collection(
              doc(metric_collection, metric.id),
              'emission_factors'
            );
            const emission_factor_query: Query = query(
              emission_factor_collection,
              where('deleted', '==', null)
            );
            const emission_factor_docs: QuerySnapshot = await getDocs(emission_factor_query);
            emission_factor_docs.docs.forEach((emission_factor) => {
              emission_factors_arr.push({
                id: emission_factor.id,
                start_date: emission_factor.data().start_date.toDate(),
                end_date: emission_factor.data().end_date.toDate(),
                source: emission_factor.data().source,
                factor: parseFloat(emission_factor.data()?.factor || 0),
                metric: {
                  id: metric.id,
                  ...metric.data()
                },
                metric_group: {
                  id: metric_group.id,
                  ...metric_group.data()
                },
                deleted: emission_factor.data().deleted,
                ref: emission_factor.ref
              });
            });
          }
        );
        await Promise.all(metric_promises);
      }
    );
    await Promise.all(metric_group_promises);
    return emission_factors_arr;
  } catch (err) {
    const error = `Error while getting Emission Factors from Firebase: ${JSON.stringify({
      message: err instanceof Error ? err.message : '',
      stacktrace: err instanceof Error ? err.stack : '',
      variables: {
        emission_factors_arr: emission_factors_arr,
        metrics_groups_collection: metric_groups_collection
      }
    })}`;
    throw new Error(`Error: getEmissionFactors: ${JSON.stringify(error)}.`);
  }
};

/**
 * Soft delete Emission Factor
 * @param {string} group_id ID of Group to delete emision factor for
 * @param {string} company_id ID of Company to delete emission factor for
 * @param {string} metric_group_id ID of Metric Group to delete emission factor for
 * @param {string} metric_id ID of Metric to delete emission factor for
 * @param {string} emission_factor_id ID of Emission Factor to delete
 * @returns {Promise<void>}
 */
export const deleteEmissionFactor = async (
  metric_group_id: string,
  metric_id: string,
  emission_factor_id: string,
  group_id?: string,
  company_id?: string
): Promise<void> => {
  try {
    const emission_factor_doc =
      group_id && company_id
        ? doc(
            collection(
              db,
              `/groups/${group_id}/companies/${company_id}/metric_groups/${metric_group_id}/metrics/${metric_id}/emission_factors`
            ),
            emission_factor_id
          )
        : doc(
            collection(
              db,
              `metric_group_master_list/${metric_group_id}/metrics/${metric_id}/emission_factors`
            ),
            emission_factor_id
          );
    const deleted_date = new Date();
    await updateDoc(emission_factor_doc, {
      deleted: deleted_date
    });
    if (group_id && company_id) {
      await createAuditLog(group_id, 'delete', emission_factor_id, '', emission_factor_doc);
    }
  } catch (err) {
    const error = `Error while deleting Emission Factor from Firebase: ${JSON.stringify({
      message: err instanceof Error ? err.message : '',
      stacktrace: err instanceof Error ? err.stack : ''
    })}`;
    throw new Error(`Error: deleteEmissionFactor: ${JSON.stringify(error)}.`);
  }
};

/**
 * Create new Emission Factor record
 * @param {string} group_id ID of Group to delete emision factor for
 * @param {string} company_id ID of Company to delete emission factor for
 * @param {string} metric_group_id ID of Metric Group to delete emission factor for
 * @param {string} metric_id ID of Metric to delete emission factor for
 * @param {string} emission_factor_id ID of Emission Factor to delete
 * @returns {Promise<DocumentReference>}
 */
export const createEmissionFactor = async (
  metric_group_id: string,
  metric_id: string,
  emission_factor: EmissionFactorCreate,
  group_id?: string,
  company_id?: string
): Promise<DocumentReference> => {
  try {
    const emission_factor_doc =
      group_id && company_id
        ? doc(
            collection(
              db,
              `/groups/${group_id}/companies/${company_id}/metric_groups/${metric_group_id}/metrics/${metric_id}/emission_factors`
            )
          )
        : doc(
            collection(
              db,
              `/metric_group_master_list/${metric_group_id}/metrics/${metric_id}/emission_factors`
            )
          );
    const emission_factor_doc_data = {
      start_date: moment(emission_factor.start_date).toDate(),
      end_date: moment(emission_factor.end_date).toDate(),
      factor: emission_factor.factor,
      source: emission_factor.source,
      deleted: null
    };

    await setDoc(emission_factor_doc, emission_factor_doc_data);
    if (group_id && company_id) {
      createAuditLog(
        group_id,
        'create',
        '',
        JSON.stringify(emission_factor_doc_data),
        emission_factor_doc
      );
    }
    return emission_factor_doc;
  } catch (err) {
    const error = `Error while creating Emission Factor on Firebase: ${JSON.stringify({
      message: err instanceof Error ? err.message : '',
      stacktrace: err instanceof Error ? err.stack : ''
    })}`;
    throw new Error(`Error: createEmissionFactor: ${JSON.stringify(error)}.`);
  }
};

/**
 * Update existing Emission Factor record
 * @param {string} group_id ID of Group to delete emision factor for
 * @param {string} company_id ID of Company to delete emission factor for
 * @param {string} metric_group_id ID of Metric Group to delete emission factor for
 * @param {string} metric_id ID of Metric to delete emission factor for
 * @param {string} emission_factor_id ID of Emission Factor to delete
 * @returns {Promise<void>}
 */
export const updateEmissionFactor = async (
  metric_group_id: string,
  metric_id: string,
  emission_factor: EmissionFactor,
  original_factor_data: EmissionFactor,
  group_id?: string,
  company_id?: string
): Promise<DocumentReference> => {
  try {
    const emission_factor_doc =
      group_id && company_id
        ? doc(
            collection(
              db,
              `/groups/${group_id}/companies/${company_id}/metric_groups/${metric_group_id}/metrics/${metric_id}/emission_factors`
            ),
            emission_factor.id
          )
        : doc(
            collection(
              db,
              `/metric_group_master_list/${metric_group_id}/metrics/${metric_id}/emission_factors`
            ),
            emission_factor.id
          );
    const emission_factor_doc_data = {
      start_date: moment(emission_factor.start_date).toDate(),
      end_date: moment(emission_factor.end_date).toDate(),
      factor:
        typeof emission_factor.factor === 'string'
          ? parseFloat(emission_factor.factor)
          : emission_factor.factor,
      source: emission_factor.source,
      deleted: null
    };
    const original_emission_factor_data = {
      start_date: original_factor_data.start_date,
      end_date: original_factor_data.end_date,
      factor: original_factor_data.factor,
      source: original_factor_data.source,
      deleted: null
    };
    await updateDoc(emission_factor_doc, emission_factor_doc_data);
    if (group_id && company_id) {
      createAuditLog(
        group_id,
        'update',
        JSON.stringify(original_emission_factor_data),
        JSON.stringify(emission_factor_doc_data),
        emission_factor_doc
      );
    }
    return emission_factor_doc;
  } catch (err) {
    const error = `Error while updating Emission Factor from Firebase: ${JSON.stringify({
      message: err instanceof Error ? err.message : '',
      stacktrace: err instanceof Error ? err.stack : ''
    })}`;
    throw new Error(`Error: updateEmissionFactor: ${JSON.stringify(error)}.`);
  }
};

/**
 * Retrieves an emission factor for a specific metric and reporting period.
 * @param {Group} group - ID of Group to get emision factor for
 * @param {Company} company - ID of Company to get emission factor for
 * @param {MetricGroup} metric_group - ID of Metric Group to get emission factor for
 * @param {Metric} metric - ID of Metric to get emission factor for
 * @param {ReportingPeriod} reporting_period - Reporting period object
 * @returns {Promise<EmissionFactor | null>}
 */
export const getEmissionFactorForReportingPeriod = async (
  group_id: string,
  company_id: string,
  metric_group_id: string,
  metric_id: string,
  reporting_period: ReportingPeriod
): Promise<EmissionFactor | null> => {
  const emission_factors_collection_path = `/groups/${group_id}/companies/${company_id}/metric_groups/${metric_group_id}/metrics/${metric_id}/emission_factors`;
  const emission_factors_collection: CollectionReference = collection(
    db,
    emission_factors_collection_path
  );
  const emission_factors_query: Query = query(
    emission_factors_collection,
    where('deleted', '==', null),
    where('start_date', '<=', moment(reporting_period.start).toDate()),
    orderBy('start_date', 'desc')
  );
  try {
    const emission_factor_documents: QuerySnapshot = await getDocs(emission_factors_query);
    if (emission_factor_documents.docs.length > 0) {
      const emission_factor_doc = emission_factor_documents.docs[0];
      const emission_factor_data: DocumentData = emission_factor_doc.data();
      const emission_factor: EmissionFactor = {
        id: emission_factor_doc.id,
        start_date: emission_factor_data.start_date.toDate(),
        end_date: emission_factor_data.end_date.toDate(),
        source: emission_factor_data.source,
        factor: parseFloat(emission_factor_data.factor),
        deleted: emission_factor_data.deleted,
        ref: doc(emission_factors_collection, emission_factor_doc.id)
      };
      return emission_factor;
    } else {
      return null;
    }
  } catch (err: unknown) {
    const tracking_id: string = uuidv4();
    log(
      'error',
      new MetadataError(
        err instanceof Error
          ? err.message
          : 'Error: lib/app/emission_factor.ts failed on an unknown error while calling getEmissionFactorForReportingPeriod.',
        {
          group_id: group_id,
          company_id: company_id,
          metric_group_id: metric_group_id,
          metric_id: metric_id,
          reporting_period: reporting_period
        },
        tracking_id
      )
    );
    throw new Error(`Error: getEmissionFactorForReportingPeriod: ${JSON.stringify(err)}.`);
  }
};

/**
 * Retrieves the latest emission factor by start date within a specific metric collection.
 * @param {string} metric_group_id - ID of Metric Group to get emission factor for.
 * @param {string} metric_id - ID of Metric to get emission factor for.
 * @param {CollectionReference} metric_collection - Optionally provide the reference to the metric collection in which the latest emission factor should be searched for.
 * @returns {Promise<EmissionFactor | null>}
 */
export const getLatestMetricEmissionFactorFromMasterList = async (
  metric_group_id: string,
  metric_id: string,
  metric_collection?: CollectionReference
): Promise<EmissionFactor | null> => {
  try {
    const emission_factors_collection: CollectionReference = metric_collection
      ? collection(doc(metric_collection, metric_id), 'emission_factors')
      : collection(
          db,
          `metric_group_master_list/${metric_group_id}/metrics/${metric_id}/emission_factors`
        );
    // TODO: Use compound queries to filter on the database instead of filtering in memory. Doing so will optmise cost and performance.
    // NOTE: Compound queries require indexing. The current index configuration does not work as expected.
    const emission_factors_query: Query = query(
      emission_factors_collection,
      where('deleted', '==', null) /*,
      orderBy("start", "desc"),
      limit(1)*/
    );
    const emission_factors_documents: QuerySnapshot = await getDocs(emission_factors_query);
    const emission_factor: EmissionFactor | null =
      emission_factors_documents.docs
        .map((emission_factor_doc) => {
          return {
            id: emission_factor_doc.id,
            start_date: emission_factor_doc.data().start_date.toDate(),
            end_date: emission_factor_doc.data().end_date.toDate(),
            source: emission_factor_doc.data().source,
            factor: parseFloat(emission_factor_doc.data().factor),
            deleted: emission_factor_doc.data().deleted,
            ref: doc(emission_factors_collection, emission_factor_doc.id)
          };
        })
        .sort((a, b) => {
          return moment(b.start_date).diff(a.start_date);
        })[0] || null;
    return emission_factor;
  } catch (err: unknown) {
    const tracking_id: string = uuidv4();
    throw new MetadataError(
      err instanceof Error
        ? err.message
        : 'Error: lib/app/emission_factor.ts failed on an unknown error while calling getLatestMetricEmissionFactorFromMasterList.',
      {
        metric_group_id: metric_group_id,
        metric_id: metric_id,
        metric_collection: metric_collection
      },
      tracking_id
    );
  }
};

/**
 * Function to check if an Emission Factor can be safely deleted
 * @param {string} group_id ID of Group to check Metric for
 * @param {string} company_id ID of Company to check Metric for
 * @param {DocumentReference} emission_factor_ref document reference of emission factor
 * @returns {boolean}
 */
export const allowDeleteEmissionFactor = async (
  group_id: string,
  company_id: string,
  emission_factor_ref: DocumentReference
) => {
  try {
    const query_params: Array<FirestoreQueryParam> = [
      {
        field_name: 'emission_factor',
        operator: '==',
        value: emission_factor_ref
      }
    ];
    const metric_records = await getMetricRecords(group_id, company_id, query_params);
    const allow_delete = metric_records.length === 0;
    return allow_delete;
  } catch (err: unknown) {
    const tracking_id: string = uuidv4();
    log(
      'error',
      new MetadataError(
        err instanceof Error
          ? err.message
          : 'Error: lib/app/emission_factor.ts failed on an unknown error while calling allowDeleteEmissionFactor.',
        {
          group_id: group_id,
          company_id: company_id,
          emission_factor_ref: emission_factor_ref
        },
        tracking_id
      )
    );
    throw new Error(`Error: allowDeleteEmissionFactor: ${JSON.stringify(err)}.`);
  }
};

/**
 * Create a batch of emission factor documents of a configured company.
 * @param {Array<EmissionFactor>} emission_factors list of emission factor objects to be added to batch.
 * @param {string} group_id ID of Group to create emission factor for.
 * @param {string} company_id ID of Company to create emission factor for.
 * @returns {Promise<Array<BatchWrite>>}
 */
export const createEmissionFactorBatch = async (
  emission_factors: Array<EmissionFactor>,
  group_id: string,
  company_id: string
): Promise<Array<BatchWrite>> => {
  try {
    if (!(group_id && company_id && emission_factors.length > 0)) {
      throw new MetadataError('Required parameters missing.', {
        group_id: group_id,
        company_id: company_id,
        emission_factors: emission_factors
      });
    }
    const emission_factor_writes: Array<BatchWrite> = [];
    const audit_log_batch_writes: Array<BatchWrite> = [];
    emission_factors.forEach((emission_factor: EmissionFactor) => {
      const emission_factor_ref: DocumentReference = refEmissionFactor(
        emission_factor.id,
        emission_factor.metric_group.id,
        emission_factor.metric.id,
        group_id,
        company_id
      );
      const emission_factor_data: EmissionFactorData = {
        start_date: moment(emission_factor.start_date).toDate(),
        end_date: moment(emission_factor.end_date).toDate(),
        factor: emission_factor.factor,
        source: emission_factor.source,
        deleted: null
      };
      emission_factor_writes.push({
        reference: emission_factor_ref,
        operation: 'create',
        data: emission_factor_data
      });
      const audit_log_ref: DocumentReference = generateAuditLogDoc(group_id);
      const audit_log_data = generateAuditLogData(
        emission_factor.factor.toString(),
        'create',
        '',
        emission_factor_ref,
        auth.currentUser?.email
      );
      audit_log_batch_writes.push({
        reference: audit_log_ref,
        operation: 'create',
        data: audit_log_data
      });
    });
    await processBatchWrites(emission_factor_writes).catch((error) => {
      throw new MetadataError(error);
    });
    await processBatchWrites(audit_log_batch_writes).catch((error) => {
      throw new MetadataError(error);
    });
    return emission_factor_writes;
  } catch (err: unknown) {
    const tracking_id: string = uuidv4();
    throw new MetadataError(
      err instanceof Error
        ? err.message
        : 'Error: createEmissionFactorBatch failed on an unknown error.',
      {
        emission_factors: emission_factors,
        group_id: group_id,
        company_id: company_id
      },
      tracking_id
    );
  }
};

/**
 * Get all non-deleted emission factors for specified metrics.
 * @param {boolean} master_list Query metrics from the master list.
 * @param {Array<MetricExtended>} metrics list of metrics for which emission factors should be fetched.
 * @param {string} group_id ID of Group to check emission factor for.
 * @param {string} company_id ID of Company to check emission factor for.
 * @returns {Promise<Array<EmissionFactor>>}
 */
export const getEmissionFactorsForMetrics = async (
  master_list: boolean,
  metrics: Array<MetricExtended>,
  group_id?: string,
  company_id?: string
): Promise<Array<EmissionFactor>> => {
  try {
    validateMasterListParams(master_list, group_id, company_id);
    const emission_factors: Array<EmissionFactor> = [];
    const metric_promises: Array<Promise<void>> = metrics.map(async (metric: MetricExtended) => {
      const metric_id: string =
        master_list && metric.master_list_metric
          ? metric.master_list_metric.id
          : master_list && !metric.master_list_metric && metric.id
            ? metric.id
            : !master_list && metric.master_list_metric && metric.id
              ? metric.id
              : '';
      const metric_group_id: string =
        master_list && metric.metric_group.master_list_metric_group
          ? metric.metric_group.master_list_metric_group.id
          : master_list && !metric.metric_group.master_list_metric_group && metric.metric_group.id
            ? metric.metric_group.id
            : !master_list && metric.metric_group.master_list_metric_group && metric.metric_group.id
              ? metric.metric_group.id
              : '';
      const mission_factor_collection_path: string = master_list
        ? `/metric_group_master_list/${metric_group_id}/metrics/${metric_id}/emission_factors`
        : `groups/${group_id}/companies/${company_id}/metric_groups/${metric_group_id}/metrics/${metric_id}/emission_factors`;
      const mission_factor_query_params: Array<FirestoreQueryParam> = [
        { field_name: 'deleted', operator: '==', value: null }
      ];
      const emission_factor_snapshot: QuerySnapshot = await readFirestoreDocs(
        mission_factor_collection_path,
        mission_factor_query_params
      );
      emission_factor_snapshot.docs.forEach((emission_factor_doc: QueryDocumentSnapshot) => {
        const emission_factor_data: DocumentData = emission_factor_doc.data();
        emission_factors.push({
          id: emission_factor_doc.id,
          start_date: emission_factor_data.start_date.toDate(),
          end_date: emission_factor_data.end_date.toDate(),
          source: emission_factor_data.source,
          factor: parseFloat(emission_factor_data?.factor || 0),
          metric: metric,
          metric_group: metric.metric_group,
          deleted: emission_factor_data.deleted,
          ref: emission_factor_doc.ref
        });
      });
    });
    await Promise.all(metric_promises);
    return emission_factors;
  } catch (err: unknown) {
    const tracking_id: string = uuidv4();
    throw new MetadataError(
      err instanceof Error
        ? err.message
        : 'Error: getEmissionFactorsForMetrics failed on an unknown error.',
      {
        master_list: master_list,
        metrics: metrics,
        group_id: group_id,
        company_id: company_id
      },
      tracking_id
    );
  }
};

/**
 * Get the latest non-deleted emission factor for each metric within the specified metrics.
 * @param {boolean} master_list Query metrics from the master list.
 * @param {Array<MetricExtended>} metrics list of metrics for which latest emission factors should be fetched.
 * @param {string} group_id ID of Group to check emission factor for.
 * @param {string} company_id ID of Company to check emission factor for.
 * @returns {Promise<Array<EmissionFactor>>}
 */
export const getLatestEmissionFactorForMetrics = async (
  master_list: boolean,
  metrics: Array<MetricExtended>,
  group_id?: string,
  company_id?: string
): Promise<Array<EmissionFactor>> => {
  try {
    validateMasterListParams(master_list, group_id, company_id);
    const emission_factors_latest: Array<EmissionFactor> = [];
    const emission_factors: Array<EmissionFactor> = await getEmissionFactorsForMetrics(
      master_list,
      metrics,
      group_id,
      company_id
    );
    /* TODO: Use compound queries to obtain the latest emission factors instead of filtering in memory.
             This function will then likely be removed and a latest_emission_factor_only flag will be added to getEmissionFactorsForMetrics. */
    const emission_factors_grouped: Array<Array<EmissionFactor>> = Object.values(
      emission_factors.reduce((result: EmissionFactorGroup, currentValue: EmissionFactor) => {
        (result[currentValue['metric']['id']] = result[currentValue['metric']['id']] || []).push(
          currentValue
        );
        return result;
      }, {})
    );
    emission_factors_grouped.forEach((emission_factor_group: Array<EmissionFactor>) => {
      const emission_factor_latest: EmissionFactor =
        emission_factor_group.sort((a: EmissionFactor, b: EmissionFactor) => {
          return moment(b.start_date).diff(a.start_date);
        })[0] || null;
      emission_factors_latest.push(emission_factor_latest);
    });
    return emission_factors_latest;
  } catch (err: unknown) {
    const tracking_id: string = uuidv4();
    throw new MetadataError(
      err instanceof Error
        ? err.message
        : 'Error: getLatestEmissionFactorForMetrics failed on an unknown error.',
      {
        master_list: master_list,
        metrics: metrics,
        group_id: group_id,
        company_id: company_id
      },
      tracking_id
    );
  }
};

/**
 * Get all non-deleted emission factors for specified standards.
 * @param {boolean} master_list Query metrics and emission factors from the master list.
 * @param {Array<Standard>} standards list of metrics for which emission factors should be fetched.
 * @param quantitative_only fetch only metrics for quantitative standards. Defaults to false.
 * @param {string} group_id ID of Group to check emission factor for.
 * @param {string} company_id ID of Company to check emission factor for.
 * @returns {Promise<Array<EmissionFactor>>}
 */
// NOTE: Currently unused. Requires more testing before use.
/*
export const getEmissionFactorsForStandards = async (
  master_list: boolean,
  standards: Array<Standard>,
  quantitative_only = false,
  group_id?: string,
  company_id?: string
): Promise<Array<EmissionFactor>> => {
  try {
    validateMasterListParams(master_list, group_id, company_id);
    const standard_ids: Array<string> = quantitative_only
      ? standards
        .filter((standard: Standard) => (standard.is_quantitative = true))
        .map((standard: Standard) => standard.id)
      : standards.map((standard: Standard) => standard.id);
    const metrics: Array<MetricExtended> = await getMetrics(
      master_list,
      group_id,
      company_id,
      standard_ids,
      undefined
    );
    const emission_factors: Array<EmissionFactor> = await getEmissionFactorsForMetrics(
      master_list,
      metrics,
      group_id,
      company_id
    );
    return emission_factors;
  } catch (err: unknown) {
    const tracking_id: string = uuidv4();
    throw new MetadataError(
      err instanceof Error
        ? err.message
        : 'Error: getEmissionFactorsForStandards failed on an unknown error.',
      {
        master_list: master_list,
        standards: standards,
        quantitative_only: quantitative_only,
        group_id: group_id,
        company_id: company_id
      },
      tracking_id
    );
  }
};
*/

/**
 * Get the latest non-deleted emission factor for each metric within the specified standards.
 * @param {boolean} master_list Query metrics and emission factors from the master list.
 * @param {Array<Standard>} standards list of standards for which latest emission factors should be fetched.
 * @param quantitative_only fetch only metrics for quantitative standards. Defaults to false.
 * @param {string} group_id ID of Group to check emission factor for.
 * @param {string} company_id ID of Company to check emission factor for.
 * @returns {Promise<Array<EmissionFactorStandard>>}
 */
// NOTE: Currently unused. Requires more testing before use.
/*
export const getLatestEmissionFactorForStandards = async (
  master_list: boolean,
  standards: Array<Standard>,
  quantitative_only = false,
  group_id?: string,
  company_id?: string
): Promise<Array<EmissionFactorStandard>> => {
  try {
    validateMasterListParams(master_list, group_id, company_id);
    const emission_factors_latest: Array<EmissionFactorStandard> = [];
    const standard_promises: Array<Promise<void>> = standards.map(async (standard: Standard) => {
      const emission_factors: Array<EmissionFactor> = await getEmissionFactorsForStandards(
        master_list,
        [standard],
        quantitative_only,
        group_id,
        company_id
      );
      const emission_factors_grouped: Array<Array<EmissionFactor>> = Object.values(
        emission_factors.reduce((result: EmissionFactorGroup, currentValue: EmissionFactor) => {
          (result[currentValue['metric']['id']] = result[currentValue['metric']['id']] || []).push(
            currentValue
          );
          return result;
        }, {})
      );
      emission_factors_grouped.forEach((emission_factor_group: Array<EmissionFactor>) => {
        const emission_factor_latest: EmissionFactor =
          emission_factor_group.sort((a: EmissionFactor, b: EmissionFactor) => {
            return moment(b.start_date).diff(a.start_date);
          })[0] || null;
        emission_factors_latest.push({
          ...emission_factor_latest,
          standard: standard
        });
      });
    });
    await Promise.all(standard_promises);
    return emission_factors_latest;
  } catch (err: unknown) {
    const tracking_id: string = uuidv4();
    throw new MetadataError(
      err instanceof Error
        ? err.message
        : 'Error: getLatestEmissionFactorForStandards failed on an unknown error.',
      {
        master_list: master_list,
        standards: standards,
        quantitative_only: quantitative_only,
        group_id: group_id,
        company_id: company_id
      },
      tracking_id
    );
  }
};
*/
