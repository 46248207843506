import * as React from 'react';
import { Box, Drawer } from '@mui/material';
import UsersTable from './UsersTable';
import AddUser from './AddUser';
import { getUsers } from '../../lib/user_management/users';
import { User, Role, Module, Group } from '@esg/esg-global-types';
import { getRoles } from '../../lib/user_management/roles';
import { FeedbackSnackbarContext } from '../../context/FeedbackSnackbarContext';
import { getModules } from '../../lib/app/module';
import { getGroupCompanies } from '../../lib/app/group';
import { UserContext } from '../../context/UserContext';
import { getAdminGroups } from '../../util/user_access';
import { MetadataError } from '@ep/error-handling';
import { uuidv4 } from '@firebase/util';
import { log } from '../../util/log';

const ManageUsersHub = () => {
  const { setFeedbackData } = React.useContext(FeedbackSnackbarContext);
  const user_info: User | null = React.useContext(UserContext);
  const [users, setUsers] = React.useState<User[]>([]);
  const [groups, setGroups] = React.useState<Group[]>([]);
  const [roles, setRoles] = React.useState<Role[]>([]);
  const [modules, setModules] = React.useState<Array<Module>>([]);
  const [tableLoading, setTableLoading] = React.useState<boolean>(false);
  const [showCreateUser, setShowCreateUser] = React.useState<boolean>(false);
  const admin_groups: Array<string> = user_info ? getAdminGroups(user_info) : [];

  React.useEffect(() => {
    try {
      (async () => {
        await fetchUserEntities().catch((err: unknown) => {
          throw new Error(
            err instanceof Error
              ? err.message
              : 'Error: CompanyAccessConfig failed on an unknown error while initializing.'
          );
        });
      })().catch((err: unknown) => {
        throw new Error(
          err instanceof Error
            ? err.message
            : 'Error: CompanyAccessConfig failed on an unknown error while initializing.'
        );
      });
    } catch (err: unknown) {
      const tracking_id: string = uuidv4();
      log(
        'error',
        new MetadataError(
          err instanceof Error
            ? err.message
            : 'Error: ManageUsersHub failed on an unknown error while initializing.',
          {
            user_info: user_info
          },
          tracking_id
        )
      );
      setFeedbackData({
        message: `Unable to fetch user list. Tracking ID: ${tracking_id}`,
        state: true,
        type: 'error'
      });
    }
  }, []);

  const fetchUserEntities = async () => {
    setTableLoading(true);
    if (user_info) {
      const [users, groups, roles, modules]: [
        Array<User>,
        Array<Group>,
        Array<Role>,
        Array<Module>
      ] = await Promise.all([getUsers(true), getGroupCompanies(), getRoles(), getModules()]);
      setGroups(groups);
      setRoles(roles);
      setUsers(users);
      // NOTE: Filtering of modules is temporary while the other modules are under development.
      setModules(
        modules.filter((module: Module) => {
          return module.id === 'metric_collection' || module.id === 'reports';
        })
      );
      setTableLoading(false);
    }
  };

  const openCreateDrawer = async () => {
    setShowCreateUser(true);
  };

  return (
    <>
      <Drawer
        anchor={'right'}
        open={showCreateUser}
        onClose={() => setShowCreateUser(false)}
        PaperProps={{ style: { width: '60%' } }}
      >
        <AddUser
          groups={groups.filter((group: Group) => {
            if (user_info?.super_admin) return true;
            return admin_groups.includes(group.id);
          })}
          roles={roles}
          modules={modules}
          handleNewUser={fetchUserEntities}
          closeDrawer={() => setShowCreateUser(false)}
        ></AddUser>
      </Drawer>
      <Box
        sx={{
          textAlign: 'start',
          padding: '2%',
          display: 'flex',
          flexDirection: 'column'
        }}
        className="div-metrics-hub"
      >
        <UsersTable
          tableLoading={tableLoading}
          users={users}
          modules={modules}
          groups={groups}
          roles={roles}
          handleUpdatedUser={fetchUserEntities}
          handleCreateUser={openCreateDrawer}
          handleSetUserRows={(users: Array<User>) => setUsers(users)}
        ></UsersTable>
      </Box>
    </>
  );
};

export default ManageUsersHub;
