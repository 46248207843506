import * as React from 'react';
import Box from '@mui/material/Box';
import { LoadingButton } from '@mui/lab';
import { Typography, Divider, Stepper, Step, StepLabel, Button } from '@mui/material';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import { styled } from '@mui/system';
import { getAuth } from 'firebase/auth';
import axios, { AxiosError } from 'axios';
import { FeedbackSnackbarContext } from '../../context/FeedbackSnackbarContext';
import { API_ENDPOINT } from '../../env';
import { Company, Country, Group, Module, Role, User } from '@esg/esg-global-types';
import AccountDetails from './shared/AccountDetails';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import CompanyAccessSelect from './shared/CompanyAccessSelect';
import CompanyAccessConfig from './shared/CompanyAccessConfig';
import { InputErrors } from '../../@types/shared';
import {
  validateEmail,
  validatePasswordInput,
  validatePhoneNumber
} from '../../lib/validation/text_validation';
import { MetadataError } from '@ep/error-handling';
import { uuidv4 } from '@firebase/util';
import { log } from '../../util/log';

type StepProgress = 'invalid' | 'next' | 'finished';

interface UserGroups {
  [group_id: string]: {
    admin: boolean;
    companies?: {
      [company_id: string]: {
        role: string;
        permissions?: string[];
      };
    };
  };
}

interface NewUserDetails {
  email_address: string;
  first_name: string;
  last_name: string;
  contact_number: string;
  password: string;
  super_admin: boolean;
  groups: UserGroups;
  type: 'internal' | 'external' | 'any';
}

const default_new_user: User = {
  id: '',
  created: new Date(),
  modified: null,
  deleted: null,
  first_name: '',
  last_name: '',
  email: '',
  phone: '',
  super_admin: false,
  groups: {},
  type: 'any',
  last_accessed: null
};

const steps = ['Account Details', 'Access a Company', 'Configure Access'];

const DrawBoxContainer = styled(Box)({
  margin: 30
});

const formatName = (name: string) => {
  return name.charAt(0).toUpperCase() + name.slice(1).toLowerCase();
};

const AddUser = ({
  handleNewUser,
  groups,
  roles,
  modules,
  closeDrawer
}: {
  handleNewUser: () => void;
  groups: Array<Group>;
  roles: Array<Role>;
  modules: Array<Module>;
  closeDrawer: () => void;
}) => {
  const { setFeedbackData } = React.useContext(FeedbackSnackbarContext);
  const [userData, setUserData] = React.useState<User>(default_new_user);
  const [countryData, setCountryData] = React.useState<Country | null>(null);
  const [password, setPassword] = React.useState<string>('');
  const [confirmPassword, setConfirmPassword] = React.useState<string>('');
  const [configGroup, setConfigGroup] = React.useState<Group | null>(null);
  const [configCompany, setConfigCompany] = React.useState<Company | null>(null);
  const [activeStep, setActiveStep] = React.useState<number>(0);
  const [inputErrors, setInputErrors] = React.useState<InputErrors>({
    first_name: '',
    last_name: '',
    email: '',
    phone: '',
    password: '',
    confirm_password: '',
    chosen_group: '',
    chosen_company: ''
  });
  const [loadingUserCreate, setLoadingUserCreate] = React.useState<boolean>(false);

  // Validate current step input values
  let step_progress: StepProgress = 'invalid';
  switch (activeStep) {
    case 0:
      if (
        userData.first_name !== '' &&
        userData.last_name !== '' &&
        countryData &&
        validateEmail(userData.email) &&
        validatePhoneNumber(userData.phone, countryData.phone_regex) &&
        validatePasswordInput(password) &&
        password === confirmPassword
      ) {
        step_progress = 'next';
      } else step_progress = 'invalid';
      break;
    case 1:
      if (userData.super_admin || (configGroup && userData.groups[configGroup.id]?.admin))
        step_progress = 'finished';
      else if (configGroup && configCompany) step_progress = 'next';
      else step_progress = 'invalid';
      break;
    case 2:
      if (
        configGroup &&
        configCompany &&
        userData.groups[configGroup.id]?.companies?.[configCompany.id]
      )
        step_progress = 'finished';
      else step_progress = 'invalid';
      break;
    default:
      break;
  }

  const handleStepChange = (new_step: number) => {
    setActiveStep(new_step);
  };

  const handleInputErrors = (input_id: string, error: string) => {
    setInputErrors({ ...inputErrors, [input_id]: error });
  };

  const handleAddUserSubmit = async (event: React.MouseEvent) => {
    try {
      event.preventDefault();
      const user_details: NewUserDetails = {
        first_name: formatName(userData.first_name),
        last_name: formatName(userData.last_name),
        email_address: userData.email,
        contact_number: userData.phone,
        password: password,
        super_admin: userData.super_admin,
        groups: userData.groups,
        type: userData.type
      };
      await getAuth()
        .currentUser?.getIdToken()
        .then(async (token) => {
          setLoadingUserCreate(true);
          await axios({
            method: 'post',
            url: `${API_ENDPOINT}/create_new_user_request`,
            data: user_details,
            headers: {
              authorization: String('Bearer ' + token)
            }
          })
            .then(() => {
              setLoadingUserCreate(false);
              handleClose();
              handleNewUser();
            })
            .catch((error) => {
              throw error;
            });
        })
        .catch((error: unknown) => {
          throw error;
        });
    } catch (err: unknown) {
      let error_message = 'Contact Energy Partners if problem persists';
      if (err instanceof AxiosError && err.response?.data === 'auth/phone-number-already-exists') {
        error_message = 'Number already registered to another user';
      }
      const tracking_id: string = uuidv4();
      log(
        'error',
        new MetadataError(
          err instanceof Error
            ? err.message
            : 'Error: DeleteUser failed on an unknown error while calling handleDeleteUser.',
          {
            userData: userData
          },
          tracking_id
        )
      );
      setFeedbackData({
        message: `${error_message}. Tracking ID: ${tracking_id}`,
        state: true,
        type: 'error'
      });
    } finally {
      setLoadingUserCreate(false);
    }
  };

  const handleClose = () => {
    setUserData(default_new_user);
    setActiveStep(0);
    setConfigGroup(null);
    setConfigCompany(null);
    setPassword('');
    setConfirmPassword('');
    closeDrawer();
  };

  return (
    <Box>
      <DrawBoxContainer
        sx={{
          display: 'flex',
          alignItems: 'center',
          flexWrap: 'wrap',
          gap: 2
        }}
      >
        <PersonAddIcon />
        <Typography variant="h6">Add New User</Typography>
      </DrawBoxContainer>
      <Divider />
      <DrawBoxContainer>
        <Stepper activeStep={activeStep}>
          {steps.map((label) => {
            const stepProps: { completed?: boolean } = {};
            const labelProps: {
              optional?: React.ReactNode;
            } = {};
            return (
              <Step key={label} {...stepProps}>
                <StepLabel {...labelProps}>{label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
        {activeStep === 0 && (
          <AccountDetails
            user={userData}
            password={password}
            confirm_password={confirmPassword}
            input_errors={inputErrors}
            handleUserChange={(new_user: User) => setUserData(new_user)}
            handleCountryChange={(new_country: Country | null) => setCountryData(new_country)}
            handleChangePassword={(new_password: string) => setPassword(new_password)}
            handleChangeConfirmPassword={(new_confirm_password: string) =>
              setConfirmPassword(new_confirm_password)
            }
            handleInputErrors={handleInputErrors}
          />
        )}
        {activeStep === 1 && (
          <CompanyAccessSelect
            user={userData}
            chosen_group={configGroup}
            chosen_company={configCompany}
            group_choices={groups}
            input_errors={inputErrors}
            handleUserChange={(new_user: User) => setUserData(new_user)}
            handleGroupChange={(new_group: Group) => {
              setConfigGroup(new_group);
              setUserData({ ...userData, groups: {} });
              setConfigCompany(null);
            }}
            handleCompanyChange={(new_company: Company) => setConfigCompany(new_company)}
            handleInputErrors={handleInputErrors}
          />
        )}
        {activeStep === 2 && configGroup && configCompany && (
          <CompanyAccessConfig
            user={userData}
            chosen_group={configGroup}
            chosen_company={configCompany}
            roles={roles}
            modules={modules}
            handleUserChange={(new_user: User) => setUserData(new_user)}
          />
        )}
        <Divider />
        <Box
          sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', pt: 2, px: 4 }}
        >
          <Button startIcon={<ChevronLeftIcon />} onClick={handleClose} sx={{ mr: 2 }}>
            Cancel
          </Button>
          <Button
            disabled={activeStep === 0}
            onClick={() => handleStepChange(activeStep - 1)}
            sx={{ mr: 2, minWidth: 150 }}
            variant="contained"
          >
            Back
          </Button>
          <LoadingButton
            loading={loadingUserCreate}
            disabled={step_progress === 'invalid'}
            onClick={(event: React.MouseEvent) =>
              activeStep === steps.length - 1 || step_progress === 'finished'
                ? handleAddUserSubmit(event)
                : handleStepChange(activeStep + 1)
            }
            variant="contained"
            sx={{ minWidth: 150 }}
          >
            {activeStep === steps.length - 1 || step_progress === 'finished'
              ? 'Create User'
              : 'Next'}
          </LoadingButton>
        </Box>
      </DrawBoxContainer>
    </Box>
  );
};

export default AddUser;
