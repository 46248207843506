import * as React from 'react';
import { Box, Drawer, Tooltip, Typography } from '@mui/material';
import BusinessCenterOutlinedIcon from '@mui/icons-material/BusinessCenterOutlined';
import { Company, Group, Sector } from '@esg/esg-global-types';
import {
  SectorData,
  createSectors,
  deleteSector,
  getSectors,
  sector_label,
  updateSector
} from '../../../lib/app/sector';
import { FeedbackSnackbarContext } from '../../../context/FeedbackSnackbarContext';
import DeleteConfirmModal from '../../shared/modal/DeleteConfirmModal';
import EditIcon from '@mui/icons-material/Edit';
import ConfigAddWidget, { CreateInput } from '../ConfigAddWidget';
import { MetadataError } from '@ep/error-handling';
import { uuidv4 } from '@firebase/util';
import { log } from '../../../util/log';
import { DataGrid, GridActionsCellItem, GridColDef } from '@mui/x-data-grid';
import { PanelSectorsToolbar } from './PanelSectorsToolbar';
import { PanelSectorsNoRows } from './PanelSectorsNoRows';
import { PanelSectorsLoading } from './PanelSectorsLoading';
import { GroupContext } from '../../../context/GroupContext';
import { CompanyContext } from '../../../context/CompanyContext';
import DeleteIcon from '@mui/icons-material/Delete';
import ConfigEditWidget, { EditInput } from '../ConfigEditWidget';
import { BatchWrite } from '../../../lib/app/db_util';

interface PanelSectorsProps {
  master_list?: boolean;
}

/**
 * Datagrid handling the display of configured or Master List Sectors
 * @returns {JSX.Element}
 */
const PanelSectors = ({ master_list }: PanelSectorsProps) => {
  const { setFeedbackData } = React.useContext(FeedbackSnackbarContext);
  const group: Group | null = React.useContext(GroupContext);
  const company: Company | null = React.useContext(CompanyContext);
  const [actionRow, setActionRow] = React.useState<Sector | null>(null);
  const [isCreateWidget, setIsCreateWidget] = React.useState<boolean>(true);
  const [sectorRows, setSectorRows] = React.useState<Array<Sector>>([]);
  const [displayWidgetPanelRight, setDisplayWidgetPanelRight] = React.useState<boolean>(false);
  const [openDialogDelete, setOpenDialogDelete] = React.useState<boolean>(false);
  const [gridLoading, setGridLoading] = React.useState<boolean>(true);

  // Handler functions
  const handleCreateClick = (): void => {
    setActionRow(null);
    setIsCreateWidget(true);
    setDisplayWidgetPanelRight(true);
  };

  const handleEditClick = (sector: Sector): void => {
    setActionRow(sector);
    setIsCreateWidget(false);
    setDisplayWidgetPanelRight(true);
  };

  const handleDeleteClick = async (row: Sector): Promise<void> => {
    try {
      setActionRow(row);
      setOpenDialogDelete(true);
    } catch (err: unknown) {
      const tracking_id: string = uuidv4();
      log(
        'error',
        new MetadataError(
          err instanceof Error
            ? err.message
            : 'Error: PanelSectors failed on an unknown error while calling handleDeleteClick.',
          {
            group: group,
            company: company,
            row: row
          },
          tracking_id
        )
      );
      setFeedbackData({
        message: `Unable to delete sector. Tracking ID: ${tracking_id}`,
        state: true,
        type: 'error'
      });
    }
  };

  const handleUpdateRows = (rows_data: Array<Sector>): void => {
    setSectorRows(rows_data);
  };

  const handleCloseDeleteModal = (): void => {
    setOpenDialogDelete(false);
  };

  const columns: Array<GridColDef> = [
    {
      field: 'name',
      headerName: 'Name',
      headerAlign: 'left',
      align: 'left',
      flex: 1
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: '',
      headerAlign: 'right',
      align: 'right',
      hideable: false,
      flex: 1,
      getActions: ({ row }) => {
        return [
          <>
            {master_list && (
              <GridActionsCellItem
                key={1}
                icon={
                  <Tooltip title="Edit Sector">
                    <EditIcon color={!row.locked ? 'primary' : 'action'} />
                  </Tooltip>
                }
                size="large"
                label="Edit"
                sx={{
                  color: 'primary.main'
                }}
                onClick={() => handleEditClick(row)}
                disabled={row.locked}
              />
            )}
            <GridActionsCellItem
              key={2}
              icon={
                <Tooltip title="Delete Sector">
                  <DeleteIcon color="primary" />
                </Tooltip>
              }
              size="large"
              label="Delete"
              sx={{
                color: 'primary.main'
              }}
              onClick={() => handleDeleteClick(row)}
            />
          </>
        ];
      }
    }
  ];

  const create_inputs: readonly CreateInput[] = master_list
    ? [
        {
          id: 'name',
          type: 'text',
          label: 'Name'
        }
      ]
    : [];

  const edit_inputs: EditInput[] = master_list
    ? [
        {
          id: 'name',
          type: 'text',
          label: 'Name'
        }
      ]
    : [];

  // Row functions
  const fetchRows = async (): Promise<void> => {
    setGridLoading(true);
    try {
      // Load rows from database.
      const sectors: Array<Sector> = master_list
        ? await getSectors(master_list)
        : group && company
          ? await getSectors(false, group.id, company.id)
          : [];
      // Load rows to memory.
      setSectorRows(sectors);
    } catch (err: unknown) {
      const tracking_id: string = uuidv4();
      log(
        'error',
        new MetadataError(
          err instanceof Error
            ? err.message
            : 'Error: PanelSectors failed on an unknown error while calling fetchRows.',
          {
            group: group,
            company: company
          },
          tracking_id
        )
      );
      setFeedbackData({
        message: `Unable to fetch sectors. Tracking ID: ${tracking_id}`,
        state: true,
        type: 'error'
      });
      return;
    } finally {
      setGridLoading(false);
    }
  };

  React.useEffect(
    () => {
      try {
        (async () => {
          setSectorRows([]);
          fetchRows();
        })().catch((error) => {
          throw new Error(error);
        });
      } catch (err: unknown) {
        const tracking_id: string = uuidv4();
        log(
          'error',
          new MetadataError(
            err instanceof Error
              ? err.message
              : 'Error: PanelSectors failed on an unknown error while initialising.',
            null,
            tracking_id
          )
        );
        setFeedbackData({
          message: `An error occurred. Tracking ID: ${tracking_id}`,
          state: true,
          type: 'error'
        });
      }
      return;
    },
    master_list ? [] : [group, company]
  );

  const createRow = async (name: string): Promise<void> => {
    try {
      const sector_data: SectorData = {
        deleted: null,
        name: name
      };
      const new_sector_writes: Array<BatchWrite> = await createSectors(!!master_list, [
        sector_data
      ]);
      if (new_sector_writes) {
        handleUpdateRows([
          ...new_sector_writes.map((new_sector_write: BatchWrite) => {
            return {
              id: new_sector_write.reference.id,
              deleted: new_sector_write.data.deleted,
              name: new_sector_write.data.name
            };
          }),
          ...sectorRows
        ]);
      }
    } catch (err: unknown) {
      const tracking_id: string = uuidv4();
      log(
        'error',
        new MetadataError(
          err instanceof Error
            ? err.message
            : 'Error: PanelSectors failed on an unknown error while calling createRow.',
          {
            name: name
          },
          tracking_id
        )
      );
      setFeedbackData({
        message: `Unable to create sectors. Tracking ID: ${tracking_id}`,
        state: true,
        type: 'error'
      });
    }
  };

  const updateRow = async (updated_row: Sector, original_data: Sector): Promise<void> => {
    try {
      await updateSector(!!master_list, updated_row, original_data, group?.id, company?.id).then(
        () => {
          handleUpdateRows(
            sectorRows.map((row: Sector) => (updated_row.id === row.id ? { ...updated_row } : row))
          );
        }
      );
    } catch (err: unknown) {
      const tracking_id: string = uuidv4();
      log(
        'error',
        new MetadataError(
          err instanceof Error
            ? err.message
            : 'Error: PanelSectors failed on an unknown error while calling updateRow.',
          {
            master_list: master_list,
            group: group,
            company: company,
            updated_row: updated_row,
            original_data: original_data
          },
          tracking_id
        )
      );
      setFeedbackData({
        message: `Unable to update sector. Tracking ID: ${tracking_id}`,
        state: true,
        type: 'error'
      });
    }
  };

  const deleteRow = async (sector: Sector): Promise<void> => {
    try {
      await deleteSector(!!master_list, sector.id, sector.name, group?.id, company?.id).then(() => {
        setSectorRows(
          sectorRows.filter((row: Sector) => {
            return row.id !== sector.id;
          })
        );
      });
    } catch (err: unknown) {
      const tracking_id: string = uuidv4();
      log(
        'error',
        new MetadataError(
          err instanceof Error
            ? err.message
            : 'Error: PanelSectors failed on an unknown error while calling deleteRow.',
          {
            group: group,
            company: company,
            sector: sector
          },
          tracking_id
        )
      );
      setFeedbackData({
        message: `Unable to remove sector. Tracking ID: ${tracking_id}`,
        state: true,
        type: 'error'
      });
    } finally {
      setOpenDialogDelete(false);
    }
  };

  return (
    <>
      {/* Delete Confirmation Modal */}
      <DeleteConfirmModal
        open={openDialogDelete}
        allow_delete={true}
        delete_warning="Any Metrics and Emission Factors related to this Sector will also be deleted"
        handleCloseDeleteModal={handleCloseDeleteModal}
        delete_label={actionRow && actionRow.name}
        handleDelete={actionRow && (() => deleteRow(actionRow))}
        allow_archive={false}
      />

      {/* Side Widget Panel */}
      <Drawer
        anchor={'right'}
        open={displayWidgetPanelRight}
        onClose={() => setDisplayWidgetPanelRight(false)}
        PaperProps={{ style: { width: '30%', padding: '1.5rem' } }}
      >
        {/* Create / Edit Widget */}
        {isCreateWidget ? (
          <>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                flexWrap: 'wrap'
              }}
            >
              <BusinessCenterOutlinedIcon sx={{ marginRight: '2rem' }} fontSize="large" />
              <Typography variant="h5" color="inherit" align="left">
                Add {sector_label.one}
              </Typography>
            </Box>
            {master_list && (
              <ConfigAddWidget
                create_label={sector_label.one}
                create_icon={
                  <BusinessCenterOutlinedIcon sx={{ marginRight: '2rem' }} fontSize="large" />
                }
                create_function_inputs={create_inputs}
                handleClose={() => setDisplayWidgetPanelRight(false)}
                createFunction={(name: string) => createRow(name)}
                hide_configured_entities={true}
                hide_title={true}
              />
            )}
          </>
        ) : (
          <ConfigEditWidget
            edit_label="Sector"
            edit_icon={<BusinessCenterOutlinedIcon sx={{ marginRight: '2rem' }} fontSize="large" />}
            handleClose={() => setDisplayWidgetPanelRight(false)}
            edit_entity={actionRow}
            edit_function_inputs={edit_inputs}
            handleEditInput={(input_id: string, value: unknown) =>
              actionRow && setActionRow({ ...actionRow, [input_id]: value })
            }
            confirmEditFunction={(updated_sector: Sector, original_sector: Sector) =>
              updateRow(updated_sector, original_sector)
            }
          />
        )}
      </Drawer>

      {/* Interactive Data Table */}
      <DataGrid
        autoHeight
        initialState={{
          pagination: {
            paginationModel: { pageSize: 10, page: 0 }
          },
          sorting: {
            sortModel: [{ field: 'modified', sort: 'desc' }]
          }
        }}
        hideFooter={sectorRows.length > 1 ? false : true}
        columnHeaderHeight={sectorRows.length < 1 ? 0 : 56}
        pageSizeOptions={[10, 25, 50, 100]}
        loading={gridLoading}
        rows={sectorRows}
        columns={columns}
        disableRowSelectionOnClick
        slots={{
          toolbar: PanelSectorsToolbar,
          noRowsOverlay: PanelSectorsNoRows,
          loadingOverlay: PanelSectorsLoading
        }}
        slotProps={{ toolbar: { handleCreate: handleCreateClick } }}
        sx={{ '&, [class^=MuiDataGrid]': { border: 'none' }, width: '100%' }}
      />
    </>
  );
};

export default PanelSectors;
