import {
  QuerySnapshot,
  QueryDocumentSnapshot,
  DocumentData,
  DocumentReference,
  doc,
  collection,
  runTransaction,
  Transaction,
  DocumentSnapshot
} from 'firebase/firestore';
import { EmissionFactor, EntityLabel, MetricGroup } from '@esg/esg-global-types';
import { uuidv4 } from '@firebase/util';
import { MetadataError } from '@ep/error-handling';
import { FirestoreQueryParam } from '../../@types/shared';
import { createFirestoreDoc, readFirestoreDocs, updateFirestoreDoc } from '../app/db_util';
import { validateMasterListParams } from '../../util/validation';
import { createAuditLog } from '../app/audit';
import { db } from '../google/firebase';
import { MetricExtended, getMetrics, refMetric } from './metric';
import { getEmissionFactorsForMetrics } from '../app/emission_factor';

export type MetricGroupData = Omit<MetricGroup, 'id'>;

// Singular and plural label for model entity.
export const metric_group_label: EntityLabel = {
  one: 'Metric Group',
  many: 'Metric Groups'
};

/**
 * Create Firestore database reference for either configured Metric Group or Master List Metric Group.
 * @param {string} id ID of object to reference.
 * @param {Group} group_id ID of configured group.
 * @param {string} company_id ID of configured company.
 * @returns {DocumentReference}
 */
export const refMetricGroup = (
  master_list: boolean,
  id: string,
  group_id?: string,
  company_id?: string
): DocumentReference =>
  doc(
    collection(
      db,
      !master_list && group_id && company_id
        ? `groups/${group_id}/companies/${company_id}/metric_groups`
        : 'metric_group_master_list'
    ),
    id
  );

/**
 * Create a collection path string for the master list or configured metric groups.
 * @param {boolean} master_list Reference the master list collection.
 * @param {string} group_id When not referencing a master list collection, a group id for the configured company is required.
 * @param {string} company_id When not referencing a master list collection, a company id for the configured company is required.
 * @returns {string} Path to a metric groups collection.
 */
export const createMetricGroupCollectionPath = (
  master_list: boolean,
  group_id?: string,
  company_id?: string
): string => {
  try {
    validateMasterListParams(master_list, group_id, company_id);
    return master_list
      ? `metric_group_master_list`
      : `groups/${group_id}/companies/${company_id}/metric_groups`;
  } catch (err: unknown) {
    const tracking_id: string = uuidv4();
    throw new MetadataError(
      err instanceof Error
        ? err.message
        : 'Error: getMetricGroupCollectionPath failed on an unknown error.',
      {
        master_list: master_list,
        group_id: group_id,
        company_id: company_id
      },
      tracking_id
    );
  }
};

/**
 * Creates a standardised map of an MetricGroup object from a Firestore snapshot, aiming to follow the DRY principle.
 * @param {QuerySnapshot} snapshot Query result from Firestore.
 * @param {boolean} master_list Conditionally include non-master list properties.
 * @returns {Promise<Array<MetricGroup>>} List of mapped metric group objects.
 */
export const mapMetricGroupFromSnapshot = async (
  snapshot: QuerySnapshot,
  master_list: boolean
): Promise<Array<MetricGroup>> => {
  try {
    const metric_groups: Array<MetricGroup> = snapshot.docs.map(
      (metric_group: QueryDocumentSnapshot) => {
        const metric_group_data: DocumentData = metric_group.data();
        return {
          ...{
            id: metric_group.id,
            deleted: metric_group_data.deleted,
            name: metric_group_data.name,
            description: metric_group_data.description
          },
          // Conditionally add non-master list properties.
          ...(!master_list && {
            master_list_metric_group: metric_group_data.master_list_metric_group
          })
        };
      }
    );
    return metric_groups;
  } catch (err: unknown) {
    const tracking_id: string = uuidv4();
    throw new MetadataError(
      err instanceof Error
        ? err.message
        : 'Error: mapMetricGroupFromSnapshot failed on an unknown error.',
      {
        snapshot: snapshot,
        master_list: master_list
      },
      tracking_id
    );
  }
};

/**
 * Query all metric group documents for current portal company joined with relative metric group data
 * @param {boolean} master_list Return master list or configured metric group documents.
 * @param {string} group_id Group object of current portal company
 * @param {string} company_id id of current portal company to reference firestore documents
 * @param {string} id query a specific Metric Group by id.
 * @returns {Promise<Array<MetricGroup>>}
 */
export const getMetricGroups = async (
  master_list: boolean,
  group_id?: string,
  company_id?: string,
  id?: string
): Promise<Array<MetricGroup>> => {
  try {
    const collection_path: string = createMetricGroupCollectionPath(
      master_list,
      group_id,
      company_id
    );
    const query_params: Array<FirestoreQueryParam> = [
      { field_name: 'deleted', operator: '==', value: null }
    ];
    if (id !== undefined && id.length > 0)
      query_params.push({ field_name: 'id', operator: '==', value: id });
    const metric_group_snapshot: QuerySnapshot = await readFirestoreDocs(
      collection_path,
      query_params
    );
    const metric_groups: Array<MetricGroup> = metric_group_snapshot.docs.map(
      (metric_group: QueryDocumentSnapshot) => {
        const metric_group_data: DocumentData = metric_group.data();
        return {
          ...{
            id: metric_group.id,
            deleted: metric_group_data.deleted,
            name: metric_group_data.name,
            description: metric_group_data.description
          },
          // Conditionally add non-master list properties.
          ...(!master_list && {
            master_list_metric_group: metric_group_data.master_list_metric_group
          })
        };
      }
    );
    return metric_groups;
  } catch (err: unknown) {
    const tracking_id: string = uuidv4();
    throw new MetadataError(
      err instanceof Error ? err.message : 'Error: getMetricGroups failed on an unknown error.',
      {
        master_list: master_list,
        group_id: group_id,
        company_id: company_id
      },
      tracking_id
    );
  }
};

/**
 * Query a metric group by name for either configured or master list metric groups.
 * @param {boolean} master_list Return master list or configured metric documents.
 * @param {string} metric_group_name Name of the metric's group.
 * @param {string} group_id When not referencing a master list document or collection, a group id for the configured company is required.
 * @param {string} company_id When not referencing a master list document or collection, a company id for the configured company is required.
 * @param {boolean} deleted Query only deleted or non-deleted metric groups. Defaults to both deleted and non-deleted metrics.
 * @returns {Promise<MetricGroup | undefined>} A single mapped metric group record.
 */
export const getMetricGroupByName = async (
  master_list: boolean,
  metric_group_name: string,
  group_id?: string,
  company_id?: string,
  deleted?: boolean
): Promise<MetricGroup | undefined> => {
  // Query metric group by name from Firestore.
  const collection_path: string = createMetricGroupCollectionPath(
    master_list,
    group_id,
    company_id
  );
  const query_params: Array<FirestoreQueryParam> = [
    { field_name: 'name', operator: '==', value: metric_group_name }
  ];
  // Conditionally query deleted or non-deleted metric groups from Firestore.
  if (deleted !== undefined)
    query_params.push({
      field_name: 'deleted',
      operator: deleted === true ? '!=' : '==',
      value: null
    });
  const metric_group_snapshot: QuerySnapshot = await readFirestoreDocs(
    collection_path,
    query_params
  );
  const metric_groups: Array<MetricGroup> = await mapMetricGroupFromSnapshot(
    metric_group_snapshot,
    master_list
  );
  if (metric_groups.length > 1)
    throw new Error(
      `Error: getMetricGroupByName: Found more than one metric group for ${metric_group_name}: ${JSON.stringify(metric_groups)}.`
    );
  const metric_group: MetricGroup = metric_groups[0];
  return metric_group;
};

/**
 * Create Metric Group with relative data
 * @param {boolean} master_list Flag to create new Metric Group within master list collection
 * @param {string | undefined} group_id Optional ID of Group which Metric Group is being created for
 * @param {string | undefined} company_id Optional ID of Company which Metric Group is being created for
 * @param {MetricGroupData} metric_group Data of new Metric Group to add to doc
 * @returns {DocumentReference}
 */
export const createMetricGroup = async (
  master_list: boolean,
  metric_group: MetricGroupData,
  group_id?: string,
  company_id?: string
): Promise<DocumentReference> => {
  const collection_path: string = createMetricGroupCollectionPath(
    master_list,
    group_id,
    company_id
  );
  try {
    const created_metric_group: DocumentReference = await createFirestoreDoc(
      collection_path,
      metric_group
    );
    if (!master_list && group_id && company_id) {
      await createAuditLog(group_id, 'create', '', metric_group.name, created_metric_group);
    }
    return created_metric_group;
  } catch (err: unknown) {
    const tracking_id: string = uuidv4();
    throw new MetadataError(
      err instanceof Error
        ? err.message
        : 'Error: lib/metric_capture/metric_group.ts failed on an unknown error while calling createMetricGroup.',
      {
        master_list: master_list,
        group_id: group_id,
        company_id: company_id,
        metric_group: metric_group
      },
      tracking_id
    );
  }
};

/**
 * Update Metric Group with relative data
 * @param {boolean} master_list Flag to update metric group in master list
 * @param {MetricGroup} updated_metric_group New metric group data to push into document
 * @param {MetricGroup} original_metric_group Old metric group data
 * @param {string} group_id Optional ID of Group to update metric group for
 * @param {string} company_id Optional ID of Company to update metric group for
 * @returns {void}
 */
export const updateMetricGroup = async (
  master_list: boolean,
  updated_metric_group: MetricGroup,
  original_metric_group: MetricGroup,
  group_id?: string,
  company_id?: string
): Promise<void> => {
  try {
    const collection_path = createMetricGroupCollectionPath(master_list, group_id, company_id);
    const metric_group_data: MetricGroupData = {
      deleted: updated_metric_group.deleted,
      name: updated_metric_group.name,
      description: updated_metric_group.description
    };
    const updated_metric_group_doc: DocumentReference = await updateFirestoreDoc(
      collection_path,
      updated_metric_group.id,
      metric_group_data
    );
    if (!master_list && group_id && company_id) {
      await createAuditLog(
        group_id,
        'update',
        JSON.stringify(original_metric_group),
        JSON.stringify(metric_group_data),
        updated_metric_group_doc
      );
    }
  } catch (err: unknown) {
    const tracking_id: string = uuidv4();
    throw new MetadataError(
      err instanceof Error
        ? err.message
        : 'Error: lib/metric_capture/metric_group.ts failed on an unknown error while calling updateMetricGroup.',
      {
        group_id: group_id,
        company_id: company_id,
        updated_metric_group: updated_metric_group,
        original_metric_group: original_metric_group
      },
      tracking_id
    );
  }
};

/**
 * Soft delete a Metric Group and any related Metrics and Emission Factors in Firestore transaction
 * @param {boolean} master_list Flag to delete entities from their respective master lists
 * @param {string | undefined} group_id Optional Group to delete metric group from
 * @param {string | undefined} company_id Optional Company to delete metric group from
 * @param {string} metric_group_id ID of metric group to delete
 * @param {string} metric_group_name Name of metric group being deleted
 * @return {void}
 */
export const deleteMetricGroupWithMetrics = async (
  master_list: boolean,
  metric_group_id: string,
  group_id?: string,
  company_id?: string
): Promise<void> => {
  try {
    const metric_group_doc: DocumentReference = refMetricGroup(
      master_list,
      metric_group_id,
      group_id,
      company_id
    );
    const metrics: Array<MetricExtended> = await getMetrics(
      master_list,
      group_id,
      company_id,
      undefined,
      undefined,
      [metric_group_id]
    );
    const emission_factors: Array<EmissionFactor> = await getEmissionFactorsForMetrics(
      master_list,
      metrics,
      group_id,
      company_id
    );

    await runTransaction(db, async (transaction: Transaction) => {
      const metric_group_snapshot: DocumentSnapshot = await transaction.get(metric_group_doc);
      if (!metric_group_snapshot.exists()) {
        throw 'Could not find Metric Group document to delete';
      }
      transaction.update(metric_group_doc, { deleted: new Date() });
      metrics.forEach((metric: MetricExtended) => {
        transaction.update(
          refMetric(master_list, metric.id, metric.metric_group.id, group_id, company_id),
          {
            deleted: new Date()
          }
        );
      });
      emission_factors.forEach((emission_factor: EmissionFactor) => {
        emission_factor.ref && transaction.update(emission_factor.ref, { deleted: new Date() });
      });
    });
  } catch (err: unknown) {
    const tracking_id: string = uuidv4();
    throw new MetadataError(
      err instanceof Error
        ? err.message
        : 'Error: lib/metric_capture/metric_group.ts failed on an unknown error while calling deleteMetricGroupWithMetrics.',
      {
        group_id: group_id,
        company_id: company_id,
        metric_group_id: metric_group_id
      },
      tracking_id
    );
  }
};
