import React from 'react';
import {
  Autocomplete,
  AutocompleteRenderGroupParams,
  AutocompleteRenderInputParams,
  Box,
  Checkbox,
  InputAdornment,
  SvgIconProps,
  TextField
} from '@mui/material';
import { Standard } from '@esg/esg-global-types';
import {
  getStandards,
  getStandardsMasterList,
  getUnconfiguredStandards,
  standard_label
} from '../../../../lib/app/standard';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

interface StandardSelectProps {
  handleChangeStandards?: (standards: Array<Standard> | Standard | null) => void;
  class_name?: string;
  options?: Array<Standard>;
  selected_options?: Array<Standard> | Standard | null;
  disabled?: boolean;
  input_label?: string;
  group_by?: 'version' | 'category';
  multi_select?: boolean;
  tag_limit?: number;
  group_id?: string;
  company_id?: string;
  unconfigured_only?: boolean;
  is_quantitative?: boolean;
  master_list?: boolean;
  start_adornment?: React.ReactElement<SvgIconProps>;
  auto_select?: boolean;
  onClick?: () => void;
}

// Checkbox icons
const check_icon_blank = <CheckBoxOutlineBlankIcon fontSize="small" />;
const check_icon_filled = <CheckBoxIcon fontSize="small" />;

/**
 * Standard selection auto-complete component that handles a variety of built-in scenarios.
 * @param {Array<Standard> | Standard | null} props.handleChangeMetrics Callback function to notify the parent component when the selected options have changed.
 * @param {Array<Standard>} props.options Custom list of options provided by the parent component. Defaults to none.
 * @param {Array<Standard> | Standard | null} props.selected_options Custom list of selected options provided by the parent component. Changes component from an uncontrolled to controlled.
 * @param {boolean} props.disabled Renders the component in a disabled state along with any other provided components. Defaults to enabled.
 * @param {string} props.input_label Sets a custom component input label. Defaults to the singular version of the main entity name.
 * @param {string} props.group_by Group the options by any one of a variety of grouping options. Defaults to ungrouped.
 * @param {boolean} props.multi_select Allow multi option selection and keeps the select open while selecting. Defaults to a single select and closes after selecting an option.
 * @param {number} props.tag_limit Sets a limit on the amount of tags rendered in the input. Defaults to unlimited.
 * @param {string} props.group_id Query configured standards for the provided group. Works in conjunction with props.company_id. Optionally works in conjunction with unconfigured_only. Defaults to empty list.
 * @param {string} props.company_id Query configured standards for the provided company. Works in conjunction with props.group_id. Optionally works in conjunction with unconfigured_only. Defaults to empty list.
 * @param {boolean} props.unconfigured_only Query only unconfigured standards for the provided group_id and company_id. Defaults to false.
 * @param {boolean} props.master_list Forcefully query standards from the master list. Does not require group_id and company_id. Defaults to false.
 * @param {React.ReactElement<SvgIconProps>} props.start_adornment Set an input icon for the autocomplete.
 * @param {boolean} props.auto_select Pre-populate the autocomplete input when only one standard is available.
 */
const StandardSelect = (props: StandardSelectProps) => {
  const [optionList, setOptions] = React.useState<Array<Standard>>([]);
  const [selectedOptions, setSelectedOptions] = React.useState<Array<Standard> | Standard | null>(
    props.multi_select ? [] : null
  );

  // Query validations
  const get_unconfigured_standards: boolean =
    props.options === undefined &&
    !props.master_list &&
    props.group_id &&
    props.company_id &&
    props.unconfigured_only
      ? true
      : false;

  const get_configured_standards: boolean =
    props.options === undefined && !props.master_list && props.group_id && props.company_id
      ? true
      : false;

  // Define autocomplete options
  React.useEffect(() => {
    (async () => {
      const standards: Array<Standard> = props.options
        ? props.options
        : props.master_list
          ? await getStandardsMasterList(props.is_quantitative)
          : get_unconfigured_standards
            ? await getUnconfiguredStandards(
                props.group_id || '',
                props.company_id || '',
                props.is_quantitative
              )
            : get_configured_standards
              ? await getStandards(
                  props.group_id || '',
                  props.company_id || '',
                  props.is_quantitative
                )
              : [];
      if (standards) {
        setOptions(standards);
      }
      // When auto-select is true, automatically populate the autocomplete when only one standard is present.
      if (props.auto_select && standards.length === 1) {
        setSelectedOptions(standards[0]);
        props.handleChangeStandards && props.handleChangeStandards(standards[0]);
      }
    })();
  }, [props.group_id, props.company_id]);

  // Define selected options
  React.useEffect(() => {
    setSelectedOptions(
      props.multi_select
        ? Array.isArray(props.selected_options)
          ? props.selected_options
          : []
        : props.selected_options
          ? props.selected_options
          : null
    );
  }, [props.options]);

  // Group selection
  const selected_options_array: Array<Standard> =
    selectedOptions && !Array.isArray(selectedOptions)
      ? [selectedOptions]
      : selectedOptions && Array.isArray(selectedOptions)
        ? selectedOptions
        : [];

  const checkGroup = (group_key: string): boolean => {
    const group_length: number = optionList.filter(
      (standard: Standard) => (props.group_by ? standard[props.group_by] : '') === group_key
    ).length;
    const selected_group_length: number = selected_options_array.filter(
      (standard: Standard) => (props.group_by ? standard[props.group_by] : '') === group_key
    ).length;
    return group_length === selected_group_length;
  };

  const changeGroup = (group_key: string): void => {
    const grouped_options: Array<Standard> = optionList.filter(
      (standard: Standard) => (props.group_by ? standard[props.group_by] : '') === group_key
    );
    const selected_group_options: Array<Standard> = selected_options_array.filter(
      (standard: Standard) => (props.group_by ? standard[props.group_by] : '') === group_key
    );
    if (selected_group_options.length > 0) {
      const filtered_options: Array<Standard> = (
        Array.isArray(selectedOptions) ? selectedOptions : []
      ).filter(
        (standard: Standard) => (props.group_by ? standard[props.group_by] : '') !== group_key
      );
      setSelectedOptions(filtered_options);
      props.handleChangeStandards && props.handleChangeStandards(filtered_options);
    } else {
      setSelectedOptions((prev_state: Array<Standard> | Standard | null) => [
        ...(Array.isArray(prev_state) ? prev_state : []),
        ...grouped_options
      ]);
      props.handleChangeStandards && props.handleChangeStandards(grouped_options);
    }
  };

  return (
    <Box id="standard-select" onClick={props.onClick}>
      <Autocomplete
        fullWidth
        disabled={props.disabled}
        limitTags={props.tag_limit}
        options={optionList}
        groupBy={
          props.group_by
            ? (option: Standard) => (props.group_by ? option[props.group_by] : '')
            : undefined
        }
        value={selectedOptions}
        getOptionLabel={(option: Standard) => {
          return option.name;
        }}
        disableCloseOnSelect={props.multi_select}
        isOptionEqualToValue={(option: Standard, value: Standard) => option.id === value.id}
        multiple={props.multi_select}
        noOptionsText={`No ${standard_label.many.toLowerCase()}`}
        renderOption={(render_props, option: Standard, { selected }) => (
          <Box
            component="li"
            sx={{ display: 'flex', alignItems: 'center' }}
            {...render_props}
            key={option.id}
          >
            {props.multi_select && (
              <Checkbox
                icon={check_icon_blank}
                checkedIcon={check_icon_filled}
                style={{ marginRight: 8 }}
                checked={selected}
              />
            )}
            {option.name}
          </Box>
        )}
        renderGroup={
          props.multi_select
            ? (params: AutocompleteRenderGroupParams) => (
                <Box component="div" key={params.key}>
                  <Box
                    component="li"
                    style={{
                      fontSize: '0.85rem',
                      color: '#616161',
                      fontWeight: 500
                    }}
                  >
                    <Checkbox
                      icon={check_icon_blank}
                      checkedIcon={check_icon_filled}
                      checked={checkGroup(params.group)}
                      onChange={() => changeGroup(params.group)}
                    />
                    {params.group}
                  </Box>
                  <span>{params.children}</span>
                </Box>
              )
            : undefined
        }
        renderInput={(params: AutocompleteRenderInputParams) => (
          <TextField
            {...params}
            label={props.input_label ?? standard_label.one}
            variant="standard"
            InputProps={{
              ...params.InputProps,
              startAdornment: props.start_adornment ? (
                <>
                  <InputAdornment position="start">{props.start_adornment}</InputAdornment>
                  {params.InputProps.startAdornment}
                </>
              ) : (
                <>{params.InputProps.startAdornment}</>
              )
            }}
          />
        )}
        onChange={(
          event: React.SyntheticEvent<Element, Event>,
          value: Array<Standard> | Standard | null
        ) => {
          setSelectedOptions(value);
          props.handleChangeStandards && props.handleChangeStandards(value);
        }}
      />
    </Box>
  );
};

export default StandardSelect;
