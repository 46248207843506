import React from 'react';
import { Tooltip, Drawer, Box, Typography } from '@mui/material';
import { DataGrid, GridColDef, GridActionsCellItem, GridValueGetterParams } from '@mui/x-data-grid';
import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import DeleteConfirmModal from '../../shared/modal/DeleteConfirmModal';
import { FeedbackSnackbarContext } from '../../../context/FeedbackSnackbarContext';
import { MetadataError } from '@ep/error-handling';
import { uuidv4 } from '@firebase/util';
import { log } from '../../../util/log';
import { PanelMetricsLoading } from './PanelMetricsLoading';
import { Company, EmissionFactor, Group, MetricGroup, Standard } from '@esg/esg-global-types';
import { getStandards, getStandardsMasterList } from '../../../lib/app/standard';
import { PanelMetricsToolbar } from './PanelMetricsToolbar';
import { PanelMetricsNoRows } from './PanelMetricsNoRows';
import { GroupContext } from '../../../context/GroupContext';
import { CompanyContext } from '../../../context/CompanyContext';
import {
  getMetrics,
  MetricExtended,
  createMetrics,
  getUnconfiguredMetrics,
  metric_label,
  deleteMetricWithEmissionFactors,
  allowDeleteMetric,
  getMetricByName,
  undeleteMetricWithEmissionFactors,
  MetricData,
  updateMetric
} from '../../../lib/metric_capture/metric';
import { DocumentReference, getDoc } from 'firebase/firestore';
import StandardSelect from '../../shared/input/select/StandardSelect';
import ConfigAddWidget, { CreateInput } from '../ConfigAddWidget';
import { PanelTabs, PanelTabsWrapper } from '../../shared/tab/PanelTabsWrapper';
import MetricSelect from '../../shared/input/select/MetricSelect';
import IncludeLatestEmissionFactorCheckbox from '../../shared/input/checkbox/IncludeEmissionFactorCheckbox';
import ConfigEditWidget, { EditInput } from '../ConfigEditWidget';
import MetricGroupSelect from '../../shared/input/select/MetricGroupSelect';
import { BatchWrite } from '../../../lib/app/db_util';

export interface MetricGridRow extends MetricExtended {
  standard: Standard;
}

interface PanelMetricGroupsProps {
  master_list?: boolean;
}

/**
 * Datagrid handling the display of configured or Master List Metrics with relative Standard data
 * @returns {JSX.Element}
 */
const PanelMetrics = ({ master_list }: PanelMetricGroupsProps) => {
  const { setFeedbackData } = React.useContext(FeedbackSnackbarContext);
  const group: Group | null = React.useContext(GroupContext);
  const company: Company | null = React.useContext(CompanyContext);
  const [displayWidgetPanelRight, setDisplayWidgetPanelRight] = React.useState<boolean>(false);
  const [isCreateWidget, setIsCreateWidget] = React.useState<boolean>(true);
  const [gridLoading, setGridLoading] = React.useState(true);
  const [loadingUnconfiguredMetrics, setloadingUnconfiguredMetrics] = React.useState<boolean>(true);
  const [metricRows, setMetricRows] = React.useState<Array<MetricGridRow>>([]);
  const [actionRow, setActionRow] = React.useState<MetricGridRow | null>(null);
  const [showDeleteModal, setShowDeleteModal] = React.useState(false);
  const [allowMetricDelete, setAllowMetricDelete] = React.useState<boolean | null>(null);
  const [unconfiguredMetrics, setUnconfiguredMetrics] = React.useState<Array<MetricExtended>>([]);
  const [configuredStandards, setConfiguredStandards] = React.useState<Array<Standard>>([]);
  const [selectedConfiguredStandards, setSelectedConfiguredStandards] = React.useState<
    Array<Standard>
  >([]);
  const [selectedMasterListStandard, setSelectedMasterListStandard] =
    React.useState<Standard | null>(null);
  const [selectedMetrics, setSelectedMetrics] = React.useState<Array<MetricExtended>>([]);
  const [selectedEmissionFactors, setSelectedEmissionFactors] = React.useState<
    Array<EmissionFactor>
  >([]);
  const [filteredUnconfiguredMetrics, setFilteredUnconfiguredMetrics] = React.useState<
    Array<MetricExtended>
  >([]);

  const fetchMetricsForStandards = async (): Promise<void> => {
    if (group && company) {
      try {
        setloadingUnconfiguredMetrics(true);
        const unconfigured_metrics: Array<MetricExtended> = await getUnconfiguredMetrics(
          group?.id,
          company?.id,
          undefined,
          true
        );
        setUnconfiguredMetrics(unconfigured_metrics);
        setloadingUnconfiguredMetrics(false);
      } catch (err: unknown) {
        const tracking_id: string = uuidv4();
        throw new MetadataError(
          err instanceof Error
            ? err.message
            : 'Error: PanelMetrics failed on an unknown error while calling fetchMetricsForStandards.',
          {
            group: group,
            company: company
          },
          tracking_id
        );
      }
    }
  };

  React.useEffect(() => {
    try {
      setUnconfiguredMetrics([]);
      if (group && company) {
        (async () => {
          const company_configured_standards: Array<Standard> = await getStandards(
            group?.id,
            company.id
          );
          await Promise.all(
            company_configured_standards.map(async (standard) => {
              const document_reference = await getDoc(
                standard.master_list_standard as DocumentReference
              );
              standard.id = document_reference.id;
            })
          );
          setConfiguredStandards(company_configured_standards); //This is master level standards
          await fetchMetricsForStandards();
        })();
      }
    } catch (err: unknown) {
      const tracking_id: string = uuidv4();
      throw new MetadataError(
        err instanceof Error
          ? err.message
          : 'Error: PanelMetrics failed on an unknown error while initializing unconfiguredMetrics.',
        {
          group: group,
          company: company
        },
        tracking_id
      );
    }
  }, [group, company]);

  React.useEffect(() => {
    if (selectedConfiguredStandards.length > 0) {
      const selected_standard_ids = selectedConfiguredStandards.map(
        (standard: Standard) => standard.id
      );
      setFilteredUnconfiguredMetrics(
        unconfiguredMetrics
          .filter((metric: MetricExtended) => selected_standard_ids.includes(metric.standard.id))
          .sort((metric_a: MetricExtended, metric_b: MetricExtended) => {
            const standard_name_a: string = (metric_a.standard as Standard).name;
            const standard_name_b: string = (metric_b.standard as Standard).name;
            return standard_name_a < standard_name_b
              ? -1
              : standard_name_b > standard_name_a
                ? 1
                : 0;
          })
      );
    } else {
      setFilteredUnconfiguredMetrics([]);
    }
  }, [selectedConfiguredStandards, unconfiguredMetrics]);

  const standardsInputFactory = (
    handleInputChange: (input_id: string, value: Standard | Array<Standard> | null) => void
  ): JSX.Element => {
    return (
      <StandardSelect
        master_list
        options={master_list ? undefined : configuredStandards}
        multi_select={master_list ? false : true}
        handleChangeStandards={(standards: Array<Standard> | Standard | null) => {
          if (master_list && !Array.isArray(standards)) {
            handleInputChange('standard', standards);
            setSelectedMasterListStandard(standards);
          } else {
            setSelectedConfiguredStandards(standards as Array<Standard>);
            if ((standards as Array<Standard>).length < 1) handleInputChange('metrics', null);
          }
        }}
      />
    );
  };

  const metricGroupsInputFactory = (
    handleInputChange: (input_id: string, value: MetricGroup | Array<MetricGroup> | null) => void
  ): JSX.Element => {
    return (
      <MetricGroupSelect
        master_list
        handleChangeMetricGroups={(metric_groups: Array<MetricGroup> | MetricGroup | null) => {
          handleInputChange('metric_group', metric_groups);
        }}
      />
    );
  };

  const metricInputFactory = (
    handleInputChange: (
      input_id: string,
      value: MetricExtended | Array<MetricExtended> | null
    ) => void
  ): JSX.Element => {
    return (
      <MetricSelect
        options={filteredUnconfiguredMetrics}
        tag_limit={5}
        multi_select={true}
        group_by="standard.name"
        handleChangeMetrics={(metrics) => {
          setSelectedMetrics(metrics as Array<MetricExtended>);
          handleInputChange('metrics', metrics);
        }}
        loading={loadingUnconfiguredMetrics}
      />
    );
  };

  const emissionFactorInputFactory = (
    handleInputChange: (input_id: string, value: Array<EmissionFactor> | null) => void
  ): JSX.Element => {
    if (
      selectedConfiguredStandards.some(
        (standard: Standard) => standard.require_emission_factor === true
      )
    ) {
      return (
        <IncludeLatestEmissionFactorCheckbox
          metrics={selectedMetrics}
          handleChange={(emission_factors) => {
            setSelectedEmissionFactors(emission_factors as Array<EmissionFactor>);
            handleInputChange('emisson_factors', emission_factors);
          }}
        />
      );
    }
    return <></>;
  };

  // Handler functions
  const handleCreateClick = (): void => {
    setActionRow(null);
    setSelectedMasterListStandard(null);
    setIsCreateWidget(true);
    setDisplayWidgetPanelRight(true);
  };

  const handleEditClick = (metric: MetricGridRow): void => {
    setActionRow(metric);
    setIsCreateWidget(false);
    setSelectedMasterListStandard(metric.standard);
    setDisplayWidgetPanelRight(true);
  };

  const handleUpdateRows = (rows_data: Array<MetricGridRow>): void => {
    setMetricRows(rows_data);
  };

  const handleDeleteClick = async (metric_row: MetricGridRow): Promise<void> => {
    try {
      if (group && company) {
        setActionRow(metric_row);
        setAllowMetricDelete(
          await allowDeleteMetric(group.id, company.id, metric_row.id, metric_row.metric_group.id)
        );
        setShowDeleteModal(true);
      }
    } catch (err: unknown) {
      const tracking_id: string = uuidv4();
      log(
        'error',
        new MetadataError(
          err instanceof Error
            ? err.message
            : 'Error: PanelMetrics failed on an unknown error while calling handleClickClick.',
          {
            group: group,
            company: company,
            'metric_row.id': metric_row.id,
            metric_group_id: metric_row.metric_group.id
          },
          tracking_id
        )
      );
      setFeedbackData({
        message: `Unable to delete metric. Tracking ID: ${tracking_id}`,
        state: true,
        type: 'error'
      });
    }
  };

  const handleCloseDeleteModal = (): void => {
    setShowDeleteModal(false);
  };

  // Row functions
  const fetchRows = async (): Promise<void> => {
    setGridLoading(true);
    try {
      if (group && company) {
        // Load rows from database.
        const [metrics, standards]: [Array<MetricExtended>, Array<Standard>] = await Promise.all(
          master_list
            ? [getMetrics(true), getStandardsMasterList()]
            : [getMetrics(false, group.id, company.id), getStandards(group.id, company.id)]
        ).catch((err: unknown) => {
          throw new Error(
            err instanceof Error
              ? err.message
              : 'Error: PanelMetrics failed on an unknown error while calling fetchRows.'
          );
        });
        const metric_rows: Array<MetricGridRow> = [];
        metrics.forEach((metric: MetricExtended) => {
          const standard: Standard | undefined = standards.find(
            (standard: Standard) => standard.id === metric.standard.id
          );
          if (standard) {
            metric_rows.push({
              ...metric,
              standard: standard
            });
          }
        });
        // Load rows to memory.
        setMetricRows(metric_rows);
      }
    } catch (err: unknown) {
      const tracking_id: string = uuidv4();
      log(
        'error',
        new MetadataError(
          err instanceof Error
            ? err.message
            : 'Error: PanelMetrics failed on an unknown error while calling fetchRows.',
          {
            group: group,
            company: company
          },
          tracking_id
        )
      );
      setFeedbackData({
        message: `Unable to fetch metrics. Tracking ID: ${tracking_id}`,
        state: true,
        type: 'error'
      });
    } finally {
      setGridLoading(false);
    }
  };

  const createRowsFromMasterList = async (): Promise<void> => {
    try {
      if (group && company) {
        // Check whether each of the selected metrics have been previously deleted, then undelete the metrics and its emission factors accordingly.
        const metrics_create: Array<MetricExtended> = [];
        const metric_promises: Array<Promise<void>> = selectedMetrics.map(
          async (metric: MetricExtended) => {
            const metric_delete: MetricExtended | undefined = await getMetricByName(
              false,
              metric.metric_group.name,
              metric.name,
              group?.id,
              company?.id,
              true
            );
            if (metric_delete) {
              const matched_emission_factor: EmissionFactor | undefined =
                selectedEmissionFactors.find(
                  (emission_factor: EmissionFactor) => emission_factor.metric.id === metric.id
                );
              await undeleteMetricWithEmissionFactors(
                group?.id ? group.id : '',
                company?.id ? company.id : '',
                metric_delete.id,
                metric_delete.metric_group.id,
                matched_emission_factor
              );
            } else {
              metrics_create.push(metric);
            }
          }
        );
        await Promise.all(metric_promises);
        // Create new metrics and emission factors then update the UI accordingly.
        selectedEmissionFactors.length > 0
          ? await createMetrics(
              !!master_list,
              metrics_create,
              selectedEmissionFactors,
              group.id,
              company.id
            )
          : await createMetrics(!!master_list, metrics_create, undefined, group.id, company.id);
        setUnconfiguredMetrics(
          unconfiguredMetrics.filter((metric: MetricExtended) => !selectedMetrics.includes(metric))
        );
        setSelectedConfiguredStandards([]);
        fetchRows();
      }
    } catch (err: unknown) {
      const tracking_id: string = uuidv4();
      throw new MetadataError(
        err instanceof Error
          ? err.message
          : 'Error: PanelMetrics failed on an unknown error while calling createRowsFromMasterList.',
        {
          group: group,
          company: company
        },
        tracking_id
      );
    }
  };

  const createRow = async (
    metric_group: MetricGroup,
    name: string,
    standard: Standard,
    description: string,
    scope?: number,
    unit?: string
  ): Promise<void> => {
    try {
      const metric_data: MetricData = {
        deleted: null,
        name: name,
        description: description,
        scope: standard.require_emission_factor && scope ? Number(scope) : null,
        unit: standard.is_quantitative && unit ? unit : null,
        standard: standard,
        metric_group: metric_group
      };
      const new_metric_writes: Array<BatchWrite> = await createMetrics(!!master_list, [
        metric_data
      ]);
      if (new_metric_writes) {
        handleUpdateRows([
          ...new_metric_writes.map((new_metric_write: BatchWrite) => {
            return {
              id: new_metric_write.reference.id,
              metric_group: metric_group,
              ...metric_data,
              standard: standard
            };
          }),
          ...metricRows
        ]);
      }
    } catch (err: unknown) {
      const tracking_id: string = uuidv4();
      log(
        'error',
        new MetadataError(
          err instanceof Error
            ? err.message
            : 'Error: PanelMetrics failed on an unknown error while calling createRow.',
          {
            metric_group: metric_group,
            name: name,
            standard: standard,
            description: description,
            scope: scope,
            unit: unit
          },
          tracking_id
        )
      );
      setFeedbackData({
        message: `Unable to create metrics. Tracking ID: ${tracking_id}`,
        state: true,
        type: 'error'
      });
    }
  };

  const updateRow = async (
    updated_row: MetricGridRow,
    original_data: MetricGridRow
  ): Promise<void> => {
    try {
      await updateMetric(!!master_list, updated_row, original_data, group?.id, company?.id).then(
        () => {
          handleUpdateRows(
            metricRows.map((row: MetricGridRow) =>
              updated_row.id === row.id ? { ...updated_row } : row
            )
          );
        }
      );
    } catch (err: unknown) {
      const tracking_id: string = uuidv4();
      log(
        'error',
        new MetadataError(
          err instanceof Error
            ? err.message
            : 'Error: PanelMetrics failed on an unknown error while calling updateRow.',
          {
            master_list: master_list,
            group: group,
            company: company,
            updated_row: updated_row,
            original_data: original_data
          },
          tracking_id
        )
      );
      setFeedbackData({
        message: `Unable to update metric. Tracking ID: ${tracking_id}`,
        state: true,
        type: 'error'
      });
    }
  };

  const deleteRow = async (row: MetricGridRow): Promise<void> => {
    try {
      if (group && company) {
        // Soft delete Metric database record.
        await deleteMetricWithEmissionFactors(
          !!master_list,
          row.id,
          row.metric_group.id,
          row.name,
          group.id,
          company.id
        ).catch((err: unknown) => {
          throw new Error(
            err instanceof Error
              ? err.message
              : 'Error: PanelMetrics failed on an unknown error while calling deleteRow.'
          );
        });
        // Remove from memory.
        setMetricRows(metricRows.filter((obj) => obj.id !== row.id));
        fetchMetricsForStandards();
      }
    } catch (err: unknown) {
      const tracking_id: string = uuidv4();
      log(
        'error',
        new MetadataError(
          err instanceof Error
            ? err.message
            : 'Error: PanelMetrics failed on an unknown error while calling deleteRow.',
          {
            group: group,
            company: company,
            row: row
          },
          tracking_id
        )
      );
      setFeedbackData({
        message: `Unable to delete metric. Tracking ID: ${tracking_id}`,
        state: true,
        type: 'error'
      });
    }
  };

  React.useEffect(
    () => {
      try {
        (async () => {
          setMetricRows([]);
          fetchRows();
        })().catch((error) => {
          throw new Error(error);
        });
      } catch (err: unknown) {
        const tracking_id: string = uuidv4();
        log(
          'error',
          new MetadataError(
            err instanceof Error
              ? err.message
              : 'Error: PanelMetrics failed on an unknown error while calling fetchRows.',
            null,
            tracking_id
          )
        );
        setFeedbackData({
          message: `An error occurred. Tracking ID: ${tracking_id}`,
          state: true,
          type: 'error'
        });
      }
      return;
    },
    master_list ? [] : [group, company]
  );

  const columns: GridColDef[] = [
    {
      field: 'standard_name',
      headerName: 'Standard',
      headerAlign: 'left',
      align: 'left',
      valueGetter: (params: GridValueGetterParams) => {
        return params.row.standard.name;
      },
      flex: 1
    },
    {
      field: 'standard_is_quantitative',
      headerName: 'Type',
      headerAlign: 'left',
      align: 'left',
      valueGetter: (params: GridValueGetterParams) => {
        return params.row.standard.is_quantitative ? 'Quantitative' : 'Qualitative';
      },
      flex: 1
    },
    {
      field: 'group_name',
      headerName: 'Category',
      headerAlign: 'left',
      align: 'left',
      valueGetter: (params: GridValueGetterParams) => {
        return params.row.metric_group.name;
      },
      flex: 1
    },
    {
      field: 'name',
      headerName: 'Name',
      headerAlign: 'left',
      align: 'left',
      flex: 1
    },
    {
      field: 'unit',
      headerName: 'Unit',
      headerAlign: 'left',
      align: 'left',
      valueGetter: (params: GridValueGetterParams) => {
        return params.row.standard.is_quantitative ? params.value : 'Text';
      },
      flex: 1
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: '',
      headerAlign: 'right',
      align: 'right',
      hideable: false,
      flex: 1,
      getActions: ({ row }) => {
        return [
          <>
            {master_list && (
              <GridActionsCellItem
                key={1}
                icon={
                  <Tooltip title="Edit Metric">
                    <EditIcon color={!row.locked ? 'primary' : 'action'} />
                  </Tooltip>
                }
                size="large"
                label="Edit"
                sx={{
                  color: 'primary.main'
                }}
                onClick={() => handleEditClick(row)}
                disabled={row.locked}
              />
            )}
            <GridActionsCellItem
              key={2}
              icon={
                <Tooltip title="Delete metric">
                  <DeleteIcon color="primary" />
                </Tooltip>
              }
              size="large"
              label="Delete"
              sx={{
                color: 'primary.main'
              }}
              onClick={() => handleDeleteClick(row)}
            />
          </>
        ];
      }
    }
  ];

  const create_inputs: CreateInput[] = master_list
    ? [
        {
          id: 'standard',
          type: 'node',
          label: 'Standard',
          inputNodeFactory: standardsInputFactory
        },
        {
          id: 'metric_group',
          type: 'node',
          label: 'Metric Group',
          inputNodeFactory: metricGroupsInputFactory
        },
        {
          id: 'name',
          type: 'text',
          label: 'Name'
        },
        {
          id: 'description',
          type: 'text',
          label: 'Description'
        },
        {
          id: 'scope',
          type: 'number',
          label: 'Scope',
          optional: true,
          hideInput: selectedMasterListStandard?.require_emission_factor !== true
        },
        {
          id: 'unit',
          type: 'text',
          label: 'Unit',
          optional: true,
          hideInput: selectedMasterListStandard?.is_quantitative !== true
        }
      ]
    : [
        {
          id: 'standards',
          type: 'node',
          label: 'Standards',
          inputNodeFactory: standardsInputFactory,
          optional: true
        },
        {
          id: 'metrics',
          type: 'node',
          label: 'Metrics',
          inputNodeFactory: metricInputFactory
        },
        {
          id: 'emission_factors',
          type: 'node',
          label: 'Emission Factors',
          inputNodeFactory: emissionFactorInputFactory,
          optional: true
        }
      ];

  const edit_inputs: EditInput[] = master_list
    ? [
        {
          id: 'name',
          type: 'text',
          label: 'Name'
        },
        {
          id: 'description',
          type: 'text',
          label: 'Description'
        },
        {
          id: 'scope',
          type: 'number',
          label: 'Scope',
          hideInput: selectedMasterListStandard?.require_emission_factor !== true
        },
        {
          id: 'unit',
          type: 'text',
          label: 'Unit',
          hideInput: selectedMasterListStandard?.is_quantitative !== true
        }
      ]
    : [];

  return (
    <>
      {/* Delete Confirmation Modal */}
      <DeleteConfirmModal
        allow_delete={allowMetricDelete}
        allow_archive={false}
        open={showDeleteModal}
        handleCloseDeleteModal={handleCloseDeleteModal}
        item_type={'Metric'}
        delete_warning={
          'You will be required to create this Metric again if deleted. All Emission Factors configured for this Metric will also be deleted'
        }
        delete_label={actionRow && `${actionRow.metric_group.name}: ${actionRow.name}`}
        handleDelete={actionRow && (() => deleteRow(actionRow))}
      />

      {/* Side Widget Panel */}
      <React.Fragment key={'right'}>
        <Drawer
          anchor={'right'}
          open={displayWidgetPanelRight}
          onClose={() => {
            setDisplayWidgetPanelRight(false);
            setSelectedConfiguredStandards([]);
            setSelectedMetrics([]);
          }}
          PaperProps={{ style: { width: '40%', padding: '1.5rem' } }}
        >
          {/* Create / Edit Widget */}
          {isCreateWidget ? (
            <>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  flexWrap: 'wrap'
                }}
              >
                <AssessmentOutlinedIcon sx={{ marginRight: '2rem' }} fontSize="large" />
                <Typography variant="h5" color="inherit" align="left">
                  Add {metric_label.many}
                </Typography>
              </Box>
              {master_list ? (
                <ConfigAddWidget
                  create_label={metric_label.one}
                  create_icon={
                    <AssessmentOutlinedIcon sx={{ marginRight: '2rem' }} fontSize="large" />
                  }
                  create_function_inputs={create_inputs}
                  handleClose={() => setDisplayWidgetPanelRight(false)}
                  createFunction={(
                    standard: Standard,
                    metric_group: MetricGroup,
                    name: string,
                    description: string,
                    scope?: number,
                    unit?: string
                  ) => createRow(metric_group, name, standard, description, scope, unit)}
                  hide_configured_entities={true}
                  hide_title={true}
                />
              ) : (
                <PanelTabsWrapper>
                  <PanelTabs label="Generic" disabled={false}>
                    <ConfigAddWidget
                      create_label={'Metrics'}
                      create_icon={
                        <AssessmentOutlinedIcon sx={{ marginRight: '2rem' }} fontSize="large" />
                      }
                      create_function_inputs={create_inputs}
                      grid_spacing={3}
                      handleClose={() => setDisplayWidgetPanelRight(false)}
                      createFunction={() => createRowsFromMasterList()}
                      hide_configured_entities={true}
                      hide_title={true}
                    />
                  </PanelTabs>
                  <PanelTabs label={'Custom'} disabled={true} />
                </PanelTabsWrapper>
              )}
            </>
          ) : (
            <ConfigEditWidget
              edit_label="Metric"
              edit_icon={<AssessmentOutlinedIcon sx={{ marginRight: '2rem' }} fontSize="large" />}
              handleClose={() => setDisplayWidgetPanelRight(false)}
              edit_entity={actionRow}
              edit_function_inputs={edit_inputs}
              handleEditInput={(input_id: string, value: unknown) =>
                actionRow && setActionRow({ ...actionRow, [input_id]: value })
              }
              confirmEditFunction={(
                updated_metric: MetricGridRow,
                original_metric: MetricGridRow
              ) => updateRow(updated_metric, original_metric)}
            />
          )}
        </Drawer>
      </React.Fragment>

      {/* Interactive Data Table */}
      <DataGrid
        autoHeight
        initialState={{
          pagination: {
            paginationModel: { pageSize: 10, page: 0 }
          }
        }}
        pageSizeOptions={[10, 25, 50, 100]}
        loading={gridLoading}
        rows={metricRows}
        columns={columns}
        disableRowSelectionOnClick
        slots={{
          toolbar: PanelMetricsToolbar,
          noRowsOverlay: PanelMetricsNoRows,
          loadingOverlay: PanelMetricsLoading
        }}
        slotProps={{ toolbar: { handleCreate: handleCreateClick, buttonDisabled: gridLoading } }}
        sx={{ '&, [class^=MuiDataGrid]': { border: 'none' } }}
      />
    </>
  );
};

export default PanelMetrics;
