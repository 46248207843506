import { EntityLabel, Role } from '@esg/esg-global-types';
import { FirestoreQueryParam } from '../../@types/shared';
import {
  DocumentData,
  DocumentReference,
  QueryDocumentSnapshot,
  QuerySnapshot,
  doc,
  setDoc
} from 'firebase/firestore';
import { deleteFirestoreDoc, readFirestoreDocs, updateFirestoreDoc } from '../app/db_util';
import { uuidv4 } from '@firebase/util';
import { MetadataError } from '@ep/error-handling';
import { db } from '../google/firebase';

export type RoleData = Omit<Role, 'id'>;

// Singular and plural label for model entity.
export const role_label: EntityLabel = {
  one: 'Role',
  many: 'Roles'
};

/**
 * Query all role documents for master list
 * @param {string} id Optional query for a specific role document
 * @returns {Promise<Array<Role>>}
 */
export const getRoles = async (id?: string): Promise<Array<Role>> => {
  try {
    const collection_path = `roles_master_list`;
    const query_params: Array<FirestoreQueryParam> = [
      { field_name: 'deleted', operator: '==', value: null }
    ];
    if (id !== undefined && id.length > 0)
      query_params.push({ field_name: 'id', operator: '==', value: id });
    const role_snapshot: QuerySnapshot = await readFirestoreDocs(collection_path, query_params);
    const roles: Array<Role> = role_snapshot.docs.map((role: QueryDocumentSnapshot) => {
      const role_data: DocumentData = role.data();
      return {
        id: role.id,
        deleted: role_data.deleted,
        name: role_data.name,
        description: role_data.description,
        views: role_data.views
      };
    });
    return roles;
  } catch (err: unknown) {
    const tracking_id: string = uuidv4();
    throw new MetadataError(
      err instanceof Error ? err.message : 'Error: getRoles failed on an unknown error.',
      {
        id: id
      },
      tracking_id
    );
  }
};

/**
 * Create Role with relative data
 * @param {RoleData} role Data of new Role to add to doc
 * @returns {DocumentReference}
 */
export const createRole = async (role: RoleData): Promise<DocumentReference> => {
  try {
    const role_id: string = role.name.replaceAll(' ', '_').toLowerCase();
    const role_doc: DocumentReference = doc(db, `roles_master_list/${role_id}`);
    await setDoc(role_doc, role);
    return role_doc;
  } catch (err: unknown) {
    const tracking_id: string = uuidv4();
    throw new MetadataError(
      err instanceof Error
        ? err.message
        : 'Error: lib/metric_capture/role.ts failed on an unknown error while calling createRole.',
      {
        role: role
      },
      tracking_id
    );
  }
};

/**
 * Update Role with relative data
 * @param {Role} updated_role New role data to push into document
 * @returns {void}
 */
export const updateRole = async (updated_role: Role): Promise<void> => {
  try {
    const collection_path = 'roles_master_list';
    const role_data: RoleData = {
      deleted: updated_role.deleted,
      name: updated_role.name
    };
    await updateFirestoreDoc(collection_path, updated_role.id, role_data);
  } catch (err: unknown) {
    const tracking_id: string = uuidv4();
    throw new MetadataError(
      err instanceof Error
        ? err.message
        : 'Error: lib/metric_capture/role.ts failed on an unknown error while calling updateRole.',
      {
        updated_role: updated_role
      },
      tracking_id
    );
  }
};

/**
 * Soft delete a Role.
 * @param {string} role_id ID of role to delete
 * @returns {Promise<void>}
 */
export const deleteRole = async (role_id: string): Promise<void> => {
  const collection_path = `roles_master_list`;
  try {
    await deleteFirestoreDoc(collection_path, role_id);
  } catch (err: unknown) {
    const tracking_id: string = uuidv4();
    throw new MetadataError(
      err instanceof Error
        ? err.message
        : 'Error: lib/metric_capture/role.ts failed on an unknown error while calling deleteRole.',
      {
        role_id: role_id
      },
      tracking_id
    );
  }
};
