import { refCompanyDoc } from '../app/company';
import {
  collection,
  updateDoc,
  doc,
  DocumentReference,
  CollectionReference,
  QuerySnapshot,
  getDoc,
  DocumentData,
  QueryDocumentSnapshot,
  DocumentSnapshot
} from 'firebase/firestore';
import { FirestoreQueryParam } from '../../@types/shared';
import { CaptureContext } from '../../@types/shared';
import {
  MetricRecord,
  File,
  ReportingPeriodGroup,
  ExternalCompany,
  ReportingPeriod
} from '@esg/esg-global-types';
import { MetricExtended } from './metric';
import { createAuditLog } from '../app/audit';
import { deleteFile, getDuplicateFile, getFiles } from './file';
import { uuidv4 } from '@firebase/util';
import { log } from '../../util/log';
import { MetadataError } from '@ep/error-handling';
import { getReportingPeriodsByGroup } from './reporting_period';
import {
  createFirestoreDoc,
  processBatchWrites,
  readFirestoreDocs,
  updateFirestoreDoc
} from '../app/db_util';
import { generateAuditLogData, generateAuditLogDoc } from '../app/audit';
import { auth, db } from '../google/firebase';
import { BatchWrite } from '../app/db_util';
import { QuantitativeMetricRecordGridRow } from '../../components/metric_collection_data_management/metric_records/quantitative/MetricRecordGridQuantitative';
import axios from 'axios';
import { API_ENDPOINT } from '../../env';

export interface MetricRecordWithMetricExtended extends MetricRecord {
  metric: MetricExtended;
}

type MetricRecordData = Omit<MetricRecord, 'id'>;

export interface MetricRecordInputData {
  metric?: MetricExtended;
  emission_factor?: DocumentReference;
  note?: string | null;
  proportion?: number;
  value?: string | number;
  scope?: number;
  status?: boolean;
}

/**
 * Function to query all metric record documents for current portal company
 * @param {Group} group Group object of current portal company
 * @param {string} company_id id of current portal company to reference firestore documents
 * @param {Array<FirestoreQueryParam> | undefined} context_query_params Optional capture context parameters to use in firestore query
 * @returns {Promise<Array<MetricRecord>>}
 */
export const getMetricRecords = async (
  group_id: string,
  company_id: string,
  context_query_params?: Array<FirestoreQueryParam>
): Promise<Array<MetricRecord>> => {
  const collection_path = `groups/${group_id}/companies/${company_id}/metric_records/`;
  const query_params: Array<FirestoreQueryParam> = [
    ...(context_query_params ?? []),
    { field_name: 'deleted', operator: '==', value: null }
  ];
  try {
    const metric_records_snapshot: QuerySnapshot = await readFirestoreDocs(
      collection_path,
      query_params
    );
    const metric_records: Array<MetricRecord> = metric_records_snapshot.docs.map(
      (metric_record: QueryDocumentSnapshot) => {
        const metric_record_data: DocumentData = metric_record.data();
        return {
          id: metric_record.id,
          deleted: metric_record_data.deleted,
          country: metric_record_data.country,
          region: metric_record_data.region,
          division: metric_record_data.division,
          subdivision: metric_record_data.subdivision,
          site: metric_record_data.site,
          metric: metric_record_data.metric,
          reporting_period: metric_record_data.reporting_period,
          reporting_period_group: metric_record_data.reporting_period_group,
          standard: metric_record_data.standard,
          source: metric_record_data.source,
          value: metric_record_data.value,
          proportion: metric_record_data.proportion,
          emission_factor: metric_record_data.emission_factor,
          note: metric_record_data.note,
          scope: metric_record_data.scope,
          external_company: metric_record_data.external_company,
          status: metric_record_data.status,
          is_averaged: metric_record_data.is_averaged,
          created: metric_record_data.created,
          modified: metric_record_data.modified
        };
      }
    );
    return metric_records;
  } catch (err: unknown) {
    const tracking_id: string = uuidv4();
    throw new MetadataError(
      err instanceof Error ? err.message : 'Error: getMetricRecords failed on an unknown error.',
      {
        group_id: group_id,
        company_id: company_id,
        collection_path: collection_path,
        query_params: query_params
      },
      tracking_id
    );
  }
};

/**
 * Function to query metric records for current portal company based on entity parameters
 * @param {Group} group Group object of current portal company
 * @param {string} company_id id of current portal company to reference firestore documents
 * @param {CaptureContext} capture_context object containing company entity objects used to filter desired metric records
 * @returns {Promise<Array<MetricRecord>>}
 */
export const getMetricRecordsForContext = async (
  group_id: string,
  company_id: string,
  capture_context: CaptureContext
): Promise<Array<MetricRecord>> => {
  const company_doc = refCompanyDoc(group_id, company_id);
  const reporting_period: DocumentReference | null = capture_context.reporting_period
    ? doc(company_doc, 'reporting_periods', capture_context.reporting_period.id)
    : null;
  const query_params: Array<FirestoreQueryParam> = (() => {
    switch (capture_context.level) {
      case 'national':
        return [
          {
            field_name: 'country',
            operator: '==',
            value: doc(
              collection(refCompanyDoc(group_id, company_id), 'countries'),
              capture_context.country?.id
            )
          },
          {
            field_name: 'region',
            operator: '==',
            value: null
          }
        ];
      case 'regional':
        return [
          {
            field_name: 'region',
            operator: '==',
            value: doc(
              collection(refCompanyDoc(group_id, company_id), 'regions'),
              capture_context.region?.id
            )
          },
          {
            field_name: 'division',
            operator: '==',
            value: null
          }
        ];
      case 'divisional':
        return [
          {
            field_name: 'division',
            operator: '==',
            value: doc(
              collection(refCompanyDoc(group_id, company_id), 'divisions'),
              capture_context.division?.id
            )
          },
          {
            field_name: 'subdivision',
            operator: '==',
            value: null
          }
        ];
      case 'subdivisional':
        return [
          {
            field_name: 'subdivision',
            operator: '==',
            value: doc(
              collection(refCompanyDoc(group_id, company_id), 'subdivisions'),
              capture_context.subdivision?.id
            )
          },
          {
            field_name: 'site',
            operator: '==',
            value: null
          }
        ];
      case 'site':
        return [
          {
            field_name: 'site',
            operator: '==',
            value: doc(
              collection(refCompanyDoc(group_id, company_id), 'sites'),
              capture_context.site?.id
            )
          }
        ];
      default:
        return [];
    }
  })();
  if (capture_context.reporting_period) {
    if (
      capture_context.reporting_period.id === 'all' ||
      capture_context.standard?.is_quantitative === false
    ) {
      const reporting_periods = await getReportingPeriodsByGroup(
        group_id,
        company_id,
        capture_context.reporting_period_group as ReportingPeriodGroup
      );
      if (reporting_periods.length === 0) {
        throw new Error(
          `Could not fetch Metric Records: No Reporting Periods found for ${capture_context.reporting_period_group?.name}`
        );
      }
      query_params.push({
        field_name: 'reporting_period',
        operator: 'in',
        value: reporting_periods.map((reporting_period) => {
          return doc(company_doc, 'reporting_periods', reporting_period.id);
        })
      });
    } else {
      query_params.push({
        field_name: 'reporting_period',
        operator: '==',
        value: doc(company_doc, 'reporting_periods', capture_context.reporting_period.id)
      });
    }
  }
  if (!capture_context.standard?.is_quantitative && capture_context.reporting_period_group) {
    query_params.push({
      field_name: 'reporting_period_group',
      operator: '==',
      value: doc(company_doc, 'reporting_period_groups', capture_context.reporting_period_group.id)
    });
  }
  if (capture_context.external_company) {
    query_params.push({
      field_name: 'external_company',
      operator: '==',
      value: doc(company_doc, 'external_companies', capture_context.external_company.id)
    });
  }
  if (capture_context.standard) {
    query_params.push({
      field_name: 'standard',
      operator: '==',
      value: doc(company_doc, 'standards', capture_context.standard.id)
    });
  }
  try {
    const metric_records: Array<MetricRecord> = await getMetricRecords(
      group_id,
      company_id,
      query_params
    );
    return metric_records;
  } catch (err) {
    const error = `Error while getting Metric Records for Context from Firebase: ${JSON.stringify({
      message: err instanceof Error ? err.message : '',
      stacktrace: err instanceof Error ? err.stack : '',
      variables: {
        company_doc: company_doc,
        reporting_period: reporting_period
      }
    })}`;
    throw new Error(`Error: getMetricRecordsForContext: ${JSON.stringify(error)}.`);
  }
};

/**
 * Function to join metric record data with relative metric data
 * @param {Array<MetricRecord>} metric_records Array of metric record objects
 * @param {Array<MetricExtended>} metrics Array of all company metrics to use per metric record
 * @returns {Array<MetricRecordWithMetricExtended>}
 */
export const joinMetricRecordsMetrics = (
  metric_records: Array<MetricRecord>,
  metrics: Array<MetricExtended>
): Array<MetricRecordWithMetricExtended> => {
  const metrics_map: Record<string, MetricExtended> = {};
  metrics.forEach((metric: MetricExtended) => {
    metrics_map[metric.id] = metric;
  });

  const metric_records_join_metrics: Array<MetricRecordWithMetricExtended> = metric_records
    .filter((metric_record: MetricRecord) => {
      const metric_id = metric_record.metric.id;
      return !!metrics_map[metric_id];
    })
    .map((metric_record: MetricRecord) => {
      const metric_id = metric_record.metric.id;
      return {
        ...metric_record,
        metric: metrics_map[metric_id]
      };
    });

  return metric_records_join_metrics;
};

/**
 * Function to create metric record with relative metric data in firestore
 * @param {string} group_id ID of Group to create metric record for
 * @param {string} company_id ID of Company to create metric record for
 * @param {CaptureContext} capture_context Context of entities metric record is being created for
 * @param {MetricExtended} metric Relative metric of new metric record
 * @param {number} value Value of new metric record
 * @param {DocumentReference} emission_factor Emission factor of metric for metric record
 * @param {number} proportion Proportion of metric record value allocated to client
 * @param {string} note Additional information for metric record
 * @returns {Promise<DocumentReference | undefined>}
 */
export const createMetricRecord = async (
  group_id: string,
  company_id: string,
  capture_context: CaptureContext,
  metric: MetricExtended,
  value: number | string,
  conditional_inputs: MetricRecordInputData
): Promise<DocumentReference | undefined> => {
  const company_doc: DocumentReference = refCompanyDoc(group_id, company_id);
  const collection_path = `groups/${group_id}/companies/${company_id}/metric_records`;
  try {
    if (capture_context.standard) {
      const metric_record_data: MetricRecordData = {
        created: new Date(),
        modified: new Date(),
        deleted: null,
        country: capture_context.country
          ? doc(collection(company_doc, 'countries'), capture_context.country.id)
          : null,
        region: capture_context.region
          ? doc(collection(company_doc, 'regions'), capture_context.region.id)
          : null,
        division: capture_context.division
          ? doc(collection(company_doc, 'divisions'), capture_context.division.id)
          : null,
        subdivision: capture_context.subdivision
          ? doc(collection(company_doc, 'subdivisions'), capture_context.subdivision.id)
          : null,
        site: capture_context.site
          ? doc(collection(company_doc, 'sites'), capture_context.site.id)
          : null,
        external_company: capture_context.external_company
          ? doc(collection(company_doc, 'external_companies'), capture_context.external_company.id)
          : null,
        reporting_period: capture_context.reporting_period
          ? doc(collection(company_doc, 'reporting_periods'), capture_context.reporting_period.id)
          : null,
        ...(capture_context.reporting_period_group &&
          !capture_context.standard.is_quantitative && {
            reporting_period_group: doc(
              collection(company_doc, 'reporting_period_groups'),
              capture_context.reporting_period_group.id
            )
          }),
        metric: doc(
          collection(
            doc(collection(company_doc, 'metric_groups'), metric.metric_group.id),
            'metrics'
          ),
          metric.id
        ),
        standard: doc(collection(company_doc, 'standards'), capture_context.standard.id),
        value: value,
        source: 'manual',
        is_averaged: false,
        ...conditional_inputs
      };
      const new_metric_record: DocumentReference = await createFirestoreDoc(
        collection_path,
        metric_record_data
      );
      await createAuditLog(
        group_id,
        'create',
        '',
        {
          metric: metric.name,
          note: conditional_inputs.note ?? '',
          proportion: conditional_inputs.proportion ?? '',
          value: value
        },
        new_metric_record
      );
      return new_metric_record;
    }
  } catch (err: unknown) {
    const tracking_id: string = uuidv4();
    throw new MetadataError(
      err instanceof Error
        ? err.message
        : 'Error: lib/metric_capture/metric_record.ts failed on an unknown error while calling createMetricRecord.',
      {
        group_id: group_id,
        company_id: company_id
      },
      tracking_id
    );
  }
};

/**
 * Function to create averaged metric records with relative metric data in firestore when capturing by reporting period group
 * @param {string} group_id ID of Group to create metric record for
 * @param {string} company_id ID of Company to create metric record for
 * @param {CaptureContext} capture_context Context of entities metric record is being created for
 * @param {MetricExtended} metric Relative metric of new metric record
 * @param {number} value Value of new metric record
 * @param {DocumentReference} emission_factor Emission factor of metric for metric record
 * @param {number} proportion Proportion of metric record value allocated to client
 * @param {string} note Additional information for metric record
 * @returns {Promise<Array<MetricRecord>>}
 */
export const createAverageMetricRecord = async (
  group_id: string,
  company_id: string,
  capture_context: CaptureContext,
  metric: MetricExtended,
  value: number,
  conditional_inputs: MetricRecordInputData
): Promise<Array<MetricRecord>> => {
  try {
    const company_doc = refCompanyDoc(group_id, company_id);
    const metric_records_collection: CollectionReference = collection(
      company_doc,
      'metric_records'
    );
    const external_company = capture_context.external_company;
    const reporting_periods = await getReportingPeriodsByGroup(
      group_id,
      company_id,
      capture_context.reporting_period_group as ReportingPeriodGroup
    );
    const averaged_value = value / reporting_periods.length;
    const metric_record_batch_writes: Array<BatchWrite> = [];
    const audit_log_batch_writes: Array<BatchWrite> = [];
    const metric_record_references: Array<DocumentReference> = [];
    const created_metric_records: Array<MetricRecord> = [];
    reporting_periods.forEach((reporting_period: ReportingPeriod) => {
      if (capture_context.standard) {
        const metric_records_ref: DocumentReference = doc(metric_records_collection);
        const metric_record: MetricRecordData = {
          created: new Date(),
          modified: new Date(),
          deleted: null,
          country: capture_context.country
            ? doc(collection(company_doc, 'countries'), capture_context.country.id)
            : null,
          region: capture_context.region
            ? doc(collection(company_doc, 'regions'), capture_context.region.id)
            : null,
          division: capture_context.division
            ? doc(collection(company_doc, 'divisions'), capture_context.division.id)
            : null,
          subdivision: capture_context.subdivision
            ? doc(collection(company_doc, 'subdivisions'), capture_context.subdivision.id)
            : null,
          site: capture_context.site
            ? doc(collection(company_doc, 'sites'), capture_context.site.id)
            : null,
          value: averaged_value,
          source: 'manual',
          reporting_period: doc(collection(company_doc, 'reporting_periods'), reporting_period.id),
          metric: doc(
            collection(
              doc(collection(company_doc, 'metric_groups'), metric.metric_group.id),
              'metrics'
            ),
            metric.id
          ),
          standard: doc(collection(company_doc, 'standards'), capture_context.standard.id),
          external_company: external_company
            ? doc(collection(company_doc, 'external_companies'), external_company.id)
            : null,
          ...conditional_inputs
        };
        metric_record_references.push(metric_records_ref);
        metric_record_batch_writes.push({
          reference: metric_records_ref,
          operation: 'create',
          data: metric_record
        });
        created_metric_records.push({
          ...metric_record,
          id: metric_records_ref.id,
          reporting_period: reporting_period
        });
        const audit_log_ref: DocumentReference = generateAuditLogDoc(group_id);
        const audit_log_data = generateAuditLogData(
          `${metric_record.value}`,
          'create',
          '',
          metric_records_ref,
          auth.currentUser?.email
        );
        audit_log_batch_writes.push({
          reference: audit_log_ref,
          operation: 'create',
          data: audit_log_data
        });
      }
    });
    await processBatchWrites(metric_record_batch_writes).catch((error) => {
      console.warn(error);
    });

    await processBatchWrites(audit_log_batch_writes).catch((error) => {
      throw new Error(error);
    });
    return created_metric_records;
  } catch (err: unknown) {
    const tracking_id: string = uuidv4();
    throw new MetadataError(
      err instanceof Error
        ? err.message
        : 'Error: lib/metric_capture/reporting_period.ts failed on an unknown error while calling createAverageMetricRecord.',
      {
        group_id: group_id,
        company_id: company_id,
        capture_context: capture_context,
        metric: metric,
        value: value,
        ...conditional_inputs
      },
      tracking_id
    );
  }
};

/**
 * Function to delete metric record
 * @param {string} group_id ID of Group Metric Record is being deleted for.
 * @param {string} company_id ID of Company Metric Record is being deleted for.
 * @param {MetricRecord | QuantitativeMetricRecordGridRow} metric_record MetricRecord being deleted
 * @returns {Promise<void>}
 */
export const deleteMetricRecord = async (
  group_id: string,
  company_id: string,
  metric_record: MetricRecord | QuantitativeMetricRecordGridRow
): Promise<void> => {
  try {
    const metric_record_doc = doc(
      collection(refCompanyDoc(group_id, company_id), 'metric_records'),
      metric_record.id
    );
    await updateDoc(metric_record_doc, {
      deleted: new Date()
    });
    const files: Array<File> = await getFiles(group_id, company_id, metric_record.id);
    files?.forEach(async (file: File) => {
      await deleteFile(group_id, company_id, file);
    });
    await createAuditLog(
      group_id,
      'delete',
      {
        metric: (metric_record.metric as MetricExtended).name,
        note: metric_record.note ? metric_record.note : '',
        proportion: metric_record.proportion ? metric_record.proportion : '',
        value: metric_record.value ? metric_record.value : ''
      },
      '',
      metric_record_doc
    );
    return;
  } catch (err: unknown) {
    const tracking_id: string = uuidv4();
    throw new MetadataError(
      err instanceof Error
        ? err.message
        : 'Error: lib/metric_capture/metric_record.ts failed on an unknown error while calling deleteMetricRecord.',
      {
        group_id: group_id,
        company_id: company_id,
        metric_record: metric_record
      },
      tracking_id
    );
  }
};

/**
 * Updates the metric record with new values.
 * @param {string} group_id ID of Group Metric Record is being updated for.
 * @param {string} company_id ID of Company Metric Record is being updated for.
 * @param {string} metric_record_id ID of Metric Record being updated
 * @param {MetricRecordInputData} original_metric_record_data Old data of Metric Record being updated
 * @param {MetricRecordInputData} updated_metric_record_data New data of Metric Record being updated
 * @returns {Promise<void>}
 */
export const updateMetricRecord = async (
  group_id: string,
  company_id: string,
  metric_record_id: string,
  original_metric_record_data: MetricRecordInputData,
  updated_metric_record_data: MetricRecordInputData
): Promise<void> => {
  const company_doc: DocumentReference = refCompanyDoc(group_id, company_id);
  const collection_path = `groups/${group_id}/companies/${company_id}/metric_records`;
  try {
    const updated_metric_record_doc: DocumentReference = await updateFirestoreDoc(
      collection_path,
      metric_record_id,
      {
        ...updated_metric_record_data,
        ...(updated_metric_record_data.metric && {
          metric: doc(
            collection(
              doc(
                collection(company_doc, 'metric_groups'),
                updated_metric_record_data.metric.metric_group.id
              ),
              'metrics'
            ),
            updated_metric_record_data.metric.id
          )
        }),
        modified: new Date()
      }
    );
    await createAuditLog(
      group_id,
      'update',
      {
        metric: original_metric_record_data.metric?.name ?? '',
        note: original_metric_record_data.note ? original_metric_record_data.note : '',
        proportion: original_metric_record_data.proportion
          ? original_metric_record_data.proportion / 100
          : '',
        value: original_metric_record_data.value ? original_metric_record_data.value : ''
      },
      {
        metric: updated_metric_record_data.metric?.name ?? '',
        note: updated_metric_record_data.note ?? '',
        proportion: updated_metric_record_data.proportion ?? 1,
        value: updated_metric_record_data.value ?? ''
      },
      updated_metric_record_doc
    );
  } catch (err: unknown) {
    const tracking_id: string = uuidv4();
    throw new MetadataError(
      err instanceof Error
        ? err.message
        : 'Error: lib/metric_capture/metric_record.ts failed on an unknown error while calling updateMetricRecord.',
      {
        group_id: group_id,
        company_id: company_id,
        updated_metric_record: updated_metric_record_data,
        original_metric_record: original_metric_record_data
      },
      tracking_id
    );
  }
};

/**
 * Join metric records with an external company object.
 * @param {Array<MetricRecords>} metric_records Array of indexable metric record objects
 * @returns {Array<MetricRecords>}
 */
export const joinMetricRecordsExternalCompany = (
  metric_records: Array<MetricRecord>
): Promise<Array<MetricRecord>> => {
  try {
    const metric_records_external_company: Array<Promise<MetricRecord>> = metric_records.map(
      async (metric_record: MetricRecord) => {
        const external_company_document_snapshot: DocumentSnapshot | null =
          metric_record.external_company
            ? await getDoc(metric_record.external_company as DocumentReference)
            : null;
        const external_company_data: DocumentData | null =
          external_company_document_snapshot?.data() ?? null;
        const metric_record_external_company: ExternalCompany | null =
          external_company_document_snapshot && external_company_data
            ? {
                id: external_company_document_snapshot.id,
                name: external_company_data.name,
                active: external_company_data.active,
                created: external_company_data.created,
                modified: external_company_data.modified,
                deleted: external_company_data.deleted
              }
            : null;
        return {
          ...metric_record,
          external_company: metric_record_external_company
        };
      }
    );
    return Promise.all(metric_records_external_company);
  } catch (err: unknown) {
    const tracking_id: string = uuidv4();
    log(
      'error',
      new MetadataError(
        err instanceof Error
          ? err.message
          : 'Error: lib/metric_capture/metric_record.ts failed on an unknown error while calling joinMetricRecordsExternalCompany.',
        {
          metric_records: metric_records
        },
        tracking_id
      )
    );
    throw new Error(`Error: joinMetricRecordsExternalCompany: ${JSON.stringify(err)}.`);
  }
};

/**
 * Function to find an existing qualitative Metric Record corresponding to a Reporting Period Group and Metric
 * @param {string} group_id ID of Group to find Metric Record in.
 * @param {string} company_id ID of Company to find Metric Record in.
 * @param {MetricExtended} metric Metric to find existing Metric Record for.
 * @param {ReportingPeriodGroup} reporting_period_group Reporting Period Group to find existing Metric Record for.
 * @returns {Promise<MetricRecord | null>}
 */
export const getQualitativeMetricRecord = async (
  group_id: string,
  company_id: string,
  metric: MetricExtended,
  reporting_period_group: ReportingPeriodGroup
): Promise<MetricRecord | null> => {
  const query_params: Array<FirestoreQueryParam> = [
    {
      field_name: 'reporting_period_group',
      operator: '==',
      value: doc(
        db,
        `groups/${group_id}/companies/${company_id}/reporting_period_groups`,
        reporting_period_group.id
      )
    },
    {
      field_name: 'metric',
      operator: '==',
      value: doc(
        db,
        `groups/${group_id}/companies/${company_id}/metric_groups/${metric.metric_group.id}/metrics`,
        metric.id
      )
    }
  ];
  const metric_records: Array<MetricRecord> = await getMetricRecords(
    group_id,
    company_id,
    query_params
  );
  if (metric_records.length > 0) {
    return metric_records[0];
  }
  return null;
};

/**
 * Function to remove file from metric record and potentially from cloud storage if not used by any other metric records
 * @param {string} group_id Group ID of file to remove
 * @param {string} company_id Company ID of file to remove
 * @param {string} metric_record_id ID of Metric Record to remove file from
 * @param {File} delete_file File to remove
 * @returns {Promise<void>}
 */
export const removeMetricRecordFile = async (
  group_id: string,
  company_id: string,
  metric_record_id: string,
  delete_file: File
): Promise<void> => {
  try {
    await deleteFile(group_id, company_id, delete_file);
    const remaining_file: File | null = await getDuplicateFile(
      group_id,
      company_id,
      delete_file.name,
      metric_record_id
    );
    if (!remaining_file) {
      await axios({
        method: 'post',
        url: `${API_ENDPOINT}/remove_file`,
        data: { file_path: delete_file.file_path }
      });
    }
  } catch (err: unknown) {
    const tracking_id: string = uuidv4();
    log(
      'error',
      new MetadataError(
        err instanceof Error
          ? err.message
          : 'Error: lib/metric_capture/metric_record.ts failed on an unknown error while calling removeMetricRecordSupportingDoc.',
        {
          metric_record_id: metric_record_id,
          delete_file: delete_file
        },
        tracking_id
      )
    );
    throw new Error(`Error: removeMetricRecordSupportingDoc: ${JSON.stringify(err)}.`);
  }
};
