import {
  collection,
  getDocs,
  query,
  CollectionReference,
  Query,
  QuerySnapshot,
  where,
  addDoc,
  doc,
  updateDoc,
  DocumentReference,
  deleteField
} from 'firebase/firestore';
import { refCompanyDoc } from '../app/company';
import {
  Division,
  EntityLabel,
  MetricRecord,
  ReportingPeriod,
  Site,
  Subdivision
} from '@esg/esg-global-types';
import { createAuditLog } from '../app/audit';
import { db } from '../google/firebase';
import { BatchWrite, processBatchWrites } from '../app/db_util';
import { getReportingPeriods } from './reporting_period';
import { getMetricRecords } from './metric_record';
import { FirestoreQueryParam } from '../../@types/shared';

// Singular and plural label for model entity.
export const division_label: EntityLabel = {
  one: 'Division',
  many: 'Divisions'
};

/**
 * Function to query all division documents for current portal company
 * @param {Group} group Group object of current portal company
 * @param {string} company_id id of current portal company to reference firestore documents
 * @returns {Array<Division>}
 */
export const getDivisions = async (group_id: string, company_id: string) => {
  const divisions_collection: CollectionReference = collection(
    refCompanyDoc(group_id, company_id),
    'divisions'
  );
  const divisions_query: Query = query(divisions_collection, where('deleted', '==', null));
  try {
    const divisions_docs: QuerySnapshot = await getDocs(divisions_query);
    const divisions: Array<Division> = divisions_docs.docs.map((division) => {
      return {
        id: division.id,
        deleted: division.data().deleted,
        name: division.data().name,
        archived: division.data().archived,
        reference: division.ref
      };
    });
    return divisions;
  } catch (err) {
    const error = `Error while getting Divisions from Firebase: ${JSON.stringify({
      message: err instanceof Error ? err.message : '',
      stacktrace: err instanceof Error ? err.stack : '',
      variables: {
        divisions_collection: divisions_collection,
        divisions_query: divisions_query
      }
    })}`;
    throw new Error(`Error: getDivisions: ${JSON.stringify(error)}.`);
  }
};

/**
 * Function to create division with relative data
 * @param {string} group_id ID of Group which Division is being created for
 * @param {string} company_id ID of Company which Division is being created for
 * @param {string} division_name Name of new Division
 * @returns {DocumentReference}
 */
export const createDivision = async (
  group_id: string,
  company_id: string,
  division_name: string
) => {
  try {
    if (group_id && company_id && division_name) {
      const company_doc = refCompanyDoc(group_id, company_id);
      const divisions_collection: CollectionReference = collection(company_doc, 'divisions');
      const new_division_data = {
        deleted: null,
        name: division_name
      };
      const new_division = await addDoc(divisions_collection, new_division_data);
      await createAuditLog(group_id, 'create', '', division_name, new_division);
      return new_division;
    }
  } catch (err) {
    const error = `Error while creating Division in Firebase: ${JSON.stringify({
      message: err instanceof Error ? err.message : '',
      stacktrace: err instanceof Error ? err.stack : ''
    })}`;
    throw new Error(`Error: createDivision: ${JSON.stringify(error)}.`);
  }
};

/**
 * Function to update division with relative data
 * @param {string} group_id ID of Group to update division for
 * @param {string} company_id ID of Company to update division for
 * @param {Division} updated_division New Division data to push into document
 * @returns {void}
 */
export const updateDivision = async (
  group_id: string,
  company_id: string,
  updated_division: Division,
  original_division: Division
) => {
  try {
    if (group_id && company_id) {
      const company_doc = refCompanyDoc(group_id, company_id);
      const division_doc = doc(collection(company_doc, 'divisions'), updated_division.id);
      await updateDoc(division_doc, {
        deleted: updated_division.deleted,
        name: updated_division.name
      });
      await createAuditLog(
        group_id,
        'update',
        updated_division.name,
        original_division.name,
        division_doc
      );
    }
  } catch (err) {
    const error = `Error while udpating Division in Firebase: ${JSON.stringify({
      message: err instanceof Error ? err.message : '',
      stacktrace: err instanceof Error ? err.stack : ''
    })}`;
    throw new Error(`Error: updateDivision: ${JSON.stringify(error)}.`);
  }
};

/**
 * Function to delete division
 * @param {string} group_id ID of Group to delete division for
 * @param {string} company_id ID of Company to delete division for
 * @param {string} division_id ID of division to delete
 * @param {Array<Subdivision>} delete_subdivisions Child subdivisions to cascade delete
 * @param {Array<Site>} delete_sites Child sites to cascade delete
 * @returns {void}
 */
export const deleteDivision = async (
  group_id: string,
  company_id: string,
  division_id: string,
  division_name: string,
  delete_subdivisions?: Array<Subdivision>,
  delete_sites?: Array<Site>
) => {
  try {
    if (group_id && company_id && division_id) {
      const cascade_delete_writes: Array<BatchWrite> = [];
      const division_doc = doc(
        collection(refCompanyDoc(group_id, company_id), `divisions`),
        division_id
      );
      await updateDoc(division_doc, {
        deleted: new Date()
      });
      if (delete_subdivisions) {
        delete_subdivisions.forEach((subdivision: Subdivision) => {
          const subdivision_ref: DocumentReference = doc(
            db,
            `groups/${group_id}/companies/${company_id}/subdivisions/${subdivision.id}`
          );
          cascade_delete_writes.push({ reference: subdivision_ref, operation: 'delete', data: {} });
        });
      }
      if (delete_sites) {
        delete_sites.forEach((site: Site) => {
          const site_ref: DocumentReference = doc(
            db,
            `groups/${group_id}/companies/${company_id}/sites/${site.id}`
          );
          cascade_delete_writes.push({ reference: site_ref, operation: 'delete', data: {} });
        });
      }
      await processBatchWrites(cascade_delete_writes);
      await createAuditLog(group_id, 'delete', division_name, '', division_doc);
    }
    return;
  } catch (err) {
    const error = `Error while deleting Division in Firebase: ${JSON.stringify({
      message: err instanceof Error ? err.message : '',
      stacktrace: err instanceof Error ? err.stack : ''
    })}`;
    throw new Error(`Error: deleteDivision: ${JSON.stringify(error)}.`);
  }
};

/**
 * Function to archive division
 * @param {string} group_id ID of Group to archive division for
 * @param {string} company_id ID of Company to archive division for
 * @param {string} division_id ID of division to archive
 * @param {Array<Subdivision>} archive_subdivisions Child subdivisions to cascade archive
 * @param {Array<Site>} archive_sites Child sites to cascade archive
 * @returns {void}
 */
export const archiveDivision = async (
  group_id: string,
  company_id: string,
  division_id: string,
  archive_subdivisions?: Array<Subdivision>,
  archive_sites?: Array<Site>
) => {
  try {
    if (group_id && company_id && division_id) {
      const cascade_archive_writes: Array<BatchWrite> = [];
      const division_doc = doc(
        collection(refCompanyDoc(group_id, company_id), `divisions`),
        division_id
      );
      await updateDoc(division_doc, {
        archived: new Date()
      });
      if (archive_subdivisions) {
        archive_subdivisions.forEach((subdivision: Subdivision) => {
          const subdivision_ref: DocumentReference = doc(
            db,
            `groups/${group_id}/companies/${company_id}/subdivisions/${subdivision.id}`
          );
          cascade_archive_writes.push({
            reference: subdivision_ref,
            operation: 'update',
            data: { archived: new Date() }
          });
        });
      }
      if (archive_sites) {
        archive_sites.forEach((site: Site) => {
          const site_ref: DocumentReference = doc(
            db,
            `groups/${group_id}/companies/${company_id}/sites/${site.id}`
          );
          cascade_archive_writes.push({
            reference: site_ref,
            operation: 'update',
            data: { archived: new Date() }
          });
        });
      }
      await processBatchWrites(cascade_archive_writes);
      await createAuditLog(group_id, 'archive', '', '', division_doc);
    }
    return;
  } catch (err) {
    const error = `Error while archiving Division in Firebase: ${JSON.stringify({
      message: err instanceof Error ? err.message : '',
      stacktrace: err instanceof Error ? err.stack : ''
    })}`;
    throw new Error(`Error: archiveDivision: ${JSON.stringify(error)}.`);
  }
};

/**
 * Function to unarchive division
 * @param {string} group_id ID of Group to unarchive division for
 * @param {string} company_id ID of Company to unarchive division for
 * @param {string} division_id ID of division to unarchive
 * @param {Array<Subdivision>} unarchive_subdivisions Child subdivisions to cascade unarchive
 * @param {Array<Site>} unarchive_sites Child sites to cascade unarchive
 * @returns {void}
 */
export const unarchiveDivision = async (
  group_id: string,
  company_id: string,
  division_id: string,
  unarchive_subdivisions?: Array<Subdivision>,
  unarchive_sites?: Array<Site>
) => {
  try {
    if (group_id && company_id && division_id) {
      const cascade_unarchive_writes: Array<BatchWrite> = [];
      const division_doc = doc(
        collection(refCompanyDoc(group_id, company_id), `divisions`),
        division_id
      );
      await updateDoc(division_doc, {
        archived: deleteField()
      });
      if (unarchive_subdivisions) {
        unarchive_subdivisions.forEach((subdivision: Subdivision) => {
          const subdivision_ref: DocumentReference = doc(
            db,
            `groups/${group_id}/companies/${company_id}/subdivisions/${subdivision.id}`
          );
          cascade_unarchive_writes.push({
            reference: subdivision_ref,
            operation: 'update',
            data: { archived: deleteField() }
          });
        });
      }
      if (unarchive_sites) {
        unarchive_sites.forEach((site: Site) => {
          const site_ref: DocumentReference = doc(
            db,
            `groups/${group_id}/companies/${company_id}/sites/${site.id}`
          );
          cascade_unarchive_writes.push({
            reference: site_ref,
            operation: 'update',
            data: { archived: deleteField() }
          });
        });
      }
      await processBatchWrites(cascade_unarchive_writes);
      await createAuditLog(group_id, 'archive', '', 'null', division_doc);
    }
    return;
  } catch (err) {
    const error = `Error while unarchiving Division in Firebase: ${JSON.stringify({
      message: err instanceof Error ? err.message : '',
      stacktrace: err instanceof Error ? err.stack : ''
    })}`;
    throw new Error(`Error: unarchiveDivision: ${JSON.stringify(error)}.`);
  }
};

/**
 * Function to check if a Division can be safely deleted
 * @param {string} group_id ID of Group to check Division for
 * @param {string} company_id ID of Company to check Division for
 * @param {string} division_id ID of Division to check
 * @returns {boolean}
 */
export const allowDeleteDivision = async (
  group_id: string,
  company_id: string,
  division_id: string
) => {
  try {
    const reporting_periods = await getReportingPeriods(group_id, company_id, true);
    if (reporting_periods.length === 0) return true;
    const reporting_period_ids = reporting_periods.map(
      (reporting_period: ReportingPeriod) => reporting_period.id
    );
    const query_params: Array<FirestoreQueryParam> = [
      {
        field_name: 'division',
        operator: '==',
        value: doc(collection(refCompanyDoc(group_id, company_id), 'divisions'), division_id)
      }
    ];
    const division_metric_records = await getMetricRecords(group_id, company_id, query_params);
    const allow_delete = !division_metric_records.some((metric_record: MetricRecord) => {
      if (reporting_period_ids.includes(metric_record.reporting_period?.id ?? '')) {
        return true;
      }
    });
    return allow_delete;
  } catch (err) {
    const error = `Error while checking Division for deletion: ${JSON.stringify({
      message: err instanceof Error ? err.message : '',
      stacktrace: err instanceof Error ? err.stack : ''
    })}`;
    throw new Error(`Error: allowDeleteDivision: ${JSON.stringify(error)}.`);
  }
};
