import * as React from 'react';
import { DataGrid, GridActionsCellItem, GridColDef } from '@mui/x-data-grid';
import EditUser from './EditUser';
import { Drawer, Tooltip } from '@mui/material';
import DeleteUser from './DeleteUser';
import { User, Role, Module, Group } from '@esg/esg-global-types';
import { UserContext } from '../../context/UserContext';
import { getAdminGroups, getUserTypes } from '../../util/user_access';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import UnarchiveIcon from '@mui/icons-material/Unarchive';
import { UsersTableLoading } from './UsersTableLoading';
import { UsersTableNoRows } from './UsersTableNoRows';
import { UsersTableToolbar } from './UsersTableToolbar';
import moment from 'moment';
import { getAuth } from 'firebase/auth';
import axios, { AxiosError } from 'axios';
import { CLOUD_FUNCTION_URL } from '../../env';
import { uuidv4 } from '@firebase/util';
import { log } from '../../util/log';
import { MetadataError } from '@ep/error-handling';
import { FeedbackSnackbarContext } from '../../context/FeedbackSnackbarContext';
import { restoreUser } from '../../lib/user_management/users';
import RestoreConfirmModal from '../shared/modal/RestoreConfirmModal';

interface PropTypes {
  tableLoading: boolean;
  users: Array<User>;
  groups: Array<Group>;
  roles: Array<Role>;
  modules: Array<Module>;
  handleUpdatedUser(): void;
  handleCreateUser(): void;
  handleSetUserRows(users: Array<User>): void;
}

const UsersTable = (props: PropTypes) => {
  const user_info: User | null = React.useContext(UserContext);
  const { setFeedbackData } = React.useContext(FeedbackSnackbarContext);
  const [actionRow, setActionRow] = React.useState<User | null>(null);
  const [showEditDrawer, setShowEditDrawer] = React.useState<boolean>(false);
  const [showDeleteDrawer, setShowDeleteDrawer] = React.useState<boolean>(false);
  const [showDeletedUsers, setShowDeletedUsers] = React.useState<boolean>(false);
  const [showRestoreModal, setShowRestoreModal] = React.useState<boolean>(false);
  const admin_groups: Array<string> = user_info ? getAdminGroups(user_info) : [];

  const visible_users: Array<User> = props.users.filter((user: User) => {
    if (user.deleted && !showDeletedUsers) return false;
    if (user_info?.super_admin) return true;
    return (
      admin_groups.filter((group_id: string) => Object.keys(user.groups).includes(group_id))
        .length > 0
    );
  });

  const handleOpenEditDrawer = (action_row: User) => {
    setActionRow(action_row);
    setShowEditDrawer(true);
  };

  const handleOpenDeleteDrawer = (action_row: User) => {
    setActionRow(action_row);
    setShowDeleteDrawer(true);
  };

  const handleUpdateUserRow = (update_user: User) => {
    props.handleSetUserRows(
      props.users.map((user: User) => {
        return update_user.id === user.id ? update_user : user;
      })
    );
  };

  const handleDeleteUserRow = (delete_user: User) => {
    props.handleSetUserRows(
      props.users.map((user: User) => {
        return user.id === delete_user.id ? { ...delete_user, deleted: new Date() } : user;
      })
    );
  };

  const handleRestoreUser = async (restore_user: User) => {
    try {
      await getAuth()
        .currentUser?.getIdToken()
        .then(async (token) => {
          await axios({
            method: 'post',
            url: `${CLOUD_FUNCTION_URL}/reAddUser`,
            data: {
              email_address: restore_user.email
            },
            headers: {
              authorization: String('Bearer ' + token)
            }
          })
            .then(async () => {
              await restoreUser(restore_user.id).catch((error) => {
                setFeedbackData({ message: error.message, state: true, type: 'error' });
              });
              handleUpdateUserRow({ ...restore_user, deleted: null });
              setFeedbackData({
                message: `${restore_user.email} Successfully Restored`,
                state: true,
                type: 'success'
              });
            })
            .catch((err) => {
              throw new Error(err.message);
            });
        })
        .catch((err) => {
          throw new Error(err.message);
        });
    } catch (err: unknown) {
      let error_message = 'Could not restore User, Contact Energy Partners if problem persists';
      if (err instanceof AxiosError && err.response?.data === 'No Existing User found with email') {
        error_message = `No User found with email: ${restore_user.email}`;
      }
      const tracking_id: string = uuidv4();
      log(
        'error',
        new MetadataError(
          err instanceof Error
            ? err.message
            : 'Error: UsersTable failed on an unknown error while calling handleRestoreUser.',
          {
            restore_user: restore_user
          },
          tracking_id
        )
      );
      setFeedbackData({
        message: `${error_message}. Tracking ID: ${tracking_id}`,
        state: true,
        type: 'error'
      });
    }
  };

  // Handler functions
  const handleCreateClick = (): void => {
    props.handleCreateUser();
  };

  const handleCloseRestoreModal = () => {
    setShowRestoreModal(false);
    setActionRow(null);
  };

  const columns: GridColDef[] = [
    { field: 'id', headerName: 'User ID', flex: 1.2 },
    {
      field: 'username',
      headerName: 'User',
      flex: 1,
      sortable: false,
      editable: false,
      renderCell: (params) => {
        return params.row.first_name && params.row.last_name
          ? params.row.first_name + ' ' + params.row.last_name
          : '';
      }
    },
    {
      field: 'first_name',
      headerName: 'Name',
      flex: 1,
      editable: false
    },
    {
      field: 'last_name',
      headerName: 'Last Name',
      flex: 1,
      editable: false
    },
    {
      field: 'email',
      headerName: 'Email',
      flex: 1.5,
      editable: false
    },
    {
      field: 'phone',
      headerName: 'Phone Number',
      description: 'Contact number for user, does not mean its the MFA number',
      sortable: false,
      flex: 1.2,
      editable: false
    },
    {
      field: 'type',
      headerName: 'Type',
      sortable: false,
      flex: 1,
      editable: false,
      renderCell: (params) => {
        return getUserTypes().filter((obj) => {
          return obj.id === params.row.type;
        })[0].name;
      }
    },
    {
      field: 'created',
      headerName: 'Created',
      sortable: false,
      flex: 1,
      editable: false,
      renderCell: (params) => {
        return params.row.created
          ? moment(params.row.created.toDate()).format('YYYY-MM-DD HH:mm')
          : '';
      }
    },
    {
      field: 'modified',
      headerName: 'Modified',
      sortable: false,
      flex: 1,
      editable: false,
      renderCell: (params) => {
        return params.row.modified
          ? moment(params.row.modified.toDate()).format('YYYY-MM-DD HH:mm')
          : '';
      }
    },
    {
      field: 'last_accessed',
      headerName: 'Last Accessed',
      sortable: false,
      flex: 1,
      editable: false,
      renderCell: (params) => {
        return params.row.last_accessed
          ? moment(params.row.last_accessed.toDate()).format('YYYY-MM-DD HH:mm')
          : '';
      }
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: '',
      align: 'right',
      flex: 1,
      getActions: ({ row }) => {
        return [
          row.deleted ? (
            <Tooltip title="Restore User" key={2}>
              <GridActionsCellItem
                disabled={
                  !user_info?.super_admin &&
                  (row.super_admin || getAdminGroups(row).length > 0) &&
                  user_info?.email !== row.email
                }
                icon={<UnarchiveIcon sx={{ fontSize: '1.5rem' }} />}
                label="Restore"
                sx={{
                  color: 'primary.main'
                }}
                onClick={() => {
                  setActionRow(row);
                  setShowRestoreModal(true);
                }}
              />
            </Tooltip>
          ) : (
            <>
              <Tooltip title="Edit User" key={0}>
                <GridActionsCellItem
                  disabled={
                    !user_info?.super_admin &&
                    (row.super_admin || getAdminGroups(row).length > 0) &&
                    user_info?.email !== row.email
                  }
                  icon={<EditIcon sx={{ fontSize: '1.5rem' }} />}
                  label="Edit"
                  sx={{
                    color: 'primary.main'
                  }}
                  onClick={() => handleOpenEditDrawer(row)}
                />
              </Tooltip>
              <Tooltip title="Delete User" key={1}>
                <GridActionsCellItem
                  disabled={
                    !user_info?.super_admin &&
                    (row.super_admin || getAdminGroups(row).length > 0) &&
                    user_info?.email !== row.email
                  }
                  icon={<DeleteIcon sx={{ fontSize: '1.5rem' }} />}
                  label="Delete"
                  sx={{
                    color: 'primary.main'
                  }}
                  onClick={() => handleOpenDeleteDrawer(row)}
                />
              </Tooltip>
            </>
          )
        ];
      }
    }
  ];
  return (
    <>
      <RestoreConfirmModal
        open={showRestoreModal}
        handleCloseRestoreModal={handleCloseRestoreModal}
        restore_explainer={
          'This User will have all of their old permissions and will be able to sign in using their old email and password'
        }
        restore_label={actionRow && actionRow.email}
        handleRestore={actionRow && (() => handleRestoreUser(actionRow))}
      />
      <Drawer
        anchor={'right'}
        open={showEditDrawer}
        onClose={() => setShowEditDrawer(false)}
        PaperProps={{ style: { width: '60%' } }}
      >
        <EditUser
          groups={props.groups.filter((group: Group) => {
            if (user_info?.super_admin) return true;
            return admin_groups.includes(group.id);
          })}
          roles={props.roles}
          modules={props.modules}
          edit_user={actionRow}
          handleUpdateUserRow={(updated_user: User) => handleUpdateUserRow(updated_user)}
          closeDrawer={() => {
            setActionRow(null);
            setShowEditDrawer(false);
          }}
        />
      </Drawer>
      <Drawer
        anchor={'right'}
        open={showDeleteDrawer}
        onClose={() => setShowDeleteDrawer(false)}
        PaperProps={{ style: { width: '30%' } }}
      >
        <DeleteUser
          delete_user={actionRow}
          users={props.users}
          handleDeleteUserRow={(delete_user: User) => handleDeleteUserRow(delete_user)}
          closeDrawer={() => {
            setActionRow(null);
            setShowDeleteDrawer(false);
          }}
        />
      </Drawer>
      <DataGrid
        autoHeight={true}
        loading={props.tableLoading}
        rows={visible_users}
        columns={columns}
        pageSizeOptions={[10, 25, 50, 100]}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 25
            }
          },
          columns: {
            columnVisibilityModel: {
              id: false,
              first_name: false,
              last_name: false
            }
          }
        }}
        disableRowSelectionOnClick
        slots={{
          toolbar: UsersTableToolbar,
          noRowsOverlay: UsersTableNoRows,
          loadingOverlay: UsersTableLoading
        }}
        slotProps={{
          toolbar: {
            handleCreate: handleCreateClick,
            handleShowDeleted: (show: boolean) => setShowDeletedUsers(show)
          }
        }}
        getRowClassName={(params) => {
          return `users-grid-row-${params.row.deleted ? 'deleted' : 'enabled'}`;
        }}
        sx={{
          '& .MuiDataGrid-row': {
            boxSizing: 'border-box'
          },
          '& .users-grid-row-deleted': {
            fontStyle: 'italic',
            color: 'rgba(66, 66, 66, 0.8)',
            backgroundColor: 'rgba(66, 66, 66, 0.1)'
          },
          '&, [class^=MuiDataGrid]': { border: 'none' }
        }}
      />
    </>
  );
};

export default UsersTable;
