import * as React from 'react';
import { Box, Drawer, Tooltip, Typography } from '@mui/material';
import SegmentIcon from '@mui/icons-material/Segment';
import { Module } from '@esg/esg-global-types';
import { FeedbackSnackbarContext } from '../../../context/FeedbackSnackbarContext';
import DeleteConfirmModal from '../../shared/modal/DeleteConfirmModal';
import EditIcon from '@mui/icons-material/Edit';
import ConfigAddWidget, { CreateInput } from '../ConfigAddWidget';
import { MetadataError } from '@ep/error-handling';
import { uuidv4 } from '@firebase/util';
import { log } from '../../../util/log';
import {
  DataGrid,
  GridActionsCellItem,
  GridColDef,
  GridRenderCellParams,
  GridValueFormatterParams
} from '@mui/x-data-grid';
import { PanelModulesLoading } from './PanelModulesLoading';
import { PanelModulesNoRows } from './PanelModulesNoRows';
import { PanelModulesToolbar } from './PanelModulesToolbar';
import DeleteIcon from '@mui/icons-material/Delete';
import { DocumentReference } from 'firebase/firestore';
import ConfigEditWidget, { EditInput } from '../ConfigEditWidget';
import {
  ModuleData,
  createModule,
  deleteModule,
  getModules,
  module_label,
  updateModule
} from '../../../lib/app/module';
import TextListInput from '../../shared/input/text/TextListInput';

/**
 * Datagrid handling the display of master list Modules
 * @returns {JSX.Element}
 */
const PanelModules = () => {
  const { setFeedbackData } = React.useContext(FeedbackSnackbarContext);
  const [actionRow, setActionRow] = React.useState<Module | null>(null);
  const [isCreateWidget, setIsCreateWidget] = React.useState<boolean>(true);
  const [moduleRows, setModuleRows] = React.useState<Array<Module>>([]);
  const [displayWidgetPanelRight, setDisplayWidgetPanelRight] = React.useState<boolean>(false);
  const [openDialogDelete, setOpenDialogDelete] = React.useState<boolean>(false);
  const [gridLoading, setGridLoading] = React.useState<boolean>(true);

  const viewsInputFactory = (
    handleInputChange: (input_id: string, value: Array<string> | null) => void
  ): JSX.Element => {
    return (
      <TextListInput
        input_label="Add View"
        list_label="Views"
        initial_value={actionRow?.views ?? []}
        handleListChange={(views: Array<string>) => {
          handleInputChange('views', views);
        }}
      />
    );
  };

  // Handler functions
  const handleCreateClick = (): void => {
    setActionRow(null);
    setIsCreateWidget(true);
    setDisplayWidgetPanelRight(true);
  };

  const handleEditClick = (module: Module): void => {
    setActionRow(module);
    setIsCreateWidget(false);
    setDisplayWidgetPanelRight(true);
  };

  const handleDeleteClick = async (row: Module): Promise<void> => {
    try {
      setActionRow(row);
      setOpenDialogDelete(true);
    } catch (err: unknown) {
      const tracking_id: string = uuidv4();
      log(
        'error',
        new MetadataError(
          err instanceof Error
            ? err.message
            : 'Error: PanelModules failed on an unknown error while calling handleDeleteClick.',
          {
            row: row
          },
          tracking_id
        )
      );
      setFeedbackData({
        message: `Unable to delete module. Tracking ID: ${tracking_id}`,
        state: true,
        type: 'error'
      });
    }
  };

  const handleUpdateRows = (rows_data: Array<Module>): void => {
    setModuleRows(rows_data);
  };

  const handleCloseDeleteModal = (): void => {
    setOpenDialogDelete(false);
  };

  const columns: Array<GridColDef> = [
    {
      field: 'name',
      headerName: 'Name',
      headerAlign: 'left',
      align: 'left',
      flex: 1
    },
    {
      field: 'description',
      headerName: 'Description',
      headerAlign: 'left',
      align: 'left',
      renderCell: (params: GridRenderCellParams) => (
        <Box sx={{ py: 2, maxHeight: '100px' }}>
          <Typography
            sx={{
              textAlign: 'left',
              fontSize: '0.9rem',
              whiteSpace: 'normal',
              wordWrap: 'break-word',
              maxHeight: '90px',
              overflow: 'hidden',
              textOverflow: 'ellipsis'
            }}
          >
            {params.value}
          </Typography>
        </Box>
      ),
      flex: 1
    },
    {
      field: 'views',
      headerName: 'Views',
      headerAlign: 'left',
      align: 'left',
      valueFormatter: (params: GridValueFormatterParams) => {
        return params.value.join(', ');
      },
      flex: 1
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: '',
      headerAlign: 'right',
      align: 'right',
      hideable: false,
      flex: 1,
      getActions: ({ row }) => {
        return [
          <>
            <GridActionsCellItem
              key={1}
              icon={
                <Tooltip title="Edit Module">
                  <EditIcon color={!row.locked ? 'primary' : 'action'} />
                </Tooltip>
              }
              size="large"
              label="Edit"
              sx={{
                color: 'primary.main'
              }}
              onClick={() => handleEditClick(row)}
              disabled={row.locked}
            />
            <GridActionsCellItem
              key={2}
              icon={
                <Tooltip title="Delete Module">
                  <DeleteIcon color="primary" />
                </Tooltip>
              }
              size="large"
              label="Delete"
              sx={{
                color: 'primary.main'
              }}
              onClick={() => handleDeleteClick(row)}
            />
          </>
        ];
      }
    }
  ];

  const create_inputs: readonly CreateInput[] = [
    {
      id: 'name',
      type: 'text',
      label: 'Name'
    },
    {
      id: 'description',
      type: 'text',
      label: 'Description'
    },
    {
      id: 'views',
      type: 'node',
      label: 'Views',
      inputNodeFactory: viewsInputFactory
    }
  ];

  const edit_inputs: EditInput[] = [
    {
      id: 'name',
      type: 'text',
      label: 'Name'
    },
    {
      id: 'description',
      type: 'text',
      label: 'Description'
    },
    {
      id: 'views',
      type: 'node',
      label: 'Views',
      inputNodeFactory: viewsInputFactory
    }
  ];

  // Row functions
  const fetchRows = async (): Promise<void> => {
    setGridLoading(true);
    try {
      // Load rows from database.
      const modules: Array<Module> = await getModules();
      // Load rows to memory.
      setModuleRows(modules);
    } catch (err: unknown) {
      const tracking_id: string = uuidv4();
      log(
        'error',
        new MetadataError(
          err instanceof Error
            ? err.message
            : 'Error: PanelModules failed on an unknown error while calling fetchRows.',
          {},
          tracking_id
        )
      );
      setFeedbackData({
        message: `Unable to fetch modules. Tracking ID: ${tracking_id}`,
        state: true,
        type: 'error'
      });
      return;
    } finally {
      setGridLoading(false);
    }
  };

  React.useEffect(() => {
    try {
      (async () => {
        setModuleRows([]);
        fetchRows();
      })().catch((error) => {
        throw new Error(error);
      });
    } catch (err: unknown) {
      const tracking_id: string = uuidv4();
      log(
        'error',
        new MetadataError(
          err instanceof Error
            ? err.message
            : 'Error: PanelModules failed on an unknown error while initialising.',
          null,
          tracking_id
        )
      );
      setFeedbackData({
        message: `An error occurred. Tracking ID: ${tracking_id}`,
        state: true,
        type: 'error'
      });
    }
    return;
  }, []);

  const createRow = async (
    name: string,
    description: string,
    views: Array<string>
  ): Promise<void> => {
    try {
      const module_data: ModuleData = {
        deleted: null,
        name: name,
        description: description,
        views: views
      };
      const new_module: DocumentReference = await createModule(module_data);
      if (new_module) {
        handleUpdateRows([
          {
            id: new_module.id,
            ...module_data
          },
          ...moduleRows
        ]);
      }
    } catch (err: unknown) {
      const tracking_id: string = uuidv4();
      log(
        'error',
        new MetadataError(
          err instanceof Error
            ? err.message
            : 'Error: PanelModules failed on an unknown error while calling createRow.',
          {
            name: name,
            description: description,
            views: views
          },
          tracking_id
        )
      );
      setFeedbackData({
        message: `Unable to create module. Tracking ID: ${tracking_id}`,
        state: true,
        type: 'error'
      });
    }
  };

  const updateRow = async (updated_row: Module, original_data: Module): Promise<void> => {
    try {
      await updateModule(updated_row).then(() => {
        handleUpdateRows(
          moduleRows.map((row: Module) => (updated_row.id === row.id ? { ...updated_row } : row))
        );
      });
    } catch (err: unknown) {
      const tracking_id: string = uuidv4();
      log(
        'error',
        new MetadataError(
          err instanceof Error
            ? err.message
            : 'Error: PanelModule failed on an unknown error while calling updateRow.',
          {
            updated_row: updated_row,
            original_data: original_data
          },
          tracking_id
        )
      );
      setFeedbackData({
        message: `Unable to update module. Tracking ID: ${tracking_id}`,
        state: true,
        type: 'error'
      });
    }
  };

  const deleteRow = async (module: Module): Promise<void> => {
    try {
      await deleteModule(module.id).then(() => {
        setModuleRows(
          moduleRows.filter((row: Module) => {
            return row.id !== module.id;
          })
        );
      });
    } catch (err: unknown) {
      const tracking_id: string = uuidv4();
      log(
        'error',
        new MetadataError(
          err instanceof Error
            ? err.message
            : 'Error: PanelModules failed on an unknown error while calling deleteRow.',
          {
            module: module
          },
          tracking_id
        )
      );
      setFeedbackData({
        message: `Unable to remove module. Tracking ID: ${tracking_id}`,
        state: true,
        type: 'error'
      });
    } finally {
      setOpenDialogDelete(false);
    }
  };

  return (
    <>
      {/* Delete Confirmation Modal */}
      <DeleteConfirmModal
        open={openDialogDelete}
        allow_delete={true}
        handleCloseDeleteModal={handleCloseDeleteModal}
        delete_label={actionRow && actionRow.name}
        handleDelete={actionRow && (() => deleteRow(actionRow))}
        allow_archive={false}
      />

      {/* Side Widget Panel */}
      <Drawer
        anchor={'right'}
        open={displayWidgetPanelRight}
        onClose={() => setDisplayWidgetPanelRight(false)}
        PaperProps={{ style: { width: '30%', padding: '1.5rem' } }}
      >
        {/* Create / Edit Widget */}
        {isCreateWidget ? (
          <>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                flexWrap: 'wrap'
              }}
            >
              <SegmentIcon sx={{ marginRight: '2rem' }} fontSize="large" />
              <Typography variant="h5" color="inherit" align="left">
                Add {`${module_label.one}`}
              </Typography>
            </Box>
            <ConfigAddWidget
              create_label={module_label.one}
              create_icon={<SegmentIcon sx={{ marginRight: '2rem' }} fontSize="large" />}
              create_function_inputs={create_inputs}
              handleClose={() => setDisplayWidgetPanelRight(false)}
              createFunction={(name: string, description: string, views: Array<string>) =>
                createRow(name, description, views)
              }
              hide_configured_entities={true}
              hide_title={true}
            />
          </>
        ) : (
          <ConfigEditWidget
            edit_label="Module"
            edit_icon={<SegmentIcon sx={{ marginRight: '2rem' }} fontSize="large" />}
            handleClose={() => setDisplayWidgetPanelRight(false)}
            edit_entity={actionRow}
            edit_function_inputs={edit_inputs}
            handleEditInput={(input_id: string, value: unknown) =>
              actionRow && setActionRow({ ...actionRow, [input_id]: value })
            }
            confirmEditFunction={(updated_module: Module, original_module: Module) =>
              updateRow(updated_module, original_module)
            }
          />
        )}
      </Drawer>

      {/* Interactive Data Table */}
      <DataGrid
        autoHeight
        rowHeight={75}
        initialState={{
          pagination: {
            paginationModel: { pageSize: 10, page: 0 }
          },
          sorting: {
            sortModel: [{ field: 'modified', sort: 'desc' }]
          }
        }}
        hideFooter={moduleRows.length > 1 ? false : true}
        columnHeaderHeight={moduleRows.length < 1 ? 0 : 56}
        pageSizeOptions={[10, 25, 50, 100]}
        loading={gridLoading}
        rows={moduleRows}
        columns={columns}
        disableRowSelectionOnClick
        slots={{
          toolbar: PanelModulesToolbar,
          noRowsOverlay: PanelModulesNoRows,
          loadingOverlay: PanelModulesLoading
        }}
        slotProps={{ toolbar: { handleCreate: handleCreateClick } }}
        sx={{ '&, [class^=MuiDataGrid]': { border: 'none' }, width: '100%' }}
      />
    </>
  );
};

export default PanelModules;
